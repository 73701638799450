import IsnProfilePlaceholder from 'assets/images/Placeholders/isn/isnProfilePlaceholder.png';
import SignProfilePlaceholder from 'assets/images/Placeholders/custom-branding/signProfilePlaceholder.png';
import IsnUserProfilePlaceholder from 'assets/images/Placeholders/isn/isnUserProfilePlaceholder.png';
import SignUserProfilePlaceholder from 'assets/images/Placeholders/custom-branding/signUserProfilePlaceholder.svg';
import IsnCoverPlaceholder from 'assets/images/Placeholders/isn/isnCoverPlaceholder.png';
import SignCoverPlaceholder from 'assets/images/Placeholders/custom-branding/signCoverPlaceholder.png';
import IsnListProfilePlaceholder from 'assets/images/Placeholders/isn/isnListProfilePlaceholder.png';
import SignListProfilePlaceholder from 'assets/images/Placeholders/custom-branding/signListProfilePlaceholder.png';
import IsnModuleProfilePlaceholder from 'assets/images/Placeholders/isn/isnModuleProfilePlaceholder.png';
import SignModuleProfilePlaceholder from 'assets/images/Placeholders/custom-branding/signModuleProfilePlaceholder.png';
import IsnModuleCoverPlaceholder from 'assets/images/Placeholders/isn/isnModuleCoverPlaceholder.png';
import SignModuleCoverPlaceholder from 'assets/images/Placeholders/custom-branding/signModuleCoverPlaceholder.png';
import IsnModalCoverPlaceholder from 'assets/images/Placeholders/isn/isnModalCoverPlaceholder.png';
import SignModalCoverPlaceholder from 'assets/images/Placeholders/custom-branding/signModalCoverPlaceholder.png';
import IsnOrganizationProfilePlaceholder from 'assets/images/Placeholders/isn/isnOrganizationProfilePlaceholder.png';
import SignOrganizationProfilePlaceholder from 'assets/images/Placeholders/custom-branding/signOrganizationProfilePlaceholder.png';
import IsnMessageArchivePlaceholder from 'assets/images/Placeholders/isn/isnMessageArchivePlaceholder.png';
import SignMessageArchivePlaceholder from 'assets/images/Placeholders/custom-branding/signMessageArchivePlaceholder.svg';
import customBrandingListPlaceholder from 'assets/images/Placeholders/custom-branding/customBrandingListPlaceholder.png';

export const brandNames: any = {
  isn_admin: "Indigenous Sentinels Network",
  sign_admin: "Seacoast Indigenous Guardians Network",
};

export const getCurrentBranding = () => {
  return process.env.REACT_APP_BRAND_NAME ?? "";
};

export const invitationAppNames = {
  ISN: { label: "ISN", value: "isn" },
  SIGN: { label: "SIGN", value: "sign" },
  SKIPPER_SCIENCE: { label: "SKIPPER SCIENCE", value: "skipper_science" },
};

export const programInvitationApps = [
  { value: "isn", label: "ISN" },
  { value: "sign", label: "SIGN" },
  { value: "skipper_science", label: "SKIPPER SCIENCE" },
];

export const invitationApps = [
  { value: "isn", label: "ISN" },
  { value: "sign", label: "SIGN" },
];

export const getAppName = (isSuperAdmin: boolean) => {
  return isSuperAdmin
    ? { value: "", label: "" }
    : {
        value: process.env.REACT_APP_BRAND_NAME,
        label: process.env.REACT_APP_BRAND_NAME,
      };
};

type BrandTypes =
  | 'profile'
  | 'userProfile'
  | 'listingProfile'
  | 'moduleProfile'
  | 'organizationProfile'
  | 'cover'
  | 'modalCover'
  | 'moduleCover'
  | 'messageArchive';
type ProfileKeys = 'isn_admin' | 'sign_admin' | 'custom_branding';

const brandPlaceholders: Record<BrandTypes, Record<ProfileKeys, string>> = {
  profile: {
    isn_admin: IsnProfilePlaceholder,
    sign_admin: SignProfilePlaceholder,
    custom_branding: customBrandingListPlaceholder
  },
  userProfile: {
    isn_admin: IsnUserProfilePlaceholder,
    sign_admin: SignUserProfilePlaceholder,
    custom_branding: customBrandingListPlaceholder
  },

  listingProfile: {
    isn_admin: IsnListProfilePlaceholder,
    sign_admin: SignListProfilePlaceholder,
    custom_branding: customBrandingListPlaceholder
  },
  moduleProfile: {
    isn_admin: IsnModuleProfilePlaceholder,
    sign_admin: SignModuleProfilePlaceholder,
    custom_branding: customBrandingListPlaceholder
  },

  organizationProfile: {
    isn_admin: IsnOrganizationProfilePlaceholder,
    sign_admin: SignOrganizationProfilePlaceholder,
    custom_branding: customBrandingListPlaceholder
  },

  cover: {
    isn_admin: IsnCoverPlaceholder,
    sign_admin: SignCoverPlaceholder,
    custom_branding: customBrandingListPlaceholder
  },
  moduleCover: {
    isn_admin: IsnModuleCoverPlaceholder,
    sign_admin: SignModuleCoverPlaceholder,
    custom_branding: customBrandingListPlaceholder
  },
  modalCover: {
    isn_admin: IsnModalCoverPlaceholder,
    sign_admin: SignModalCoverPlaceholder,
    custom_branding: customBrandingListPlaceholder
  },

  messageArchive: {
    isn_admin: IsnMessageArchivePlaceholder,
    sign_admin: SignMessageArchivePlaceholder,
    custom_branding: customBrandingListPlaceholder
  },
};

// export const getBrandName = (key: string, searchString: string) => {
//   return {
//     url: `${baseURL}/common/dropdown/${key}?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&search_string=${searchString}`,
//   };
// };

export const getBrandName = (key: string, searchString: string) => {
  return {
    url: `/admin/users/drop-down?search_string=${searchString}&entity=${key}`,
  };
};
