import React from "react";
import coverImage from "assets/images/Auth/img-placeholder.png";
import darkCoverImage from "assets/images/Auth/dark-img-placeholder.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import "./Gridcard.scss";
import { IfetchPrograms } from "store/programs/initialState";
import useDateFormatter from "hooks/formatDateWithPattern";
import { DarkMode } from "store/darkMode/initialState";
import useActions from "hooks/useActions";
import PopOverMenu from "view/components/PopOverMenu";
import { PerimeterIcon } from "assets/icons/HeroIcons";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import ellipsize from "ellipsize";
import usePermissions from "hooks/usePermissions";
import useCustomBranding from "hooks/useCustomBranding";

export const GridCard = () => {
  const { data } = useSelector<RootState, IfetchPrograms>(
    (state) => state?.allprograms
  );
  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state.darkMode
  );

  const { programs } = usePermissions();
  const { programsActions } = useActions();
  const { getBrandPlaceholder } = useCustomBranding();
  const { formatDate } = useDateFormatter();

  const getProgramsActions = (data: any) => {
    const modalData = {
      status: data?.programName?.status,
      id: data?.programName?.programId,
      name: data?.programName?.programName,
      programAdmins: [],
      profileImage: data.programName?.image?.file?.thumb,
      path: "directory",
      openAccess: data?.openAccess,
    };

    return programsActions(modalData);
  };
  return (
    <div className="grid w-full gap-4 lg:grid-cols-2 xl:grid-cols-3">
      {data?.programs?.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className="flex rounded-lg shadow-sm bg-bgWhite dark:bg-secondaryLight hover:shadow-lg "
          >
            <img
              src={
                item?.programName?.image?.file?.thumb
                  ? item?.programName?.image?.file?.thumb
                  : getBrandPlaceholder("moduleProfile")
              }
              className="object-cover w-[88px] rounded-lg rounded-r-none"
              alt={item?.programName}
            />

            <div className="flex flex-col items-start justify-start flex-grow px-3 py-3 shadow-sm hover:shadow-lg">
              <Link
                to={
                  programs.canViewProgram
                    ? `/programs/${item.programName.programId}/profile`
                    : ""
                }
                className={`${programs.canViewProgram ? "cursor-pointer" : "cursor-default"} relative flex items-start h-[50px] w-full justify-start`}
              >
                <p className="flex-grow break-all text-base font-medium text-left capitalize text-secondary dark:text-textMain dark:hover:text-primary">
                  {ellipsize(item?.programName?.programName, 30)}
                </p>
                <div
                  className={`flex justify-center items-center flex-grow-0 flex-shrink-0 px-1 rounded ${
                    item?.type === "Public"
                      ? "bg-[#e3f8e6] dark:bg-primary_50"
                      : "bg-primaryExtraLight dark:bg-primary_40"
                  }`}
                >
                  <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 px-1 ">
                    <p
                      className={`flex-grow-0 flex-shrink-0 text-xs text-center font-Overpass ${
                        item?.type === "Public"
                          ? "text-lightGreen"
                          : "text-primaryDark"
                      } dark:text-primaryExtraLight`}
                    >
                      {item?.type}
                    </p>
                  </div>
                </div>
              </Link>
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1 pt-1">
                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[3px] ">
                  <p className="flex-grow-0 flex-shrink-0 text-xs text-left text-textMid dark:text-textMain">
                    {item?.members}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-xs text-left text-textMid dark:text-textMain">
                    Members
                  </p>
                </div>
                <svg
                  width={8}
                  height={8}
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="relative flex-grow-0 flex-shrink-0 w-2 h-2"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3 4C3 3.44772 3.44772 3 4 3C4.55229 3 5 3.44772 5 4C5 4.55228 4.55229 5 4 5C3.44772 5 3 4.55228 3 4Z"
                    fill="#2C3236"
                    className="dark:fill-caption"
                  />
                </svg>
                <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[3px] py-1">
                  <p className="flex-grow-0 flex-shrink-0 text-xs text-left text-textMid dark:text-textMain">
                    {item?.numberOfObservations}
                  </p>
                  <p className="flex-grow-0 flex-shrink-0 text-xs text-left text-textMid dark:text-textMain">
                    Observations
                  </p>
                </div>
              </div>
              <div className="flex  flex-row justify-between items-center w-full relative gap-0.5 ">
                <div className="relative flex flex-wrap items-center justify-start w-full gap-2 pr-2">
                  <p className="text-xs text-left text-[#54595f] dark:text-textMain">
                    {item?.programCode}
                  </p>
                  <p className="text-xs text-left text-textMidLight">
                    {formatDate(item?.timeframe)}
                  </p>
                </div>
                <div className="">
                  {getProgramsActions(item).some(
                    (action: any) => action.isVisible === true
                  ) ? (
                    <PopOverMenu
                      style={"0px"}
                      icon={<PerimeterIcon className={` `} />}
                    >
                      <ActionDropDownMenu
                        data={item}
                        actions={getProgramsActions(item)}
                      />
                    </PopOverMenu>
                  ) : (
                    <PerimeterIcon className="" />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
