import { WarningIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { openCreatePasswordModalAction } from "store/modals/reducer.actions";
import { UserProfileState } from "store/userProfile/initialState";
import Button from "view/components/Button";

interface IProps {
  helperText: any;
  icon?: any;
}

export const CreateAnAccountBtn: React.FC<IProps> = ({ helperText, icon }) => {
  const dispatch = useDispatch();
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const { users } = usePermissions();
  const InviteUser = () => {
    dispatch(openCreatePasswordModalAction(userProfile));
  };

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-12 pt-4 pb-12">
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5">
        {icon ? (
          <>
            <div className="flex items-center">
              <WarningIcon className="h-[16px] w-[16px]" />
              <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left text-textMidLight ml-2 dark:text-textMain">
                {helperText}
              </p>
            </div>
          </>
        ) : (
          <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left text-textMidLight dark:text-textMain">
            {" "}
            Reminder: {helperText}
          </p>
        )}
      </div>
      <>
        {users.canInviteUsers && (
          <Button
            type="button"
            disabled={userProfile.status === "deleted"}
            text=" Create Account"
            filledColor="primary"
            outlinedColor="primary"
            textColor="textWhite"
            className="px-5 py-2"
            width="35"
            height="13"
            fontStyle="font-semibold"
            variant="outlined"
            onClick={InviteUser}
          />
        )}
      </>
    </div>
  );
};
