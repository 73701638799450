export interface IformDetails {
  createdBy: {
    id: number;
    name: string;
    personImage: {
      original: string;
      thumb: string;
    };
  };
  dapId: number | null;
  dateAdded: string;
  dateUpdated: string;
  description: string;
  id: number;
  name: string;
  observations: number;
  picture: {
    original: string;
    thumb: string;
  };
  pictureId: number;
  program: {
    id: number;
    imageId: string;
    name: string;
    profileImage: {
      original: string;
      thumb: string;
    };
    programStatus: string | undefined;
  };
  formFields: any;
  protocolId: number | null;
  species: number;
  status: string;
  templateCount: number;
  dynamicFormToken: string;
  allowAnonObs: boolean;
  offlineDisabled: boolean;
}
const formDetails: IformDetails = {
  createdBy: {
    id: 0,
    name: "",
    personImage: {
      original: "",
      thumb: "",
    },
  },
  dapId: null,
  dateAdded: "",
  dateUpdated: "",
  description: "",
  id: 0,
  name: "",
  observations: 0,
  picture: {
    original: "",
    thumb: "",
  },
  program: {
    id: 0,
    imageId: "",
    name: "",
    profileImage: {
      original: "",
      thumb: "",
    },
    programStatus: "",
  },
  protocolId: null,
  species: 0,
  status: "",
  templateCount: 0,
  pictureId: 0,
  formFields: {},
  dynamicFormToken: "",
  allowAnonObs: false,
  offlineDisabled: false
};

export interface IfetchFormDatails {
  formDetails: IformDetails;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

const initialState: IfetchFormDatails = {
  formDetails: formDetails,
  message: "",
  stateIs: "Pending",
};

export { initialState };
