import { FieldArray } from "formik";
import React from "react";
import RegionFilterItem from "../RegionFilterItem";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Stepper } from "store/addReportStepper/initialState";
import useCustomBranding from "hooks/useCustomBranding";

interface RegionFilterFieldsArrayProps {
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  handleBlur: any;
  isSubmitting: boolean;
  features: any[];
}

export const RegionFilterFieldsArray: React.FC<
  RegionFilterFieldsArrayProps
> = ({
  errors,
  values,
  touched,
  handleBlur,
  setFieldValue,
  isSubmitting,
  features,
}) => {
  const { regions } = useSelector<RootState, Stepper>(
    (state) => state?.reportStepper
  );
    const {primaryColor} = useCustomBranding();
  return (
    <FieldArray name="regionFilter">
      {({ push, remove }) => {
        return (
          <div className="pt-3">
            <div className="">
              <div className="mt-1 overflow-y-auto">
                {values.regionFilter?.map((region: any, index: number) => {
                  return (
                    <RegionFilterItem
                      index={index}
                      errors={errors}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      values={values}
                      removeFilterItem={() => remove(index)}
                      columns={regions}
                    />
                  );
                })}
              </div>
              <button
                disabled={isSubmitting}
                onClick={() =>
                  push({
                    column: regions[0],
                    condition: "inside_the_drawn_area",
                    operator: {
                      name: "AND",
                      value: "and",
                    },
                    regions: features,
                  })
                }
                type="button"
                className="flex items-center justify-center gap-1 rounded-3xl"
              >
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 "
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 5C12.5523 5 13 5.44772 13 6V11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H13V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V13L6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11L11 11V6C11 5.44772 11.4477 5 12 5Z"
                    fill={primaryColor}
                  />
                </svg>
                <div className="flex justify-center items-center    pt-1.5 pb-2">
                  <p className="text-sm font-semibold text-center text-primary">
                    Add another one
                  </p>
                </div>
              </button>
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {!Array.isArray(errors.regionFilter) && (
                <p className="flex-grow capitalize w-[1/2] text-xs text-left text-accent_1Dark">
                  {errors.regionFilter}
                </p>
              )}
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};
