import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import useDateFormatter from "hooks/formatDateWithPattern";
import useCustomBranding from "hooks/useCustomBranding";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();
  const { getBrandPlaceholder } = useCustomBranding();

  if (columnDef.id === "image") {
    return (
      <div className="relative flex items-center justify-start">
        <img
          src={cellValue || getBrandPlaceholder("moduleProfile")}
          alt=""
          height="48px"
          width="64px"
          className="object-cover"
          style={{ flexShrink: 0 }}
        />
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "commonName") {
    return (
      <div
        style={{ flexWrap: "wrap" }}
        className=" flex flex-col w-full justify-start items-start relative gap-0.5"
      >
        <p className="text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark">
          {`${cellValue?.name}`}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "type") {
    return (
      <div
        style={{ flexWrap: "wrap" }}
        className=" flex flex-col w-full justify-start items-start relative gap-0.5"
      >
        <p className="text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark">
          {`${cellValue?.name}`}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "dateAdded") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p
          className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
        >
          {ellipsize(`${cellValue}`, 40)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
