import * as React from "react";
import type { SVGProps } from "react";
interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
}

export const DocumentCircleIcon =  ({
    fill = "#005C89",
    ...restProps
  }: IconProps) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="12.5" r="12.5" fill={fill} />
    <path
      d="M9.66667 12.5H13.8333M9.66667 14.0714H15.5M9.66667 15.6429H12.1667M18 16.4286V10.9286L13.8333 7H9.66667C9.22464 7 8.80072 7.16556 8.48816 7.46026C8.17559 7.75496 8 8.15466 8 8.57143V16.4286C8 16.8453 8.17559 17.245 8.48816 17.5397C8.80072 17.8344 9.22464 18 9.66667 18H16.3333C16.7754 18 17.1993 17.8344 17.5118 17.5397C17.8244 17.245 18 16.8453 18 16.4286Z"
      stroke="white"
      stroke-width="0.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 7V9.4C14 9.82435 14.1686 10.2313 14.4686 10.5314C14.7687 10.8314 15.1757 11 15.6 11H18"
      stroke="white"
      stroke-width="0.7"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default DocumentCircleIcon;
