import React, { useEffect, useState } from "react";
import MessagesContacts from "./MessagesContacts";
import DisplayedMessages from "./DisplayedMessages";
import { RootState } from "store";
import { IfetchRecipients } from "store/messages/initialState";
import { useSelector } from "react-redux";
import { filtersInitialState } from "store/filters/initialState";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { fetchRecipientsAction } from "store/messages";
import { Toasts } from "view/components/Toasts";
import apiLibrary from "services/api";
import {
  messagesAppIdAction,
  messagesFilterReset,
  messagesNewChatAction,
  messagesStatusAction,
} from "store/filters";
import { resetChatDetails } from "store/messagesDetail/reducer.actions";
import {
  appendNewMessageInThreads,
  resetUnreadCounter,
} from "store/messages/reducer.actions";
import { cloneDeep } from "lodash";
import { useLocation } from "react-router";
import Head from "view/components/Head";
import { Breadcrumb } from "Components/Messages/BreadCrumb";
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";
import AppButtons from "Components/Messages/AppButtons";
import { getCurrentBranding } from "utils/customBranding";
import { getAppCode, getAppName } from "hooks/useCustomBranding/constants";

const generateDynamicMessagesLinks = (organizationProfile: any = null) => {
  const links = [];

  if (organizationProfile) {
    links.push(
      { path: `/organizations/list`, label: `Organizations` },
      {
        path: `/organizations/${organizationProfile?.id}/profile`,
        label: `${organizationProfile?.name}`,
      }
    );
  }

  links.push({ path: `/messages`, label: `Messages` });

  return links;
};

export const Messages = () => {
  const { state } = useLocation();
  const user = state && state.user;
  const organizationProfile = state && state.organizationProfile;
  const appName = getAppName();
  // STATES
  const [selectedContact, setSelectedContact] = useState<any | null>(null);
  const { messageAppIdFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const currentBrand = getCurrentBranding();
  const dynamicMessagesLinks =
    generateDynamicMessagesLinks(organizationProfile);

  // DISPATCH HOOK
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  // SELECTORS
  const { messageStatusFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { messageNewChatFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { data } = useSelector<RootState, IfetchRecipients>(
    (state) => state?.allRecipients
  );

  useEffect(() => {
    if (user && user.userId && messageAppIdFilter?.appId) {
      createNewConversation(user.userId);
      if (organizationProfile) {
        dynamicMessagesLinks.push(
          {
            path: `/organizations/${organizationProfile?.id}/profile`,
            label: `${organizationProfile?.name}`,
          },
          { path: `/organizations`, label: `Organization` }
        );
      }
    }
  }, [state, messageAppIdFilter?.appId]);

  // If Id exists, but conversation against that id does not exist
  useEffect(() => {
    if (selectedContact?.id) {
      const recipient = data?.recipients?.find(
        (rec) => rec.id === selectedContact?.id
      );
      if (!recipient) {
        setSelectedContact(null);
      } else {
        setSelectedContact(recipient);
      }
    }
  }, [data?.recipients]);

  useEffect(() => {
    //  When contact is selected, reset unread counter
    if (selectedContact?.unreadCounter) {
      dispatch(
        resetUnreadCounter({
          conversationId: selectedContact?.id,
        })
      );
    }

    // Scroll to selected contact
    if (selectedContact) {
      scrollIntoView(selectedContact.id);
    }
  }, [selectedContact]);

  // Scroll to that contact
  const scrollIntoView = (id: number) => {
    const recipients = cloneDeep(data?.recipients);
    const index = recipients.findIndex((msg: any) => msg.id == id);

    const parentContainer = document.querySelector(".conversation-list");
    const selectedContactDiv = document.getElementById(`conversation${index}`);

    if (parentContainer && selectedContactDiv) {
      parentContainer.scrollTo({
        top: selectedContactDiv.offsetTop - 300,
        behavior: "smooth",
      });
    }
  };

  const createNewConversation = async (userId: number) => {
    const recipients = cloneDeep(data?.recipients);
    try {
      const userData = {
        user_id: Number(userId),
        app_name: messageAppIdFilter?.appId,
      };
      const { data } = await apiLibrary.Messages.createConversation(userData);
      const existingConversation = recipients?.find(
        (msg: any) => msg?.id == data?.id
      );

      if (existingConversation) {
        setSelectedContact(existingConversation);
      } else {
        if (data.archived) {
          dispatch(messagesStatusAction(true));
        } else if (!data.archived && messageStatusFilter.archived) {
          dispatch(messagesStatusAction(false));
        } else {
          dispatch(appendNewMessageInThreads({ data }));
        }
        setSelectedContact(data);
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      console.log("An error occurred while fetching contact persons:", error);
    }
  };

  const handleSelectedOption = async (value: any) => {
    dispatch(resetChatDetails());
    dispatch(messagesFilterReset());
    setSelectedContact(null);
    dispatch(messagesNewChatAction(false));
    if (value?.value) {
      await createNewConversation(value?.value);
    }
  };

  const handleSelectedContact = async (contact: any) => {
    if (contact?.id === selectedContact?.id) {
      return;
    }
    dispatch(resetChatDetails());
    dispatch(messagesFilterReset());
    if (messageNewChatFilter?.newChat) {
      setSelectedContact(null);
      await createNewConversation(contact?.id);
      dispatch(messagesNewChatAction(false));
    } else {
      setSelectedContact(contact);
    }
  };

  // Fetch messages on message status filter change
  useEffect(() => {
    messageAppIdFilter?.appId &&
      handleFetchRecipients(messageAppIdFilter?.appId);
  }, [messageStatusFilter, messageAppIdFilter?.appId]);

  useEffect(() => {
    dispatch(messagesAppIdAction(appName));
    return () => {
      dispatch(messagesFilterReset());
    };
  }, []);

  const handleFetchRecipients = (appId: string) => {
    dispatch(fetchRecipientsAction(appId));
    dispatch(messagesAppIdAction(appId));
  };

  const handleOnClick = (appType: string) => {
    handleFetchRecipients(appType);
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-start items-center pt-5">
        {messageNewChatFilter?.newChat ? (
          <div className="flex items-center gap-3">
            <button
              onClick={() => {
                dispatch(messagesNewChatAction(false));
              }}
            >
              <ArrowLeftIcon height={22} width={22} fill={"#9B9FA5"} />
            </button>
            <p className="text-sm dark:text-textMain ">New Chat</p>
          </div>
        ) : (
          <Breadcrumb links={dynamicMessagesLinks} />
        )}
      </div>

      <div className="grid grid-cols-12 pt-4">
        <Head title="Messages" />
        <div className="col-span-3 bg-bgWhite dark:bg-bgtetriary">
          {data?.appsInfo?.length > 0 && !messageNewChatFilter?.newChat && (
            <div className="overflow-x-auto">
              <AppButtons
                appInfo={data?.appsInfo}
                handleOnClick={handleOnClick}
              />
            </div>
          )}

          <MessagesContacts
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            handleSelectedOption={handleSelectedOption}
            handleSelectedContact={handleSelectedContact}
          />
        </div>
        <div className="col-span-9">
          <DisplayedMessages selectedContact={selectedContact} />
        </div>
      </div>
    </div>
  );
};

export default Messages;
