import React, { ReactNode, useEffect, useState } from "react";

const AuthLayout: React.FC<{
  backgroundImage: string;
  children: ReactNode;
}> = ({ backgroundImage, children }) => {
  return (
    <div
      className="flex justify-between relative  dark:bg-secondaryLight w-full h-screen "
      style={{
      backgroundImage: `url('${backgroundImage}')`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      }}
    >
            <div className="w-[464px] xl:w-[509px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-start items-center h-full bg-bgWhite opacity-90  dark:bg-secondaryLight ">
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
