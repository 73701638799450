import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import axios from "axios";
import { baseURL } from "config";
import React, { useEffect, useState } from "react";
import { apiClient } from "services/api/config";
import store from "store";
import { SurveyQuestionElementBase } from "survey-react-ui";
import { MaterialUiSelect } from "view/components/InputFields/Select";

export const getRegionPickerProperties = [
  {
    name: "_id",
    category: "general",
    visibleIndex: 3,
  },
];

export default class SurveyRegionPickerQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
    this.state = {
      value: props.question?.value || "",
      options: [],
    };
  }

  get question() {
    return this.props.question;
  }

  // ✅ Fetch options & pass to SurveyJS as choices
  async fetchOptions() {
    try {
      const pathSegments = window.location.pathname.split("/");
      const programId =
        store.getState()?.formSurveyJsDetails?.formDetails?.programId;

      if (!programId) {
        console.error("formId is missing from the URL");
        return;
      }

      const { data } = await axios.get(
        `${baseURL}/common/dropdown/regions?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`
      );
      console.log("dataaaaaa", data);
      const formattedOptions = data;

      this.setState({ options: formattedOptions });

      // ✅ Pass choices to SurveyJS
      this.question.choices = formattedOptions;

      // ✅ Restore previously selected value
      if (this.question.value) {
        this.setState({ value: this.question.value });
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  }

  componentDidMount() {
    this.fetchOptions();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.question?.value !== this.props.question?.value) {
      this.setState({ value: this.props.question.value });
    }
  }

  // ✅ Handle selection change
  handleChange = (newValue: any) => {
    this.setState({ value: newValue });
    this.question.value = newValue; // ✅ Ensure value is stored in SurveyJS
  };

  renderElement() {
    const { options, value } = this.state;

    const isDesignMode = this.question.survey?._isDesignMode; // ✅ Detects preview mode

    return (
      <FormControl fullWidth disabled={isDesignMode}>
        <Select
          className="border-2 border-[#bdc0c4]"
          value={value}
          onChange={(event) => this.handleChange(event.target.value)}
          displayEmpty
          MenuProps={{
            disablePortal: true,
            PaperProps: {
              container: document.body,
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PopoverClasses: { root: "z-[9999]" }, // Ensures it's above the modal
          }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem
            value=""
            className="text-[#bdc0c4] font-medium pointer-events-none opacity-50"
          >
            Regions
          </MenuItem>
          {options.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}
