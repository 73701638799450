import React, { useEffect, useLayoutEffect } from "react";
// Components
import { Header, Tabs, Pagination, Table } from "Components/Brands";
import { TailSpin } from "react-loader-spinner";
// Hooks
import { BrandsTable } from "hooks/useTable";
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { filtersInitialState } from "store/filters/initialState";
import { Filters } from "Components/Brands/Filters";
import NoRecordFound from "view/components/NoRecordFound";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";
import { IfetchBrands } from "store/brands/initialState";
import { fetchBrandsAction } from "store/brands";
import { setBrandsFiltersAction } from "store/filters/reducer.actions";
import useCustomBranding from "hooks/useCustomBranding";
import { changeColumnsOrderBrandsDirectory } from "store/directoriesOrders/reducer.actions";

export const Brands = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data, stateIs } = useSelector<RootState, IfetchBrands>(
    (state) => state?.allBrands
  );
  useLayoutEffect(() => {
    dispatch(setBrandsFiltersAction());
  }, []);

  const { brandsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setSorting,
    sorting,
  } = BrandsTable(data?.brands);
  const { primaryColor } = useCustomBranding();

  useEffect(() => {
    dispatch(fetchBrandsAction());
    dispatch(changeColumnsOrderBrandsDirectory(brandsFilter?.status));
    saveFiltersDataToLocalStorage(brandsFilter);
  }, [brandsFilter]);

  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-[95.5%] w-full gap-4">
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columns={columnsConfig}
                columnVisibility={columnVisibility}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {stateIs === "Idle" && data?.brands.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color={primaryColor}
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columnOrder={columnOrder}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
          <Pagination />
        </div>
      </div>
    </div>
  );
};
