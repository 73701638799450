{/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
  <rect width="32" height="32" rx="16" fill="#005C89" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M23.3334 16.0007C23.3334 16.2532 23.1907 16.484 22.9648 16.5969L10.9648 22.5969C10.7348 22.712 10.4591 22.6844 10.2564 22.5261C10.0537 22.3679 9.96011 22.1071 10.0159 21.856L11.3171 16.0007L10.0159 10.1453C9.96011 9.89422 10.0537 9.63346 10.2564 9.47519C10.4591 9.31691 10.7348 9.28936 10.9648 9.40437L22.9648 15.4044C23.1907 15.5173 23.3334 15.7481 23.3334 16.0007ZM12.5348 16.6673L11.6213 20.778L21.176 16.0007L11.6213 11.2233L12.5348 15.334H17.3334C17.7015 15.334 18 15.6325 18 16.0007C18 16.3688 17.7015 16.6673 17.3334 16.6673H12.5348Z"
    fill="white"
  />
</svg>; */}

import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  width?: string | number,
  height?: string | number,
}

export const PaperAirplaneRightButtonIcon = ({
  fill = "#005C89",
  width,
  height,
  ...restProps
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 32}
    height={ height || 32}
    viewBox="0 0 32 32"
    fill={fill}
  >
    <rect width="32" height="32" rx="16" fill={fill} />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.3334 16.0007C23.3334 16.2532 23.1907 16.484 22.9648 16.5969L10.9648 22.5969C10.7348 22.712 10.4591 22.6844 10.2564 22.5261C10.0537 22.3679 9.96011 22.1071 10.0159 21.856L11.3171 16.0007L10.0159 10.1453C9.96011 9.89422 10.0537 9.63346 10.2564 9.47519C10.4591 9.31691 10.7348 9.28936 10.9648 9.40437L22.9648 15.4044C23.1907 15.5173 23.3334 15.7481 23.3334 16.0007ZM12.5348 16.6673L11.6213 20.778L21.176 16.0007L11.6213 11.2233L12.5348 15.334H17.3334C17.7015 15.334 18 15.6325 18 16.0007C18 16.3688 17.7015 16.6673 17.3334 16.6673H12.5348Z"
      fill="white"
    />
  </svg>

  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width={restProps.width || 24}
  //   height={restProps.height || 24}
  //   viewBox="0 0 10 10"
  //   fill={fill}
  //   className="flex-grow-0 flex-shrink-0 w-6 h-6 relative"
  //   {...restProps}
  // >
  //   <path
  //     fill-rule="evenodd"
  //     clip-rule="evenodd"
  //     d="M23.3334 16.0007C23.3334 16.2532 23.1907 16.484 22.9648 16.5969L10.9648 22.5969C10.7348 22.712 10.4591 22.6844 10.2564 22.5261C10.0537 22.3679 9.96011 22.1071 10.0159 21.856L11.3171 16.0007L10.0159 10.1453C9.96011 9.89422 10.0537 9.63346 10.2564 9.47519C10.4591 9.31691 10.7348 9.28936 10.9648 9.40437L22.9648 15.4044C23.1907 15.5173 23.3334 15.7481 23.3334 16.0007ZM12.5348 16.6673L11.6213 20.778L21.176 16.0007L11.6213 11.2233L12.5348 15.334H17.3334C17.7015 15.334 18 15.6325 18 16.0007C18 16.3688 17.7015 16.6673 17.3334 16.6673H12.5348Z"
  //     fill= {fill}
  //   />
  // </svg>
);
export default PaperAirplaneRightButtonIcon;
