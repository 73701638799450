import * as React from 'react';
import type { SVGProps } from "react";
interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
}

export const ImageCircleIcon =  ({
  fill = "#005C89",
  ...restProps
}: IconProps) => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...restProps}
  >
    <circle cx='12.5' cy='12.5' r='12.5' fill={fill} />
    <path
      d='M16.3077 8H8.69231C8.30996 8 8 8.30996 8 8.69231V16.3077C8 16.69 8.30996 17 8.69231 17H16.3077C16.69 17 17 16.69 17 16.3077V8.69231C17 8.30996 16.69 8 16.3077 8Z'
      stroke='white'
      stroke-width='0.7'
    />
    <path
      d='M11 11C11.5523 11 12 10.7761 12 10.5C12 10.2239 11.5523 10 11 10C10.4477 10 10 10.2239 10 10.5C10 10.7761 10.4477 11 11 11Z'
      stroke='white'
      stroke-width='0.7'
    />
    <path
      d='M10 15L11.125 13.125L12.25 15L14.125 12L16 15'
      stroke='white'
      stroke-width='0.7'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
export default ImageCircleIcon;
