import React from "react";
// Components
import { Interoperability, PubliclyVisible } from "./Components";

interface IProps {
  handleChange: any;
  setFieldValue: any;
  values: any;
  editMode?: any;
}

export const Settings: React.FC<IProps> = ({
  handleChange,
  setFieldValue,
  values,
  editMode
}) => {
  return (
    <div className="w-full bg-bgWhite py-6 rounded-lg">
      <div className="px-12">
        <p className="text-secondaryMid font-Overpass text-xl font-bold">Settings</p>
        <div className="flex gap-6 py-2">
          {/* Interoperability Section*/}
          <div className="w-2/4">
            <Interoperability
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              values={values}
              editMode={editMode}
            />
          </div>
          {/* Publicly Visible Section*/}
          <div className="w-2/4">
            <PubliclyVisible setFieldValue={setFieldValue} values={values} editMode={editMode} />
          </div>
        </div>
      </div>
    </div>
  );
};
