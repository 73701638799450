import useDateFormatter from "hooks/formatDateWithPattern";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Statuses } from "Components/Users/Statuses";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import usePermissions from "hooks/usePermissions";
import { capitalCase } from "change-case";
import useCustomBranding from "hooks/useCustomBranding";
import useRoles from "hooks/roles";
import { useEffect, useRef, useState } from "react";
import CustomProfilePlaceholder from "assets/images/Placeholders/custom-branding/customBrandingListPlaceholder.png";


export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const navigate = useNavigate();
  const { formatDate } = useDateFormatter();
  const { users } = usePermissions();
  const { programId } = useParams();
  const { isOrganizationAdmin } = useRoles();
  const { getBrandPlaceholder } = useCustomBranding();

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const maxVisible = 3;
  const safeCellValue = Array.isArray(cellValue) ? cellValue : [];
  const hiddenCount = safeCellValue.length - maxVisible;
  const visibleItems = safeCellValue.slice(0, maxVisible);
  const hiddenItems = safeCellValue.slice(maxVisible);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpenDropdown(false);
      }
    };

    if (isOpenDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenDropdown]);

  // function to add ellipsis
  const imagesData = [
    { image: getBrandPlaceholder("userProfile") },
    { image: getBrandPlaceholder("userProfile") },
    { image: getBrandPlaceholder("userProfile") },
    { image: getBrandPlaceholder("userProfile") },
    { image: getBrandPlaceholder("userProfile") },
  ];
  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "name"
  ) {
    return (
      <Link
        to={
          users.canViewUsersProfile &&
          ((programId && isOrganizationAdmin()) || !programId)
            ? `/profile/${cellValue?.personId}/person`
            : ""
        }
      >
        <div className="flex justify-start w-[250px] items-center relative group">
          <img
            src={
              cellValue?.imageUrl
                ? cellValue?.imageUrl
                : getBrandPlaceholder("userProfile")
            }
            alt=""
            height="100"
            width="100"
            className="object-cover mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
          <div
            style={{ flexWrap: "wrap" }}
            className="flex flex-col flex-wrap w-full justify-start items-start relative gap-0.5"
          >
            <p className="text-sm text-left break-words break-all text-textMid group-hover:text-primaryDark">
              {ellipsize(cellValue?.firstName, 20)}{" "}
              {ellipsize(cellValue?.lastName, 20)}
            </p>
            <p className="text-sm text-left break-all text-textMid group-hover:text-primaryDark">
              {cellValue?.email}
            </p>
          </div>
        </div>
      </Link>
    );
  } else if (columnDef.id === "status" && cellValue !== null) {
    return (
      <div className="pl-2 status">
        <Statuses status={cellValue} />
      </div>
    );
  } else if (
    cellValue !== null &&
    (columnDef.id === "activeOn" ||
      columnDef.id === "memberSince" ||
      columnDef.id === "lastActive")
  ) {
    return (
      <div className="relative flex items-start flex-grow w-full px-2 py-1 ">
        <p className="text-sm text-left text-textMid dark:text-textMain w-[110px]">
          {formatDate(cellValue?.toString())}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "usedApps") {
    return (
      <>
        <div className="relative" ref={dropdownRef}>
          <div className="flex -space-x-2 w-[200px] py-1 px-2 flex-wrap">
            {visibleItems.map((el: any, index: number) => {
              const zIndex = maxVisible - index;
              return (
                <div key={index} className="flex items-center gap-2">
                  <img
                    className="inline-block rounded-full"
                    height="40"
                    width="40"
                    src={el?.image?.thumb || CustomProfilePlaceholder}
                    style={{ zIndex: zIndex }}
                    alt={el?.title}
                  />
                  {/* <span className="text-sm break-words text-textMid">
                    {ellipsize(el?.title || "", 10)}
                  </span> */}
                </div>
              );
            })}

            {hiddenCount > 0 && (
              <button
                className="py-1.5 px-3 text-[12px] bg-primaryExtraLight text-primary bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 transition"
                onClick={() => setIsOpenDropdown(!isOpenDropdown)}
              >
                +{hiddenCount}
              </button>
            )}
          </div>
          {isOpenDropdown && hiddenCount > 0 && (
            <div className="absolute top-full right-0 mt-3 w-[200px] bg-textWhite shadow-[0_0_6px_rgba(0,0,0,0.2)] rounded-xl p-2 z-10 transform-none max-h-[30vh] overflow-y-auto ease-in-out transition-transform duration-[198ms]">
              {hiddenItems.map((el: any, index: number) => (
                <div
                  key={index}
                  className="flex items-center gap-2 py-1 mb-1 last:mb-0 bg-red-300 cursor-pointer"
                >
                  <img
                    className="inline-block rounded-full"
                    height="35"
                    width="35"
                    src={el?.image?.thumb || CustomProfilePlaceholder}
                    alt={el?.title}
                  />
                  <span className="text-sm break-words text-textMid capitalize">
                    {ellipsize(el?.title || "", 10)}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  } else if (cellValue !== null && columnDef.id === "role") {
    return (
      <div className="flex items-start w-[250px] justify-start  py-1">
        <p className="w-full pl-3 text-sm text-left break-words text-textMid">
          {cellValue.map((role: string) => capitalCase(role)).join(", ")}
        </p>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "email") {
    return (
      <div className="flex items-start justify-start w-full py-1">
        <p className="pl-3 text-sm text-left text-textMid">{cellValue}</p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address"
            ? "justify-start w-[205px] px-0"
            : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid`}>
          {ellipsize(`${cellValue}`, 70)}
        </p>
      </div>
    );
  } else {
    return null; // Default behavior if none of the conditions are met
  }
};