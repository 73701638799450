import { useEffect, useRef, useState } from "react";
import {
  useCustomizeColorOfHigilightedPoint,
  useHandleClickOnPoint,
  useInitializeCustomLayersListControl,
  useInitializeMap,
  usePaintAllGlobalLayersOnMap,
  usePaintAllPointsOnMap,
} from "../../hooks";

const LAYER_DEFAULT_COLOR = "#FFA500";
const LAYER_SELECTED_COLOR = "red";
import mapboxgl from "mapbox-gl";

const Map = ({ element, answer, eleName, setFieldValue, editMode }: any) => {
  const mapContainer = useRef<any>(null);
  const [selectedGlobalLayers, setSelectedGlobalLayers] = useState<any>([]);
  const [markers, setMarkers] = useState<any>([]);


  const [globalMarkers,setGlobalMarkers] = useState<any>([]);



  const [newPopup, setNewPopup] = useState<any>();
  const [popups, setPopups] = useState<any>([]);
  const customControlId = "pointpicker" + element.id;

  // initializing the map
  const { map, isMapFullyLoaded } = useInitializeMap(
    mapContainer,
    customControlId,
    "",
    "",
    editMode
  );

  // initializing custom control for global layers listing to map controls
  useInitializeCustomLayersListControl(
    isMapFullyLoaded,
    map,
    selectedGlobalLayers,
    setSelectedGlobalLayers,
    element.selectedLayers,
    customControlId
  );

  // painting the all global layers on the map
  usePaintAllGlobalLayersOnMap(
    selectedGlobalLayers,
    map,
    isMapFullyLoaded,
    LAYER_DEFAULT_COLOR,
    null,
    popups,
    setPopups,
    setNewPopup,
    editMode,
    element?.type,
    globalMarkers,
    setGlobalMarkers
  );

  // paint all points on the map
  const [clickedPoint, setClickedPoint] = usePaintAllPointsOnMap(
    element,
    answer,
    map,
    isMapFullyLoaded,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR,
    markers,
    setMarkers
  );

  // handle click on point
  useHandleClickOnPoint(
    clickedPoint,
    setClickedPoint,
    element,
    eleName,
    setFieldValue,
    editMode,
    answer,
    element.selectedPoints
  );


  // change the color of highlighted point
  useCustomizeColorOfHigilightedPoint(
    map,
    isMapFullyLoaded,
    element,
    LAYER_SELECTED_COLOR,
    LAYER_DEFAULT_COLOR,
    markers,
    setMarkers,
    setClickedPoint,
    answer
  );

  // display popup of points whenever click on it
  useEffect(() => {
    if (!newPopup || editMode) return;

    const { coordinates, html, id } = newPopup;

    const popup = new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(html)
      .addTo(map);

    setPopups((prev: any) => [...popups, { id, item: popup }]);
  }, [newPopup]);

  useEffect(() => {
    if (popups.length > 0) {
      popups.forEach((p: any) => {
        if (p.popup) {
          p?.popup?.remove();
        }
      });
      setPopups([]);
    }
  }, [editMode]);
  return <div ref={mapContainer} className="h-[400px] w-full"></div>;
};

export default Map;
