import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
// Extend the FlyToOptions interface to include the padding property
interface ExtendedFlyToOptions extends mapboxgl.FlyToOptions {
  padding?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
}
const useCreateMarker = (
  map: mapboxgl.Map, // Using specific type for better type safety
  isMapFullyLoaded: boolean,
  coordinates: [number, number] // Assuming coordinates is always an array of two numbers
) => {
  const markerRef = useRef<mapboxgl.Marker | null>(null);

  useEffect(() => {
    if (
      map &&
      isMapFullyLoaded &&
      coordinates &&
      coordinates[0] &&
      coordinates[1]
    ) {
      // Initialize the marker if it doesn't exist
      if (!markerRef.current) {
        markerRef.current = new mapboxgl.Marker()
          .setLngLat(coordinates)
          .addTo(map);
      } else {
        // Update the marker's position if it already exists
        markerRef.current.setLngLat(coordinates);
      }

      // Fly the map to the new coordinates using the extended type
      map.flyTo({
        center: coordinates,
        zoom: 14, // Adjust the zoom level as necessary
        essential: true, // Ensures the camera moves smoothly to the new location
        speed: 1.2, // Optional: adjust flyTo speed
        curve: 1, // Optional: smooth the flight curve
        padding: {
          top: 50, // Padding from the top edge
          bottom: 50, // Padding from the bottom edge
          left: 50, // Padding from the left edge
          right: 50, // Padding from the right edge
        },
      } as ExtendedFlyToOptions);
    }
  }, [map, isMapFullyLoaded, coordinates]);

  return null;
};

export { useCreateMarker };
