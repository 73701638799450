import * as actionTypes from "./action-types";

export const closeDashboardAlertModal = () => {
  return {
    type: actionTypes.CLOSE_DASHBOARD_ALERT_MODAL,
  };
};
export const closeDashboardAlertConfirmationModal = () => {
  return {
    type: actionTypes.CLOSE_DASHBOARD_ALERT_CONFIRMATION_MODAL,
  };
};
export const openDashboardAlertConfirmationModal = () => {
  return {
    type: actionTypes.OPEN_DASHBOARD_ALERT_CONFIRMATION_MODAL,
  };
};

export const closeSignOutModal = () => {
  return {
    type: actionTypes.CLOSE_SIGNOUT_MODAL,
  };
};
export const openSignOutModal = () => {
  return {
    type: actionTypes.OPEN_SIGNOUT_MODAL,
  };
};

export const closePhoneVerificationModelAction = () => {
  return {
    type: actionTypes.CLOSE_PHONE_NUMBER_VERIFICATION_MODAL,
  };
};

export const openPhoneVerificationModalAction = (res: any) => {
  return {
    type: actionTypes.OPEN_PHONE_NUMBER_VERIFICATION_MODAL,
    payload: res,
  };
};

export const openEmailVerificationModalAction = (res: any) => {
  return {
    type: actionTypes.OPEN_EMAIL_VERIFICATION_MODAL,
    payload: res,
  };
};

export const closeEmailVerificationModelAction = () => {
  return {
    type: actionTypes.CLOSE_EMAIL_VERIFICATION_MODAL,
  };
};

export const openDeleteAccountModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_DELETE_ACCOUNT_MODAL,
    payload: data,
  };
};

export const closeDeleteAccountModalAction = () => {
  return {
    type: actionTypes.CLOSE_DELETE_ACCOUNT_MODAL,
  };
};

export const openRemovePersonFromCommunityAction = (data: any) => {
  return {
    type: actionTypes.OPEN_REMOVE_PERSON_COMMUNITY_MODAL,
    payload: data,
  };
};

export const closeRemovePersonFromCommunityAction = () => {
  return {
    type: actionTypes.CLOSE_REMOVE_PERSON_COMMUNITY_MODAL,
  };
};

export const openDeleteAccountRequestModalAction = () => {
  return {
    type: actionTypes.OPEN_DELETE_ACCOUNT_REQUEST_MODAL,
  };
};

export const closeDeleteAccountRequestModalAction = () => {
  return {
    type: actionTypes.CLOSE_DELETE_ACCOUNT_REQUEST_MODAL,
  };
};

export const openResetPasswordModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_RESET_PASSWORD_MODAL,
    payload: data,
  };
};

export const closeResetPasswordModalAction = () => {
  return {
    type: actionTypes.CLOSE_RESET_PASSWORD_MODAL,
  };
};

export const openConfirmationAssignAsAdminAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_CONFIRMATION_ASSIGN_AS_ADMINA_MODAL,
    payload: data,
  };
};
export const closeConfirmationAssignAsAdminAction = () => {
  return {
    type: actionTypes.CLOSE_CONFIRMATION_ASSIGN_AS_ADMINA_MODAL,
  };
};

export const openReinviteUserModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_REINVITE_USER_MODAL,
    payload: data,
  };
};
export const closeReinviteUserModalAction = () => {
  return {
    type: actionTypes.CLOSE_REINVITE_USER_MODAL,
  };
};

export const openCreatePasswordModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_CREATE_PASSWORD_MODAL,
    payload: data,
  };
};

export const closeCreatePasswordModalAction = () => {
  return {
    type: actionTypes.CLOSE_CREATE_PASSWORD_MODAL,
  };
};

export const openDeactivateUserModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_DEACTIVATE_USER_MODAL,
    payload: data,
  };
};

export const closeDeactivateUserModalAction = () => {
  return {
    type: actionTypes.CLOSE_DEACTIVATE_USER_MODAL,
  };
};

export const openRestorePersonModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_RESTORE_PERSON_MODAL,
    payload: data,
  };
};

export const closeRestorePersonModalAction = () => {
  return {
    type: actionTypes.CLOSE_RESTORE_PERSON_MODAL,
  };
};

export const openOrganizationsProfileDetailModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ORGANIZATION_PROFILE_DETAIL_MODAL,
    payload: data,
  };
};

export const closeOrganizationsProfileDetailModalAction = () => {
  return {
    type: actionTypes.CLOSE_ORGANIZATION_PROFILE_DETAIL_MODAL,
  };
};

export const openCreateCommunityModalAction = () => {
  return {
    type: actionTypes.OPEN_CREATE_COMMUNITY_MODAL,
  };
};

export const closeCreateCommunityModalAction = () => {
  return {
    type: actionTypes.CLOSE_CREATE_COMMUNITY_MODAL,
  };
};

export const openEditCommunityModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_EDIT_COMMUNITY_MODAL,
    payload: data,
  };
};

export const closeEditCommunityModalAction = () => {
  return {
    type: actionTypes.CLOSE_EDIT_COMMUNITY_MODAL,
  };
};

export const openCommunityAccessModalAction = (data: any, access: string) => {
  return {
    type: actionTypes.OPEN_COMMUNITY_ACCESS_MODAL,
    payload: data,
    access: access,
  };
};

export const closeCommunityAccessModalAction = () => {
  return {
    type: actionTypes.CLOSE_COMMUNITY_ACCESS_MODAL,
  };
};

export const openArchiveOrRestoreCommunityModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_COMMUNITY_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreCommunityModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_COMMUNITY_MODAL,
  };
};

export const openArchiveOrPublishOrganizationModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_PUBLISH_ORGANIZATION_MODAL,
    payload: data,
  };
};

export const closeArchiveOrPublishOrganizationModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_PUBLISH_ORGANIZATION_MODAL,
  };
};

export const openDeleteACommunityModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_DELETE_A_COMMUNITY_MODAL,
    payload: data,
  };
};

export const closeDeleteACommunityModalAction = () => {
  return {
    type: actionTypes.CLOSE_DELETE_A_COMMUNITY_MODAL,
  };
};

export const openAssignAnAdminToCommunityModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ASSIGN_AN_ADMIN_TO_COMMUNITY_MODAL,
    payload: data,
  };
};

export const closeAssignAnAdminToCommunityModalAction = () => {
  return {
    type: actionTypes.CLOSE_ASSIGN_AN_ADMIN_TO_COMMUNITY_MODAL,
  };
};

export const openAddExistingMemberstocommunityModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ADD_MEMBERS_TO_COMMUNITY_MODAL,
    payload: data,
  };
};

export const closeAddExistingMemberstocommunityModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_MEMBERS_TO_COMMUNITY_MODAL,
  };
};

export const openCreateOrganizationModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_CREATE_ORGANIZATION_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeCreateOrganizationModalAction = () => {
  return {
    type: actionTypes.CLOSE_CREATE_ORGANIZATION_MODAL,
  };
};
export const openOrganizationsModalAction = () => {
  return {
    type: actionTypes.OPEN_CREATE_ORGANIZATION_MODAL,
  };
};
export const closeOrganizationsModalAction = () => {
  return {
    type: actionTypes.CLOSE_CREATE_ORGANIZATION_MODAL,
  };
};

export const openAddExistingOrgzanizationsModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ADD_EXISTING_ORGANIZATIONS_MODAL,
    payload: data,
  };
};

export const closeAddExistingOrgzanizationsModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_EXISTING_ORGANIZATIONS_MODAL,
  };
};

export const openAddExistingCommunityModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ADD_EXISTING_COMMUNITY_MODAL,
    payload: data,
  };
};

export const closeAddExistingCommunityToOrganizationModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_EXISTING_COMMUNITY_MODAL,
  };
};

export const openAddFormModalAction = (
  data?: any,
  editMode: any = false,
  templateMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_EDIT_TEMPLATE_FORM_MODAL,
    payload: data,
    editMode: editMode,
    templateMode: templateMode,
  };
};

export const closeAddFormModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_EDIT_TEMPLATE_FORM_MODAL,
  };
};

export const openformDetailsAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_FORM_DETAILS_MODAL,
    payload: data,
  };
};

export const closeformDetailsAction = () => {
  return {
    type: actionTypes.CLOSE_FORM_DETAILS_MODAL,
  };
};

export const openImportSummaryAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_IMPORT_SUMMARY_MODAL,
    payload: data,
  };
};

export const closeImportSummaryAction = () => {
  return {
    type: actionTypes.CLOSE_IMPORT_SUMMARY_MODAL,
  };
};

export const openAddPersonModalAction = () => {
  return {
    type: actionTypes.OPEN_ADD_PERSON_MODAL,
  };
};

export const colseAddPersonModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_PERSON_MODAL,
  };
};

export const openArchiveOrRestoreFormModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_FORM_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreFormModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_FORM_MODAL,
  };
};

export const openDeleteFormModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_DELETE_A_FORM_MODAL,
    payload: data,
  };
};

export const closeDeleteFormModalAction = () => {
  return {
    type: actionTypes.CLOSE_DELETE_A_FORM_MODAL,
  };
};

export const openAddProgramModalAction = (organizationData: any = null) => {
  return {
    type: actionTypes.OPEN_ADD_PROGRAM_MODAL,
    payload: organizationData,
  };
};

export const closeAddProgramModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_PROGRAM_MODAL,
  };
};

export const openEditProgramModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_EDIT_PROGRAM_MODAL,
    payload: data,
  };
};

export const closeEditProgramModalAction = () => {
  return {
    type: actionTypes.CLOSE_EDIT_PROGRAM_MODAL,
  };
};

export const closeAddFileModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_FILE_MODAL,
  };
};

export const openAddFileModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ADD_FILE_MODAL,
    payload: data,
  };
};
export const openArchiveOrRestoreProgramModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_PROGRAM_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreProgramModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_PROGRAM_MODAL,
  };
};

export const openAssignAnAdminToProgramModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ASSIGN_AN_ADMIN_TO_PROGRAM_MODAL,
    payload: data,
  };
};

export const closeAssignAnAdminToProgramModalAction = () => {
  return {
    type: actionTypes.CLOSE_ASSIGN_AN_ADMIN_TO_PROGRAM_MODAL,
  };
};

export const openCompleteProgramModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_COMPLETE_PROGRAM_MODAL,
    payload: data,
  };
};

export const closeCompleteProgramModalAction = () => {
  return {
    type: actionTypes.CLOSE_COMPLETE_PROGRAM_MODAL,
  };
};

export const openAddExistingCommunitiesOrOrganizationsToProgramModalAction = (
  data: any
) => {
  return {
    type: actionTypes.OPEN_ADD_EXISTING_COMMUNITIES_OR_ORGANIZATIONS_TO_PROGRAM_MODAL,
    payload: data,
  };
};

export const closeAddExistingCommunitiesOrOrganizationsToProgramModalAction =
  () => {
    return {
      type: actionTypes.CLOSE_ADD_EXISTING_COMMUNITIES_OR_ORGANIZATIONS_TO_PROGRAM_MODAL,
    };
  };

export const closeRemoveFromProgramAction = () => {
  return {
    type: actionTypes.CLOSE_REMOVE_FROM_PROGRAM_MODAL,
  };
};
export const openRemoveFromProgramAction = (data: any) => {
  return {
    type: actionTypes.OPEN_REMOVE_FROM_PROGRAM_MODAL,
    payload: data,
  };
};
export const closeRemoveProgramAction = () => {
  return {
    type: actionTypes.CLOSE_REMOVE_PROGRAM_MODAL,
  };
};
export const openRemoveProgramAction = (data: any) => {
  return {
    type: actionTypes.OPEN_REMOVE_PROGRAM_MODAL,
    payload: data,
  };
};
export const openRemoveCommunityFromOrganizationAction = (data: any) => {
  return {
    type: actionTypes.OPEN_REMOVE_COMMUNITY_FROM_ORGANIZATION_MODAL,
    payload: data,
  };
};
export const closeRemoveCommunityFromOrganizationAction = () => {
  return {
    type: actionTypes.CLOSE_REMOVE_COMMUNITY_FROM_ORGANIZATION_MODAL,
  };
};

export const openManageProgramMembersModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_MANAGE_PROGRAM_MEMBERS_MODAL,
    payload: data,
  };
};

export const closeManageProgramMembersModalAction = () => {
  return {
    type: actionTypes.CLOSE_MANAGE_PROGRAM_MEMBERS_MODAL,
  };
};

export const openAddEditDuplicateProtocolModalAction = (
  data?: any,
  editMode: any = false,
  duplicateMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_EDIT_DUPLICATE_PROTOCOL_MODAL,
    payload: data,
    editMode: editMode,
    duplicateMode: duplicateMode,
  };
};

export const closeAddEditDuplicateProtocolModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_EDIT_DUPLICATE_PROTOCOL_MODAL,
  };
};

export const openVideoLightBoxModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_VIDEO_LIGHTBOX_MODAL,
    payload: data,
  };
};

export const closeVideoLightBoxModalAction = () => {
  return {
    type: actionTypes.CLOSE_VIDEO_LIGHTBOX_MODAL,
  };
};

export const openImageLightBoxModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_IMAGE_LIGHTBOX_MODAL,
    payload: data,
  };
};

export const closeImageLightBoxModalAction = () => {
  return {
    type: actionTypes.CLOSE_IMAGE_LIGHTBOX_MODAL,
  };
};

export const openImageVideoLightBoxModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_IMAGE_VIDEO_LIGHTBOX_MODAL,
    payload: data,
  };
};

export const closeImageVideoLightBoxModalAction = () => {
  return {
    type: actionTypes.CLOSE_IMAGE_VIDEO_LIGHTBOX_MODAL,
  };
};

export const openDeleteFileModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_DELETE_A_FILE_MODAL,
    payload: data,
  };
};

export const closeDeleteFileModalAction = () => {
  return {
    type: actionTypes.CLOSE_DELETE_A_FILE_MODAL,
  };
};

export const openProtocolFileModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_PROTOCOL_FILE_MODAL,
    payload: data,
  };
};

export const closeProtocolFileModalAction = () => {
  return {
    type: actionTypes.CLOSE_PROTOCOL_FILE_MODAL,
  };
};
export const openManageConnectionProtocolModal = (data: any) => {
  return {
    type: actionTypes.OPEN_MANAGE_CONNECTION_PROTOCOL_MODAL,
    payload: data,
  };
};

export const closeManageConnectionProtocolModal = () => {
  return {
    type: actionTypes.CLOSE_MANAGE_CONNECTION_PROTOCOL_MODAL,
  };
};

export const openArchiveOrRestoreProtocolModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_PROTOCOL_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreProtocolModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_PROTOCOL_MODAL,
  };
};

export const openCreateOrEditDataAccessPolicyModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_CREATE_OR_EDIT_DATA_ACCESS_POLICY_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeCreateOrEditDataAccessPolicyModalAction = () => {
  return {
    type: actionTypes.CLOSE_CREATE_OR_EDIT_DATA_ACCESS_POLICY_MODAL,
  };
};

export const openArchiveOrRestoreObservationModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_OBSERVATION_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreObservationModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_OBSERVATION_MODAL,
  };
};

export const openInviteExternalReviewerQualityAssuranceObservationModalAction =
  (data: any) => {
    return {
      type: actionTypes.OPEN_INVITE_EXTERNAL_REVIEWER_QUALITY_ASSURANCE_OBSERVATION_MODAL,
      payload: data,
    };
  };

export const closeInviteExternalReviewerQualityAssuranceObservationModalAction =
  () => {
    return {
      type: actionTypes.CLOSE_INVITE_EXTERNAL_REVIEWER_QUALITY_ASSURANCE_OBSERVATION_MODAL,
    };
  };

export const openArchiveOrRestoreQualityAssuranceObservationModalAction = (
  data: any
) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_QUALITY_ASSURANCE_OBSERVATION_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreQualityAssuranceObservationModalAction =
  () => {
    return {
      type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_QUALITY_ASSURANCE_OBSERVATION_MODAL,
    };
  };
export const openRemoveManagerModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_REMOVE_MANAGER_MODAL,
    payload: data,
  };
};

export const closeRemoveManagerModalAction = () => {
  return {
    type: actionTypes.CLOSE_REMOVE_MANAGER_MODAL,
  };
};
export const openInviteRegisteredExternalReviewerModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_INVITE_REGISTERED_EXTERNAL_REVIEWER_MODAL,
    payload: data,
  };
};

export const closeInviteRegisteredExternalReviewerModalAction = () => {
  return {
    type: actionTypes.CLOSE_INVITE_REGISTERED_EXTERNAL_REVIEWER_MODAL,
  };
};

export const openSuccessfullInviteMessageModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_SUCCESSFULL_MESSAGE_INVITE_MANAGER_MODAL,
    payload: data,
  };
};

export const closeSuccessfullInviteMessageModalAction = () => {
  return {
    type: actionTypes.CLOSE_SUCCESSFULL_MESSAGE_INVITE_MANAGER_MODAL,
  };
};
export const openNotificationsCenterModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_NOTIFICATIONS_CENTER_MODAL,
    payload: data,
  };
};

export const closeNotificationsCenterModalAction = () => {
  return {
    type: actionTypes.CLOSE_NOTIFICATIONS_CENTER_MODAL,
  };
};
export const openDeleteManagerModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_DELETE_MANAGER_MODAL,
    payload: data,
  };
};

export const closeDeleteManagerModalAction = () => {
  return {
    type: actionTypes.CLOSE_DELETE_MANAGER_MODAL,
  };
};
export const openRemoveNotificationRecipientsModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_NOTIFICATIONS_RECIPIENTS_MANAGER_MODAL,
    payload: data,
  };
};

export const openNotificationsSummaryModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_NOTIFICATIONS_SUMMARY_MODAL,
    payload: data,
  };
};

export const closeNotificationsSummaryModalAction = () => {
  return {
    type: actionTypes.CLOSE_NOTIFICATIONS_SUMMARY_MODAL,
  };
};
export const openReportsSummaryModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_REPORTS_SUMMARY_MODAL,
    payload: data,
  };
};

export const closeReportsSummaryModalAction = () => {
  return {
    type: actionTypes.CLOSE_REPORTS_SUMMARY_MODAL,
  };
};

export const openReportsMapModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_REPORTS_MAP_MODAL,
    payload: data,
  };
};

export const closeReportsMapModalAction = () => {
  return {
    type: actionTypes.CLOSE_REPORTS_MAP_MODAL,
  };
};

export const closeRemoveNotificationRecipientsModalAction = () => {
  return {
    type: actionTypes.CLOSE_REMOVE_NOTIFICATIONS_RECIPIENTS_MODAL,
  };
};

export const openAddManagerModal = (data?: any) => {
  return {
    type: actionTypes.OPEN_ADD_MANAGER_MODAL,
    payload: data,
  };
};
export const closeAddManagerModal = () => {
  return {
    type: actionTypes.CLOSE_ADD_MANAGER_MODAL,
  };
};

export const openIniviteDataEndViewerModal = (data?: any) => {
  return {
    type: actionTypes.OPEN_INVITE_DATA_END_VIEWER_MODAL,
    payload: data,
  };
};
export const closeIniviteDataEndViewerModal = () => {
  return {
    type: actionTypes.CLOSE_INVITE_DATA_END_VIEWER_MODAL,
  };
};

export const openReviewerDetailsModal = (data?: any) => {
  return {
    type: actionTypes.OPEN_REVIEWER_DETAILS_MODAL,
    payload: data,
  };
};
export const closeReviewerDetailsModal = () => {
  return {
    type: actionTypes.CLOSE_REVIEWER_DETAILS_MODAL,
  };
};

// MESSAGE ACTIONS DELETE CHAT MODAL
export const openChatDeleteModal = (data: any) => {
  return {
    type: actionTypes.OPEN_CHAT_DELETE_MODAL,
    payload: data,
  };
};
export const closeChatDeleteModal = () => {
  return {
    type: actionTypes.CLOSE_CHAT_DELETE_MODAL,
  };
};

// GENERIC ARCHIVE CHAT MODAL
export const openChatArchiveModal = (data: any) => {
  return {
    type: actionTypes.OPEN_CHAT_ARCHIVE_MODAL,
    payload: data,
  };
};
export const closeChatArchiveModal = () => {
  return {
    type: actionTypes.CLOSE_CHAT_ARCHIVE_MODAL,
  };
};

export const openArchiveOrRestoreCategoryModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_CATEGORY_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreCategoryModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_CATEGORY_MODAL,
  };
};

export const openAddOrEditCategoryModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_OR_EDIT_CATEGORY_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeAddOrEditCategoryModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_OR_EDIT_CATEGORY_MODAL,
  };
};

export const openArchiveOrRestoreBehaviorsModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_BEHAVIORS_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreBehaviorsModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_BEHAVIORS_MODAL,
  };
};

export const openAddOrEditBehaviorsModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_OR_EDIT_BEHAVIORS_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeAddOrEditBehaviorsModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_OR_EDIT_BEHAVIORS_MODAL,
  };
};

export const openArchiveOrRestoreConditionsModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_CONDITIONS_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreConditionsModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_CONDITIONS_MODAL,
  };
};

export const openAddOrEditConditionsModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_OR_EDIT_CONDITIONS_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeAddOrEditConditionsModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL,
  };
};

export const openAddOrEditTypeModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_OR_EDIT_TYPE_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeAddOrEditTypeModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_OR_EDIT_TYPE_MODAL,
  };
};

export const openArchiveOrRestoreTypeModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_TYPE_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreTypeModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_TYPE_MODAL,
  };
};

export const openAddOrEditMarkerGroupModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_OR_EDIT_MARKER_GROUPS_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeAddOrEditMarkerGroupModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_OR_EDIT_MARKER_GROUPS_MODAL,
  };
};

export const openArchiveOrRestoreMarkerGroupModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_MARKER_GROUPS_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreMarkerGroupModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_MARKER_GROUPS_MODAL,
  };
};

export const openAddOrEditMarkerStylesModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_OR_EDIT_MARKER_STYLES_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeAddOrEditMarkerStylesModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_OR_EDIT_MARKER_STYLES_MODAL,
  };
};

export const openArchiveOrRestoreMarkerStylesModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_MARKER_STYLES_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreMarkerStylesModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_MARKER_STYLES_MODAL,
  };
};

export const openAddOrEditSpecieModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_OR_EDIT_SPECIE_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeAddOrEditSpecieModal = () => {
  return {
    type: actionTypes.CLOSE_ADD_OR_EDIT_SPECIE_MODAL,
  };
};

export const openArchiveOrRestoreSpecieModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_SPECIE_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreSpecieModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_SPECIE_MODAL,
  };
};

export const openReUseSpeciesListModalAction = () => {
  return {
    type: actionTypes.OPEN_RE_USE_SPECIES_LIST_MODAL,
  };
};

export const closeReUseSpeciesListModalAction = () => {
  return {
    type: actionTypes.CLOSE_RE_USE_SPECIES_LIST_MODAL,
  };
};

//----------

export const openAddOrEditObjectModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_OR_EDIT_OBJECT_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeAddOrEditObjectModal = () => {
  return {
    type: actionTypes.CLOSE_ADD_OR_EDIT_OBJECT_MODAL,
  };
};

export const openArchiveOrRestoreObjectModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_OBJECT_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreObjectModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_OBJECT_MODAL,
  };
};

export const openReUseObjectsListModalAction = () => {
  return {
    type: actionTypes.OPEN_RE_USE_OBJECTS_LIST_MODAL,
  };
};

export const closeReUseObjectsListModalAction = () => {
  return {
    type: actionTypes.CLOSE_RE_USE_OBJECTS_LIST_MODAL,
  };
};

export const openDeleteTagsModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_DELETE_A_TAG_MODAL,
    payload: data,
  };
};

export const closeDeleteTagsModalAction = () => {
  return {
    type: actionTypes.CLOSE_DELETE_A_TAG_MODAL,
  };
};

export const openAddOrEditTagModalAction = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_ADD_OR_EDIT_TAG_MODAL,
    payload: data,
    editMode: editMode,
  };
};

export const closeAddOrEditTagModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_OR_EDIT_TAG_MODAL,
  };
};

export const openAddExistingSpeciesOrObjectsToCommunityModalAction = (
  data: any
) => {
  return {
    type: actionTypes.OPEN_ADD_EXISTING_SPECIES_OR_OBJECTS_TO_COMMUNITY_MODAL,
    payload: data,
  };
};

export const closeAddExistingSpeciesOrObjectsToCommunityModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_EXISTING_SPECIES_OR_OBJECTS_TO_COMMUNITY_MODAL,
  };
};

export const openPauseResumeDeleteNotificationModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_PAUSE_RESUME_DELETE_NOTIFICATION_MODAL,
    payload: data,
  };
};

export const closePauseResumeDeleteNotificationModalAction = () => {
  return {
    type: actionTypes.CLOSE_PAUSE_RESUME_DELETE_NOTIFICATION_MODAL,
  };
};
export const openArchiveOrRestoreGeographyLayerModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_GEOGRAPHY_LAYER_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreGeographyLayerModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_GEOGRAPHY_LAYER_MODAL,
  };
};

export const openArchiveRestoreDeleteReportsModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_RESTORE_DELETE_REPORT_MODAL,
    payload: data,
  };
};

export const closeArchiveRestoreDeleteReportsModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_RESTORE_DELETE_REPORT_MODAL,
  };
};

export const openEditCommunityTagsModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_EDIT_COMMUNITY_TAGS_MODAL,
    payload: data,
  };
};

export const closeEditCommunityTagsModalAction = () => {
  return {
    type: actionTypes.CLOSE_EDIT_COMMUNITY_TAGS_MODAL,
  };
};

export const openEditOrganizationTagsModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_EDIT_ORGANIZATION_TAGS_MODAL,
    payload: data,
  };
};

export const closeEditOrganizationTagsModalAction = () => {
  return {
    type: actionTypes.CLOSE_EDIT_ORGANIZATION_TAGS_MODAL,
  };
};

export const openEditProgramTagsModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_EDIT_PROGRAM_TAGS_MODAL,
    payload: data,
  };
};

export const closeEditProgramTagsModalAction = () => {
  return {
    type: actionTypes.CLOSE_EDIT_PROGRAM_TAGS_MODAL,
  };
};

export const openProcessedReportModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_PROCESSED_REPORT_MODAL,
    payload: data,
  };
};

export const closeProcessedReportModalAction = () => {
  return {
    type: actionTypes.CLOSE_PROCESSED_REPORT_MODAL,
  };
};

export const openAddEditDuplicateRoleModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_ADD_EDIT_DUPLICATE_ROLE_MODAL,
    payload: data,
  };
};

export const closeAddEditDuplicateRoleModalAction = () => {
  return {
    type: actionTypes.CLOSE_ADD_EDIT_DUPLICATE_ROLE_MODAL,
  };
};

export const openEditPermissionModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_EDIT_PERMISSIONS_MODAL,
    payload: data,
  };
};

export const closeEditPermissionModalAction = () => {
  return {
    type: actionTypes.CLOSE_EDIT_PERMISSIONS_MODAL,
  };
};

export const openDeleteRoleModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_DELETE_ROLE_MODAL,
    payload: data,
  };
};

export const closeDeleteRoleModalAction = () => {
  return {
    type: actionTypes.CLOSE_DELETE_ROLE_MODAL,
  };
};
export const openSuccessMessageModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_SUCCESSFUL_MESSAGE_MODAL,
    payload: data,
  };
};

export const closeSuccessMessageModalAction = () => {
  return {
    type: actionTypes.CLOSE_SUCCESSFUL_MESSAGE_MODAL,
  };
};

export const openImportStatusDetailModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_IMPORT_STATUS_DETAIL_MODAL,
    payload: data,
  };
};

export const openDownloadOptionsImportModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_DOWNLOAD_OPTIONS_IMPORT_MODAL,
    payload: data,
  };
};

export const closeImportStatusDetailModalAction = () => {
  return {
    type: actionTypes.CLOSE_IMPORT_STATUS_DETAIL_MODAL,
  };
};
export const closeDownloadOptionsImportModalAction = () => {
  return {
    type: actionTypes.CLOSE_DOWNLOAD_OPTIONS_IMPORT_MODAL,
  };
};

export const openDeletePollsAndSurveyModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_DELETE_POLLS_AND_SURVEY_MODAL,
    payload: data,
  };
};

export const closeDeletePollsAndSurveyModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_DELETE_POLLS_AND_SURVEY_MODAL,
    payload: data,
  };
};

export const openArchiveOrRestorePollsAndSurveyModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_POLLS_AND_SURVEY_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestorePollsAndSurveyModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_POLLS_AND_SURVEY_MODAL,
    payload: data,
  };
};
export const openAddSurveyFormModal = (data?: any, editMode: any = false) => {
  return {
    type: actionTypes.OPEN_ADD_SURVEY_FORM_MODAL,
    payload: data,
    editMode: editMode,
  };
};
export const closeAddSurveyFormModal = (data?: any) => {
  return {
    type: actionTypes.CLOSE_ADD_SURVEY_FORM_MODAL,
    payload: data,
  };
};

export const openPollsAndSurveysExpiryDateModal = (
  data?: any,
  editMode: any = false
) => {
  return {
    type: actionTypes.OPEN_POLLS_AND_SURVEYS_EXPIRY_DATE_MODAL,
    payload: data,
  };
};
export const closePollsAndSurveysExpiryDateModal = (data?: any) => {
  return {
    type: actionTypes.CLOSE_POLLS_AND_SURVEYS_EXPIRY_DATE_MODAL,
    payload: data,
  };
};

export const openImportInfoModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_IMPORT_INFO_MODAL,
    payload: data,
  };
};

export const closeImportInfoModalAction = () => {
  return {
    type: actionTypes.CLOSE_IMPORT_INFO_MODAL,
  };
};

export const openConfigurationModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_CONFIGURATION_DASHBOARD_MODAL,
    payload: data,
  };
};

export const closeConfigurationModalAction = () => {
  return {
    type: actionTypes.CLOSE_CONFIGURATION_DASHBOARD_MODAL,
  };
};

export const openDownladPollsAndSurveysResultByVersionModalAction = (
  data?: any
) => {
  return {
    type: actionTypes.OPEN_DOWNLOAD_POLLS_AND_SURVEYS_RESULT_BY_VERSION_MODAL,
    payload: data,
  };
};

export const closeDownladPollsAndSurveysResultByVersionModalAction = (
  data?: any
) => {
  return {
    type: actionTypes.CLOSE_DOWNLOAD_POLLS_AND_SURVEYS_RESULT_BY_VERSION_MODAL,
    payload: data,
  };
};

export const openContactInfoModalAction = (data?: any, status?: string) => {
  const payloadData = {
    data: data,
    status: status,
  };
  return {
    type: actionTypes.OPEN_CONTACT_INFO_MODAL,
    payload: payloadData,
  };
};

export const closeContactInfoModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_CONTACT_INFO_MODAL,
    payload: data,
  };
};

export const openAddEditContactInfoModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_ADD_EDIT_CONTACT_INFO_MODAL,
    payload: data,
  };
};

export const closeAddEditContactInfoModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_ADD_EDIT_CONTACT_INFO_MODAL,
    payload: data,
  };
};

export const openPauseResumeProgramModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_PAUSE_RESUME_PROGRAM_MODAL,
    payload: data,
  };
};

export const closePauseResumeProgramModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_PAUSE_RESUME_PROGRAM_MODAL,
    payload: data,
  };
};

export const openMapSettngsProgramModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_MAP_SETTINGS_PROGRAM_MODAL,
    payload: data,
  };
};

export const closeMapSettngsProgramModalAction = () => {
  return {
    type: actionTypes.CLOSE_MAP_SETTINGS_PROGRAM_MODAL,
  };
};

export const openActiveSeasonsHistoryModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_ACTIVE_SEASONS_HISTORY_MODAL,
    payload: data,
  };
};

export const closeActiveSeasonsHistoryModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_ACTIVE_SEASONS_HISTORY_MODAL,
    payload: data,
  };
};

export const openReportVersionDataModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_REPORT_VERSION_DATA_MODAL,
    payload: data,
  };
};

export const closeReportVersionDataModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_REPORT_VERSION_DATA_MODAL,
    payload: data,
  };
};

export const openNotifyAboutNewVersionModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_NOTIFY_ABOUT_NEW_VERSION_MODAL,
    payload: data,
  };
};

export const closeNotifyAboutNewVersionModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_NOTIFY_ABOUT_NEW_VERSION_MODAL,
    payload: data,
  };
};

export const openUndoAndArchiveImportModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_UNDO_AND_ARCHIVE_IMPORT_MODAL,
    payload: data,
  };
};

export const closeUndoAndArchiveImportModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_UNDO_AND_ARCHIVE_IMPORT_MODAL,
    payload: data,
  };
};

export const openExifViewerModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_EXIF_VIEWER_MODAL,
    payload: data,
  };
};

export const closeExifViewerModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_EXIF_VIEWER_MODAL,
    payload: data,
  };
};

export const openAssignProgramAdminPermissionToImportModalAction = (
  data?: any
) => {
  return {
    type: actionTypes.OPEN_ASSIGN_PROGRAM_ADMIN_PERMISSION_TO_IMPORT_MODAL_ACTION,
    payload: data,
  };
};

export const closeAssignProgramAdminPermissionToImportModalAction = (
  data?: any
) => {
  return {
    type: actionTypes.CLOSE_ASSIGN_PROGRAM_ADMIN_PERMISSION_TO_IMPORT_MODAL_ACTION,
    payload: data,
  };
};

export const openManageLayersPropertiesModalAction = (data?: any) => {
  return {
    type: actionTypes.OPEN_MANAGE_LAYERS_PROPERTIES_MODAL,
    payload: data,
  };
};

export const closeManageLayersPropertiesModalAction = (data?: any) => {
  return {
    type: actionTypes.CLOSE_MANAGE_LAYERS_PROPERTIES_MODAL,
    payload: data,
  };
};

// Brand Modal Actions

export const openArchiveOrRestoreBrandModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_ARCHIVE_OR_RESTORE_BRAND_MODAL,
    payload: data,
  };
};

export const closeArchiveOrRestoreBrandModalAction = () => {
  return {
    type: actionTypes.CLOSE_ARCHIVE_OR_RESTORE_BRAND_MODAL,
  };
};

export const openViewDnsBrandModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_VIEW_DNS_BRAND_MODAL,
    payload: data,
  };
};

export const closeViewDnsBrandModalAction = () => {
  return {
    type: actionTypes.CLOSE_VIEW_DNS_BRAND_MODAL,
  };
};

export const openUserAlreadyExistsModalAction = (data: any) => {
  return {
    type: actionTypes.OPEN_USER_ALREADY_EXISTS_MODAL,
    payload: data,
  };
};

export const closeUserAlreadyExistsModalAction = () => {
  return {
    type: actionTypes.CLOSE_USER_ALREADY_EXISTS_MODAL,
  };
};
export const openAudioModal= (
  data?: any
) => {
  return {
    type: actionTypes.OPEN_AUDIO_MODAL_ACTION,
    payload: data,
  };
};

export const closeAudioModal = (
  data?: any
) => {
  return {
    type: actionTypes.CLOSE_AUDIO_MODAL_ACTION,
    payload: data,
  };
};
