import React, { useEffect, useState } from "react";
// Components
import { TableBody, TableHead } from "./components";
// Hooks
import useActions from "hooks/useActions";
// Store Utils
import { usersSortingAction } from "store/filters";
import { useDispatch } from "react-redux";
// Utils
import getAllRowsData from "utils/getAllRowsData";
// Exteranl Tools
import { snakeCase } from "change-case";
// Styling
import "./UserData.scss";
// types
import { TableProps } from "./types";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { useParams } from "react-router-dom";
import { getParentEntity } from "utils/getParentEntitywithParams";

export const KEYS_TO_BE_REMOVED = ["id", "personId"];

const generatingDirectoryActions = (
  columns: any,
  rowsData: any,
  parentEntity?: any
) => {
  const fileDownload = async () => {
    if (rowsData.length === 0) {
      Toasts.error("Please select at least one person from the table");
      return;
    }

    try {
      const res = await apiLibrary.file.fileDownload(
        "users",
        columns,
        rowsData.map((item: any) => {
          return item.personId;
        }),
        parentEntity
      );

      // Convert the response to a Blob
      const blob = new Blob([res], { type: "application/octet-stream" });

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute to a URL created from the Blob
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute to specify the file name
      link.download = `persons_${new Date().toISOString()}.csv`;

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);

      Toasts.success("Download complete");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

  return [
    {
      label: "Export Details",
      onClick: () => fileDownload(),
      divider: false,
      isVisible: true,
    },
  ];
};

export const Table: React.FC<TableProps> = ({
  table,
  columnOrder,
  columns,
  columnVisibility,
  flexRender,
  setSorting,
  selectedRows,
  sorting,
}) => {
  interface ParentEntity {
    label: string;
    value: string;
  }
  const dispatch = useDispatch();
  const { userActions } = useActions();
  const { programId, communityId, organizationId } = useParams();
  const [parentEntity, setParentEntity] = useState<ParentEntity | null>(null);

  useEffect(() => {
    const entity = getParentEntity({ programId, communityId, organizationId });
    if (entity) {
      setParentEntity(entity);
    }
  }, [programId, communityId, organizationId]);

  const DirectoryActions = () => {
    const filteredKeys = filterKeys(columnVisibility, columnOrder);

    return generatingDirectoryActions(
      filteredKeys,
      getAllRowsData(table, columnOrder, columnVisibility, columns),
      parentEntity
    );
  };

  const getUserActions = (data: any) => {
    const modalData = {
      firstName: data.name.firstName,
      lastName: data.name.lastName,
      imageUrl: data.name.imageUrl,
      personId: data.name.personId,
      email: data.name.email,
      contactPreference: data.contactPreference,
      phoneNumber: data.phoneNumber,
      userId: data.name.userId,
      virtual: data.virtual,
      status: data.status,
      role: data.role ?? "",
      path: "directory",
      openAccess: data?.openAccess,
      usedApps: data?.usedApps,
    };
    return userActions(modalData);
  };

  const toggleSorting = (columnId: string) => {
    setSorting((currentSorting: any) => {
      const existingSort = currentSorting.find(
        (sort: any) => sort.columnId === columnId
      );
      const columnsId = snakeCase(columnId);
      if (!existingSort) {
        dispatch(usersSortingAction(columnsId, "asc"));

        return [{ columnId, type: "asc" }];
      } else if (existingSort.type === "asc") {
        dispatch(usersSortingAction(columnsId, "desc"));

        return [{ columnId, type: "desc" }];
      } else {
        dispatch(usersSortingAction("", ""));

        return [];
      }
    });
  };
  const handleClickOnTableColumnHeading = (header: any) =>
    header.column.getCanSort() && toggleSorting(header.column.id);

  return (
    <div className="w-full overflow-hidden overflow-x-auto max-h-[80vh] overflow-y-auto">
      <table className="w-full">
        <TableHead
          table={table}
          sorting={sorting}
          flexRender={flexRender}
          DirectoryActions={DirectoryActions()}
          handleClickOnColumnHead={handleClickOnTableColumnHeading}
        />
        <TableBody
          table={table}
          columnVisibility={columnVisibility}
          getUserActions={getUserActions}
          flexRender={flexRender}
        />
      </table>
    </div>
  );
};

export function filterKeys(
  obj: Record<string, boolean>,
  keys: string[]
): string[] {
  return keys
    .filter((key) => obj[key] && key !== "select")
    .map((key) => snakeCase(key));
}
