import { MAXIMUM_FILE_SIZE } from "constants/FileSize";
import { useEffect, useRef, useState } from "react";
import apiLibrary from "services/api";
import { Header } from "./Components/Header";
import { Toasts } from "view/components/Toasts";
import usePermissions from "hooks/usePermissions";
import _ from "lodash";
import { getCurrentBranding } from "utils/customBranding";
import SIGNLogo from "assets/logo/SIGNLogo";
import ISNSidebarLogo from "assets/logo/ISNSidebarLogo";
import { useParams } from "react-router-dom";
import brandPlaceholder from "assets/images/Placeholders/brandPlaceholderImg.png";
import backgroundPlaceholderImage from "assets/images/Placeholders/background-placeholder-image.jpg";

export const brandLogos: any = {
  isn: <ISNSidebarLogo width={16} height={16} />,
  sign: <SIGNLogo width={28} height={28} />,
};
interface IProps {
  setFieldValue?: any;
  values?: any;
  text?: string;
  editMode?: boolean;
}
export const SystemSettings: React.FC<IProps> = ({
  setFieldValue,
  values,
  text,
  editMode,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { brandId } = useParams();
  const { universalSettings, universalSettingsImages } = usePermissions();

  // if brand Id exist but edit mode is false
  const viewMode = !!brandId && !editMode;

  const [settings, setSettings] = useState([
    {
      key: "login_page_image",
      fileId: null,
      filePath: null,
      title: "Login Page",
    },
    {
      key: "forget_password_page_image",
      fileId: null,
      filePath: null,
      title: "Forgot Password Page",
    },
  ]);

  // const getUniversalSettings = async () => {
  //   try {

  //     if (!brandId) {
  //       const { data } = await apiLibrary.stystemSettings.getUniversalSettings();
  //       const passwordImageData = data?.isnAdmin?.find((item: any) => item?.key === "forget_password_page_image");
  //       const loginImageData = data?.isnAdmin?.find((item: any) => item?.key === "login_page_image");
  //       setFieldValue("passwordImageId", passwordImageData)
  //       setFieldValue("loginImageId", loginImageData)
  //     }

  //   } catch (error: any) {
  //     const errorMsg = error?.response?.data?.message ?? error.message;
  //     Toasts.error(errorMsg);
  //     console.error("Error fetching universal settings:", error);
  //   }
  // };

  // useEffect(() => {
  //   getUniversalSettings();
  // }, []);

  return (
    <div className={`flex flex-col items-start justify-start w-full pb-20`}>
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <div
          className={`flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-10 pt-6 pb-10 rounded-lg bg-bgWhite dark:bg-secondaryLight ${!setFieldValue && "px-8"}`}
        >
          {universalSettingsImages.canViewUniversalSettingsImages && (
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-8">
                <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5">
                  <p
                    className={`font-Overpass font-semibold text-left text-secondaryMid dark:text-textMain capitalize text-lg`}
                  >
                    Background images configuration
                  </p>
                  <p className="text-sm text-left font-Overpass text-textMidLight dark:text-textMain">
                    {text}
                  </p>
                </div>
                <>
                  <div className="flex w-3/4 gap-6">
                    {Object.entries(values) // Convert the values object into an array of [key, value] pairs
                      .filter(
                        ([key, value]) =>
                          key === "loginImageId" || key === "passwordImageId"
                      ) // Filter out keys
                      .map(
                        (
                          [key, value]: any,
                          index // Map over the filtered keys
                        ) => (
                          <ImagePreview
                            imageKey={key} // Use the index as key for React elements, better to use unique identifiers if available
                            fileId={value?.fileId || value?.id} // Assuming the value directly contains the fileId
                            filePath={
                              value?.filePath ||
                              value?.path ||
                              value?.file?.original
                            } // Assuming you need the same value for filePath, adjust if necessary
                            setFieldValue={setFieldValue} // Pass the key as imageKey
                            viewMode={viewMode}
                            values={values}
                          />
                        )
                      )}
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SystemSettings;

interface ImageData {
  imageKey: string;
  fileId: number | null;
  filePath: string | null;
  setFieldValue?: any;
  viewMode?: boolean;
  values?: any;
}

const ImagePreview: React.FC<ImageData> = ({
  imageKey,
  fileId,
  filePath,
  setFieldValue,
  viewMode,
  values,
}) => {
  const { universalSettings, universalSettingsImages } = usePermissions();
  const [isLoading, setIsLoading] = useState(false);

  const currentBrand = getCurrentBranding();
  const CURRENT_BRAND_LOGO = brandLogos[currentBrand];

  const fileInputRef = useRef<HTMLInputElement>(null);

  /**
   * Handles the upload of a user's profile image.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The event triggered by selecting a file for upload.
   */
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageKey: string
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];

        if (!validImageTypes.includes(file.type)) {
          event.target.value = "";
          return Toasts.error(
            "Please select a valid image file (jpg/jpeg/png)"
          );
        }
        const fileSize = file.size / 1024 / 1024; // Calculate the size of the uploaded file in megabytes.
        if (fileSize > 25) {
          return Toasts.error("File size must be less than 25 mb!");
        }

        setIsLoading(true);
        const uploadResponse = await apiLibrary.file.fileUpload(file);
        setFieldValue(imageKey, uploadResponse.data);
        // Toasts.success(message);

        // getUniversalSettings();
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errorMsg);
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * Handles the click event to trigger the file input element.
   */
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="flex flex-col justify-start items-start flex-grow w-[25%] mx-1  pb-1">
      <p
        className={`font-Overpass font-semibold text-left text-secondaryMid dark:text-textMain capitalize text-md mb-2`}
      >
        {imageKey === "loginImageId" ? "Login page" : "Forgot password page"}
      </p>
      <div
        className="flex justify-start items-start self-stretch  flex-shrink-0 h-[244px] relative  rounded-lg"
        style={{
          backgroundImage: `url('${filePath || backgroundPlaceholderImage}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex flex-col justify-start items-center self-stretch  flex-shrink-0 w-[116px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 gap-2 px-2 py-4 bg-bgWhite bg-opacity-90 dark:bg-secondaryLight dark:bg-opacity-90 border border-[#dcdee0] h-full">
          <img
            src={values.logoId?.file?.thumb || brandPlaceholder}
            alt="brand logo"
            className="w-12 h-12 object-contain"
          />
          <div className="relative self-stretch flex-shrink-0 h-2 ">
            <div className="w-[100px] h-2 absolute left-[-1px] top-[-1px] bg-[#dcdee0]" />
          </div>
          <div className="relative self-stretch flex-shrink-0 h-2 ">
            <div className="w-[100px] h-2 absolute left-[-1px] top-[-1px] bg-[#dcdee0]" />
          </div>
          <div className="relative self-stretch flex-shrink-0 h-2 ">
            <div
              className={`w-[100px] h-2 absolute left-[-1px] top-[-1px] rounded-lg bg-primary `}
              style={{ background: values?.theme?.primaryColor }}
            />
          </div>
        </div>
        <div className="relative self-stretch flex-grow">
          <input
            disabled={isLoading}
            type="file"
            className="absolute top-0 left-0 hidden opacity-0 cursor-pointer"
            multiple={false}
            ref={fileInputRef}
            accept="image/jpeg, image/jpg, image/png"
            onChange={(e) => handleImageUpload(e, imageKey)}
          />
          {isLoading ? (
            <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50">
              <div className="inline-flex items-center">
                <svg
                  className="w-12 h-12 mr-2 animate-spin text-secondaryMidLight dark:text-primaryExtraLight"
                  xmlns="https://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            </div>
          ) : (
            <>
              {universalSettingsImages.canEditUniversalSettingsImages &&
                !viewMode && (
                  <button
                    disabled={isLoading}
                    onClick={handleClick}
                    type="button"
                    className="flex justify-start items-start absolute right-[2px] top-0 gap-2 p-0.5 rounded-3xl bg-white"
                  >
                    <svg
                      width={32}
                      height={32}
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="relative flex-shrink-0 w-8 h-8 "
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <rect width={32} height={32} rx={16} fill="white" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.0704 9C13.736 9 13.4238 9.1671 13.2383 9.4453L12.4258 10.6641C11.8694 11.4987 10.9327 12 9.92963 12H9C8.44772 12 8 12.4477 8 13V22C8 22.5523 8.44772 23 9 23H23C23.5523 23 24 22.5523 24 22V13C24 12.4477 23.5523 12 23 12H22.0704C21.0673 12 20.1306 11.4987 19.5742 10.6641L18.7617 9.4453C18.5762 9.1671 18.264 9 17.9296 9H14.0704ZM11.5742 8.3359C12.1306 7.5013 13.0673 7 14.0704 7H17.9296C18.9327 7 19.8694 7.5013 20.4258 8.3359L21.2383 9.5547C21.4238 9.8329 21.736 10 22.0704 10H23C24.6569 10 26 11.3431 26 13V22C26 23.6569 24.6569 25 23 25H9C7.34315 25 6 23.6569 6 22V13C6 11.3431 7.34315 10 9 10H9.92963C10.264 10 10.5762 9.8329 10.7617 9.5547L11.5742 8.3359ZM16 15C14.8954 15 14 15.8954 14 17C14 18.1046 14.8954 19 16 19C17.1046 19 18 18.1046 18 17C18 15.8954 17.1046 15 16 15ZM12 17C12 14.7909 13.7909 13 16 13C18.2091 13 20 14.7909 20 17C20 19.2091 18.2091 21 16 21C13.7909 21 12 19.2091 12 17Z"
                        fill="#2C3236"
                      />
                    </svg>
                  </button>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
