import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FileBackupIcon from "assets/icons/HeroIcons/FileBackupIcon";
import Button from "view/components/Button";
import apiLibrary from "services/api";
import { useSelector } from "react-redux";
import { RootState } from "store";
import LoadingBar from "react-top-loading-bar";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { reportVersionsAction } from "store/runReport";
import { runReportData } from "store/runReport/initialState";
import useCustomBranding from "hooks/useCustomBranding";

export const LoaderReportCard = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [progress, setProgress] = useState(0);
  const [dataFetched, setDataFetched] = useState(false); // Track if data is fetched
  const navigate = useNavigate();
  const { reportsId } = useParams<any>();
  const { primaryColor } = useCustomBranding();
  const abortControllerRef = useRef<AbortController | null>(null); // Reference for the abort controller

  // Fetch report data function with abort signal
  const fetchReportData = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort any ongoing request before starting a new one
    }

    abortControllerRef.current = new AbortController(); // Create a new abort controller for the current request

    try {
      const { data } = await apiLibrary.Reports.getProcessedReportVersion(
        reportsId,
        abortControllerRef.current.signal
      );

      if (data?.id) {
        setProgress(100); // Set progress to 100 once data is successfully fetched
        setDataFetched(true); // Mark the data as fetched to prevent further retries
        dispatch(reportVersionsAction(false));
        // Ensure redirect happens only after progress reaches 100%
        if (progress === 100) {
          navigate(`/reports/${reportsId}/profile`);
        }
      }
    } catch (error: any) {
      if (error.name !== "AbortError") {
        console.error("Error fetching report data", error);
      }
    }
  }, [reportsId, dispatch, navigate, progress]); // Ensure progress is included in dependencies

  // Simulate loader progress and fetch report data after completion
  const simulateLoaderProgress = useCallback(async () => {
    let progress = 10;
    const maxProgress = 100;
    const steps = 3;
    const increment = (maxProgress - progress) / steps;

    for (let step = 1; step <= steps; step++) {
      if (dataFetched) return; // Exit early if data has already been fetched

      progress += increment;
      setProgress(Math.min(maxProgress, progress));

      // Simulate loading delay (no intervals or timeouts)
      await new Promise((resolve: any) => resolve());
    }

    // Fetch report data after loader progress completes
    if (!dataFetched) {
      await fetchReportData(); // Fetch report data
    }
  }, [dataFetched, fetchReportData]);

  useEffect(() => {
    if (!dataFetched) {
      simulateLoaderProgress(); // Start the loader if data hasn't been fetched yet
    }

    return () => {
      // Cleanup: Abort any pending requests when the component unmounts or when fetching completes
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [dataFetched, reportsId, simulateLoaderProgress]);
  return (
    <div className="flex flex-col items-center justify-center w-full p-6 bg-bgWhite">
      <div className="flex flex-col items-center justify-center w-full">
        <FileBackupIcon fill={primaryColor}/>
        <p className="pt-6 text-lg font-semibold text-center">
          Please wait! Your report is being processed...
        </p>
        <p className="text-sm text-center textMidLight">
          Processing... Approximate remaining time about 1 min...
        </p>
      </div>
      {/* {progress > 0 && ( */}
      <div className="my-4 spinner">
        <TailSpin
          height="40"
          width="40"
          color={primaryColor}
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass="tailspin-loader"
          visible={true}
          strokeWidth={3}
        />
      </div>
      <div className="flex items-center gap-2 loadingbar">
        <div className="relative w-[30vw] h-1 bg-secondary_25">
          <LoadingBar
            color={primaryColor}
            progress={progress}
            height={3}
            containerClassName="!absolute"
            shadow={false}
            waitingTime={1000000}
          />
        </div>
        <p className="text-sm font-Overpass text-textMid dark:text-caption ">
          {progress}%
        </p>
      </div>
      <div className="flex justify-center mt-6 mb-4">
        <Button
          type="button"
          text="Go to Reports Directory"
          filledColor="primary"
          outlinedColor="primary"
          textColor="textWhite"
          className="px-5 py-2 "
          width="35"
          height="13"
          fontStyle="font-semibold"
          variant="outlined"
          onClick={() => navigate(`/reports/list`)}
        />
      </div>
      <div>
        <p className="text-primary text-lg">
          Your report will keep processing in the background, you will recieve a
          notification when the report is done
        </p>
      </div>
    </div>
  );
};
export default LoaderReportCard;
