import sortObjectByOrder from "utils/SortObjectByOrder";
import {
  GET_ALL_USERS,
  START_LOADING_USERS,
  ERROR_LOADING_USERS,
  STOP_LOADING_USERS,
  RESET_DATA,
} from "./action-types";
import initialState from "./initialState";
import { userDirectoryColumnsOrder } from "utils/directoryColumnsOrders";

export const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_USERS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_USERS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_USERS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_USERS: {
      const users = action.payload.users.map((item: any) => {

        const {
          address,
          apartment,
          city,
          communities,
          country,
          email,
          firstName,
          forms,
          id,
          lastActive,
          lastName,
          contactPreference,
          memberSince,
          observations,
          personId,
          personImage,
          phoneNumber,
          programs,
          role,
          state,
          status,
          zipCode,
          openAccess,
          usedApps,
        } = item;
        const data = {
          id: personId,
          name: {
            firstName: firstName ?? "",
            lastName: lastName ?? "",
            email,
            personId,
            imageUrl:
              personImage?.file !== null
                && personImage?.file?.original,
            userId: id,
          },
          personId,
          contactPreference,
          role: role ? role.sort() : [],
          lastActive,
          status,
          programs,
          forms,
          communities,
          observations,
          memberSince,
          email,
          phoneNumber,
          city,
          country,
          zipCode,
          state,
          address,
          apartment,
          openAccess,
          usedApps,
        };
        return sortObjectByOrder(data, userDirectoryColumnsOrder);
      });

      return {
        ...state,
        data: {
          users: users,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    }
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
