import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import { sentenceCase } from "change-case";
import { useSelector } from "react-redux";
import { DarkMode } from "store/darkMode/initialState";
import { RootState } from "store";
import useCustomBranding from "hooks/useCustomBranding";

type MaterialUiSelectProps = {
  options: any[];
  valueKey: string;
  labelKey: string;
  initialValue: string | undefined;
  handleChange: (value: any) => void;
  error?: any;
  height?: number;
  width?: any;
};

export function MaterialUiSelect({
  options = [],
  valueKey,
  labelKey,
  initialValue,
  handleChange,
  error = null,
  height = 40,
  width = 340,
}: MaterialUiSelectProps) {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );
  const { primaryColor } = useCustomBranding();
  useEffect(() => {
    if (initialValue !== undefined) {
      const initialOption = options.find(
        (option) => option[valueKey] === initialValue
      );
      setSelectedOption(initialOption || null);
    }
  }, [initialValue, options, valueKey]);

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    const selectedObj = options.find(
      (option) => option[valueKey].toString() === selectedValue
    );
    setSelectedOption(selectedObj || null);
    handleChange(selectedObj);
  };

  return (
    <>
      <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: ${primaryColor} !important; 
          }
        
        `}
      </style>
      <div className="w-full">
        <FormControl sx={{ m: 0, width: width }}>
          <Select
            displayEmpty
            value={selectedOption ? selectedOption[valueKey]?.toString() : ""}
            onChange={handleSelect}
            className=" hover:border-lineMid dark:border-lineLight"
            input={<OutlinedInput />}
            IconComponent={CustomIcon}
            renderValue={(selected) => {
              return selectedOption ? (
                <p className="dark:text-textMain">{selectedOption[labelKey]}</p>
              ) : (
                <em className="dark:text-textMain">Select an option</em>
              );
            }}
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              height: height,
              "& .MuiOutlinedInput-root": {
                // border: "2px solid #ccc",
                // borderRadius: "4px",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  // borderColor: "#ccc",
                },
              "& .MuiSelect-select": {
                height: "100%",
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            {options.map((option) => {
              return (
                <MenuItem
                  className={`dark:bg-secondaryLight ${
                    selectedOption &&
                    selectedOption[valueKey]?.toString() ===
                      option[valueKey]?.toString()
                      ? "dark:text-primary"
                      : "dark:text-textMain"
                  } dark:hover:text-primary`}
                  key={option[valueKey]}
                  value={option[valueKey]?.toString()}
                >
                  {option[labelKey]}
                </MenuItem>
              );
            })}
          </Select>
          {error && (
            <FormHelperText
              style={{
                color: "#BF200B",
              }}
            >
              {error}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    </>
  );
}

/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */

function CustomIcon(props: any) {
  return (
    <CheveronDownIcon
      height={16}
      width={16}
      preserveAspectRatio="xMidYMid meet"
      style={{ marginRight: "12px", cursor: "pointer" }}
      {...props}
    />
  );
}
