import generateUniqueId from "generate-unique-id";
import { panelNames } from "..";
import {
  convertJsonOfGeographicalPanel,
  convertJsonOfHarvestPanel,
  convertUpComingJsonOfGeographicalPanel,
  convertUpComingJsonOfHarvestPanel,
  convertUpComingQuestion,
} from "../dataConverters";
import {
  GENERAL_INFO_QUESTIONS_NAME,
  SAMPLE_QUESTIONS_NAME,
  SPECIES_DETAILS_QUESTIONS_NAME,
} from "../panelsQuestionsName";
import { panelsName } from "../panelsTitle";
import questionTypes from "../questionTypes";
import convertQuestionObj, {
  convertTheChoicesOfQuestions,
} from "./convertQuestionObj";
import { v4 as uuidv4 } from "uuid";
import { baseURL } from "config";
import store from "store";

function structureFormJSON(
  originalJson: any,
  panelsJson: any,
  globalStoreData: any,
  format = "request",
  dataSourcesData?: any
) {
  const panels = Object.values(panelsName);
  const updatedJson = structuredClone(originalJson);
  const elements = updatedJson.pages[0].elements;
  let updatedElements;
  const programId =
    store.getState()?.formSurveyJsDetails?.formDetails?.programId;

  if (format === "request") {
    updatedElements = elements?.map((ele: any, index: number) => {
      if (panels.toString().includes(ele.name)) {
        const panel = panelsJson.find((p: any) => p.name === ele.name);
        if (ele.name === panelsName.HARVEST) {
          return convertJsonOfHarvestPanel(ele, globalStoreData, ele);
        } else if (ele.name === panelsName.GEOGRAPHICAL_INFORMATION) {
          return convertJsonOfGeographicalPanel(ele, globalStoreData, ele);
        } else {
          const updatedPanelJson = {
            ...ele,
          };
          const updatedElements = updatedPanelJson.elements?.map((e: any) => {
            if (
              (e.type === questionTypes.tagbox ||
                e.type === questionTypes.dropdown) &&
              e.choicesByUrl &&
              e.choicesByUrl.url
            ) {
              const updatedElement = { ...e };
              updatedElement.choices = setTheChoicesOfDropdowns(
                e.name,
                dataSourcesData
              );
              updatedElement.elementId = uuidv4();
              updatedElement.questionId = uniqueIdGenertorInNumber();
              const updatedElementWithChoices =
                convertTheChoicesOfQuestions(updatedElement);
              return updatedElementWithChoices;
            } else {
              return convertQuestionObj(e, globalStoreData);
            }
          });
          updatedPanelJson.elements = updatedElements
            ? [...updatedElements]
            : [];
          // console.log("updatedElements", updatedElements)

          return updatedPanelJson;
        }
      }
      if (ele.type === questionTypes.paneldynamic) {
        const updatedTemplateElements = ele.templateElements?.map((e: any) => {
          if (ele.type === questionTypes.regionPicker) {
            const updatedElement = { ...ele };
            updatedElement.choices = setTheChoicesOfDropdowns(
              ele.type,
              dataSourcesData
            );
            updatedElement.elementId = uuidv4();
            updatedElement.questionId = uniqueIdGenertorInNumber();
            updatedElement.choicesByUrl = {
              url: `${baseURL}/common/dropdown/regions?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
              titleName: "text",
              valueName: "value",
            };
            updatedElement.updatedDefaultValue = updatedElement?.defaultValue
              ? updatedElement.choices?.find(
                  (choice: any) =>
                    choice?.value === updatedElement?.defaultValue
                )
              : undefined;
            return updatedElement;
          }

          return convertQuestionObj(e, globalStoreData);
        });

        ele.templateElements = updatedTemplateElements
          ? [...updatedTemplateElements]
          : [];

        return ele;
      } else if (questionTypes.panel && panelNames.includes(ele.name)) {
        const question = { ...updateElementsRequiredStatusDynamically(ele) };
        const updatedElements = question.elements?.map((e: any) => {
          return convertQuestionObj(e, globalStoreData);
        });
        updatedElements.elements = updatedElements ? [...updatedElements] : [];

        return updatedElements;
      } else if (ele.type === questionTypes.panel) {
        const updatedElements = ele.elements?.map((e: any) => {
          if (e.type === questionTypes.regionPicker) {
            const updatedElement = { ...e };
            updatedElement.choices = setTheChoicesOfDropdowns(
              e.type,
              dataSourcesData
            );

            updatedElement.elementId = uuidv4();
            updatedElement.questionId = uniqueIdGenertorInNumber();
            updatedElement.choicesByUrl = {
              url: `${baseURL}/common/dropdown/regions?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
              titleName: "text",
              valueName: "value",
            };
            updatedElement.updatedDefaultValue = updatedElement?.defaultValue
              ? updatedElement.choices?.find(
                  (choice: any) =>
                    choice?.value === updatedElement?.defaultValue
                )
              : undefined;
            return updatedElement;
          }

          return convertQuestionObj(e, globalStoreData);
        });

        ele.elements = updatedElements ? [...updatedElements] : [];

        return ele;
      } else if (ele.type === questionTypes.regionPicker) {
        const updatedElement = { ...ele };
        updatedElement.choices = setTheChoicesOfDropdowns(
          ele.type,
          dataSourcesData
        );

        updatedElement.elementId = uuidv4();
        updatedElement.questionId = uniqueIdGenertorInNumber();
        updatedElement.choicesByUrl = {
          url: `${baseURL}/common/dropdown/regions?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
          titleName: "text",
          valueName: "value",
        };
        updatedElement.updatedDefaultValue = updatedElement?.defaultValue
          ? updatedElement.choices?.find(
              (choice: any) => choice?.value === updatedElement?.defaultValue
            )
          : undefined;
        return updatedElement;
      }

      return convertQuestionObj(ele, globalStoreData);
    });
  } else if (format === "response") {
    updatedElements = elements?.map((ele: any, index: number) => {
      // if element is panel then updating it
      if (panels.toString().includes(ele.name)) {
        if (ele.name === panelsName.HARVEST) {
          return convertUpComingJsonOfHarvestPanel(ele);
        } else if (ele.name === panelsName.GEOGRAPHICAL_INFORMATION) {
          return convertUpComingJsonOfGeographicalPanel(ele);
        } else if (ele.panelType === "static" && ele.type === "paneldynamic") {
          return {
            ...ele,
            type: "panel",
            name: ele.name,
            titleLocation: ele.titleLocation,
            elements: ele.templateElements,
          };
        }
        return {
          ...ele,
          type: ele.type,
          name: ele.name,
          titleLocation: ele.titleLocation,
        };
      } else {
        if (ele.elements) {
          const elements = ele.elements?.map((e: any) => {
            return convertUpComingQuestion(e);
          });

          ele.elements = elements ? [...elements] : [];
        } else if (ele.templateElements) {
          const templateElements = ele.templateElements?.map((e: any) => {
            return convertUpComingQuestion(e);
          });

          ele.templateElements = templateElements ? [...templateElements] : [];
        }
      }

      return ele;
    });
  }

  updatedJson.pages[0].elements = updatedElements;

  return updatedJson;
}

export default structureFormJSON;

function updateTemplateElementsRequiredStatusDynamically(panel: any) {
  if (panel.elements) {
  }
  panel.elements?.forEach((element: any) => {
    if (element.type !== "html") {
      let flagName =
        "show" + element.name.charAt(0).toUpperCase() + element.name.slice(1);

      if (panel.hasOwnProperty(flagName)) {
        element.isRequired = panel[flagName];
      } else {
        element.isRequired = true;
      }
    }
  });
  const updatedTemplateElements = panel.elements?.filter(
    (element: any) => element.type !== "html"
  );
  panel.elements = updatedTemplateElements;

  return panel;
}

function updateElementsRequiredStatusDynamically(panel: any) {
  panel.elements = panel.elements?.map((element: any) => {
    if (element.type === "html") {
      return element;
    }
    const capitalizedName =
      element.name.charAt(0).toUpperCase() + element.name.slice(1);
    const flagName = `show${capitalizedName}`;
    const requireFlagName = `require${element.name}`;
    if (panel.hasOwnProperty(flagName)) {
      element.isRequired = panel[flagName];
      panel[requireFlagName] = panel[flagName];
    }

    return element;
  });

  return panel;
}

function setTheChoicesOfDropdowns(
  fieldName: any,
  dataSourcesData: {
    observers: any[];
    types: any[];
    objects: any[];
    categories: any[];
    species: any[];
    regions: any[];
  }
) {
  switch (fieldName) {
    case SPECIES_DETAILS_QUESTIONS_NAME.Category:
      return dataSourcesData.categories;
    case SPECIES_DETAILS_QUESTIONS_NAME.SpecieName:
      return dataSourcesData.species;
    case SPECIES_DETAILS_QUESTIONS_NAME.Type:
      return dataSourcesData.types;
    case SPECIES_DETAILS_QUESTIONS_NAME.ObjectName:
      return dataSourcesData.objects;
    case SAMPLE_QUESTIONS_NAME.Sampler:
    case GENERAL_INFO_QUESTIONS_NAME.Observers:
    case GENERAL_INFO_QUESTIONS_NAME.DataEntry:
    case GENERAL_INFO_QUESTIONS_NAME.Reported:
      return dataSourcesData.observers;
    case questionTypes.regionPicker:
      return dataSourcesData.regions;
    default:
      return [];
  }
}

export function uniqueIdGenertorInNumber() {
  const questionId = generateUniqueId({
    length: 8,
    useLetters: false,
    useNumbers: true,
  });
  return Number(questionId);
}

// import generateUniqueId from "generate-unique-id";
// import { panelNames } from "..";
// import {
//   convertJsonOfGeographicalPanel,
//   convertJsonOfHarvestPanel,
//   convertUpComingJsonOfGeographicalPanel,
//   convertUpComingJsonOfHarvestPanel,
//   convertUpComingQuestion,
// } from "../dataConverters";
// import {
//   GENERAL_INFO_QUESTIONS_NAME,
//   SAMPLE_QUESTIONS_NAME,
//   SPECIES_DETAILS_QUESTIONS_NAME,
// } from "../panelsQuestionsName";
// import { panelsName } from "../panelsTitle";
// import questionTypes from "../questionTypes";
// import convertQuestionObj, {
//   convertTheChoicesOfQuestions,
// } from "./convertQuestionObj";
// import { v4 as uuidv4 } from "uuid";
// import { baseURL } from "config";
// import store from "store";

// // Helper to update region picker element common logic
// const updateRegionPickerElement = (
//   element: any,
//   programId: any,
//   dataSourcesData: any
// ): any => {
//   const updatedElement: any = { ...element };
//   updatedElement.choices = setTheChoicesOfDropdowns(
//     questionTypes.regionPicker,
//     dataSourcesData
//   );
//   updatedElement.elementId = uuidv4();
//   updatedElement.questionId = uniqueIdGenertorInNumber();
//   updatedElement.choicesByUrl = {
//     url: `${baseURL}/common/dropdown/regions?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
//     titleName: "text",
//     valueName: "value",
//   };
//   updatedElement.updatedDefaultValue = updatedElement.defaultValue
//     ? updatedElement.choices.find(
//         (choice: any) => choice.value === updatedElement.defaultValue
//       )
//     : undefined;
//   return updatedElement;
// };

// // Processes standard question element inside a panel
// const processStandardElement = (
//   element: any,
//   globalStoreData: any,
//   dataSourcesData: any
// ): any => {
//   if (
//     (element.type === questionTypes.tagbox ||
//       element.type === questionTypes.dropdown) &&
//     element.choicesByUrl &&
//     element.choicesByUrl.url
//   ) {
//     const updatedElement: any = { ...element };
//     updatedElement.choices = setTheChoicesOfDropdowns(
//       element.name,
//       dataSourcesData
//     );
//     updatedElement.elementId = uuidv4();
//     updatedElement.questionId = uniqueIdGenertorInNumber();
//     return convertTheChoicesOfQuestions(updatedElement);
//   }
//   return convertQuestionObj(element, globalStoreData);
// };

// // Update panel elements for "request" format
// const processPanelRequest = (
//   panel: any,
//   panelsJson: any,
//   globalStoreData: any,
//   dataSourcesData: any,
//   programId: any
// ): any => {
//   if (panel.name === panelsName.HARVEST) {
//     return convertJsonOfHarvestPanel(panel, globalStoreData, panel);
//   } else if (panel.name === panelsName.GEOGRAPHICAL_INFORMATION) {
//     return convertJsonOfGeographicalPanel(panel, globalStoreData, panel);
//   }

//   const updatedPanel: any = { ...panel };
//   updatedPanel.elements = (panel.elements || []).map((elem: any) => {
//     if (elem.type === questionTypes.regionPicker) {
//       return updateRegionPickerElement(elem, programId, dataSourcesData);
//     }
//     return processStandardElement(elem, globalStoreData, dataSourcesData);
//   });
//   return updatedPanel;
// };

// // Main structureFormJSON function
// function structureFormJSON(
//   originalJson: any,
//   panelsJson: any,
//   globalStoreData: any,
//   format: any = "request",
//   dataSourcesData?: any
// ): any {
//   const panels: any = Object.values(panelsName);
//   const updatedJson: any = structuredClone(originalJson);
//   const elements: any = updatedJson.pages[0].elements;
//   const programId: any =
//     store.getState()?.formSurveyJsDetails?.formDetails?.programId;
//   let updatedElements: any;

//   if (format === "request") {
//     updatedElements = elements.map((ele: any) => {
//       if (panels.toString().includes(ele.name)) {
//         const panelFromJson: any = panelsJson.find(
//           (p: any) => p.name === ele.name
//         );
//         return processPanelRequest(
//           ele,
//           panelsJson,
//           globalStoreData,
//           dataSourcesData,
//           programId
//         );
//       }
//       if (ele.type === questionTypes.paneldynamic) {
//         ele.templateElements = (ele.templateElements || []).map(
//           (templateElem: any) => {
//             if (ele.type === questionTypes.regionPicker) {
//               return updateRegionPickerElement(ele, programId, dataSourcesData);
//             }
//             return convertQuestionObj(templateElem, globalStoreData);
//           }
//         );
//         return ele;
//       }
//       if (ele.type === questionTypes.regionPicker) {
//         return updateRegionPickerElement(ele, programId, dataSourcesData);
//       }
//       if (ele.type === questionTypes.panel && panelNames.includes(ele.name)) {
//         const panelWithRequiredStatus: any =
//           updateElementsRequiredStatusDynamically(ele);
//         panelWithRequiredStatus.elements = (
//           panelWithRequiredStatus.elements || []
//         ).map((el: any) => convertQuestionObj(el, globalStoreData));
//         return panelWithRequiredStatus;
//       }
//       if (ele.type === questionTypes.panel) {
//         ele.elements = (ele.elements || []).map((el: any) => {
//           if (el.type === questionTypes.regionPicker) {
//             return updateRegionPickerElement(el, programId, dataSourcesData);
//           }
//           return convertQuestionObj(el, globalStoreData);
//         });
//         return ele;
//       }
//       return convertQuestionObj(ele, globalStoreData);
//     });
//   } else if (format === "response") {
//     updatedElements = elements.map((ele: any) => {
//       if (panels.toString().includes(ele.name)) {
//         if (ele.name === panelsName.HARVEST) {
//           return convertUpComingJsonOfHarvestPanel(ele);
//         } else if (ele.name === panelsName.GEOGRAPHICAL_INFORMATION) {
//           return convertUpComingJsonOfGeographicalPanel(ele);
//         } else if (ele.panelType === "static" && ele.type === "paneldynamic") {
//           return {
//             ...ele,
//             type: "panel",
//             name: ele.name,
//             titleLocation: ele.titleLocation,
//             elements: ele.templateElements,
//           };
//         }
//         return {
//           ...ele,
//           type: ele.type,
//           name: ele.name,
//           titleLocation: ele.titleLocation,
//         };
//       } else {
//         if (ele.elements) {
//           ele.elements = (ele.elements || []).map((child: any) =>
//             convertUpComingQuestion(child)
//           );
//         } else if (ele.templateElements) {
//           ele.templateElements = (ele.templateElements || []).map(
//             (child: any) => convertUpComingQuestion(child)
//           );
//         }
//       }
//       return ele;
//     });
//   }

//   updatedJson.pages[0].elements = updatedElements;
//   return updatedJson;
// }

// export default structureFormJSON;

// // Updates required status for each element inside a panel (for panel type)
// function updateElementsRequiredStatusDynamically(panel: any): any {
//   panel.elements = (panel.elements || []).map((element: any) => {
//     if (element.type === "html") return element;
//     const flagName: any = `show${element.name.charAt(0).toUpperCase()}${element.name.slice(1)}`;
//     const requireFlagName: any = `require${element.name}`;
//     if (panel.hasOwnProperty(flagName)) {
//       element.isRequired = panel[flagName];
//       panel[requireFlagName] = panel[flagName];
//     }
//     return element;
//   });
//   return panel;
// }

// function setTheChoicesOfDropdowns(fieldName: any, dataSourcesData: any): any {
//   switch (fieldName) {
//     case SPECIES_DETAILS_QUESTIONS_NAME.Category:
//       return dataSourcesData.categories;
//     case SPECIES_DETAILS_QUESTIONS_NAME.SpecieName:
//       return dataSourcesData.species;
//     case SPECIES_DETAILS_QUESTIONS_NAME.Type:
//       return dataSourcesData.types;
//     case SPECIES_DETAILS_QUESTIONS_NAME.ObjectName:
//       return dataSourcesData.objects;
//     case SAMPLE_QUESTIONS_NAME.Sampler:
//     case GENERAL_INFO_QUESTIONS_NAME.Observers:
//     case GENERAL_INFO_QUESTIONS_NAME.DataEntry:
//     case GENERAL_INFO_QUESTIONS_NAME.Reported:
//       return dataSourcesData.observers;
//     case questionTypes.regionPicker:
//       return dataSourcesData.regions;
//     default:
//       return [];
//   }
// }

// export function uniqueIdGenertorInNumber(): any {
//   const questionId: any = generateUniqueId({
//     length: 8,
//     useLetters: false,
//     useNumbers: true,
//   });
//   return Number(questionId);
// }
