import {
  ERROR_LOADING_BRAND,
  GET_BRAND,
  RESET_DATA,
  START_LOADING_BRAND,
  STOP_LOADING_BRAND,
} from "./action-types";
import initialState from "./initialState";

export const singleBrandReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_BRAND:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_BRAND:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_BRAND:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_BRAND:

      const {
        id,
        adminUrl,
        appUrl,
        brandCode,
        brandName,
        favicon,
        forgetPasswordImage,
        interoperability,
        loginImage,
        logo,
        organization,
        status,
        theme,
        type,
        dnsPropagationStatus
      } = action.payload;

      const brandData = {
        id,
        adminUrl,
        appUrl,
        brandCode,
        brandName,
        favicon,
        forgetPasswordImage,
        interoperability,
        loginImage,
        logo,
        organization,
        organizationImage: organization?.organizationImage,
        status,
        theme,
        type,
        dnsPropagationStatus
      };

      return {
        ...state,
        data: {
          brand: brandData,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
