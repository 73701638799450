import React from "react";
// Components
import { UploadFavicon, UploadLogo } from "./Components";
import { ViewLogos } from "./Components/View";
import { useParams } from "react-router-dom";

interface IProps {
  setFieldValue: any;
  values: any;
  errors: any;
  editMode?: any;
}

export const Logos: React.FC<IProps> = ({ setFieldValue, values, errors, editMode }) => {
  const { brandId } = useParams();
  const viewMode = !!brandId && !editMode;

  return (
    <div className="w-full bg-bgWhite py-6 rounded-lg">
      <div className="px-12">
        <p className="text-secondaryMid text-xl font-Overpass font-bold">Logos</p>
        <div className="flex gap-6 py-2">
          {viewMode ?
            <ViewLogos
              values={values}
            />
            :
            <>
              {/* Uplaod Logo */}
              <div className="w-2/4">
                <UploadLogo
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                />{" "}
              </div>
              {/* Upload Favicon */}
              <div className="w-2/4">
                <UploadFavicon
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                />{" "}
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};
