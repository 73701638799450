import React from "react";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import CheckBoxUnchecked from "assets/icons/HeroIcons/CheckBoxUnchecked";
import { FormControlLabel } from "@mui/material";
import CheckBoxChecked from "assets/icons/HeroIcons/CheckBoxChecked";
import useCustomBranding from "hooks/useCustomBranding";

interface CustomCheckboxProps extends CheckboxProps {
  label?: any;
  labelPlacement?: "top" | "start" | "bottom" | "end";
  className?: string;
  padding?: string;
  required?: boolean;
  labelClassName?: string;
  checked?: any;
  ref?: any;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  labelPlacement,
  className,
  padding,
  required,
  labelClassName,
  checked,
  ref,
  ...props
}) => {
  const checkboxSize = "22px"; // Adjust the size as per your requirement
  const style = padding ? { padding } : {};
  const { primaryColor } = useCustomBranding();

  if (!label) {
    return (
      <Checkbox
        checked={checked}
        sx={{
          "&:hover": { bgcolor: "transparent" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiSvgIcon-root": {
            fill: primaryColor,
          },
          ...style,
        }}
        checkedIcon={
          <CheckBoxChecked
            width={24}
            height={24}
            fill={primaryColor}
            // style={{ transform: `scale(${parseInt(checkboxSize, 10) / 24})` }}
          />
        }
        icon={
          <CheckBoxUnchecked
            width={24}
            height={24}
            // style={{ transform: `scale(${parseInt(checkboxSize, 10) / 24})` }}
          />
        }
        inputProps={{ "aria-label": "Checkbox demo" }}
        ref={ref}
        {...props}
      />
    );
  }

  return (
    <>
      <style>
        {/* {`.MuiSvgIcon-root {
          fill: ${primaryColor} !important; 
          }`} */}
      </style>
      <FormControlLabel
        className={`${className}`}
        control={
          <Checkbox
            checked={checked}
            sx={{
              "&:hover": { bgcolor: "transparent" },
              "& .MuiSvgIcon-root": {
                fill: primaryColor,
              },
            }}
            checkedIcon={
              <CheckBoxChecked
                width={24}
                height={24}
                fill={primaryColor}
                // style={{ transform: `scale(${parseInt(checkboxSize, 10) / 24})` }}
              />
            }
            required={required}
            icon={
              <CheckBoxUnchecked
                width={24}
                height={24}
                // style={{ transform: `scale(${parseInt(checkboxSize, 10) / 24})` }}
              />
            }
            inputProps={{ "aria-label": "Checkbox demo" }}
            {...props}
            ref={ref}
          />
        }
        label={<p className={`${labelClassName} font-Overpass`}>{label}</p>}
        labelPlacement={labelPlacement}
      />
    </>
  );
};

export default CustomCheckbox;
