import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons/HeroIcons";
import useCustomBranding from "hooks/useCustomBranding";
import usePermissions from "hooks/usePermissions";
import Button from "view/components/Button";

export const SubmissionButtons = ({ handleGoBackBtn, handleSubmit }: any) => {
  const { reminders } = usePermissions();
  const { primaryColor } = useCustomBranding();
  return (
    <div className="flex items-center self-stretch flex-grow-0 flex-shrink-0 gap-2">
      <Button
        type="button"
        text="Back"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        iconPosition="before"
        icon={<ArrowLeftIcon fill={primaryColor} />}
        fontStyle="font-semibold"
        variant="outlined"
        onClick={handleGoBackBtn}
      />
      <Button
        type="button"
        onClick={handleSubmit}
        text={reminders.canViewTargetMembersReminders ? "Next" : "Save"}
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        iconPosition="after"
        icon={
          reminders.canViewTargetMembersReminders ? (
            <ArrowRightIcon fill={primaryColor} />
          ) : (
            <></>
          )
        }
        fontStyle="font-semibold"
        variant="outlined"
      />
    </div>
  );
};
