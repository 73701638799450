import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import { sentenceCase } from "change-case";
import useCustomBranding from "hooks/useCustomBranding";

type MaterialUiSelectProps = {
  options: any[];
  valueKey: string;
  labelKey: string;
  initialValue: any;
  handleChange: (value: any) => void;
  error: any;
  height?: number;
  width?: any;
  placeholder?: any;
  helperText?: any;
  disabled?: boolean;
};

export function MaterialUiSelect({
  options = [],
  valueKey,
  labelKey,
  initialValue,
  handleChange,
  placeholder,
  error = null,
  height = 40,
  width = "100%",
  helperText = null,
  disabled,
}: MaterialUiSelectProps) {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const {primaryColor} = useCustomBranding();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (options.length > 0) {
      const initialOption = options.find(
        (option) => option[valueKey] == initialValue
      );
      setSelectedOption(initialOption || null);
    }
  }, [initialValue, options, valueKey, labelKey]);

  const handleSelect = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    const selectedObj = options.find(
      (option) => option[valueKey].toString() === selectedValue
    );
    handleChange(selectedObj);
    setSelectedOption(selectedObj || null);
  };
  const handleIconClick = () => {
    if (!disabled) {
      setOpen((prev) => !prev);
    }
  };
  return (
    <>
      <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: ${primaryColor} !important; 
          }
        
        `}
      </style>
      <div className="w-full">
        <FormControl sx={{ m: 0, width: width }}>
          <Select
            displayEmpty
            defaultValue={initialValue}
            value={initialValue}
            disabled={disabled}
            onChange={handleSelect}
            className="w-full dark:border-lineLight"
            input={<OutlinedInput />}
            // IconComponent={CustomIcon}
            IconComponent={(props: any) => (
              <CustomIcon
                {...props}
                onClick={handleIconClick}
                open={open}
                isDisabled={disabled}
              />
            )}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            renderValue={(selected) => {
              return selectedOption ? (
                <p className="dark:text-textMain">{selectedOption[labelKey]}</p>
              ) : (
                <p className="text-[#9ca3af] dark:text-textMain">
                  {placeholder}
                </p>
              );
            }}
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              height: height,
              "& .MuiOutlinedInput-root": {
                border: "2px solid #ccc",
                borderRadius: "4px",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#000",
                },
              "& .MuiSelect-select": {
                height: "100%",
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            {placeholder && (
              <MenuItem disabled value="">
                <p className="color-[#9ca3af] dark:text-textMain">
                  {placeholder}
                </p>
              </MenuItem>
            )}

            {options.map((option) => {
              return (
                <MenuItem
                  className={`dark:bg-secondaryLight ${
                    selectedOption &&
                    selectedOption[valueKey]?.toString() ===
                      option[valueKey]?.toString()
                      ? "dark:text-primary"
                      : "dark:text-textMain"
                  } dark:hover:text-primary`}
                  key={option[valueKey]}
                  value={option[valueKey]?.toString()}
                >
                  {option[labelKey]}
                </MenuItem>
              );
            })}
          </Select>

          {error ? (
            <FormHelperText
              style={{
                color: "#BF200B",
                marginLeft: "0px",
              }}
            >
              {error}
            </FormHelperText>
          ) : (
            <>
              {helperText !== null && (
                <p className="flex-grow w-full text-xs text-left text-textMidLight dark:text-textMain">
                  {helperText}
                </p>
              )}
            </>
          )}
        </FormControl>
      </div>
    </>
  );
}

/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */

function CustomIcon(props: any, isDisabled: boolean) {
  const { open, onClick } = props;

  return (
    <CheveronDownIcon
      height={16}
      width={16}
      onClick={onClick}
      className={`-0 -0 w-6 h-6 relative ${open ? "transform rotate-180" : ""}`}
      preserveAspectRatio="xMidYMid meet"
      style={{ marginRight: "12px", cursor: isDisabled ? "auto" : "pointer" }}
    />
  );
}
