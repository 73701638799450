import React from "react";
import AttachFilePlaceholder from "assets/images/Placeholders/csv-file-placeholder.png";
import PlusIcon from "assets/icons/HeroIcons/PlusIcon";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { IProgram } from "store/programProfile/initialState";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  openAddFileModalAction,
  openDeleteFileModalAction,
  openImageLightBoxModalAction,
  openVideoLightBoxModalAction,
} from "store/modals/reducer.actions";
import DotCentralIcon from "assets/icons/HeroIcons/DotCentralIcon";
import videoThumbnailImg from "assets/images/video-thumbnail.png";
import pdfThumbnailImg from "assets/images/pdf-thumbnail.png";
import PopOverMenu from "view/components/PopOverMenu";
import { DotsVerticalIcon } from "assets/icons/HeroIcons";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import useActions from "hooks/useActions";
import { Toasts } from "view/components/Toasts";
import usePermissions from "hooks/usePermissions";
import useCustomBranding from "hooks/useCustomBranding";

export const FilesListingCard = () => {
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );
  const { programs } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const {primaryColor} = useCustomBranding();

  function getFileType(type: string): string {
    if (type.match(/(jpg|jpeg|png|gif|bmp|tiff)$/)) {
      return "image";
    } else if (type === "pdf") {
      return "pdf";
    } else if (
      type.match(/(mp4|mp3|mpeg|avi|mkv|wmv|flv|mov)$/) ||
      type === "video"
    ) {
      return "video";
    } else if (type.match(/(doc|docx|xls|xlsx|ppt|pptx)$/)) {
      return "document";
    } else if (type.match(/(txt|csv)$/)) {
      return "text";
    } else if (type.match(/(zip|rar|7z)$/)) {
      return "archive";
    } else {
      return "unknown";
    }
  }

  function getThumbnailSource(file: any, type: any) {
    if (type === null) return null;
    const fileType = getFileType(type);
    switch (fileType) {
      case "video":
        return videoThumbnailImg;
      case "image":
        return file;
      case "pdf":
        return pdfThumbnailImg;
      default:
        return null;
    }
  }
  const handleAddFilesModel = () => {
    dispatch(
      openAddFileModalAction({ programId: programProfile.id, path: "profile" })
    );
  };
  function getFileTypeData(filePath: any, type: any) {
    console.log(filePath, "filePath", type, "typetype");

    if (type.match(/(jpg|jpeg|png|gif|bmp|tiff)$/)) {
      dispatch(
        openImageLightBoxModalAction({
          width: 1080,
          height: 1620,
          filePath: filePath.large,
        })
      );
    } else if (type === "pdf") {
      window.open(filePath, "_blank");
    } else if (type === "mp3" || type === "mpeg") {
      window.open(filePath.original, "_blank");
    } else if (type.match(/(mp4|avi|mkv|wmv|flv|mov)$/)) {
      dispatch(
        openVideoLightBoxModalAction({
          filePath: filePath.original,
          fileType: type,
        })
      );
    } else if (type.match(/(doc|docx|xls|xlsx|ppt|pptx)$/)) {
      // Handle document files
    } else if (type.match(/(txt|csv)$/)) {
      // Handle text files
    } else if (type.match(/(zip|rar|7z)$/)) {
      // Handle archive files
    } else {
      return "unknown";
    }
  }
  const programsActions = (files: any) => {
    const copyLinkToClipboard = async (link: string) => {
      console.log(link, "link");

      try {
        await navigator.clipboard.writeText(link);
        Toasts.copy("Link copied to clipboard", "bottom-center");
      } catch (error: any) {
        Toasts.error("Failed to copy link to clipboard");
      }
    };

    const handleDownload = async (url: string, fileName: string) => {
      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }

        const blob = await response.blob();

        // Create a temporary link element
        const link = document.createElement("a");
        const objectUrl = window.URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = fileName; // Specify the desired file name

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger the click event to start the download
        link.click();

        // Clean up: Remove the link element and revoke the object URL to free memory
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectUrl);
      } catch (error: any) {
        console.error("Error downloading file:", error);
      }
    };


    const handleDeleteModalAction = () => {
      dispatch(
        openDeleteFileModalAction({
          programId: programProfile.id,
          id: files.id,
        })
      );
    };

    return [
      {
        label: "Preview",
        onClick: () => {
          getFileTypeData(files?.image ?? files?.image, files?.type);
        },
        divider: false,
        isVisible: true,
      },
      {
        label: "Download",
        onClick: () => {
          handleDownload(files?.image?.original ?? files?.image, files?.name);
        },
        divider: false,
        isVisible: true,
      },
      {
        label: "Copy link",
        onClick: () => {
          copyLinkToClipboard(files?.image?.original || files.image);
        },
        divider: false,
        isVisible: true,
      },
      {
        label: "Delete",
        onClick: () => {
          handleDeleteModalAction();
        },
        divider: false,
        isVisible: programs.canDeleteEntity,
      },
    ];
  };

  const getProgramsActions = (files: any) => {
    const modalData = {};

    return programsActions(modalData);
  };

  return (
    <div className="flex flex-col p-6 rounded-lg shadow-md bg-bgWhite hover:shadow-xl dark:bg-secondaryLight dark:border dark:border-lineLight">
      <div className="flex items-center justify-between mb-1">
        <p className="text-base font-semibold text-secondaryMid dark:text-textMain">
          {`${programProfile?.filesCount} Attached Files`}
        </p>

        <div className="flex items-center gap-6">
          {programProfile.status !== "completed" &&
            programProfile.canEditProgramDetail && (
              <button
                onClick={() => handleAddFilesModel()}
                className="flex items-center"
              >
                <PlusIcon fill={primaryColor} className="dark:fill-caption" />
                <p className="ml-2 text-sm font-semibold text-primary hover:text-primaryMid dark:text-textMain">
                  Add
                </p>
              </button>
            )}
          <Link
            to={`/programs/${programProfile?.id}/files`}
            className="flex-grow-0 flex-shrink-0 "
          >
            <p className="text-sm font-semibold text-primary hover:text-primaryMid dark:text-primaryMidLight ">
              See All
            </p>
          </Link>
        </div>
      </div>

      {programProfile?.files?.map((files: any, index: number) => {
        const fileType = getFileType(files.type); // Get the file type
        const placeholderSrc = getThumbnailSource(files, fileType); // Get the placeholder image source
        return (
          <div className="flex justify-between mt-3">
            <div className="flex gap-4">
              <div className="mt-2">
                <div className="w-8 ">
                  <img
                    src={
                      files?.image?.thumb
                        ? files?.image?.thumb || files.image?.original
                        : placeholderSrc
                    }
                    alt={files.name}
                    className="object-cover w-8 h-8 rounded-full"
                  />
                </div>
              </div>
              <div className="">
                <p className="text-[15px] flex-wrap break-all dark:text-textMain">
                  {files?.name}
                </p>
                <div className="flex items-center">
                  <span className="text-sm dark:text-caption ">
                    {files?.fileSize}
                  </span>
                  <DotCentralIcon />
                  <span className="text-sm dark:text-caption ">
                    {files?.type}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <PopOverMenu icon={<DotsVerticalIcon />}>
                <ActionDropDownMenu actions={programsActions(files)} />
              </PopOverMenu>
            </div>
          </div>
        );
      })}
    </div>
  );
};
