import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Components
import { PageHeading } from "./Components";
import { Breadcrumb } from "Components/Users";
import Button from "view/components/Button";

// generate dynamic links for brands
const generateDynamicBreadcrumbLinks = (
  brandId: any,
  brandName: any,
  viewMode?: boolean,
) => {
  switch (true) {
    case Boolean(brandId):
      return [
        { path: `/brands/list`, label: `Brands` },
        { path: `/brands/${brandId}`, label: brandName },
        ...(!viewMode
          ? [
              {
                path: ``,
                label: `Edit Brand`,
              },
            ]
          : []),
      ];
    default:
      return [
        { path: `/brands/list`, label: `Brands` },
        {
          path: `/brands/add`,
          label: `Add Brand`,
        },
      ];
  }
};


export const Header = ({
  brandName,
  isSubmitting,
  isValid,
  handleSubmit,
  editMode,
  values,
  resetForm
}: {
  brandName: string;
  isSubmitting: any;
  isValid: any;
  handleSubmit: any;
  editMode: any;
  values: any;
  resetForm: any;
}) => {
  const { brandId } = useParams();
  const navigate = useNavigate();

  // if brand Id exist but edit mode is false
  const viewMode = !!brandId && !editMode;

  // brand links
  const brandLinks = generateDynamicBreadcrumbLinks(brandId ?? "", brandName, viewMode);

  return (
    <div className="py-4">
      <div className="flex justify-between">
        <Breadcrumb links={brandLinks} />

        {viewMode ?

          values.status !== "archived" && <Button
              type="button"
              text={"Edit"}
              filledColor="primary"
              outlinedColor="primary"
              textColor="textPrimary"
              variant="outlined"
              fontStyle="font-semibold"
              onClick={() => navigate(`/brands/${brandId}/edit`)}
            />
          
          :
          <div className="flex gap-2">
            <Button
              type="button"
              text={"Cancel"}
              filledColor="primary"
              outlinedColor="primary"
              textColor="textPrimary"
              variant="outlined"
              fontStyle="font-semibold"
              onClick={() => navigate(`/brands/${brandId || "list"}`)}
            />
            <Button
              text={"Save"}
              type="submit"
              onClick={handleSubmit}
              textColor="textWhite"
              disabled={isSubmitting || !isValid}
            />
          </div>
        }
      </div>
      {viewMode && (
        <div className="py-4 flex gap-2 items-center">
          <PageHeading brandName={brandName} />
          {values.status === "archived" && <p className={`text-[12px] font-Overpass py-1 px-2 rounded-lg capitalize max-w-max ${getStatusColor(values.status || "completed")}`}>
              {values.status || "completed"}</p>}
        </div>
      )}
    </div>
  );
};

const getStatusColor = (name: string): string => {
  switch (name) {
    case "archived":
      return "bg-primaryExtraLight text-primaryMid";
    case "public":
    case "completed":
    case "active":
      return "bg-tertiaryExtraLight text-tertiaryMid";
    case "pending":
      return "bg-tersharyMigLight text-primaryTencentLight";
    default:
      return "white";
  }
};