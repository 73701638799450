import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeArchiveOrRestoreBrandModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";
import { categoriesStatusAction } from "store/filters";
import { brandsStatusAction } from "store/filters/reducer.actions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  // width: auto,
  backgroundColor: "#ffff",
};

export const archiveRestoreModal: React.FC<any> = () => {
  const { archiveRestoreBrandModal } = useSelector(
    (state: RootState) => state.modals
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    dispatch(closeArchiveOrRestoreBrandModalAction());
  };

  const archiveOrRestoreBrand = async () => {
    setIsLoading(true);
    try {
      const res = await apiLibrary.Brands.archiveOrRestoreBrand(
        archiveRestoreBrandModal.data.id,
        archiveRestoreBrandModal?.data?.status === "archived"
          ? "active"
          : "archived"
      );
      Toasts.success(res.message);
      dispatch(
        brandsStatusAction(
          archiveRestoreBrandModal?.data?.status === "archived"
            ? "active"
            : "archived"
        )
      );

      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      // Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={archiveRestoreBrandModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
          <div
            className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain capitalize">
                  {archiveRestoreBrandModal?.data?.status === "active"
                    ? "Archive Brand"
                    : "Restore Brand"}
                </p>
              </div>
              <button onClick={handleClose} type="button">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            {archiveRestoreBrandModal?.data?.organizationStatus ===
            "archived" ? (
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
                <p className="text-sm text-left text-textMid dark:text-caption">
                  This brand cannot be restored because the Organization that
                  owns the brand is archived. Restore the Organization first, in
                  order to be able to restore the brand.
                </p>
              </div>
            ) : (
              <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
                {archiveRestoreBrandModal?.data?.status === "active" ? (
                  <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-caption">
                    Are you sure you would like to archive this brand? It will
                    make the brand inaccessible to all users of this brand, and
                    thus observers can no longer log new observations from this
                    brand.
                  </p>
                ) : (
                  <p className="flex-grow w-[432px] text-sm text-left text-textMid  dark:text-caption">
                    Are you sure want to restore this brand? It will make the
                    brand visible again in the Custom Observer app. All users of
                    the brand will be able to access the brand again and thus
                    observers can again register new observations from this
                    brand.
                  </p>
                )}
              </div>
            )}
            {archiveRestoreBrandModal?.data?.organizationStatus !==
              "archived" && (
              <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                <Button
                  type="button"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 h-11"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                />

                <Button
                  disabled={isLoading}
                  type="button"
                  text={
                    archiveRestoreBrandModal?.data?.status === "active"
                      ? "Archive"
                      : "Restore"
                  }
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="w-24 h-11"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                  onClick={archiveOrRestoreBrand}
                />
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};
