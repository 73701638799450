/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeAudioModal } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "12px",
  backgroundColor: "#ffff",
};
interface FormValues {
  password: string;
  forceToUpdatePassword: boolean;
}

export const AudioModal = () => {
  const { audioModal } = useSelector((state: RootState) => state.modals);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const handleClose = () => {
    dispatch(closeAudioModal());
  };

  return (
    <Modal
      open={audioModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box
        sx={style}
        className="dark:bg-secondaryLight bg-bgPrimaryLight w-[500px] h-[200px]"
      >
        {" "}
        <div className="relative">
          <button className="absolute right-2 top-2" onClick={handleClose}>
            <XCloseIcon />
          </button>
        </div>
        <div className="h-full flex flex-col justify-center">
          <div className="py-6 px-4">
            <audio src={audioModal?.data?.url} controls />
            {audioModal?.data?.transcription && (
              <div className="py-2 mt-4">
                <p className="text-sm px-2">
                  <span className="font-medium">Transcription: </span>
                  <span>{audioModal?.data?.transcription}</span>{" "}
                </p>
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};
