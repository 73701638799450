export const OPEN_PHONE_NUMBER_VERIFICATION_MODAL =
  "OPEN_PHONE_NUMBER_VERIFICATION_MODAL";
export const CLOSE_PHONE_NUMBER_VERIFICATION_MODAL =
  "CLOSE_PHONE_NUMBER_VERIFICATION_MODAL";
export const OPEN_EMAIL_VERIFICATION_MODAL = "OPEN_EMAIL_VERIFICATION_MODAL";
export const CLOSE_EMAIL_VERIFICATION_MODAL = "CLOSE_EMAIL_VERIFICATION_MODAL";

export const OPEN_DELETE_ACCOUNT_MODAL = "OPEN_DELETE_ACCOUNT_MODAL";
export const CLOSE_DELETE_ACCOUNT_MODAL = "CLOSE_DELETE_ACCOUNT_MODAL";

export const OPEN_DELETE_ACCOUNT_REQUEST_MODAL =
  "OPEN_DELETE_ACCOUNT_REQUEST_MODAL";
export const CLOSE_DELETE_ACCOUNT_REQUEST_MODAL =
  "CLOSE_DELETE_ACCOUNT_REQUEST_MODAL";
export const OPEN_CREATE_PASSWORD_MODAL = "OPEN_CREATE_PASSWORD_MODAL";
export const CLOSE_CREATE_PASSWORD_MODAL = "CLOSE_CREATE_PASSWORD_MODAL";
export const OPEN_RESET_PASSWORD_MODAL = "OPEN_RESET_PASSWORD_MODAL";
export const CLOSE_RESET_PASSWORD_MODAL = "CLOSE_RESET_PASSWORD_MODAL";
export const OPEN_REINVITE_USER_MODAL = "OPEN_REINVITE_USER_MODAL";
export const CLOSE_REINVITE_USER_MODAL = "CLOSE_REINVITE_USER_MODAL";
export const OPEN_DEACTIVATE_USER_MODAL = "OPEN_DEACTIVATE_USER_MODAL";
export const CLOSE_DEACTIVATE_USER_MODAL = "CLOSE_DEACTIVATE_USER_MODAL";
export const OPEN_RESTORE_PERSON_MODAL = "OPEN_RESTORE_PERSON_MODAL";
export const CLOSE_RESTORE_PERSON_MODAL = "CLOSE_RESTORE_PERSON_MODAL";
export const OPEN_CONFIRMATION_ASSIGN_AS_ADMINA_MODAL =
  "OPEN_CONFIRMATION_ASSIGN_AS_ADMINA_MODAL";
export const CLOSE_CONFIRMATION_ASSIGN_AS_ADMINA_MODAL =
  "CLOSE_CONFIRMATION_ASSIGN_AS_ADMINA_MODAL";

export const OPEN_ORGANIZATION_PROFILE_DETAIL_MODAL =
  "OPEN_ORGANIZATION_PROFILE_DETAIL_MODAL";
export const CLOSE_ORGANIZATION_PROFILE_DETAIL_MODAL =
  "CLOSE_ORGANIZATION_PROFILE_DETAIL_MODAL";
export const OPEN_CREATE_COMMUNITY_MODAL = "OPEN_CREATE_COMMUNITY_MODAL";
export const CLOSE_CREATE_COMMUNITY_MODAL = "CLOSE_CREATE_COMMUNITY_MODAL";
export const OPEN_EDIT_COMMUNITY_MODAL = "OPEN_EDIT_COMMUNITY_MODAL";
export const CLOSE_EDIT_COMMUNITY_MODAL = "CLOSE_EDIT_COMMUNITY_MODAL";
export const OPEN_COMMUNITY_ACCESS_MODAL = "OPEN_COMMUNITY_ACCESS_MODAL";
export const CLOSE_COMMUNITY_ACCESS_MODAL = "CLOSE_COMMUNITY_ACCESS_MODAL";
export const OPEN_ADD_MEMBERS_TO_COMMUNITY_MODAL =
  "OPEN_ADD_MEMBERS_TO_COMMUNITY_MODAL";
export const CLOSE_ADD_MEMBERS_TO_COMMUNITY_MODAL =
  "CLOSE_ADD_MEMBERS_TO_COMMUNITY_MODAL";

export const OPEN_CREATE_ORGANIZATION_MODAL = "OPEN_CREATE_ORGANIZATION_MODAL";
export const CLOSE_CREATE_ORGANIZATION_MODAL =
  "CLOSE_CREATE_ORGANIZATION_MODAL";
export const OPEN_ADD_EXISTING_ORGANIZATIONS_MODAL =
  "OPEN_ADD_EXISTING_ORGANIZATIONS_MODAL";
export const CLOSE_ADD_EXISTING_ORGANIZATIONS_MODAL =
  "CLOSE_ADD_EXISTING_ORGANIZATIONS_MODAL";

export const OPEN_ADD_EXISTING_COMMUNITY_MODAL =
  "OPEN_ADD_EXISTING_COMMUNITY_MODAL";
export const CLOSE_ADD_EXISTING_COMMUNITY_MODAL =
  "CLOSE_ADD_EXISTING_COMMUNITY_MODAL";

export const OPEN_ADD_EDIT_TEMPLATE_FORM_MODAL =
  "OPEN_ADD_EDIT_TEMPLATE_FORM_MODAL";
export const CLOSE_ADD_EDIT_TEMPLATE_FORM_MODAL =
  "CLOSE_ADD_EDIT_TEMPLATE_FORM_MODAL";
export const OPEN_ADD_FORM_MODAL = "OPEN_ADD_FORM_MODAL";
export const CLOSE_ADD_FORM_MODAL = "CLOSE_ADD_FORM_MODAL";

export const OPEN_FORM_DETAILS_MODAL = "OPEN_FORM_DETAILS_MODAL";
export const CLOSE_FORM_DETAILS_MODAL = "CLOSE_FORM_DETAILS_MODAL";

export const OPEN_IMPORT_SUMMARY_MODAL = "OPEN_IMPORT_SUMMARY_MODAL";
export const CLOSE_IMPORT_SUMMARY_MODAL = "CLOSE_IMPORT_SUMMARY_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_FORM_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_FORM_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_FORM_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_FORM_MODAL";

export const OPEN_DELETE_A_FORM_MODAL = "OPEN_DELETE_A_FORM_MODAL";
export const CLOSE_DELETE_A_FORM_MODAL = "CLOSE_DELETE_A_FORM_MODAL";

export const OPEN_ADD_PERSON_MODAL = "OPEN_ADD_PERSON_MODAL";
export const CLOSE_ADD_PERSON_MODAL = "CLOSE_ADD_PERSON_MODAL";

export const OPEN_INVITE_REGISTERED_EXTERNAL_REVIEWER_MODAL =
  "OPEN_INVITE_REGISTERED_EXTERNAL_REVIEWER_MODAL";
export const CLOSE_INVITE_REGISTERED_EXTERNAL_REVIEWER_MODAL =
  "CLOSE_INVITE_REGISTERED_EXTERNAL_REVIEWER_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_COMMUNITY_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_COMMUNITY_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_COMMUNITY_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_COMMUNITY_MODAL";

export const OPEN_ARCHIVE_OR_PUBLISH_ORGANIZATION_MODAL =
  "OPEN_ARCHIVE_OR_PUBLISH_ORGANIZATION_MODAL";
export const CLOSE_ARCHIVE_OR_PUBLISH_ORGANIZATION_MODAL =
  "CLOSE_ARCHIVE_OR_PUBLISH_ORGANIZATION_MODAL";

export const OPEN_ASSIGN_AN_ADMIN_TO_COMMUNITY_MODAL =
  "OPEN_ASSIGN_AN_ADMIN_TO_COMMUNITY_MODAL";
export const CLOSE_ASSIGN_AN_ADMIN_TO_COMMUNITY_MODAL =
  "CLOSE_ASSIGN_AN_ADMIN_TO_COMMUNITY_MODAL";

export const OPEN_DELETE_A_COMMUNITY_MODAL = "OPEN_DELETE_A_COMMUNITY_MODAL";
export const CLOSE_DELETE_A_COMMUNITY_MODAL = "CLOSE_DELETE_A_COMMUNITY_MODAL";

export const OPEN_REMOVE_PERSON_COMMUNITY_MODAL =
  "OPEN_REMOVE_PERSON_COMMUNITY_MODAL";
export const CLOSE_REMOVE_PERSON_COMMUNITY_MODAL =
  "CLOSE_REMOVE_PERSON_COMMUNITY_MODAL";

export const OPEN_ADD_PROGRAM_MODAL = "OPEN_ADD_PROGRAM_MODAL";
export const CLOSE_ADD_PROGRAM_MODAL = "CLOSE_ADD_PROGRAM_MODAL";
export const OPEN_EDIT_PROGRAM_MODAL = "OPEN_EDIT_PROGRAM_MODAL";
export const CLOSE_EDIT_PROGRAM_MODAL = "CLOSE_EDIT_PROGRAM_MODAL";

export const OPEN_ADD_FILE_MODAL = "OPEN_ADD_FILE_MODAL";
export const CLOSE_ADD_FILE_MODAL = "CLOSE_ADD_FILE_MODAL";
export const OPEN_ARCHIVE_OR_RESTORE_PROGRAM_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_PROGRAM_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_PROGRAM_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_PROGRAM_MODAL";

export const OPEN_ASSIGN_AN_ADMIN_TO_PROGRAM_MODAL =
  "OPEN_ASSIGN_AN_ADMIN_TO_PROGRAM_MODAL";
export const CLOSE_ASSIGN_AN_ADMIN_TO_PROGRAM_MODAL =
  "CLOSE_ASSIGN_AN_ADMIN_TO_PROGRAM_MODAL";

export const OPEN_COMPLETE_PROGRAM_MODAL = "OPEN_COMPLETE_PROGRAM_MODAL";
export const CLOSE_COMPLETE_PROGRAM_MODAL = "CLOSE_COMPLETE_PROGRAM_MODAL";
export const OPEN_REMOVE_FROM_PROGRAM_MODAL = "OPEN_REMOVE_FROM_PROGRAM_MODAL";
export const CLOSE_REMOVE_FROM_PROGRAM_MODAL =
  "CLOSE_REMOVE_FROM_PROGRAM_MODAL";

export const OPEN_MAP_SETTINGS_PROGRAM_MODAL =
  "OPEN_MAP_SETTINGS_PROGRAM_MODAL";
export const CLOSE_MAP_SETTINGS_PROGRAM_MODAL =
  "CLOSE_MAP_SETTINGS_PROGRAM_MODAL";

export const OPEN_MANAGE_PROGRAM_MEMBERS_MODAL =
  "OPEN_MANAGE_PROGRAM_MEMBERS_MODAL";
export const CLOSE_MANAGE_PROGRAM_MEMBERS_MODAL =
  "CLOSE_MANAGE_PROGRAM_MEMBERS_MODAL";
export const OPEN_ADD_EXISTING_COMMUNITIES_OR_ORGANIZATIONS_TO_PROGRAM_MODAL =
  "OPEN_ADD_EXISTING_COMMUNITIES_OR_ORGANIZATIONS_TO_PROGRAM_MODAL";
export const CLOSE_ADD_EXISTING_COMMUNITIES_OR_ORGANIZATIONS_TO_PROGRAM_MODAL =
  "CLOSE_ADD_EXISTING_COMMUNITIES_OR_ORGANIZATIONS_TO_PROGRAM_MODAL";
export const OPEN_SIGNOUT_MODAL = "OPEN_SIGNOUT_MODAL";
export const CLOSE_SIGNOUT_MODAL = "CLOSE_SIGNOUT_MODAL";

export const CLOSE_DASHBOARD_ALERT_MODAL = "CLOSE_DASHBOARD_ALERT_MODAL";
export const OPEN_DASHBOARD_ALERT_CONFIRMATION_MODAL =
  "OPEN_DASHBOARD_ALERT_CONFIRMATION_MODAL";
export const CLOSE_DASHBOARD_ALERT_CONFIRMATION_MODAL =
  "CLOSE_DASHBOARD_CONFIRMATION_ALERT_MODAL";

export const OPEN_VIDEO_LIGHTBOX_MODAL = "OPEN_VIDEO_LIGHTBOX_MODAL";
export const CLOSE_VIDEO_LIGHTBOX_MODAL = "VIDEO_LIGHTBOX_MODAL";

export const OPEN_IMAGE_LIGHTBOX_MODAL = "OPEN_IMAGE_LIGHTBOX_MODAL";
export const CLOSE_IMAGE_LIGHTBOX_MODAL = "IMAGE_LIGHTBOX_MODAL";

export const OPEN_IMAGE_VIDEO_LIGHTBOX_MODAL =
  "OPEN_IMAGE_VIDEO_LIGHTBOX_MODAL";
export const CLOSE_IMAGE_VIDEO_LIGHTBOX_MODAL =
  "CLOSE_IMAGE_VIDEO_LIGHTBOX_MODAL";

export const OPEN_DELETE_A_FILE_MODAL = "OPEN_DELETE_A_FILE_MODAL";
export const CLOSE_DELETE_A_FILE_MODAL = "CLOSE_DELETE_A_FILE_MODAL";

export const OPEN_ADD_EDIT_DUPLICATE_PROTOCOL_MODAL =
  "OPEN_ADD_EDIT_DUPLICATE_PROTOCOL_MODAL";
export const CLOSE_ADD_EDIT_DUPLICATE_PROTOCOL_MODAL =
  "CLOSE_ADD_EDIT_DUPLICATE_PROTOCOL_MODAL";

export const OPEN_PROTOCOL_FILE_MODAL = "OPEN_PROTOCOL_FILE_MODAL,";
export const CLOSE_PROTOCOL_FILE_MODAL = "CLOSE_PROTOCOL_FILE_MODAL,";

export const OPEN_MANAGE_CONNECTION_PROTOCOL_MODAL =
  "OPEN_MANAGE_CONNECTION_PROTOCOL_MODAL";
export const CLOSE_MANAGE_CONNECTION_PROTOCOL_MODAL =
  "CLOSE_MANAGE_CONNECTION_PROTOCOL_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_PROTOCOL_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_PROTOCOL_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_PROTOCOL_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_PROTOCOL_MODAL";

export const OPEN_CREATE_OR_EDIT_DATA_ACCESS_POLICY_MODAL =
  "OPEN_CREATE_OR_EDIT_DATA_ACCESS_POLICY_MODAL";
export const CLOSE_CREATE_OR_EDIT_DATA_ACCESS_POLICY_MODAL =
  "CLOSE_CREATE_OR_EDIT_DATA_ACCESS_POLICY_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_OBSERVATION_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_OBSERVATION_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_OBSERVATION_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_OBSERVATION_MODAL";

export const OPEN_REMOVE_MANAGER_MODAL = "OPEN_REMOVE_MANAGER_MODAL";
export const CLOSE_REMOVE_MANAGER_MODAL = "CLOSE_REMOVE_MANAGER_MODAL";

export const OPEN_NOTIFICATIONS_CENTER_MODAL =
  "OPEN_NOTIFICATIONS_CENTER_MODAL";
export const CLOSE_NOTIFICATIONS_CENTER_MODAL =
  "CLOSE_NOTIFICATIONS_CENTER_MODAL";

export const OPEN_SUCCESSFULL_MESSAGE_INVITE_MANAGER_MODAL =
  "OPEN_SUCCESSFULL_MESSAGE_INVITE_MANAGER_MODAL";
export const CLOSE_SUCCESSFULL_MESSAGE_INVITE_MANAGER_MODAL =
  "CLOSE_SUCCESSFULL_MESSAGE_INVITE_MANAGER_MODAL";

export const OPEN_DELETE_MANAGER_MODAL = "OPEN_DELETE_MANAGER_MODAL";
export const CLOSE_DELETE_MANAGER_MODAL = "CLOSE_DELETE_MANAGER_MODAL";

export const OPEN_INVITE_DATA_END_VIEWER_MODAL =
  "OPEN_INVITE_DATA_END_VIEWER_MODAL";
export const CLOSE_INVITE_DATA_END_VIEWER_MODAL =
  "CLOSE_INVITE_DATA_END_VIEWER_MODAL";

export const OPEN_REVIEWER_DETAILS_MODAL = "OPEN_REVIEWER_DETAILS_MODAL";
export const CLOSE_REVIEWER_DETAILS_MODAL = "CLOSE_REVIEWER_DETAILS_MODAL";

export const OPEN_CHAT_ARCHIVE_MODAL = "OPEN_CHAT_ARCHIVE_MODAL";
export const CLOSE_CHAT_ARCHIVE_MODAL = "CLOSE_CHAT_ARCHIVE_MODAL";

export const OPEN_CHAT_DELETE_MODAL = "OPEN_CHAT_DELETE_MODAL";
export const CLOSE_CHAT_DELETE_MODAL = "CLOSE_CHAT_DELETE_MODAL";

export const OPEN_ADD_MANAGER_MODAL = "OPEN_ADD_MANAGER_MODAL";
export const CLOSE_ADD_MANAGER_MODAL = "CLOSE_ADD_MANAGER_MODAL";
export const OPEN_INVITE_EXTERNAL_REVIEWER_QUALITY_ASSURANCE_OBSERVATION_MODAL =
  "OPEN_INVITE_EXTERNAL_REVIEWER_QUALITY_ASSURANCE_OBSERVATION_MODAL";
export const CLOSE_INVITE_EXTERNAL_REVIEWER_QUALITY_ASSURANCE_OBSERVATION_MODAL =
  "CLOSE_INVITE_EXTERNAL_REVIEWER_QUALITY_ASSURANCE_OBSERVATION_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_QUALITY_ASSURANCE_OBSERVATION_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_QUALITY_ASSURANCE_OBSERVATION_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_QUALITY_ASSURANCE_OBSERVATION_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_QUALITY_ASSURANCE_OBSERVATION_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_CATEGORY_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_CATEGORY_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_CATEGORY_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_CATEGORY_MODAL";
export const OPEN_ADD_OR_EDIT_CATEGORY_MODAL =
  "OPEN_ADD_OR_EDIT_CATEGORY_MODAL";
export const CLOSE_ADD_OR_EDIT_CATEGORY_MODAL =
  "CLOSE_ADD_OR_EDIT_CATEGORY_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_BEHAVIOR_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_BEHAVIOR_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_BEHAVIOR_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_BEHAVIOR_MODAL";
export const OPEN_ADD_OR_EDIT_BEHAVIOR_MODAL =
  "OPEN_ADD_OR_EDIT_BEHAVIOR_MODAL";
export const CLOSE_ADD_OR_EDIT_BEHAVIOR_MODAL =
  "CLOSE_ADD_OR_EDIT_BEHAVIOR_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_CONDITIONS_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_CONDITIONS_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_CONDITIONS_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_CONDITIONS_MODAL";
export const OPEN_ADD_OR_EDIT_CONDITIONS_MODAL =
  "OPEN_ADD_OR_EDIT_CONDITIONS_MODAL";
export const CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL =
  "CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_BEHAVIORS_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_BEHAVIORS_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_BEHAVIORS_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_BEHAVIORS_MODAL";
export const OPEN_ADD_OR_EDIT_BEHAVIORS_MODAL =
  "OPEN_ADD_OR_EDIT_BEHAVIORS_MODAL";
export const CLOSE_ADD_OR_EDIT_BEHAVIORS_MODAL =
  "CLOSE_ADD_OR_EDIT_BEHAVIORS_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_TYPE_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_TYPE_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_TYPE_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_TYPE_MODAL";
export const OPEN_ADD_OR_EDIT_TYPE_MODAL = "OPEN_ADD_OR_EDIT_TYPE_MODAL";
export const CLOSE_ADD_OR_EDIT_TYPE_MODAL = "CLOSE_ADD_OR_EDIT_TYPE_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_MARKER_STYLES_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_MARKER_STYLES_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_MARKER_STYLES_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_MARKER_STYLES_MODAL";
export const OPEN_ADD_OR_EDIT_MARKER_STYLES_MODAL =
  "OPEN_ADD_OR_EDIT_MARKER_STYLES_MODAL";
export const CLOSE_ADD_OR_EDIT_MARKER_STYLES_MODAL =
  "CLOSE_ADD_OR_EDIT_MARKER_STYLES_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_MARKER_GROUPS_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_MARKER_GROUPS_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_MARKER_GROUPS_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_MARKER_GROUPS_MODAL";
export const OPEN_ADD_OR_EDIT_MARKER_GROUPS_MODAL =
  "OPEN_ADD_OR_EDIT_MARKER_GROUPS_MODAL";
export const CLOSE_ADD_OR_EDIT_MARKER_GROUPS_MODAL =
  "CLOSE_ADD_OR_EDIT_MARKER_GROUPS_MODAL";

export const OPEN_ADD_OR_EDIT_SPECIE_MODAL = "OPEN_ADD_OR_EDIT_SPECIE_MODAL";
export const CLOSE_ADD_OR_EDIT_SPECIE_MODAL = "CLOSE_ADD_OR_EDIT_SPECIE_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_SPECIE_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_SPECIE_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_SPECIE_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_SPECIE_MODAL";

export const OPEN_RE_USE_SPECIES_LIST_MODAL = "OPEN_RE_USE_SPECIES_LIST_MODAL";
export const CLOSE_RE_USE_SPECIES_LIST_MODAL =
  "CLOSE_RE_USE_SPECIES_LIST_MODAL";

export const OPEN_ADD_OR_EDIT_OBJECT_MODAL = "OPEN_ADD_OR_EDIT_OBJECT_MODAL";
export const CLOSE_ADD_OR_EDIT_OBJECT_MODAL = "CLOSE_ADD_OR_EDIT_OBJECT_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_OBJECT_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_OBJECT_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_OBJECT_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_OBJECT_MODAL";

export const OPEN_RE_USE_OBJECTS_LIST_MODAL = "OPEN_RE_USE_OBJECTS_LIST_MODAL";
export const CLOSE_RE_USE_OBJECTS_LIST_MODAL =
  "CLOSE_RE_USE_OBJECTS_LIST_MODAL";

export const OPEN_DELETE_A_TAG_MODAL = "OPEN_DELETE_A_TAG_MODAL";
export const CLOSE_DELETE_A_TAG_MODAL = "CLOSE_DELETE_A_TAG_MODAL";
export const OPEN_ADD_OR_EDIT_TAG_MODAL = "OPEN_ADD_OR_EDIT_TAG_MODAL";
export const CLOSE_ADD_OR_EDIT_TAG_MODAL = "CLOSE_ADD_OR_EDIT_TAG_MODAL";

export const OPEN_NOTIFICATIONS_RECIPIENTS_MANAGER_MODAL =
  "OPEN_NOTIFICATIONS_RECIPIENTS_MANAGER_MODAL";
export const CLOSE_REMOVE_NOTIFICATIONS_RECIPIENTS_MODAL =
  "CLOSE_REMOVE_NOTIFICATIONS_RECIPIENTS_MODAL";
export const OPEN_ADD_EXISTING_SPECIES_OR_OBJECTS_TO_COMMUNITY_MODAL =
  "OPEN_ADD_EXISTING_SPECIES_OR_OBJECTS_TO_COMMUNITY_MODAL";
export const CLOSE_ADD_EXISTING_SPECIES_OR_OBJECTS_TO_COMMUNITY_MODAL =
  "CLOSE_ADD_EXISTING_SPECIES_OR_OBJECTS_TO_COMMUNITY_MODAL";

export const OPEN_NOTIFICATIONS_SUMMARY_MODAL =
  "OPEN_NOTIFICATIONS_SUMMARY_MODAL";
export const CLOSE_NOTIFICATIONS_SUMMARY_MODAL =
  "CLOSE_NOTIFICATIONS_SUMMARY_MODAL";
export const OPEN_PAUSE_RESUME_DELETE_NOTIFICATION_MODAL =
  "OPEN_PAUSE_RESUME_DELETE_NOTIFICATION_MODAL";
export const CLOSE_PAUSE_RESUME_DELETE_NOTIFICATION_MODAL =
  "CLOSE_PAUSE_RESUME_DELETE_NOTIFICATION_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_GEOGRAPHY_LAYER_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_GEOGRAPHY_LAYER_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_GEOGRAPHY_LAYER_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_GEOGRAPHY_LAYER_MODAL";

export const OPEN_ARCHIVE_RESTORE_DELETE_REPORT_MODAL =
  "OPEN_ARCHIVE_RESTORE_DELETE_REPORT_MODAL";
export const CLOSE_ARCHIVE_RESTORE_DELETE_REPORT_MODAL =
  "CLOSE_ARCHIVE_RESTORE_DELETE_REPORT_MODAL";
export const OPEN_REPORTS_SUMMARY_MODAL = "OPEN_REPORTS_SUMMARY_MODAL";
export const CLOSE_REPORTS_SUMMARY_MODAL = "CLOSE_REPORTS_SUMMARY_MODAL";

export const OPEN_REPORTS_MAP_MODAL = "OPEN_REPORTS_MAP_MODAL";
export const CLOSE_REPORTS_MAP_MODAL = "CLOSE_REPORTS_MAP_MODAL";

export const OPEN_PROCESSED_REPORT_MODAL = "OPEN_PROCESSED_REPORT_MODAL";
export const CLOSE_PROCESSED_REPORT_MODAL = "CLOSE_PROCESSED_REPORT_MODAL";

export const OPEN_EDIT_COMMUNITY_TAGS_MODAL = "OPEN_EDIT_COMMUNITY_TAGS_MODAL";
export const CLOSE_EDIT_COMMUNITY_TAGS_MODAL =
  "CLOSE_EDIT_COMMUNITY_TAGS_MODAL";

export const OPEN_EDIT_PROGRAM_TAGS_MODAL = "OPEN_EDIT_PROGRAM_TAGS_MODAL";
export const CLOSE_EDIT_PROGRAM_TAGS_MODAL = "CLOSE_EDIT_PROGRAM_TAGS_MODAL";

export const OPEN_ADD_EDIT_DUPLICATE_ROLE_MODAL =
  "OPEN_ADD_EDIT_DUPLICATE_ROLE_MODAL";
export const CLOSE_ADD_EDIT_DUPLICATE_ROLE_MODAL =
  "CLOSE_ADD_EDIT_DUPLICATE_ROLE_MODAL";

export const OPEN_EDIT_PERMISSIONS_MODAL = "OPEN_EDIT_PERMISSIONS_MODAL";
export const CLOSE_EDIT_PERMISSIONS_MODAL = "CLOSE_EDIT_PERMISSIONS_MODAL";

export const OPEN_DELETE_ROLE_MODAL = "OPEN_DELETE_ROLE_MODAL";
export const CLOSE_DELETE_ROLE_MODAL = "CLOSE_DELETE_ROLE_MODAL";

export const OPEN_IMPORT_STATUS_DETAIL_MODAL =
  "OPEN_IMPORT_STATUS_DETAIL_MODAL";
export const CLOSE_IMPORT_STATUS_DETAIL_MODAL =
  "CLOSE_IMPORT_STATUS_DETAIL_MODAL";
export const OPEN_DOWNLOAD_OPTIONS_IMPORT_MODAL =
  "OPEN_DOWNLOAD_OPTIONS_IMPORT_MODAL";
export const CLOSE_DOWNLOAD_OPTIONS_IMPORT_MODAL =
  "CLOSE_DOWNLOAD_OPTIONS_IMPORT_MODAL";

export const OPEN_DELETE_POLLS_AND_SURVEY_MODAL =
  "OPEN_DELETE_POLLS_AND_SURVEY_MODAL";
export const CLOSE_DELETE_POLLS_AND_SURVEY_MODAL =
  "CLOSE_DELETE_POLLS_AND_SURVEY_MODAL";

export const OPEN_ARCHIVE_OR_RESTORE_POLLS_AND_SURVEY_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_POLLS_AND_SURVEY_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_POLLS_AND_SURVEY_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_POLLS_AND_SURVEY_MODAL";

export const OPEN_ADD_SURVEY_FORM_MODAL = "OPEN_ADD_SURVEY_FORM_MODAL";
export const CLOSE_ADD_SURVEY_FORM_MODAL = "CLOSE_ADD_SURVEY_FORM_MODAL";

export const OPEN_POLLS_AND_SURVEYS_EXPIRY_DATE_MODAL =
  "OPEN_POLLS_AND_SURVEYS_EXPIRY_DATE_MODAL";
export const CLOSE_POLLS_AND_SURVEYS_EXPIRY_DATE_MODAL =
  "CLOSE_POLLS_AND_SURVEYS_EXPIRY_DATE_MODAL";

export const OPEN_REMOVE_PROGRAM_MODAL = "OPEN_REMOVE_PROGRAM_MODAL";
export const CLOSE_REMOVE_PROGRAM_MODAL = "CLOSE_REMOVE_PROGRAM_MODAL";

export const OPEN_SUCCESSFUL_MESSAGE_MODAL = "OPEN_SUCCESSFUL_MESSAGE_MODAL";
export const CLOSE_SUCCESSFUL_MESSAGE_MODAL = "CLOSE_SUCCESSFUL_MESSAGE_MODAL";

export const OPEN_EDIT_ORGANIZATION_TAGS_MODAL =
  "OPEN_EDIT_ORGANIZATION_TAGS_MODAL";
export const CLOSE_EDIT_ORGANIZATION_TAGS_MODAL =
  "CLOSE_EDIT_ORGANIZATION_TAGS_MODAL";

export const OPEN_IMPORT_INFO_MODAL = "OPEN_IMPORT_INFO_MODAL";
export const CLOSE_IMPORT_INFO_MODAL = "CLOSE_IMPORT_INFO_MODAL";

export const OPEN_CONFIGURATION_DASHBOARD_MODAL =
  "OPEN_CONFIGURATION_DASHBOARD_MODAL";
export const CLOSE_CONFIGURATION_DASHBOARD_MODAL =
  "CLOSE_CONFIGURATION_DASHBOARD_MODAL";
export const OPEN_DOWNLOAD_POLLS_AND_SURVEYS_RESULT_BY_VERSION_MODAL =
  "OPEN_DOWNLOAD_POLLS_AND_SURVEYS_RESULT_BY_VERSION_MODAL";
export const CLOSE_DOWNLOAD_POLLS_AND_SURVEYS_RESULT_BY_VERSION_MODAL =
  "CLOSE_DOWNLOAD_POLLS_AND_SURVEYS_RESULT_BY_VERSION_MODAL";

export const OPEN_CONTACT_INFO_MODAL = "OPEN_CONTACT_INFO_MODAL";
export const CLOSE_CONTACT_INFO_MODAL = "CLOSE_CONTACT_INFO_MODAL";

export const OPEN_ADD_EDIT_CONTACT_INFO_MODAL =
  "OPEN_ADD_EDIT_CONTACT_INFO_MODAL";
export const CLOSE_ADD_EDIT_CONTACT_INFO_MODAL =
  "CLOSE_ADD_EDIT_CONTACT_INFO_MODAL";

export const OPEN_PAUSE_RESUME_PROGRAM_MODAL =
  "OPEN_PAUSE_RESUME_PROGRAM_MODAL";
export const CLOSE_PAUSE_RESUME_PROGRAM_MODAL =
  "CLOSE_PAUSE_RESUME_PROGRAM_MODAL";

export const OPEN_ACTIVE_SEASONS_HISTORY_MODAL =
  "OPEN_ACTIVE_SEASONS_HISTORY_MODAL";
export const CLOSE_ACTIVE_SEASONS_HISTORY_MODAL =
  "CLOSE_ACTIVE_SEASONS_HISTORY_MODAL";

export const OPEN_REPORT_VERSION_DATA_MODAL = "OPEN_REPORT_VERSION_DATA_MODAL";
export const CLOSE_REPORT_VERSION_DATA_MODAL =
  "CLOSE_REPORT_VERSION_DATA_MODAL";

export const OPEN_REMOVE_COMMUNITY_FROM_ORGANIZATION_MODAL =
  "OPEN_REMOVE_COMMUNITY_FROM_ORGANIZATION_MODAL";
export const CLOSE_REMOVE_COMMUNITY_FROM_ORGANIZATION_MODAL =
  "CLOSE_REMOVE_COMMUNITY_FROM_ORGANIZATION_MODAL";

export const OPEN_NOTIFY_ABOUT_NEW_VERSION_MODAL =
  "OPEN_NOTIFY_ABOUT_NEW_VERSION_MODAL";
export const CLOSE_NOTIFY_ABOUT_NEW_VERSION_MODAL =
  "CLOSE_NOTIFY_ABOUT_NEW_VERSION_MODAL";

export const OPEN_UNDO_AND_ARCHIVE_IMPORT_MODAL =
  "OPEN_UNDO_AND_ARCHIVE_IMPORT_MODAL";
export const CLOSE_UNDO_AND_ARCHIVE_IMPORT_MODAL =
  "CLOSE_UNDO_AND_ARCHIVE_IMPORT_MODAL";

export const OPEN_ASSIGN_PROGRAM_ADMIN_PERMISSION_TO_IMPORT_MODAL_ACTION =
  "OPEN_ASSIGN_PROGRAM_ADMIN_PERMISSION_TO_IMPORT_MODAL_ACTION";
export const CLOSE_ASSIGN_PROGRAM_ADMIN_PERMISSION_TO_IMPORT_MODAL_ACTION =
  "CLOSE_ASSIGN_PROGRAM_ADMIN_PERMISSION_TO_IMPORT_MODAL_ACTION";
export const OPEN_EXIF_VIEWER_MODAL = "OPEN_EXIF_VIEWER_MODAL";
export const CLOSE_EXIF_VIEWER_MODAL = "CLOSE_EXIF_VIEWER_MODAL";
export const OPEN_MANAGE_LAYERS_PROPERTIES_MODAL =
  "OPEN_MANAGE_LAYERS_PROPERTIES_MODAL";
export const CLOSE_MANAGE_LAYERS_PROPERTIES_MODAL =
  "CLOSE_MANAGE_LAYERS_PROPERTIES_MODAL";

// Brand Modals Actions
export const OPEN_ARCHIVE_OR_RESTORE_BRAND_MODAL =
  "OPEN_ARCHIVE_OR_RESTORE_BRAND_MODAL";
export const CLOSE_ARCHIVE_OR_RESTORE_BRAND_MODAL =
  "CLOSE_ARCHIVE_OR_RESTORE_BRAND_MODAL";

export const OPEN_VIEW_DNS_BRAND_MODAL =
  "OPEN_VIEW_DNS_BRAND_MODAL";
export const CLOSE_VIEW_DNS_BRAND_MODAL =
  "CLOSE_VIEW_DNS_BRAND_MODAL";
  
export const OPEN_USER_ALREADY_EXISTS_MODAL =
  "OPEN_USER_ALREADY_EXISTS_MODAL";
export const CLOSE_USER_ALREADY_EXISTS_MODAL =
  "CLOSE_USER_ALREADY_EXISTS_MODAL";
export const OPEN_AUDIO_MODAL_ACTION = "OPEN_AUDIO_MODAL_ACTION";
export const CLOSE_AUDIO_MODAL_ACTION = "CLOSE_AUDIO_MODAL_ACTION";
