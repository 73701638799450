import useCustomBranding from "hooks/useCustomBranding";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import CustomProfilePlaceholder from "assets/images/Placeholders/custom-branding/CustomProfilePlaceholder.png";

export const UsedApps = () => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const { getBrandPlaceholder } = useCustomBranding();
  return (
    <div className="flex flex-col self-stretch justify-start flex-grow-0 flex-shrink-0 mt-10 overflow-hidden bg-white border-t border-lineMid dark:border-lineLightitems-start">
      <div className="flex flex-col items-start self-stretch justify-center flex-grow-0 flex-shrink-0 pt-6 pl-12 pr-8">
        <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
          <div className="relative flex flex-col items-start justify-center flex-grow py-1">
            <p className="self-stretch flex-grow-0 flex-shrink-0 w-full text-lg font-semibold text-left dark:text-textMain text-secondaryMid">
              Used Apps
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 w-full flex-grow-0 flex-shrink-0 gap-2 mt-1">
          {userProfile.usedApps.length === 0 ? (
            <div className="mt-2">
              <p className="text-[15px] text-textNormal">No App Attached</p>
            </div>
          ) : (
            userProfile?.usedApps?.map((app: any) => {
              return (
                <>
                  <div className="w-full flex item-center mt-4">
                    <div className="flex items-center w-full break-words">
                      <div className="w-12 relative">
                        <img
                          src={app?.image?.thumb || CustomProfilePlaceholder}
                          alt=""
                          height={100}
                          width={100}
                          className="h-8 w-8"
                        />
                      </div>
                      <p className="text-secondaryMid text-xs  w-full dark:text-textMain">
                        {app.title}
                      </p>
                    </div>
                  </div>
                </>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
