import { Dispatch } from "redux";
import {
  ERROR_LOADING_USERS,
  GET_ALL_USERS,
  REMOVE_A_USER,
  RESET_DATA,
  START_LOADING_USERS,
  STOP_LOADING_USERS,
  UPDATE_A_USER,
} from "./action-types";
import apiLibrary from "services/api";
import { generateQueryStringForUsers } from "utils/convertFiltersToQueryString";
import { Toasts } from "view/components/Toasts";
import { getRandomProgress } from "utils/getRandomProgress";
import { setLoadingProgress } from "store/loadingBar";
import { cloneDeep } from "lodash";
import { sentenceCase } from "change-case";

export const fetchUsersAction =
  (
    communityId: any,
    programId: any,
    organizationId: any,
    kpiState: any = null,
  ) =>
  async (dispatch: Dispatch, getState: any) => {
    // dispatch(loadingState(true))
    dispatch({ type: START_LOADING_USERS });
    // dispatch(setLoadingProgress(getRandomProgress()));
    try {
      const currentState = getState();
      const filtersData = cloneDeep(currentState.Filters.usersFilter);
      if (!programId && !communityId && !organizationId) {
        delete filtersData.entityOpenAccess;
      }
      const params = generateQueryStringForUsers(
          filtersData
        );
      const query = appendCommunityOrProgramIdInParams(
        communityId,
        programId,
        organizationId,
        kpiState,
        params
      );

      const { data } = await apiLibrary.userProfile.getAllUsers(query);

      dispatch({
        type: GET_ALL_USERS,
        payload: appendCommunityOrganizationOrProgramIdRoles(communityId, programId, organizationId, data),
      });
    } catch (error: any) {
      console.log("error", error)
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
      dispatch({
        type: RESET_DATA,
      });
      // dispatch(fetchError(error))
      dispatch({ type: ERROR_LOADING_USERS });
    } finally {
      dispatch({ type: STOP_LOADING_USERS });
      // dispatch(setLoadingProgress(100));
      // dispatch(loadingState(false))
    }
  };

export const getAllUsersAction = (res: any) => {
  return {
    type: GET_ALL_USERS,
    payload: res,
  };
};

export const updateUserAction = (res: any) => {
  return {
    type: UPDATE_A_USER,
    payload: res,
  };
};

export const removeUserAction = (res: any) => {
  return {
    type: REMOVE_A_USER,
    payload: res,
  };
};

const appendCommunityOrProgramIdInParams = (
  communityId: any,
  programId: any,
  organizationId: any,
  kpiState: any = null,
  params: any
) => {
  let updatedParams = { ...params };

  if (communityId) {
    updatedParams.communityIds = communityId.toString();
  } else if (programId) {
    updatedParams.programIds = programId.toString();
  } else if (organizationId) {
    updatedParams.organizationIds = organizationId.toString();
  }

  if (kpiState && kpiState.isAdminRole) {
    updatedParams.role = "admin";
  }

  return updatedParams;
};
const appendCommunityOrganizationOrProgramIdRoles = (
  communityId: any,
  programId: any,
  organizationId: any,
  data: any
) => {
  if (communityId || programId) {
    return {
      ...data,
      users: data.users.map((user: any) => ({
        ...user,
        role: communityId ? user.communityRoles : user.programRoles,
      })),
    };
  } else if (organizationId) {
    return {
      ...data,
      users: data.users.map((user: any) => ({
        ...user,
        role: user.organizationRoles ?? [],
      })),
    };
  }

  return data
};
