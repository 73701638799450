export interface IBrands {
  id: number;
  name: string;
  status: string;
  type: string;
  dateCreated: string | null;
  organizationName: string;
  dateArchived: string | null;
  dnsPropagationStatus: string;
  organizationStatus: string;
  brandImage: {
    large: string;
    original: string;
    thumb: string;
  };
}

export interface IBrandData {
  brands: IBrands[];
  totalItems: number;
  totalPages: number;
}

export interface IfetchBrands {
  data: IBrandData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchBrands = {
  data: {
    brands: [],
    totalItems: 0,
    totalPages: 1,
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
