import React, { useState } from "react";
import Avatar from "assets/images/Auth/community-circleImg.png";
import useDateFormatter from "hooks/formatDateWithPattern";
import useCustomBranding from "hooks/useCustomBranding";

interface ProfileModalProps {
  setSelectedCommunity: any;
  selectedCommunity: any;
  ConnectCommunity: any;
  disconnectCommunity: any;
}

export const ProfileModal: React.FC<ProfileModalProps> = ({
  setSelectedCommunity,
  selectedCommunity,
  ConnectCommunity,
  disconnectCommunity,
}) => {
  const { formatDate, timeFromNowInTimeZone } = useDateFormatter();
  const {getBrandPlaceholder} = useCustomBranding();

  return (
    <div className="w-full max-h-[60vh] overflow-y-auto">
      <div className="relative p-5 rounded bg-bgWhite dark:bg-secondaryLight">
        <div className="relative">
          <div className="py-2">
            <div className="flex-grow-0 flex-shrink-0 w-28 h-28">
              <div className="absolute top-0 left-0 rounded-lg w-28 h-28">
                <img
                  src={
                    selectedCommunity?.profileImage
                      ? selectedCommunity?.personImage?.original
                      : getBrandPlaceholder("organizationProfile")
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className=" flex flex-col justify-start items-start flex-grow gap-1.5 pt-1.5">
            <div className="flex flex-col items-start justify-center w-full ">
              <p className="w-full text-lg font-semibold text-left break-words text-secondaryMid">
                {`${selectedCommunity.name}`}
              </p>
            </div>
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
              <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[3px]">
                <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-secondaryMid">
                  {selectedCommunity.programs}
                </p>
                <p className="flex-grow-0 flex-shrink-0 text-sm text-left text-secondaryMid">
                  programs
                </p>
              </div>
            </div>
            <div className="flex gap-2 py-[3px]">
              <p className="text-sm font-semibold text-left text-secondaryMid">
                Date created:
              </p>
              <div className="flex gap-1">
                <div className="flex">
                  <p className="text-sm text-center text-secondaryMid">
                    {formatDate(selectedCommunity.dateCreated)}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-start items-center self-stretch relative gap-4 pt-3 pb-[11px] rounded-lg">
          <div className="flex-grow h-px bg-lineMid" />
        </div>
        <div className="flex flex-col gap-2 pb-2">
          <div className="w-full">
            <p className="text-base font-semibold text-left text-secondaryMid">
              Related to communities
            </p>
          </div>
          <div className="relative flex flex-wrap gap-2">
            {selectedCommunity?.relatedCommunities?.map((community: any) => {
              return (
                <div className="relative rounded-full w-9 h-9">
                  <img
                    alt={community.name}
                    className="w-9 h-9 rounded-full left-[-1px] top-[-1px]"
                    src={
                      community.profileImage
                        ? community?.profileImage?.file?.original
                        : Avatar
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex justify-start items-center self-stretch relative gap-4 pt-3 pb-[11px] rounded-lg">
          <div className="flex-grow h-px bg-lineMid" />
        </div>
        <div className="gap-2 pb-2">
          <div className="w-full">
            <p className="text-base font-semibold text-left text-secondaryMid">
              Do you want to {selectedCommunity.isConnected ? "unassign" : "add"}{" "}
              this organization ?
            </p>
          </div>
          <div className="flex gap-6 pt-4">
            <button
              onClick={() => setSelectedCommunity()}
              className="font-semibold text-primary"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                if (selectedCommunity.isConnected) {
                  disconnectCommunity(selectedCommunity);
                } else {
                  ConnectCommunity(selectedCommunity);
                }
              }}
              className="font-semibold text-primary"
            >
              {selectedCommunity.isConnected ? "Disconnect" : " Add"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
