import apiClient from "services/apiClient";
async function fileDownload(
  entity: any,
  downloadColumns: any,
  selectedIds: any,
  parentEntity?: any,
  id?: any,
  versionId?: any
) {
  const queryParams: any = {
    entity: entity,
    downloadColumns: downloadColumns,
    selectedIds: selectedIds,
    id: id,
  };
  const queryParam: any = {
    downloadColumns: downloadColumns,
    selectedIds: selectedIds,
  };

  if (versionId !== null) {
    queryParams.versionId = versionId;
  }
  if (id !== null) {
    queryParams.id = id;
  }
  if (parentEntity !== null && parentEntity !== undefined) {
    const res = await apiClient.post(
      `/admin/download/${entity}?${parentEntity?.label}=${parentEntity?.value}`,
      queryParam,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    return res.data;
  }
  if (entity === "reminder_recipient") {
    const res = await apiClient.post(
      `/admin/download/${entity}?id=${id}`,
      queryParam,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    return res.data;
  } else {
    const res = await apiClient.post(`/admin/download/${entity}`, queryParams, {
      headers: {
        Accept: "application/json",
      },
    });
    return res.data;
  }
}

export default fileDownload;
