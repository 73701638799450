import XCloseSmallControlIcon from "assets/icons/HeroIcons/XCloseSmallControlIcon";
import useCustomBranding from "hooks/useCustomBranding";

interface AdminProps {
  userData: any;
  setSelectedUser: any;
  unAssignAdmin: any;
}

const Admins: React.FC<AdminProps> = ({
  userData,
  unAssignAdmin,
  setSelectedUser,
}) => {
  const {getBrandPlaceholder} = useCustomBranding();

  return (
      <div className="flex justify-start items-center gap-4 px-2 py-2 hover:bg-primary/[0.2] rounded">      <div
        onClick={() => setSelectedUser(userData)}
        className="flex items-center flex-grow w-full cursor-pointer"
      >
        <div
          onClick={() => setSelectedUser(userData)}
          className="relative flex items-center justify-between cursor-pointer"
        >
           <img
              src={
                userData?.personImage?.thumb
                  ? userData?.personImage?.thumb
                  : getBrandPlaceholder("userProfile")
              }
              alt=""
              height="32px"
              width="32px"
              className="object-cover mx-2 Img_user_Data"
              style={{ flexShrink: 0 }}
            />
          <p className="ml-3 break-words w-[540px] dark:text-textMain">{`${userData.firstName} ${userData.lastName}`}</p>
        </div>
      </div>
      <button type="button" className="flex-shrink-0">
        <XCloseSmallControlIcon onClick={() => unAssignAdmin(userData)} />
      </button>
    </div>
  );
};

export default Admins;
