import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { CopyIcon } from "assets/icons/HeroIcons";
import { closeViewDnsBrandModalAction } from "store/modals";
import { fetchDNSRecordAction } from "store/viewDNSRecord";
import { RootState } from "store";
import { IfetchDNSRecord } from "store/viewDNSRecord/initialState";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#fff",
};

interface DnsRecordTableProps {
  title: string;
  records: { name: string; value: string }[];
  copiedValue: string;
  handleCopyClick: (item: { value: string; name: string }) => void;
}

const DnsRecordTable: React.FC<DnsRecordTableProps> = ({
  title,
  records,
  copiedValue,
  handleCopyClick,
}) => (
  <>
    <p className="text-[#494949] text-2xl py-2">{title}</p>
    <table className="w-full border-gray-300 rounded-lg">
      <thead className="bg-bgBluish_2 dark:bg-bgtetriary">
        <tr className="border-b bg-gray-100 text-left">
          <th className="p-3"></th>
          <th className="p-3 text-sm font-medium text-secondaryMid dark:text-caption text-left">
            Host
          </th>
          <th className="p-3 text-sm font-medium text-secondaryMid dark:text-caption text-left">
            Value
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {records?.map((item, index) => (
          <tr key={index} className="border-b hover:bg-gray-50 transition">
            <td className="p-3"></td>
            <td className="p-3 text-sm text-textMid dark:text-caption w-[280px] text-left">
              {item.name}
            </td>
            <td className="p-3 text-sm text-textMid dark:text-caption w-[480px] text-left">
              {item.value}
            </td>
            <td className="p-3">
              <div>
                <button
                  onClick={() => handleCopyClick(item)}
                  className="relative px-2 h-[40px] bg-primary text-textWhite rounded-lg flex items-center justify-center gap-2 text-sm font-semibold"
                >
                  {copiedValue === item.value && (
                    <span className="absolute top-full mt-1 text-xs bg-primary text-textWhite px-2 py-1 rounded shadow">
                      Copied!
                    </span>
                  )}
                  <CopyIcon fill="#FFFFFF" />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
);

export const ViewDnsBrandModal: React.FC = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { viewDnsBrandModal } = useSelector((state: RootState) => state.modals);
  const { data } = useSelector<RootState, IfetchDNSRecord>(
    (state) => state.viewDNSRecord
  );
  const [copiedValue, setCopiedValue] = useState("");

  const handleClose = () => dispatch(closeViewDnsBrandModalAction());

  useEffect(() => {
    if (viewDnsBrandModal?.data?.id) {
      dispatch(fetchDNSRecordAction(viewDnsBrandModal.data.id));
    }
  }, [dispatch, viewDnsBrandModal?.data?.id]);

  const handleCopyClick = (item: { value: string; name: string }) => {
    let data = {
      hostName: item?.name,
      value: item?.value,
    };
  
    // Convert the object into a JSON string before copying
    navigator.clipboard.writeText(JSON.stringify(data)).then(() => {
      setCopiedValue(JSON.stringify(data));  // You may want to show the stringified version as copied
      setTimeout(() => setCopiedValue(""), 2000);
    });
  };
  

  return (
    <Modal
      open={viewDnsBrandModal?.isOpen}
      onClose={handleClose}
      disableAutoFocus
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight">
        <div className="flex flex-col items-start w-[900px] rounded-lg">
          <div className="w-full pt-2 flex justify-end">
            <button onClick={handleClose} className="mr-3">
              <XCloseIcon fill="#2C3236" />
            </button>
          </div>
          <div className="w-full px-7 pb-7">
            <p className="text-3xl font-bold dark:text-[#494949] pb-5 capitalize">
              {viewDnsBrandModal?.data?.name}
            </p>
            <DnsRecordTable
              title="CName (Admin)"
              records={data?.adminDnsRecords || []}
              copiedValue={copiedValue}
              handleCopyClick={handleCopyClick}
            />
            <DnsRecordTable
              title="AName (App)"
              records={data?.appDnsRecords || []}
              copiedValue={copiedValue}
              handleCopyClick={handleCopyClick}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};
