import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TailSpin } from "react-loader-spinner";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import Button from "view/components/Button";
import XCloseSmallIcon from "assets/icons/HeroIcons/XCloseSmallIcon";
import apiLibrary from "services/api";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import {
  closeAddExistingOrgzanizationsModalAction,
  openCreateOrganizationModalAction,
} from "store/modals/reducer.actions";
import TextInput from "view/components/InputField";
import { Toasts } from "view/components/Toasts";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchOrganizationsAction } from "store/oranizations";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { setLoadingProgress } from "store/loadingBar";
import usePermissions from "hooks/usePermissions";
import useCustomBranding from "hooks/useCustomBranding";
// Define the interface for SearchToAddExistingOrganizationProps (if required)
interface SearchToAddExistingOrganizationsToCommunityProps {}

// Styles for the Modal component
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  border: "none",
};

// SearchToAddExistingOrganizationsToCommunity component
export const SearchToAddExistingOrganizationsToCommunity: React.FC<
  SearchToAddExistingOrganizationsToCommunityProps
> = () => {
  const navigate = useNavigate();

  // Redux hooks
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { addExistingOrganizationsModal } = useSelector(
    (state: RootState) => state.modals
  );

  // State hooks
  const [organizationsList, setOrganizationsList] = useState<any[]>([]);
  const [connectedOrganizationsList, setConnectedOrganizationsList] = useState<
    any[]
  >([]);
  const [isOrganizationRemoved, setIsOrganizationRemoved] =
    useState<boolean>(false);

  const [searchString, setSearchString] = useState("");

  const [totalOrganizations, setTotalOrganizations] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const { communityId, programId, organizationId } = useParams<{
    communityId: string;
    programId: string;
    organizationId: string;
  }>();
  const { organizations } = usePermissions();
  const {getBrandPlaceholder} = useCustomBranding();

  const addExistingOrganizations = async () => {
    setIsLoading(true);
    try {
      const response = await apiLibrary.Communities.editCommunity(
        addExistingOrganizationsModal?.data?.communityId,
        {
          organizationIds: connectedOrganizationsList.map((org: any) => {
            return org.id;
          }),
        }
      );
      Toasts.success(response.message);

      handleClose();
      dispatch(fetchOrganizationsAction(communityId, programId));
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const getOrganizationByCommunity = async (
    isLoadMore: boolean,
    organizationId: number,
    isConnected: boolean,
    page: number,
    searchString: string,
    connectedOrganizations?: any[] | null
  ) => {
    setIsLoading(true);

    try {
      const { data } = await apiLibrary.Communities.getOrganizationsByCommunity(
        organizationId,
        isConnected,
        page,
        searchString
      );
      const reStructuredEntitiesList = reStructureTheEntitiesList(
        data?.organizations,
        isConnected
      );
      if (isConnected) {
        setConnectedOrganizationsList(reStructuredEntitiesList);
      } else {
        if (!isLoadMore) {
          if (connectedOrganizations && connectedOrganizations?.length > 0) {
            const EntitiesList = getSortedDifference(
              reStructuredEntitiesList,
              connectedOrganizations
            );
            setOrganizationsList(EntitiesList);
          } else {
            setOrganizationsList(reStructuredEntitiesList);
          }
        } else {
          if (connectedOrganizations && connectedOrganizations?.length > 0) {
            const organizationsList = getSortedDifference(
              reStructuredEntitiesList,
              connectedOrganizations
            );

            setOrganizationsList((prevList) => [
              ...prevList,
              ...organizationsList,
            ]);
          } else {
            setOrganizationsList((prevList) => [
              ...prevList,
              ...reStructuredEntitiesList,
            ]);
          }
        }

        setTotalPages(data.totalPages);
        setHasMoreData(data.totalPages > page);
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchConnectedAndExistingOrganizationByCommunity = async () => {
    await getOrganizationByCommunity(
      false,
      parseInt(addExistingOrganizationsModal?.data?.communityId),
      false,
      page,
      searchString
    );
    await getOrganizationByCommunity(
      false,
      parseInt(addExistingOrganizationsModal?.data?.communityId),
      true,
      page,
      searchString
    );
  };
  useEffect(() => {
    if (addExistingOrganizationsModal.isOpen) {
      setOrganizationsList([]);
      setPage(1);
      fetchConnectedAndExistingOrganizationByCommunity();
    }
  }, [
    addExistingOrganizationsModal?.data?.communityId,
    addExistingOrganizationsModal.isOpen,
  ]);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      getOrganizationByCommunity(
        true,
        parseInt(addExistingOrganizationsModal?.data?.communityId),
        false,
        page,
        searchString,
        connectedOrganizationsList
      );
      return page;
    });
  };

  const searchOrganizationByCommunity = async (
    organizationId: any,
    isConnected: boolean,
    page: number,
    searchString: string,
    connectedOrganizations: any[]
  ) => {
    setIsLoading(true);
    try {
      const { data } = await apiLibrary.Communities.getOrganizationsByCommunity(
        organizationId,
        isConnected,
        page,
        searchString
      );

      const reStructuredEntitiesList = reStructureTheEntitiesList(
        data?.organizations,
        isConnected
      );

      const searchedOrganizationsList = getSortedDifference(
        reStructuredEntitiesList,
        connectedOrganizations
      );

      setOrganizationsList(searchedOrganizationsList);
      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchOrganizationByCommunity = async (searchString: string) => {
    setPage(1);
    await searchOrganizationByCommunity(
      parseInt(addExistingOrganizationsModal?.data?.communityId),
      false,
      1,
      searchString,
      connectedOrganizationsList
    );
  };

  const debouncedSearch = _.debounce(handleSearchOrganizationByCommunity, 1000);

  const removeCommunityFromOrganization = async (organization: any) => {
    if (!organizations.canDeleteEntity) {
      return;
    }
    dispatch(setLoadingProgress(100));
    setIsLoading(true);
    try {
      const res =
        await apiLibrary.OrganizationDirectory.removeEntityFromOrganization(
          organization.id,
          "community",
          parseInt(addExistingOrganizationsModal?.data?.communityId)
        );
      setIsOrganizationRemoved(true);

      Toasts.success(res.message);
    } catch (error: any) {
      // Handle the error
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  const addExistingCommunity = (organization: any): void => {
    setOrganizationsList((prevOrganizationsList) => {
      const filteredorganizations = prevOrganizationsList.filter(
        (item) => item.id !== organization.id
      );

      if (filteredorganizations.length === 0) {
        callApiIforganizationsLengthIsZero();
      }
      return filteredorganizations;
    });

    setConnectedOrganizationsList((prevconnectedOrganizationsList) => {
      const isCommunityAlreadyConnected = prevconnectedOrganizationsList.some(
        (item) => item.id === organization.id
      );

      if (!isCommunityAlreadyConnected) {
        const updatedList = [...prevconnectedOrganizationsList, organization];
        return updatedList;
      }

      return prevconnectedOrganizationsList;
    });
  };

  const removeExistingMember = async (organization: any) => {
    if (organization.isConnected) {
      await removeCommunityFromOrganization(organization);
      await getOrganizationByCommunity(
        false,
        parseInt(addExistingOrganizationsModal?.data?.communityId),
        false,
        1,
        searchString
      );
      setConnectedOrganizationsList((prevconnectedOrganizationsList) =>
        prevconnectedOrganizationsList.filter(
          (item) => item.id !== organization.id
        )
      );
    }

    setOrganizationsList((prevOrganizationsList) => {
      const isCommunityPresent = prevOrganizationsList.some(
        (item) => item.id === organization.id
      );

      if (!isCommunityPresent) {
        const updatedOrganizationsList = [
          ...prevOrganizationsList,
          organization,
        ];
        return updatedOrganizationsList;
      } else {
        return prevOrganizationsList;
      }
    });

    setConnectedOrganizationsList((prevconnectedOrganizationsList) => {
      const updatedConnectedOrganizationsList =
        prevconnectedOrganizationsList.filter(
          (item) => item.id !== organization.id
        );
      return updatedConnectedOrganizationsList;
    });
  };

  const removeExistingOrganizations = async () => {
    setIsLoading(true);
    try {
      const response = await apiLibrary.Communities.editCommunity(
        addExistingOrganizationsModal?.data?.communityId,
        {
          organizationIds: connectedOrganizationsList
            .filter((item: any) => !item.isConnected)
            .map((organization: any) => {
              return organization.id;
            }),
        }
      );
      setIsOrganizationRemoved(true);
      Toasts.success("All Organizations Have Been Removed");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeAllOrganizations = (): void => {
    const isAllOrganizationsConnected = connectedOrganizationsList.every(
      (organization: any) => organization.isConnected
    );

    if (isAllOrganizationsConnected) {
      removeAllConnectedOrganizations();
    } else if (searchString !== "") {
      setOrganizationsList((prevOrganizationsList) => {
        const updatedOrganizationsList = [
          ...prevOrganizationsList,
          ...connectedOrganizationsList.filter(
            (connectedMember) =>
              !connectedMember.isConnected &&
              connectedMember.name
                .toLowerCase()
                .includes(searchString.toLowerCase())
          ),
        ];
        return updatedOrganizationsList;
      });

      setConnectedOrganizationsList((pre: any[]) => {
        const updatedConnectedOrganizationsList = pre.filter(
          (connectedMember) => connectedMember.isConnected
        );
        return updatedConnectedOrganizationsList;
      });
    } else {
      setOrganizationsList((prevOrganizationsList) => {
        const filteredConnectedOrganizationsList =
          connectedOrganizationsList.filter(
            (connectedMember) => !connectedMember.isConnected
          );

        const updatedOrganizationsList = [
          ...prevOrganizationsList,
          ...filteredConnectedOrganizationsList,
        ];

        return updatedOrganizationsList;
      });

      setConnectedOrganizationsList((pre: any[]) => {
        const updatedConnectedOrganizationsList = pre.filter(
          (connectedMember) => connectedMember.isConnected
        );
        return updatedConnectedOrganizationsList;
      });
    }
  };

  const removeAllConnectedOrganizations = async () => {
    await removeExistingOrganizations();
    await fetchConnectedAndExistingOrganizationByCommunity();
  };

  const addAllOrganizations = (): void => {
    setOrganizationsList(() => {
      return [];
    });
    setConnectedOrganizationsList((prevOrganizationsList) => {
      const updatedOrganizationsList = [
        ...prevOrganizationsList,
        ...organizationsList.filter(
          (newMember) =>
            !prevOrganizationsList.some(
              (prevMember) => prevMember.id === newMember.id
            )
        ),
      ];
      return updatedOrganizationsList;
    });
  };

  // Function to handle modal close
  const handleClose = () => {
    setOrganizationsList([]);
    setConnectedOrganizationsList([]);
    setTotalOrganizations(0);
    setTotalPages(1);
    setSearchString("");
    dispatch(closeAddExistingOrgzanizationsModalAction());
    if (isOrganizationRemoved) {
      dispatch(fetchOrganizationsAction(communityId, programId));
    }
  };

  const redirectToTheDirectory = () => {
    navigate("/organizations/list");
    handleClose();
    setTimeout(() => {
      dispatch(
        openCreateOrganizationModalAction({
          organizationIds: [],
          missionStatement: "",
          name: "",
          organizationImageId: "",
          tagIds: [],
          adminIds: [],
          profileImage: "",
        })
      );
    }, 1000);
  };

  const callApiIforganizationsLengthIsZero = () => {
    if (hasMoreData) {
      handleLoadMore();
    }
  };

  return (
    <Modal
      open={addExistingOrganizationsModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <div className="flex flex-col justify-start items-start w-[850px] rounded-lg">
          <div
            className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 bg-white rounded-lg bg-bgWhite dark:bg-secondaryLight"
            style={{
              boxShadow:
                "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
            }}
          >
            <div className="flex justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
              <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                <p className="flex-grow text-xl font-semibold text-left capitalize text-textMid dark:text-textMain">
                  Search To Add Organizations to the Community
                </p>
              </div>
              <button type="button" onClick={handleClose}>
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
            </div>
            <TextInput
              onChange={(e: any) => {
                setSearchString(e.target.value);
                debouncedSearch(e.target.value);
              }}
              value={searchString}
              type="Search"
              placeholder="Search among existing"
            />
            <div className="max-h-[60vh]  w-full   overflow-y-auto px-1">
              <div className="flex self-stretch justify-between flex-grow-0 flex-shrink-0 w-full gap-4 items-between">
                <div className="flex flex-col justify-start items-start w-[50%]">
                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0">
                    <div className="flex items-center justify-start flex-grow gap-4 px-3 py-2 rounded-lg">
                      <div className="flex justify-start items-center flex-grow relative py-0.5 dark:text-textMain">
                        {organizationsList.length} Existing Organizations
                      </div>
                      <button
                        disabled={isLoading}
                        onClick={() => {
                          addAllOrganizations();
                          callApiIforganizationsLengthIsZero();
                        }}
                        className="font-semibold text-primary"
                      >
                        Add All
                      </button>
                    </div>
                  </div>

                  <div className="w-full border border-lineMid">
                    <InfiniteScroll
                      dataLength={organizationsList.length}
                      next={handleLoadMore}
                      hasMore={hasMoreData}
                      height={400}
                      className="w-full"
                      style={{ width: "100%" }}
                      loader={
                        <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                          {organizationsList.length === 0 && (
                            <TailSpin
                              height="50"
                              width="50"
                              color="#005C89"
                              ariaLabel="tail-spin-loading"
                              radius="2"
                              wrapperStyle={{}}
                              wrapperClass="tailspin-loader"
                              visible={true}
                            />
                          )}
                        </div>
                      }
                    >
                      <div className="flex flex-col items-start justify-start w-full px-2 py-3 overflow-y-auto rounded-lg">
                        {organizationsList.map((organization: any) => {
                          return (
                            <button
                              disabled={isLoading}
                              className="relative flex items-center justify-start flex-grow-0 flex-shrink-0 w-full gap-4 px-2 py-2 rounded-lg cursor-pointer group hover:bg-bgBluish_2 dark:hover:bg-primary/[0.2]"
                              onClick={() => {
                                addExistingCommunity(organization);
                              }}
                            >
                              <img
                                src={
                                  organization?.organizationImage
                                    ? organization?.organizationImage.thumb
                                    : getBrandPlaceholder("organizationProfile")
                                }
                                alt=""
                                height="100"
                                width="100"
                                className="object-cover Img_organization_Data"
                                style={{ flexShrink: 0 }}
                              />
                              <div className="flex items-center justify-start flex-grow">
                                <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                                  <p className="w-[260px] text-left break-words dark:text-textMain">{`${organization.name}`}</p>
                                </div>
                              </div>
                              <svg
                                width={14}
                                height={12}
                                viewBox="0 0 14 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex-grow-0 flex-shrink-0"
                                preserveAspectRatio="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.19526 0.861888C7.93491 1.12224 7.93491 1.54435 8.19526 1.8047L11.7239 5.33329L1.33333 5.33329C0.965143 5.33329 0.666666 5.63177 0.666666 5.99996C0.666666 6.36815 0.965143 6.66663 1.33333 6.66663H11.7239L8.19526 10.1952C7.93491 10.4556 7.93491 10.8777 8.19526 11.138C8.45561 11.3984 8.87772 11.3984 9.13807 11.138L13.8047 6.47136C14.0651 6.21101 14.0651 5.78891 13.8047 5.52856L9.13807 0.861888C8.87772 0.601539 8.45561 0.601539 8.19526 0.861888Z"
                                  className="fill-[#2C3236] dark:fill-[#F2F2F2]"
                                />
                              </svg>
                            </button>
                          );
                        })}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
                <ConnectedOrganizationsList
                  connectedOrganizations={connectedOrganizationsList}
                  removeMember={removeExistingMember}
                  removeAllOrganizations={removeAllOrganizations}
                  isLoading={isLoading}
                />
              </div>
              <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                <p className="text-left break-words dark:text-textMain">
                  You can’t find the organization you’re looking for,
                  <span
                    className="px-2 cursor-pointer text-primaryDark"
                    onClick={redirectToTheDirectory}
                  >
                    Add It Here
                  </span>
                </p>
              </div>
            </div>

            <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
              <Button
                disabled={isLoading}
                type="button"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={handleClose}
              />

              <Button
                type="button"
                disabled={isLoading}
                text="Save"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2"
                width="35"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
                onClick={addExistingOrganizations}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

interface ConnectedOrganizationsListProps {
  connectedOrganizations: any[];
  removeMember: (organization: any) => void;
  removeAllOrganizations: () => void;
  isLoading: boolean;
}

const ConnectedOrganizationsList: React.FC<ConnectedOrganizationsListProps> = ({
  connectedOrganizations,
  removeMember,
  removeAllOrganizations,
  isLoading,
}) => {
  const {organizations} = usePermissions();
  const {getBrandPlaceholder} = useCustomBranding();

  return (
    <div className="flex flex-col justify-start items-start w-[50%] relative">
      <div className="flex flex-col w-full justify-start items-start flex-grow h-[400px]">
        <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0">
          <div className="flex items-center justify-start flex-grow gap-4 px-3 py-2 rounded-lg">
            <div className="flex justify-start items-center flex-grow relative py-0.5 dark:text-textMain">
              {connectedOrganizations.length} Added
            </div>
            <button
              disabled={isLoading || connectedOrganizations.length === 0 || !organizations.canDeleteEntity}
              onClick={removeAllOrganizations}
              className="font-semibold text-primary disabled:cursor-not-allowed"
            >
              Clear All
            </button>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow w-full px-2 py-3 overflow-y-auto border rounded-lg border-lineMid">
          {connectedOrganizations.map((organization: any, index: number) => {
            return (
              <div
                key={index}
                className="flex items-center justify-start w-full gap-4 px-2 py-2 rounded-lg cursor-pointer hover:bg-bgBluish_2 dark:hover:bg-primary/[0.2]"
              >
                <img
                  src={
                    organization?.organizationImage
                      ? organization?.organizationImage?.thumb
                      : getBrandPlaceholder("organizationProfile")
                  }
                  alt=""
                  height="100"
                  width="100"
                  className="object-cover Img_organization_Data"
                  style={{ flexShrink: 0 }}
                />

                <div className="flex items-center justify-start ">
                  <div className="flex items-center justify-start w-full gap-4 py-2 pl-1 rounded-lg">
                    <p className="w-[260px] dark:text-textMain  text-left break-words">{`${organization.name}`}</p>
                  </div>
                </div>

                <button
                  disabled={isLoading || connectedOrganizations.length === 0 || !organizations.canDeleteEntity}
                  className="font-semibold text-primary disabled:cursor-not-allowed"
                  onClick={() => removeMember(organization)}
                >
                  <XCloseSmallIcon
                    width={24}
                    height={24}
                    className="fill-[#2C3236] dark:fill-[#F2F2F2]"
                  />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

function reStructureTheEntitiesList(
  entitiesList: any,
  isConnected: boolean
): any[] {
  const reStructuredList = entitiesList.map((organization: any) => {
    const { ...rest } = organization;
    return {
      ...rest,
      isConnected: isConnected,
    };
  });

  return reStructuredList;
}

function getSortedDifference(
  entitiesList: any,
  connectedEntitiesList: any
): any[] {
  const difference = _.differenceBy(entitiesList, connectedEntitiesList, "id");
  return difference;
}
