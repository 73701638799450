import { Link } from 'react-router-dom';
import { ColumnProps } from '../../types';
import { RenderCellContent } from '../index';
import usePermissions from 'hooks/usePermissions';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IfetchProfileReports } from 'store/reportsProfile/initialState';

export const Column = ({
  cell,
  columnVisibility,
  flexRender,
  hoverEffect,
  rowData,
}: ColumnProps) => {
  const { data } = useSelector<RootState, IfetchProfileReports>(
    (state) => state?.reportsProfile
  );
  const columnId = cell.column.id;
  const isVisible = columnVisibility[columnId] ?? true;
  const isColumnVisible = isVisible && cell.column.columnDef !== undefined;
  const { reports } = usePermissions();

  const linkTo =
    reports.canViewResultReports &&
    rowData.original.processingStatus === 'ready' && data.status !== "archived"
      && `/reports/${rowData.original.reportId}/report-version/${rowData.original.version}/result`
      // : '';
  const cursor = linkTo ? 'cursor-pointer' : 'cursor-default';

  if (isColumnVisible && cell.column.columnDef.id !== 'select') {
    return (
      <td key={cell.id} className='px-4'>
        <Link to={linkTo} className={cursor}>
          <RenderCellContent
            columnDef={cell.column.columnDef}
            cellValue={cell.getValue()}
          />
        </Link>
      </td>
    );
  } else {
    return (
      <td
        key={cell.id}
        className={`sticky left-0 z-10 bg-bgWhite dark:bg-secondaryLight ${hoverEffect}`}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  }
};
