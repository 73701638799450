import { useEffect, useRef, useState } from "react";

import {
  useDrawAllGlobalLayersOnMap,
  useDrawShapesOnMap,
  useHandleControlsForEditMode,
  useInitializeMap,
  usePaintAllGlobalLayersOnMap,
} from "../../hooks";
import mapboxgl from "mapbox-gl";
import { useInitializeCustomLayersListControl } from "../useInitailizeCustomLayersListControl";
// const LAYER_DEFAULT_COLOR = "red";
// const LAYER_SELECTED_COLOR ="#FFA500";

const Map = ({
  editMode,
  element,
  answer,
  setFieldValue,
  eleName,
  setDraw,
}: any) => {
  const mapContainer = useRef<any>(null);

  const [selectedGlobalLayers, setSelectedGlobalLayers] = useState<any>([]);
  const customControlId = "drawpolygon" + element.id;
  const customDeleteControlId = "drawpolygon#delete" + element.id;
  const [newPopup, setNewPopup] = useState<any>();
  const [popups, setPopups] = useState<any>([]);
  const [IsFeauresUpdateed, setIsFeauresUpdateed] = useState(false);
  const [globalMarkers, setGlobalMarkers] = useState<any>([]);

  // initializing the map
  const { map, isMapFullyLoaded, draw } = useInitializeMap(
    mapContainer,
    customControlId,
    customDeleteControlId,
    element?.type,
    editMode,
    element?.drawMultiple,
    element?.enableSnapping,
    setIsFeauresUpdateed
  );

  // initializing custom shapes dropdown to map controls
  useInitializeCustomLayersListControl(
    isMapFullyLoaded,
    map,
    selectedGlobalLayers,
    setSelectedGlobalLayers,
    selectedGlobalLayers,
    customControlId,
    element?.type,
    editMode
  );

  // painting the all global layers on the map
  usePaintAllGlobalLayersOnMap(
    selectedGlobalLayers,
    map,
    isMapFullyLoaded,
    "red",
    draw,
    popups,
    setPopups,
    setNewPopup,
    editMode,
    element?.type,
    globalMarkers,
    setGlobalMarkers
  );

  // draw all global layers
  useDrawAllGlobalLayersOnMap(
    selectedGlobalLayers,
    map,
    isMapFullyLoaded,
    draw,
    editMode
  );

  // handling the draw polygon and delete button
  useHandleControlsForEditMode(isMapFullyLoaded, editMode);

  // drawing shapes on the map
  useDrawShapesOnMap(draw, answer, editMode);

  useEffect(() => {
    if (!IsFeauresUpdateed) return;

    const geometryCollection = draw?.getAll();

    if (geometryCollection) {
      const data = geometryCollection.features
        ?.map((f: any) => {
          let id = f.id;

          if (id?.includes("draw")) {
            id = "draw#" + f.id.split("draw#").at(-1);
          }

          const name = answer?.find((ans: any) => ans.id === id)?.name ?? "";

          const mapData = {
            features: [f],
            type: "FeatureCollection",
          };

          return {
            type: "region",
            vectorLayers: [],
            legendFileId: null,
            name,
            geoFilePath: null,
            id,
            mapData,
          };
        })
        .filter((f: any) => !f.id.includes("global"));

      setFieldValue(eleName, data);
    }

    setIsFeauresUpdateed(false);
  }, [IsFeauresUpdateed]);

  useEffect(() => {
    if (isMapFullyLoaded && draw) {
      setDraw(draw);
    }
  }, [isMapFullyLoaded]);

  // display popup of points whenever click on it
  useEffect(() => {
    if (!newPopup || editMode) return;

    const { coordinates, html, id } = newPopup;

    const popup = new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(html)
      .addTo(map);

    setPopups((prev: any) => [...popups, { id, item: popup }]);
  }, [newPopup]);

  useEffect(() => {
    if (popups.length > 0) {
      popups.forEach((p: any) => {
        if (p.popup) {
          p?.popup?.remove();
        }
      });
      setPopups([]);
    }
  }, [editMode]);

  useEffect(() => {
    if (element.selectedLayers && element.selectedLayers.length > 0) {
      setSelectedGlobalLayers(
        element.selectedLayers.map((item: any) => ({ ...item, status: false }))
      );
    }
  }, [element.selectedLayers]);

  return <div ref={mapContainer} className="h-[400px] w-full"></div>;
};

export default Map;
