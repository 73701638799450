import React, { useCallback, useEffect, useState } from "react";
import apiLibrary from "services/api";
import MultiSelect from "view/components/Multiselect";
import { MaterialUiSelect } from "view/components/Select";
import { Toasts } from "view/components/Toasts";

export const AddApp = ({ handleAddAppsOnChange, values, errors }: any) => {
  const [inputValue, setInputValue] = useState("");

  const [allApps, setAllApps] = useState<any[]>([]);

  const fetchAllApps = useCallback(
    async (searchQuery?: string) => {
      try {
        const { data } = await apiLibrary.userProfile.getUsersFilterDropdowns(
          1,
          "apps",
          searchQuery
        );
        setAllApps(
          data?.apps?.map((community: any) => ({
            value: community?.id,
            label: community?.name,
          }))
        );
      } catch (error: any) {
        // Handle API errors
        Toasts.error(error.message);
      }
    },
    [allApps]
  );

  useEffect(() => {
    fetchAllApps();
  }, []);

  const handleAddAppsSearch = (query: string) => {
    fetchAllApps(query);
  };

  return (
    <div className="flex items-center py-2">
      <p className="w-[10%] dark:text-textMain">App</p>
      <div className="flex justify-start items-center w-[90%] relative gap-1.5   rounded bg-white ">
        <MultiSelect
            inputValue={inputValue}
            onInputChange={(value: string) => {
              setInputValue(value);
              handleAddAppsSearch(value);
            }}
            className={"border-lineDark border rounded-md capitalize"}
            options={allApps}
            defaultValue={{ value: "", label: "" }}
            isMulti={false}
            placeholder="Please Select An App"
            closeOnSelect={true}
            value={values?.targetPerson?.appName}
            onChange={(option: any) => {
              handleAddAppsOnChange(option);
            }}
          />
      </div>
    </div>
  );
};
