import { useEffect } from "react";
import Map from "./Map";
import Sidebar from "./Sidebar";
import HeaderBar from "./Header";
import { displaySurveyView } from "store/surveyJS";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import { useSelector } from "react-redux";

const Geography = ({ dataSourcesData }: { dataSourcesData: any }): any => {
  const dispatch = useDispatch();
  const { formJson } = useSelector<RootState, I_InitialStateOfSurveyJS>(
    (state) => state.surveyJS
  );
  useEffect(() => {
    return () => {
      dispatch(displaySurveyView());
    };
  }, []);
  
  return (
    <main
      className="flex flex-col w-full overflow-hidden"
      style={{ height: "calc(99vh)" }}
    >
      <HeaderBar />
      <div className="flex justify-between w-full h-full relative">
        <Map />
        <Sidebar dataSourcesData={dataSourcesData} />

        {/* Disabled Overlay - applied only on this specific area */}
        {formJson.disabledOffline && (
          <div className="absolute top-0 left-0 w-full h-full bg-bgWhite bg-opacity-50 pointer-events-all z-40"></div>
        )}
      </div>
    </main>
  );
};

export { Geography };
