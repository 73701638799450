import React, { useState } from "react";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { IReportSettingsFormValues } from "../..";
import { Step } from "store/addReportStepper/initialState";
import { ISummaryReports } from "store/reportsSummary";
import { FieldArray, FormikProps } from "formik";
import PlusIcon from "assets/icons/HeroIcons/PlusIcon";
import { InformationCircleIcon, TrashIcon } from "assets/icons/HeroIcons";
import InputField from "view/components/InputField";
import { MaterialUiSelect } from "../Select";
import useCustomBranding from "hooks/useCustomBranding";

interface TimeQueryBuilderProps extends FormikProps<IReportSettingsFormValues> {
  activeStep: any;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}

export const TimeQueryBuilder: React.FC<TimeQueryBuilderProps> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  reportSummary,
  fetchReportsSummary,
  moveToPreviousStep,
  moveToNextStep,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setFieldError,
  setFieldTouched,
}) => {
  return (
    <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
        <div className="flex items-center justify-center">
          <InformationCircleIcon className="h-[15px] w-[15px]" />
          <p className="ml-2 text-sm text-textMidLight font-Overpass dark:text-textMain">
            Select the time period for which you want observations included in
            the report. Specify whether the time period should be based on the
            submission date in your personal time zone or the local time at the
            observer's location.
          </p>
        </div>
        <div className="flex items-center justify-between w-full mt-2 mb-1">
          {/* <p className="text-base text-textMid font-Overpass dark:text-textMain">
            Filter Observations
          </p> */}

          <button
            type="button"
            onClick={() => {
              setFieldValue("dateFilter", [
                {
                  condition: "==",
                  operator: "and",
                  date: "",
                  dateType: {
                    value: "observer_date",
                    name: "Submission Local Date",
                  },
                },
              ]);
              setFieldValue("repeatedDateFilter", {
                date: "",
                period: "",
                periodDuration: 1,
                periodType: {
                  value: "was_between_today_and",
                  name: "Was Between Today And",
                },
              });
            }}
          >
            {/* <p className="text-sm font-semibold cursor-pointer text-textLink">
              Clear All
            </p> */}
          </button>
        </div>
        <Row
          activeStep={activeStep}
          isLastStep={isLastStep}
          steps={steps}
          reportId={reportId}
          reportSummary={reportSummary}
          fetchReportsSummary={fetchReportsSummary}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          setFieldTouched={setFieldTouched}
        />
      </div>
    </div>
  );
};

// Row Component

export const Row: React.FC<any> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  reportSummary,
  fetchReportsSummary,
  moveToPreviousStep,
  moveToNextStep,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setFieldError,
  setFieldTouched,
}) => {
  const { primaryColor } = useCustomBranding();
  return (
    <div className="flex items-center w-full gap-3 mb-2">
      {reportSummary.reportSetting.isRepeat ? (
        <TimeQueryBuilderWhenRepeat
          activeStep={activeStep}
          isLastStep={isLastStep}
          steps={steps}
          reportId={reportId}
          reportSummary={reportSummary}
          fetchReportsSummary={fetchReportsSummary}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          setFieldTouched={setFieldTouched}
        />
      ) : (
        <div className="w-full">
          <FieldArray name="dateFilter">
            {({ push, remove }) => {
              return (
                <div className="w-full">
                  {values.dateFilter.map((item: any, index: any) => {
                    const dateFilterErrors = errors?.dateFilter
                      ? errors.dateFilter[index]
                      : {
                          condition: null,
                          operator: null,
                          date: null,
                          dateType: null,
                        };

                    const dateFilterTouched =
                      touched?.dateFilter && touched.dateFilter[index]
                        ? {
                            condition:
                              touched.dateFilter[index].condition || false,
                            operator:
                              touched.dateFilter[index].operator || false,
                            date: touched.dateFilter[index].date || false,
                            dateType:
                              touched.dateFilter[index].dateType || false,
                          }
                        : {
                            condition: false,
                            operator: false,
                            date: false,
                            dateType: false,
                          };
                    return (
                      <>
                        <div className="flex items-start w-full gap-3">
                          <div className={`w-[100px]`}>
                            {index === 0 ? (
                              <div className="w-[110px] dark:text-textMain uppercase text-center mt-5">
                                <p>Where</p>
                              </div>
                            ) : (
                              <TextInput
                                fieldAs="select"
                                label=""
                                type="select"
                                name="operator"
                                data={["and", "or"]}
                                handleChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldValue(
                                    `dateFilter[${index}].operator`,
                                    event.target.value
                                  );
                                }}
                                handleBlur={handleBlur}
                                value={values?.dateFilter[index]?.operator}
                                error={errors?.otherQueryFilter?.operator}
                                // touched={errors?.otherQueryFilter?.operator}
                              />
                            )}
                          </div>
                          <div className="flex-1 max-w-[25%]">
                            {/* <TextInput
                              label=""
                              type="text"
                              height={38}
                              name="date"
                              placeholder="Submission Date"
                              onFocus={(e: any) => e.target.blur()}
                              handleBlur={handleBlur}
                              value={"Submission Date"}
                            /> */}

                            <TextInput
                              fieldAs="select"
                              label=" "
                              type="select"
                              name={`dateFilter[${index}].dateType`}
                              data={[
                                {
                                  value: "observer_date",
                                  name: "Submission Local Date",
                                },
                                {
                                  value: "observation_date",
                                  name: "Submission Date",
                                },
                              ]}
                              handleChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const { value } = event.target;

                                values?.dateFilter?.forEach(
                                  (item: any, index: any) => {
                                    setFieldValue(
                                      `dateFilter[${index}].dateType`,
                                      value
                                    );
                                  }
                                );
                              }}
                              handleBlur={handleBlur}
                              value={values?.dateFilter[index]?.dateType}
                              error={dateFilterErrors?.dateType}
                              touched={dateFilterErrors?.dateType}
                            />
                          </div>

                          <div className="flex-1 py-3">
                            <MaterialUiSelect
                              options={[
                                {
                                  name: "is",
                                  value: "==",
                                },
                                {
                                  name: "is Not",
                                  value: "!=",
                                },
                                {
                                  name: "was On or Before",
                                  value: "<=",
                                },
                                {
                                  name: "was Before",
                                  value: "<",
                                },
                                {
                                  name: "Was On or After",
                                  value: ">=",
                                },
                                {
                                  name: "Was After",
                                  value: ">",
                                },
                              ]}
                              labelKey="name"
                              valueKey="value"
                              initialValue={
                                values?.dateFilter[index]?.condition
                              }
                              error={errors.dateFilterErrors?.condition}
                              handleChange={(value: any) => {
                                setFieldValue(
                                  `dateFilter[${index}].condition`,
                                  value.value
                                );
                              }}
                              className="capitalize"
                              width="100%"
                            />
                          </div>
                          <div className="flex-1 py-3 max-w-[25%]">
                            <InputField
                              label=""
                              type="date"
                              // height={38}
                              className={"w-full"}
                              min={index === 1 ? values.dateFilter[0].date : ""}
                              max={
                                index === 0 ? values.dateFilter[1]?.date : ""
                              }
                              name={`dateFilter[${index}].date`}
                              onChange={(values: any) => {
                                setFieldValue(
                                  `dateFilter[${index}].date`,
                                  values
                                );
                              }}
                              error={dateFilterErrors?.date}
                              value={values?.dateFilter[index]?.date}
                              touched={dateFilterTouched?.date}
                              onBlur={handleBlur}
                              // onFocus={handleFocus}
                            />
                          </div>

                          <div className="w-[50px] cursor-pointer mt-3">
                            <TrashIcon
                              height={25}
                              width={25}
                              onClick={() => {
                                if (index !== 0) {
                                  remove(index);
                                } else {
                                  setFieldValue(
                                    `dateFilter[${index}].date`,
                                    ""
                                  );
                                  // setFieldValue("dateFilter", [
                                  //   {
                                  //     condition: "==",
                                  //     operator: "and",
                                  //     date: "",
                                  //   },
                                  // ]);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {values.dateFilter.length < 2 && (
                    <button
                      type="button"
                      onClick={() => {
                        push({
                          condition: "==",
                          operator:
                            values.dateFilter.length === 0 ? "and" : "and",
                          date: "",
                          dateType:
                            values?.dateFilter[0]?.dateType.name ===
                            "observer_date"
                              ? {
                                  value: "observer_date",
                                  name: "Submission Local Date",
                                }
                              : {
                                  value: "observation_date",
                                  name: "Submission Date",
                                },
                        });
                      }}
                      className="flex items-center mb-6 disabled:cursor-pointer"
                    >
                      <PlusIcon fill={primaryColor} />
                      <p className="ml-2 text-sm font-semibold text-textLink">
                        Add Another One
                      </p>
                    </button>
                  )}
                </div>
              );
            }}
          </FieldArray>
        </div>
      )}
    </div>
  );
};

const TimeQueryBuilderWhenRepeat: React.FC<any> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  reportSummary,
  fetchReportsSummary,
  moveToPreviousStep,
  moveToNextStep,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setFieldError,
  setFieldTouched,
}) => {
  const datePeriod = {
    wasBetweenTodayAnd: "was_between_today_and",
    wasDuringThePast: "was_during_the_past",
  };
  return (
    <div className="flex w-full gap-3 mb-2 ">
      <div className="w-full ">
        {/* <TextInput
          label=""
          type="text"
          height={38}
          name="date"
          placeholder="Submission Date"
          onFocus={(e: any) => e.target.blur()}
          handleBlur={handleBlur}
          value={"Submission Date"}
        /> */}

        <TextInput
          fieldAs="select"
          label=" "
          type="select"
          name="dateType"
          data={[
            {
              value: "observer_date",
              name: "Submission Local Date",
            },
            {
              value: "observation_date",
              name: "Submission Date",
            },
          ]}
          handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            setFieldValue("repeatedDateFilter.dateType", value);
          }}
          handleBlur={handleBlur}
          value={values?.repeatedDateFilter?.dateType}
          error={errors?.repeatedDateFilter?.dateType}
          touched={touched?.repeatedDateFilter?.dateType}
        />
      </div>

      <div className="w-full">
        <TextInput
          fieldAs="select"
          label=" "
          type="select"
          name="periodType"
          data={[
            {
              value: datePeriod.wasBetweenTodayAnd,
              name: "Was Between Today And",
            },
            {
              value: datePeriod.wasDuringThePast,
              name: "Was During The Past",
            },
          ]}
          handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            setFieldValue("repeatedDateFilter.periodType", value);
          }}
          handleBlur={handleBlur}
          value={values?.repeatedDateFilter?.periodType}
          error={errors?.repeatedDateFilter?.periodType}
          touched={touched?.repeatedDateFilter?.periodType}
        />
      </div>

      {values?.repeatedDateFilter?.periodType?.value ===
      datePeriod.wasBetweenTodayAnd ? (
        <div className="min-w-[360px] mt-[12px]">
          <InputField
            label=""
            type="date"
            className={"w-full"}
            name="repeatedDateFilter.date"
            error={errors?.repeatedDateFilter?.date}
            touched={touched.repeatedDateFilter?.date}
            onChange={(values: any) =>
              setFieldValue("repeatedDateFilter.date", values)
            }
            onBlur={handleBlur}
            value={values.repeatedDateFilter?.date}
          />
        </div>
      ) : (
        <>
          <div className="w-[400px]">
            <TextInput
              label=""
              type="number"
              height={38}
              name="repeatedDateFilter.periodDuration"
              handleChange={handleChange}
              handleBlur={handleBlur}
              min={1}
              value={values?.repeatedDateFilter?.periodDuration}
              error={errors?.repeatedDateFilter?.periodDuration}
              touched={touched?.repeatedDateFilter?.periodDuration}
            />
          </div>

          <div className="w-full">
            <TextInput
              fieldAs="select"
              label=" "
              type="select"
              name="repeatedDateFilter.period"
              data={["days", "weeks", "months", "years"]}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const lowerCase = event.target.value.toLocaleLowerCase();
                setFieldValue("repeatedDateFilter.period", lowerCase);
              }}
              handleBlur={handleBlur}
              value={values?.repeatedDateFilter?.period}
              error={errors?.repeatedDateFilter?.period}
              touched={touched?.repeatedDateFilter?.period}
            />
          </div>
        </>
      )}
    </div>
  );
};
