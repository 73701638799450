import React, { useEffect, useState } from "react";
import { Toasts } from "view/components/Toasts";
import Button from "view/components/Button";

import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { ISummaryReports } from "store/reportsSummary";
import TextInput from "view/components/InputField";
import BpCheckbox from "view/components/CheckBox";
import DragDotsIcon from "assets/icons/HeroIcons/DragDotsIcon";
import CloseIcon from "./CloseIcon";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { sentenceCase } from "change-case";
import { v4 as uuidv4 } from "uuid";
import { TailSpin } from "react-loader-spinner";
import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";
import { checkIsAnythingUpdatedThisStep } from "../..";
import { Step } from "hooks/useReportsStepper";
import useCustomBranding from "hooks/useCustomBranding";
interface IColumn {
  columnName: string;
  columnType: string;
  isSelected: boolean;
  columnTitle: string;
  isCustom: boolean;
  id: string;
}

interface IColumnsInterface {
  availableColumns: IColumn[];
}

interface ColumnnsConfigurationProps {
  activeStep: any;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}

export const ColumnnsConfiguration: React.FC<ColumnnsConfigurationProps> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  reportSummary,
  fetchReportsSummary,
  moveToPreviousStep,
  moveToNextStep,
  setAnythingUpdatedThisStep,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isColumnsLoading, setIsColumnsLoading] = useState(false);
  const dispatch = useDispatch();
  const { primaryColor } = useCustomBranding();

  const [columnsConfiguration, setColumnsConfiguration] =
    useState<IColumnsInterface>({
      availableColumns: [],
    });

  const [columnsOrder, setColumnsOrder] = useState<IColumn[]>([]);

  const [searchResults, setSearchResults] = useState<IColumn[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const getColumnsByReport = async () => {
    setIsColumnsLoading(true);
    try {
      const { data } = await apiLibrary.Reports.AddReportApis.getReportColumns(
        reportSummary.id
      );
      const availableColumns = data.availableColumns.map((column: IColumn) => ({
        ...column,
        id: uuidv4(),
      }));

      const updatedColumnsOrder = availableColumns.filter(
        (column: any) => column.isSelected
      );

      // Assign indices to null 'columnOrder' values
      updatedColumnsOrder.forEach((item: any, index: number) => {
        if (item.columnOrder === null) {
          item.columnOrder = index;
        }
      });

      // Now sort the array based on the 'columnOrder'
      updatedColumnsOrder.sort(
        (a: any, b: any) => a.columnOrder - b.columnOrder
      );

      setColumnsConfiguration({ availableColumns });
      setSearchResults(availableColumns);
      setColumnsOrder(updatedColumnsOrder);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(sentenceCase(errorMsg ?? ""));
    } finally {
      setIsColumnsLoading(false);
    }
  };

  useEffect(() => {
    getColumnsByReport();
    return () => {
      setColumnsConfiguration({ availableColumns: [] });
      setSearchResults([]);
      setColumnsOrder([]);
      setSearchValue("");
    };
  }, []);

  const handleSelectColumns = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const columnId = value;

    setColumnsConfiguration((prevConfig) => {
      const updatedColumns = prevConfig.availableColumns.map((column) => ({
        ...column,
        isSelected: column.id === columnId ? checked : column.isSelected,
      }));

      const updatedColumnsOrder = updatedColumns.filter(
        (column) => column.isSelected
      );

      setSearchResults(updatedColumns);
      setColumnsOrder(updatedColumnsOrder);
      setAnythingUpdatedThisStep("dataFieldSelectionAndOrdering", true);
      return { ...prevConfig, availableColumns: updatedColumns };
    });
  };

  const handleSelectAndDeSelectAllColumns = (flag: boolean) => {
    setColumnsConfiguration((prevConfig) => {
      const updatedColumns = prevConfig.availableColumns.map((column) => ({
        ...column,
        isSelected: flag,
      }));

      const updatedColumnsOrder = updatedColumns.filter(
        (column) => column.isSelected
      );

      setSearchResults(updatedColumns);
      setColumnsOrder(updatedColumnsOrder);
      setAnythingUpdatedThisStep("dataFieldSelectionAndOrdering", true);
      return { ...prevConfig, availableColumns: updatedColumns };
    });
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.trim().toLowerCase();
    setSearchValue(value);

    const results = value
      ? columnsConfiguration.availableColumns.filter((column) =>
          sentenceCase(column.columnName).toLowerCase().includes(value)
        )
      : columnsConfiguration.availableColumns;

    setSearchResults(results);
  };

  const deselectColumn = (columnId: string) => {
    setColumnsConfiguration((prevConfig) => {
      const updatedColumns = prevConfig.availableColumns.map((column) => {
        if (column.id === columnId) {
          return { ...column, isSelected: false };
        }
        return column;
      });
      setAnythingUpdatedThisStep("dataFieldSelectionAndOrdering", true);

      return { ...prevConfig, availableColumns: updatedColumns };
    });

    setColumnsOrder((prevOrder) =>
      prevOrder.filter((column) => column.id !== columnId)
    );
    setSearchResults((prevResults: IColumn[]) => {
      const updatedColumns = prevResults.map((column) => {
        if (column.id === columnId) {
          return { ...column, isSelected: false };
        }
        return column;
      });
      return updatedColumns;
    });
  };

  const handleAddReportColumns = async () => {
    if (!checkIsAnythingUpdatedThisStep(steps, activeStep.step)) {
      moveToNextStep();
      return;
    }
    setIsLoading(true);
    const values = columnsOrder.map((column: IColumn, index: number) => {
      return {
        columnName: column.columnName,
        columnOrder: index,
        columnTitle: column.columnTitle,
        columnType: column.columnType,
        isCustom: column.isCustom,
      };
    });
    try {
      const { data } =
        await apiLibrary.Reports.AddReportApis.choseReportColumns(
          reportSummary.id,
          {
            columns: values,
          }
        );
      await fetchReportsSummary(reportSummary.id);
      moveToNextStep();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="gap-4 dark:bg-secondaryLight">
      <div className="pb-2">
        <p className="text-sm font-normal text-secondaryMidLight">
          Choose the data fields and the order that you would like them to be
          displayed in your report.
        </p>
      </div>
      <TextInput
        label=""
        type="ColumnInput"
        placeholder="Search Fields"
        value={searchValue}
        onChange={handleSearchInputChange}
      />

      <div className="flex items-start justify-start w-full max-h-[51vh] overflow-y-auto gap-4 pr-1 ">
        <div className="relative flex flex-col items-start justify-start flex-grow w-1/2">
          <div className="flex items-center self-stretch justify-end ">
            <div className="flex items-center justify-start flex-grow gap-4 px-3 py-2 rounded-lg">
              <div className="flex justify-start items-center flex-grow relative py-0.5 font-medium">
                <p className="text-textMid dark:text-textMain">
                  Available Data Fields
                </p>
              </div>
            </div>
            <div className="flex flex-col items-end justify-center py-2">
              <div className="flex items-center justify-center gap-1 rounded-3xl">
                <div className="flex justify-between items-center   relative pt-1.5 pb-2">
                  <button
                    className="mr-2 text-sm font-semibold text-center text-primary"
                    onClick={() => {
                      handleSelectAndDeSelectAllColumns(true);
                    }}
                  >
                    Select All
                  </button>
                  <button
                    className="text-sm font-semibold text-center text-primary"
                    onClick={() => getColumnsByReport()}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full h-[440px] overflow-hidden px-2 py-3 rounded-lg border dark:border-lineLight border-lineMid overflow-y-auto scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            {isColumnsLoading ? (
              <div className="loader min-h-[40vh] justify-center items-center flex w-full">
                <TailSpin
                  height="50"
                  width="50"
                  color={primaryColor}
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            ) : (
              <div className="flex flex-col w-full rounded-lg">
                {searchValue !== "" && searchResults.length === 0 ? (
                  <p className="text-sm text-gray-500">No columns found.</p>
                ) : (
                  searchResults.map((column: IColumn, index: number) => {
                    return (
                      <div
                        className="flex items-center justify-start gap-3 px-1 rounded-lg"
                        key={index}
                      >
                        <div className="flex items-center justify-center">
                          <BpCheckbox
                            onChange={handleSelectColumns}
                            value={column.id}
                            checked={column.isSelected}
                            label={
                              <div className="flex items-center justify-start flex-grow">
                                <div className="flex justify-start items-center flex-grow relative py-0.5">
                                  <p
                                    className={`capitalize ${
                                      column.isSelected
                                        ? "dark:text-textMain"
                                        : "dark:text-caption"
                                    }`}
                                  >
                                    {sentenceCase(column.columnTitle)}
                                  </p>
                                </div>
                              </div>
                            }
                            labelPlacement="end"
                          />
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-start justify-start flex-grow w-1/2 rounded-lg">
          <div className="flex flex-col items-start justify-start flex-grow">
            <div className="flex items-center self-stretch justify-end ">
              <div className="flex justify-start items-center flex-grow gap-4 px-3 py-2 rounded-lg mt-[1px]">
                <div className="flex justify-start items-center flex-grow relative py-0.5 font-medium">
                  <p className="text-textMid dark:text-textMain">
                    Current Data Field Order
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full border dark:border-lineLight border-lineMid mt-[5px] rounded">
              <ColumnSelector
                columnOrder={columnsOrder}
                setColumnOrder={setColumnsOrder}
                columns={columnsConfiguration}
                deselectColumn={deselectColumn}
                isColumnsLoading={isColumnsLoading}
                setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col items-start justify-start w-full gap-4 pt-2 bg-white rounded-lg">
        <SubmissionButtons
          isValid={columnsOrder.length === 0}
          isSubmitting={isLoading || isColumnsLoading}
          handleGoBackBtn={() => moveToPreviousStep()}
          handleSubmit={handleAddReportColumns}
          reportSummary={reportSummary}
          // values={FormikProps.values}
        />
      </div>
    </div>
  );
};

const SubmissionButtons = ({
  handleGoBackBtn,
  handleSubmit,
  isSubmitting,
  isValid,
  reportSummary,
}: any) => {
  const { reports } = usePermissions();
  const { primaryColor } = useCustomBranding();
  const urlParams = new URLSearchParams(window.location.search);
  const reportType = urlParams.get("type");
  return (
    <div className="flex items-center gap-2">
      <Button
        disabled={isSubmitting}
        type="button"
        text="Back"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowLeftIcon fill={primaryColor} />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="before"
        onClick={handleGoBackBtn}
      />
      <Button
        disabled={
          isSubmitting ||
          !reports.canCreateChooseColumnsReports ||
          (!reportSummary?.fieldsSelection && reportType === "basic")
        }
        type="submit"
        onClick={handleSubmit}
        text="Save & Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowRightIcon fill={primaryColor} />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="after"
      />
    </div>
  );
};

const ItemTypes = {
  COLUMN: "column",
};

interface ColumnSelectorProps {
  columnOrder: IColumn[];
  setColumnOrder: any;
  columns: any;
  deselectColumn: any;
  isColumnsLoading: boolean;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}

const ColumnSelector: React.FC<ColumnSelectorProps> = ({
  columnOrder,
  setColumnOrder,
  deselectColumn,
  isColumnsLoading,
  setAnythingUpdatedThisStep,
}) => {
  const { primaryColor } = useCustomBranding();
  return (
    <div className="w-full " style={{ overflow: "hidden" }}>
      <DndProvider backend={HTML5Backend}>
        <div className="w-full  h-[424px]  my-2 overflow-y-auto  scrollbar-thumb-gray-300 scrollbar-track-gray-100">
          {isColumnsLoading ? (
            <div className="flex items-center justify-center w-full min-h-full loader">
              <TailSpin
                height="50"
                width="50"
                color={primaryColor}
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            columnOrder.map((column: IColumn, index) => {
              return (
                <Column
                  key={column.id}
                  column={column}
                  index={index}
                  setColumnOrder={setColumnOrder}
                  deselectColumn={deselectColumn}
                  setAnythingUpdatedThisStep={setAnythingUpdatedThisStep}
                />
              );
            })
          )}
        </div>
      </DndProvider>
    </div>
  );
};

const Column: React.FC<{
  column: IColumn;
  index: number;
  setColumnOrder: any;
  deselectColumn: (columnId: string) => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}> = ({
  column,
  index,
  setColumnOrder,
  deselectColumn,
  setAnythingUpdatedThisStep,
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.COLUMN,
      item: { column, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index, column]
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.COLUMN,
      drop: (item: { id: string; index: number }) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Time to actually perform the action
        setColumnOrder((prevOrder: IColumn[]) => {
          const newOrder = [...prevOrder];
          const [dragged] = newOrder.splice(dragIndex, 1);
          newOrder.splice(hoverIndex, 0, dragged);
          setAnythingUpdatedThisStep("dataFieldSelectionAndOrdering", true);
          return newOrder;
        });
        item.index = hoverIndex;
      },
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    }),
    [setColumnOrder, index]
  );

  const opacity = isDragging ? 0.5 : 1;
  const cursor = isDragging ? "grab" : "pointer";

  return (
    <div ref={drop}>
      <div ref={drag} style={{ opacity, cursor, width: "100%" }}>
        <div
          className="relative flex items-center self-stretch justify-start gap-4 px-3 py-2 rounded-lg"
          key={column.id}
        >
          <DragDotsIcon
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="#2C3236"
          />
          <div className="relative flex items-center justify-start flex-grow">
            <div className="flex justify-start items-center flex-grow relative py-0.5">
              <p className="dark:text-caption">
                {column && sentenceCase(column.columnTitle)}
              </p>
            </div>
            <button
              title="remove column"
              onClick={(e) => deselectColumn(column.id)}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
