import { ArrowLeftIcon, ArrowRightIcon } from "assets/icons/HeroIcons";
import useCustomBranding from "hooks/useCustomBranding";
import React from "react";
import Button from "view/components/Button";

export const SubmissionButtons = ({
  activeStep,
  isLastStep,
  handleGoBackBtn,
  handleSubmit,
}: any) => {
  const {primaryColor} = useCustomBranding();
  return (
    <div className="flex items-center self-stretch flex-grow-0 flex-shrink-0 gap-2">
      {activeStep > 0 && (
        <Button
          type="button"
          text="Back"
          filledColor="primary"
          outlinedColor="primary"
          textColor="textWhite"
          className="px-5 py-2"
          width="35"
          height="13"
          fontStyle="font-semibold"
          icon={<ArrowLeftIcon fill={primaryColor} />}
          variant="outlined"
          iconPosition="before"
          onClick={handleGoBackBtn}
        />
      )}
      <Button
        type="button"
        onClick={handleSubmit}
        text="Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        fontStyle="font-semibold"
        variant="outlined"
        icon={<ArrowRightIcon fill={primaryColor} />}
        iconPosition="after"
      />
    </div>
  );
};
