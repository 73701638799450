import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store";
import { Statuses } from "./Components";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import CustomToolTip from "view/components/ToolTip";
import { filtersInitialState } from "store/filters/initialState";
import { fetchProfileReportsAction } from "store/reportsProfile";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import RefreshSingleCircleIcon from "assets/icons/HeroIcons/RefreshSingleCircleIcon";
import useCustomBranding from "hooks/useCustomBranding";

interface IFiltersListing {
  label: string;
  dropdownComponent?: React.ReactNode;
  name: string;
  isVisible: boolean;
}

const FiltersList = ({ isCloseDiv }: any) => {
  const { reportProfileFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { reportsId } = useParams<{
    reportsId: any;
  }>();
  const { primaryColor } = useCustomBranding();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState<string | null>(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [rightOffsetWidth, setRightOffsetWidth] = useState(false);

  const handleMouseEnter = (dropdownName: string, event: any) => {
    if (
      showDropdown === dropdownName &&
      !isDatePickerElementAndButtonLabel(event.target, dropdownName)
    ) {
      setShowDropdown(null);
    } else {
      setShowDropdown(dropdownName);
    }
  };

  function isDatePickerElementAndButtonLabel(
    target: any,
    dropdownName?: string | null
  ) {
    if (!dropdownName) {
      return false;
    }

    const selector = `#${dropdownName}id, .MuiDateCalendar-root, .MuiPickersDay-root, .MuiPickersYear-root, .MuiPickersMonth-root, .MuiPickerToolbar-root, .MuiToolbar-root, .MuiIconButton-root`; // Correct the selector string by removing the trailing comma
    return target?.closest(`${selector}`);
  }

  const List: IFiltersListing[] = [
    {
      label: "All Statuses",
      name: "allStatuses",
      isVisible: reportsId,
      dropdownComponent: <Statuses data={reportProfileFilter?.status} />,
    },
  ];

  useEffect(() => {
    if (showDropdown) {
      const currentDropdownMenu = document.querySelector(
        `#${showDropdown}id .menu`
      );

      const btn = document.querySelector(`[data-dropdown="${showDropdown}"]`);

      if (currentDropdownMenu && btn) {
        const dropdownRect = currentDropdownMenu.getBoundingClientRect();
        const btnwidth = btn.getBoundingClientRect();

        const btnwidthoffset = window.innerWidth - btnwidth.right;
        const setDicDirection = dropdownRect.width - btnwidth.width;

        const getRightOffset = btnwidthoffset - setDicDirection;
        setRightOffsetWidth(getRightOffset < 0);
      }
    }
  }, [showDropdown]);

  function handleClickOutside(event: any) {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !isDatePickerElement(event.target)
    ) {
      setShowDropdown(null);
    }
  }
  function isDatePickerElement(target: any) {
    return target.closest(
      `.MuiDateCalendar-root, .MuiPickersDay-root, .MuiPickersYear-root, .MuiPickersMonth-root, .MuiPickerToolbar-root, .MuiToolbar-root, .MuiIconButton-root, .btn`
    ); // Added MuiIconButton-root
  }

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
  return (
    <div
      className={`flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 ${
        isCloseDiv ? "h-min-[40px]" : "h-[0px] opacity-0"
      } transition-h duration-300 ease-in-out`}
    >
      <div className="flex flex-wrap items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
        {List?.filter((item) => item?.isVisible)?.map((item, index) => (
          <div
            key={index}
            data-dropdown={item.name}
            onClick={(event) => handleMouseEnter(item.name, event)}
            id={item.name + "index"}
            className="btn flex justify-center items-center dark:bg-bgtetriary dark:text-inputText dark:border-lineLight flex-grow-0 flex-shrink-0 relative gap-1 px-1 rounded-lg border cursor-pointer hover:border-primaryMid hover:bg-primary/[0.08]  border-lineMid"
          >
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2">
              <p
                className="flex-grow-0 flex-shrink-0 text-sm font-medium text-center text-secondaryMid 
 dark:text-primaryOutlined"
              >
                {item.label}
              </p>
            </div>
            <CheveronDownIcon
              height={16}
              width={16}
              fill="#2C3236"
              style={{
                transform: `rotate(${
                  showDropdown === item.name ? "180deg" : "0"
                })`,
                transition: "transform 0.3s ease",
              }}
            />
            {showDropdown === item.name && (
              <div
                id={item.name + "id"}
                className={`absolute ${
                  !rightOffsetWidth ? "left-0" : "right-0"
                }  z-30 top-full ring-offset-8`}
              >
                <div
                  className={`${
                    item.dropdownComponent && showDropdown === item.name
                      ? "visible"
                      : "invisible"
                  } menu `}
                  ref={dropdownRef}
                >
                  {item.dropdownComponent}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div>
        <CustomToolTip
          title={
            "Refresh the page to see if other versions of the reports are ready to view"
          }
          showTooltip={showTooltip}
          toggleTooltip={toggleTooltip}
        >
          <button
            className="flex items-center gap-2 border border-primary rounded-2xl pt-1 pb-1 pl-3 pr-3 text-primary hover:bg-primaryExtraLight"
            onClick={() => dispatch(fetchProfileReportsAction(reportsId))}
          >
            Refresh{" "}
            <span className="gap-2">
              <RefreshSingleCircleIcon fill={primaryColor} />
            </span>
          </button>
        </CustomToolTip>
      </div>
    </div>
  );
};

export default FiltersList;
