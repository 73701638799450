import React, { forwardRef, useEffect, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import { InputFields } from "./components/InputFields";

import { Toasts } from "view/components/Toasts";
import * as Yup from "yup";
import Button from "view/components/Button";
import ArrowRightIcon from "assets/icons/HeroIcons/ArrowRightIcon.tsx";
import apiLibrary from "services/api";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ISummaryReports } from "store/reportsSummary";
import useReportsStepper, { Step } from "hooks/useReportsStepper";
import { checkIsAnythingUpdatedThisStep } from "../..";
import { BasicReportFields } from "./components/BasicReportFields";
import moment from "moment";
import useCustomBranding from "hooks/useCustomBranding";

interface ReportNameProps {
  activeStep: Step;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: (currentStep: any, skipStep: boolean) => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}

interface IFormInitialState {
  communityIds: any[];
  filterByEntity: boolean;
  formIds: any[];
  name: string;
  organizationIds: any[];
  programIds: any[];
}

interface IFormInitialStateForBasicReport {
  filterByEntity: boolean;
  formIds: any;
  name: string;
  programIds: any;
  startDate: any;
  startTime: any;
  endDate: any;
  endTime: any;
  programTimeRang: boolean;
  fieldsSelection: boolean;
}

export const ReportName = forwardRef<FormikProps<any>, ReportNameProps>(
  (
    {
      activeStep,
      isLastStep,
      steps,
      reportId,
      reportSummary,
      fetchReportsSummary,
      moveToPreviousStep,
      moveToNextStep,
      setAnythingUpdatedThisStep,
    },
    ref
  ) => {
    const {
      setReportId,
      // moveToNextStep,
    } = useReportsStepper();

    const getProgramsFormsOrganizationsCommunitiesValues = (data: any[]) => {
      const values = data?.map((item: any) => item.value);
      return values;
    };
    const [futureDate, setFutureDate] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const reportType = urlParams.get("type");

    const convertDataForMultiselectDropDowns = (data: any[]) => {
      const values = data?.map((entity: any) => ({
        value: entity?.id,
        label: entity?.name,
      }));
      return values;
    };
    const convertDataForSingleselectDropDowns = (data: any) => {
      const values = {
        value: data?.id,
        label: data?.name,
      };
      return values;
    };
    const [formInitialValues, setFormInitialValues] =
      useState<IFormInitialState>({
        communityIds: [],
        filterByEntity: true,
        formIds: [],
        name: "",
        organizationIds: [],
        programIds: [],
      });
    const [
      formInitialValuesForBasicReports,
      setFormInitialValuesForBasicReports,
    ] = useState<IFormInitialStateForBasicReport>({
      filterByEntity: true,
      formIds: {},
      name: "",
      programIds: {},
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      programTimeRang: false,
      fieldsSelection: false,
    });
    useEffect(() => {
      const isBasicReport = reportType === "basic";

      if (reportSummary.filterByEntity) {
        const sharedData = {
          filterByEntity: reportSummary.filterByEntity ?? true,
          name: reportSummary.reportName,
        };

        if (isBasicReport) {
          setFormInitialValuesForBasicReports(
            (prev: IFormInitialStateForBasicReport) => ({
              ...sharedData,
              formIds: convertDataForSingleselectDropDowns(
                reportSummary.formsFilter[0]
              ),
              programIds: convertDataForSingleselectDropDowns(
                reportSummary.programsFilter[0]
              ),
              startDate: reportSummary.dateFilter[0]?.startDate,
              endDate: reportSummary.dateFilter[1]?.endDate,
              startTime: reportSummary.dateFilter[0]?.startTime,
              endTime: reportSummary.dateFilter[1]?.endTime,
              programTimeRang: reportSummary.programTimeRang || false,
              fieldsSelection: reportSummary.fieldsSelection || false,
            })
          );
        } else {
          setFormInitialValues((prev: IFormInitialState) => ({
            ...sharedData,
            communityIds: convertDataForMultiselectDropDowns(
              reportSummary.communitiesFilter
            ),
            formIds: convertDataForMultiselectDropDowns(
              reportSummary.formsFilter
            ),
            organizationIds: convertDataForMultiselectDropDowns(
              reportSummary.organizationsFilter
            ),
            programIds: convertDataForMultiselectDropDowns(
              reportSummary.programsFilter
            ),
          }));
        }
      } else {
        const defaultData = {
          filterByEntity: true,
          name: reportSummary.reportName,
        };

        setFormInitialValues((prev: IFormInitialState) => ({
          ...defaultData,
          communityIds: [],
          formIds: [],
          organizationIds: [],
          programIds: [],
        }));

        setFormInitialValuesForBasicReports(
          (prev: IFormInitialStateForBasicReport) => ({
            ...defaultData,
            formIds: null,
            programIds: null,
            programTimeRang: false,
            fieldsSelection: false,
            startDate: "",
            endDate: "",
            startTime: "",
            endTime: "",
          })
        );
      }
    }, [reportSummary, reportType]);

    /**
     * Handle form submission.
     * @param {object} values - Form values
     * @param {Function} setSubmitting - Function to set submitting state
     * @example
     * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
     */
    const handleSubmit = async (
      values: any,
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
      if (!checkIsAnythingUpdatedThisStep(steps, activeStep.step)) {
        if (values.fieldsSelection && reportType === "basic") {
          moveToNextStep(activeStep.step, false);
        } else if (reportType === "detailed") {
          moveToNextStep(activeStep.step, false);
        } else {
          moveToNextStep(activeStep.step, true);
        }
        return;
      }

      const prepareFormValues = (isBasic: boolean) => {
        if (isBasic) {
          return {
            dateFilter: [
              {
                condition: ">=",
                dateType: "observationDate",
                operator: "and",
                startDate: moment
                  .utc(`${values.startDate}T${values.startTime}`)
                  .format("YYYY-MM-DD"),
                startTime: moment
                  .utc(`${values.startDate}T${values.startTime}`)
                  .format("HH:mm:ss"),
              },
              {
                condition: "<=",
                dateType: "observationDate",
                operator: "and",
                endDate:
                  values.endDate === ""
                    ? moment.utc().format("YYYY-MM-DD")
                    : moment
                        .utc(`${values.endDate}T${values.endTime}`)
                        .format("YYYY-MM-DD"),
                endTime:
                  values.endDate === ""
                    ? moment.utc().format("HH:mm:ss")
                    : moment
                        .utc(`${values.endDate}T${values.endTime}`)
                        .format("HH:mm:ss"),
              },
            ],
            fieldsSelection: values.fieldsSelection,
            formId: values.formIds.value,
            programId: values.programIds.value,
            name: values.name,
            programTimeRang: values.programTimeRang,
          };
        }
        return {
          ...values,
          communityIds: getProgramsFormsOrganizationsCommunitiesValues(
            values.communityIds
          ),
          formIds: getProgramsFormsOrganizationsCommunitiesValues(
            values.formIds
          ),
          organizationIds: getProgramsFormsOrganizationsCommunitiesValues(
            values.organizationIds
          ),
          programIds: getProgramsFormsOrganizationsCommunitiesValues(
            values.programIds
          ),
        };
      };

      try {
        const formValues = prepareFormValues(reportType === "basic");
        let responseData;
        if (reportId) {
          if (reportType === "basic") {
            const { data } =
              await apiLibrary.Reports.AddReportApis.updateBasicReport(
                reportId,
                formValues
              );
            responseData = data;
          } else {
            const { data } =
              await apiLibrary.Reports.AddReportApis.updateReport(
                reportId,
                formValues
              );
            responseData = data;
          }
        } else {
          if (reportType === "basic") {
            const { data } =
              await apiLibrary.Reports.AddReportApis.addBasicReport(formValues);
            responseData = data;
          } else {
            const { data } =
              await apiLibrary.Reports.AddReportApis.addReport(formValues);
            responseData = data;
          }
        }
        setReportId(responseData.id);
        await fetchReportsSummary(responseData.id);
        if (formValues.fieldsSelection && reportType === "basic") {
          moveToNextStep(activeStep.step, false);
        } else if (reportType === "detailed") {
          moveToNextStep(activeStep.step, false);
        } else {
          moveToNextStep(activeStep.step, true);
        }
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errorMsg);
      } finally {
        setSubmitting(false);
      }
    };

    const reportNameSchema = Yup.object().shape({
      communityIds: Yup.array(),
      // filterByEntity: Yup.boolean(),
      formIds: Yup.array(),
      name: Yup.string()
        .required("Name is required")
        .max(255, "max 255 characters long"),
      organizationIds: Yup.array(),
      programIds: Yup.array(),
    });

    const reportNameSchemaForBasicReport = Yup.object().shape({
      formIds: Yup.object().shape({
        value: Yup.number().required("Form is required"),
        label: Yup.string().required("Form is required"),
      }),
      name: Yup.string()
        .required("Name is required")
        .max(255, "max 255 characters long"),
      programIds: Yup.object().shape({
        value: Yup.number().required("Program is required"),
        label: Yup.string().required("Program is required"),
      }),
      startDate: Yup.date()
        .required("Start date is required")
        .typeError("Start date must be a valid date"),
      startTime: Yup.string().nullable(),
      endDate: Yup.date()
        .nullable()
        .typeError("End date must be a valid date")
        .test(
          "endDate-after-startDate",
          "End date cannot be before the start date",
          function (value) {
            const { startDate } = this.parent; // Access other fields in the schema
            if (!value || !startDate) return true; // Pass validation if endDate is null or startDate is not defined
            return new Date(value) >= new Date(startDate); // Ensure endDate is not before startDate
          }
        ),
      endTime: Yup.string().nullable(),
      fieldsSelection: Yup.boolean().nullable(),
      programTimeRang: Yup.boolean().nullable(),
    });
    return (
      <Formik
        initialValues={
          reportType === "basic"
            ? formInitialValuesForBasicReports
            : formInitialValues
        }
        validationSchema={
          reportType === "basic"
            ? reportNameSchemaForBasicReport
            : reportNameSchema
        }
        enableReinitialize={true}
        onSubmit={handleSubmit}
        innerRef={ref}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
          setFieldTouched,
          isValid,
          dirty,
          setTouched,
          setErrors,
        }) => {
          useEffect(() => {
            setAnythingUpdatedThisStep("reportName", dirty);
          }, [dirty]);
          return (
            <Form>
              <div className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4 rounded-lg bg-bgWhite dark:bg-secondaryLight">
                {reportType === "basic" ? (
                  <>
                    <BasicReportFields
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      values={values}
                      reportSummary={reportSummary}
                      formInitialValuesForBasicReports={
                        formInitialValuesForBasicReports
                      }
                      setTouched={setTouched}
                      setErrors={setErrors}
                      setFutureDate={setFutureDate}
                      futureDate={futureDate}
                    />
                    <SubmissionButtonsForBasic
                      isValid={isValid}
                      isSubmitting={isSubmitting}
                      values={values}
                      errors={errors}
                      futureDate={futureDate}
                    />
                  </>
                ) : (
                  <>
                    <InputFields
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      values={values}
                      reportSummary={reportSummary}
                    />

                    <SubmissionButtonsForDetailed
                      isValid={isValid}
                      isSubmitting={isSubmitting}
                      // handleGoBackBtn={handleGoBackBtn}
                      values={values}
                      errors={errors}
                    />
                  </>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
);

const SubmissionButtonsForDetailed = ({
  handleGoBackBtn,
  handleSubmit,
  isSubmitting,
  isValid,
  values,
  errors,
}: any) => {
  const { primaryColor } = useCustomBranding();

  return (
    <div className="flex items-center self-stretch flex-grow-0 flex-shrink-0 gap-2">
      <Button
        disabled={isSubmitting || !isValid}
        type="submit"
        onClick={handleSubmit}
        text="Save & Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowRightIcon fill={primaryColor} />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="after"
      />
    </div>
  );
};
const SubmissionButtonsForBasic = ({
  handleGoBackBtn,
  handleSubmit,
  isSubmitting,
  isValid,
  values,
  errors,
  futureDate,
}: any) => {
  const { primaryColor } = useCustomBranding();
  return (
    <div className="flex items-center self-stretch flex-grow-0 flex-shrink-0 gap-2">
      <Button
        // disabled={
        //   values.startDate === "" ||
        //   values.name === "" ||
        //   values.programIds === null ||
        //   values.formIds === null ||
        //   futureDate
        // }
        type="submit"
        onClick={handleSubmit}
        text="Save & Next"
        filledColor="primary"
        outlinedColor="primary"
        textColor="textWhite"
        className="px-5 py-2"
        width="35"
        height="13"
        icon={<ArrowRightIcon fill={primaryColor} />}
        fontStyle="font-semibold"
        variant="outlined"
        iconPosition="after"
      />
    </div>
  );
};
