import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeAddEditContactInfoModalAction } from "store/modals/reducer.actions";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import PlusIcon from "assets/icons/HeroIcons/PlusIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import { useParams } from "react-router-dom";
import { fetchProgramProfileAction } from "store/programProfile/reducer.actions";
import { fetchCommunityProfileAction } from "store/communityProfile/reducer.actions";
import { fetchOrganizationProfileAction } from "store/organizationProfile/reducer.actions";
import { PhoneSchema } from "utils/validationSchemas";
import usePermissions from "hooks/usePermissions";
import useCustomBranding from "hooks/useCustomBranding";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};

const contactInfoValidationSchema = Yup.object().shape({
  description: Yup.string().max(1000, "1000 Max Characters"),
  emails: Yup.array().of(
    Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      title: Yup.string().required("Title is required"),
    })
  ),
  links: Yup.array().of(
    Yup.object().shape({
      links: Yup.string().required("URL is required").url("Invalid URL"),
      title: Yup.string().required("Title is required"),
    })
  ),

  phones: Yup.array().of(
    Yup.object().shape({
      phoneNumber: PhoneSchema.required("Phone number is required"),
      title: Yup.string().required("Title is required"),
    })
  ),
});

interface Email {
  email: string;
  title: string;
}
interface Link {
  links: string;
  title: string;
}

interface EmailError {
  email?: string;
  title?: string;
}

interface EmailTouched {
  email: boolean;
  title: boolean;
}

interface Phone {
  phoneNumber: string;
  title: string;
}

interface InitialState {
  description: string;
  emails: Email[];
  links: Link[];
  phones: Phone[];
}
interface AddEditContactInfoModalProps {}

export const AddEditContactInfoModal: React.FC<
  AddEditContactInfoModalProps
> = ({}) => {
  const { addEditContactInfoModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { communityId, programId, organizationId } = useParams();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { programs, organizations, communities } = usePermissions();

  const {primaryColor} = useCustomBranding();

  const [initialState, setInitialState] = useState<InitialState>({
    description: "",
    emails: [],
    links: [],
    phones: [],
    // programId: 0, // If programId is required and has a default value
  });

  const handleClose = () => {
    dispatch(closeAddEditContactInfoModalAction());
  };

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    if (
      organizationId
        ? !organizations.canCreateContactInfo
        : programId
          ? !programs.canCreateContactInfo
          : !communities.canCreateContactInfo
    ) {
      return;
    }
    try {
      const updatedValues = appendOrganizationProgramOrCommunityIds(
        values,
        communityId,
        programId,
        organizationId
      );

      const res =
        await apiLibrary.ContactInformation.createEditContact(updatedValues);
      Toasts.success(res.message);

      fetchCommunityProgramOrOrganizationProfile(
        dispatch,
        communityId,
        programId,
        organizationId
      );

      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (addEditContactInfoModal.data) {
      setInitialState(addEditContactInfoModal.data);
    }
  }, []);
  return (
    <Modal
      open={addEditContactInfoModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={initialState}
          validationSchema={contactInfoValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <Form>
                <div className="w-[100%]  rounded">
                  <div className="relative flex flex-col items-start justify-start gap-4 p-6 rounded-lg bg-bgWhite dark:bg-secondaryLight">
                    <div className="flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                        <p className="flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain">
                          Contact Info
                        </p>
                      </div>
                      <button onClick={handleClose} type="button">
                        <XCloseIcon
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                        />
                      </button>
                    </div>

                    <div className="w-[600px] overflow-y-auto px-1 max-h-[60vh] pr-5">
                      <div className="w-full mb-4">
                        <TextInput
                          label="Explaination Of The Contacts"
                          type="text"
                          rows={3}
                          fieldAs="textarea"
                          placeholder="Type any information you would like to provide to users reading this contact information..."
                          name="description"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.description}
                          error={errors?.description}
                          touched={touched.description}
                          // helperText="1000 Max Characters"
                        />

                        {!errors?.description && (
                          <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                            {values?.description?.length}/1000
                          </p>
                        )}
                      </div>

                      <div className="my-2">
                        <p className="text-base font-semibold text-left text-secondaryMid dark:text-textMain">
                          Contacts
                        </p>
                        <p className="flex-grow w-[432px] text-sm text-left text-textMidLight  dark:text-caption">
                          Let people know how to contact you with business
                          inquiries.
                        </p>
                      </div>

                      <FieldArray name="emails">
                        {({ push, remove }) => {
                          return (
                            <>
                              {values?.emails?.map((email, index) => {
                                const emailErrors: any = errors.emails?.[
                                  index
                                ] ?? { email: "", title: "" };

                                const emailTouched: any = touched.emails?.[
                                  index
                                ] ?? { email: false, title: false };

                                return (
                                  <div
                                    className="flex w-full gap-4"
                                    key={index}
                                  >
                                    <div className="w-full dark:bg-secondaryLight">
                                      <TextInput
                                        label="Email Title*"
                                        type="text"
                                        placeholder="Enter A Title"
                                        name={`emails.${index}.title`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.emails[index].title}
                                        error={emailErrors.title}
                                        touched={emailTouched.title}
                                      />
                                    </div>

                                    <div className="w-full dark:bg-secondaryLight">
                                      <TextInput
                                        label="Email*"
                                        type="text"
                                        placeholder="Email Address"
                                        name={`emails.${index}.email`}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        value={values.emails[index].email}
                                        error={emailErrors?.email ?? ""}
                                        touched={emailTouched.email}
                                      />
                                    </div>

                                    <div className="w-[50px] flex items-center cursor-pointer">
                                      <TrashIcon
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              })}

                              <button
                                type="button"
                                onClick={() =>
                                  push({
                                    email: "",
                                    title: "",
                                  })
                                }
                                className="flex items-center mt-2 mb-6 disabled:cursor-not-allowed "
                              >
                                <PlusIcon fill={primaryColor} />
                                <p
                                  className={`ml-2 text-sm font-semibold text-primary hover:text-primaryMid dark:text-textLink dark:hover:text-primaryMid`}
                                >
                                  Add Email
                                </p>
                              </button>
                            </>
                          );
                        }}
                      </FieldArray>

                      <FieldArray name="phones">
                        {({ push, remove }) => (
                          <>
                            {values?.phones?.map((phone, index) => {
                              const phoneErrors = errors.phones?.[index] as
                                | { phoneNumber?: string; title?: string }
                                | undefined;
                              const phoneTouched = touched.phones?.[index] as
                                | { phoneNumber?: boolean; title?: boolean }
                                | undefined;
                              return (
                                <div className="flex w-full gap-4" key={index}>
                                  <div className="w-full dark:bg-secondaryLight">
                                    <TextInput
                                      label="Phone Title*"
                                      type="text"
                                      placeholder="Enter A Title"
                                      name={`phones.${index}.title`}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      value={values.phones[index].title}
                                      error={phoneErrors?.title}
                                      touched={phoneTouched?.title}
                                    />
                                  </div>

                                  <div className="w-full dark:bg-secondaryLight">
                                    <TextInput
                                      label="Phone*"
                                      type="text"
                                      fieldAs="phone"
                                      name={`phones.[${index}].phoneNumber`}
                                      placeholder={"Phone"}
                                      setFieldValue={setFieldValue}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      value={values.phones[index].phoneNumber}
                                      error={phoneErrors?.phoneNumber}
                                      touched={phoneTouched?.phoneNumber}
                                      inputClassName="w-full h-full py-2 border-none disabled:cursor-not-allowed focus:border-none focus:outline-none focus:bg-transparent"
                                    />
                                  </div>

                                  <div className="w-[50px] flex items-center cursor-pointer">
                                    <TrashIcon
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                            <button
                              type="button"
                              onClick={() =>
                                push({ phoneNumber: "", title: "" })
                              }
                              className="flex items-center mt-2 mb-6 disabled:cursor-not-allowed "
                            >
                              <PlusIcon fill={primaryColor} />
                              <p
                                className={`ml-2 text-sm font-semibold text-primary hover:text-primaryMid dark:text-textLink dark:hover:text-primaryMid`}
                              >
                                Add Phone
                              </p>
                            </button>
                          </>
                        )}
                      </FieldArray>

                      <div className="mt-4 mb-3">
                        <p className="text-base font-semibold text-left text-secondaryMid dark:text-textMain">
                          Links
                        </p>
                        <p className="flex-grow w-[432px] text-sm text-left text-textMidLight  dark:text-caption">
                          Share external links. They'll be visible on your
                          organization profile.
                        </p>
                      </div>

                      <FieldArray name="links">
                        {({ push, remove }) => (
                          <>
                            {values?.links?.map((link, index) => {
                              const linkErrors: any = errors.links?.[index] ?? {
                                links: "",
                                title: "",
                              };
                              const linkTouched: any = touched.links?.[
                                index
                              ] ?? {
                                links: false,
                                title: false,
                              };

                              return (
                                <div className="flex w-full gap-4" key={index}>
                                  <div className="w-full dark:bg-secondaryLight">
                                    <TextInput
                                      label="Link Title*"
                                      type="text"
                                      placeholder="Enter A Title"
                                      name={`links.${index}.title`}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      value={values.links[index].title}
                                      error={linkErrors?.title}
                                      touched={linkTouched?.title}
                                    />
                                  </div>

                                  <div className="w-full dark:bg-secondaryLight">
                                    <TextInput
                                      label="URL*"
                                      type="text"
                                      placeholder="Enter A URL"
                                      name={`links.${index}.links`}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      value={values.links[index].links}
                                      error={linkErrors?.links}
                                      touched={linkTouched.links}
                                    />
                                  </div>

                                  <div className="w-[50px] flex items-center cursor-pointer">
                                    <TrashIcon
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                            <button
                              type="button"
                              onClick={() => push({ links: "", title: "" })}
                              className="flex items-center mt-2 mb-6 disabled:cursor-not-allowed "
                            >
                              <PlusIcon fill={primaryColor} />
                              <p
                                className={`ml-2 text-sm font-semibold text-primary hover:text-primaryMid dark:text-textLink dark:hover:text-primaryMid`}
                              >
                                Add Link
                              </p>
                            </button>
                          </>
                        )}
                      </FieldArray>
                    </div>
                  </div>

                  <div className="flex justify-end gap-2 pb-4 pr-4">
                    <Button
                      type="button"
                      text="Cancel"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="w-24 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={handleClose}
                    />
                    <Button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        (organizationId
                          ? !organizations.canCreateContactInfo
                          : programId
                            ? !programs.canCreateContactInfo
                            : !communities.canCreateContactInfo)
                      }
                      text="Save"
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="w-24 py-2"
                      width="35"
                      height="13"
                      fontStyle="font-semibold"
                      variant="filled"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};

function appendOrganizationProgramOrCommunityIds(
  values: any,
  communityId: any,
  programId: any,
  organizationId: any
) {
  const newValues = { ...values };
  switch (true) {
    case communityId !== undefined && communityId !== null:
      newValues.communityId = communityId;
      break;
    case programId !== undefined && programId !== null:
      newValues.programId = programId;
      break;
    case organizationId !== undefined && organizationId !== null:
      newValues.organizationId = organizationId;
      break;
    default:
      break;
  }

  return newValues;
}

function fetchCommunityProgramOrOrganizationProfile(
  dispatch: any,
  communityId: any,
  programId: any,
  organizationId: any
) {
  switch (true) {
    case communityId !== undefined && communityId !== null:
      dispatch(fetchCommunityProfileAction(communityId));
      break;
    case programId !== undefined && programId !== null:
      dispatch(fetchProgramProfileAction(programId));
      break;
    case organizationId !== undefined && organizationId !== null:
      dispatch(fetchOrganizationProfileAction(organizationId));
      break;
    default:
      break;
  }
}
