import React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/system";
import useCustomBranding from "hooks/useCustomBranding";

const StyledSlider = styled(Slider)(({ theme }) => {
  const { primaryColor } = useCustomBranding();
  return {
    color: "#0A7BAC",
    "& .MuiSlider-thumb": {
      backgroundColor: primaryColor,
      width: "20px",
      height: "20px",
    },
    "& .MuiSlider-rail": {
      height: "4px",
      color: "#0A7BAC",
    },
    "& .MuiSlider-track": {
      color: primaryColor,
    },
  };
});

interface RangeSliderProps {
  value: [number, number];
  onChange: (newValue: [number, number]) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({ value, onChange }) => {
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      onChange(newValue as [number, number]);
    }
  };

  return (
    <StyledSlider
      value={value}
      onChange={handleChange}
      valueLabelDisplay="auto"
      min={0}
      max={10000}
      aria-labelledby="range-slider"
    />
  );
};

export default RangeSlider;
