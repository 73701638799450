import {
  ERROR_LOADING_DNS_RECORD,
  GET_ALL_DNS_RECORD,
  RESET_DATA,
  START_LOADING_DNS_RECORD,
  STOP_LOADING_DNS_RECORD,
} from "./action-types";
import initialState from "./initialState";

export const viewDNSRecordReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_DNS_RECORD:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_DNS_RECORD:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_DNS_RECORD:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_DNS_RECORD:
      return {
        ...state,
        data: {
          adminDnsRecords: action.payload.adminDnsRecords,
          appDnsRecords: action.payload.appDnsRecords
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
