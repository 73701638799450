interface ParentEntity {
  label: string;
  value: string;
}
export const getParentEntity = ({
  programId,
  communityId,
  organizationId,
}: {
  programId?: string;
  communityId?: string;
  organizationId?: string;
}): ParentEntity | null => {
  if (programId) return { label: "program_ids", value: programId };
  if (organizationId)
    return { label: "organization_ids", value: organizationId };
  if (communityId) return { label: "community_ids", value: communityId };
  return null;
};
