export interface ViewDNSRecord {
  name: string;
  value: string;
  ttl: string;
  recordType: string;
}

export interface ViewDNSRecordData {
  adminDnsRecords: ViewDNSRecord[];
  appDnsRecords: ViewDNSRecord[];
}

export interface IfetchDNSRecord {
  data: ViewDNSRecordData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchDNSRecord = {
  data: {
    adminDnsRecords: [], // Initialize as empty array
    appDnsRecords: []    // Initialize as empty array
  },
  message: "",
  stateIs: "Idle",
};

export default initialState;
