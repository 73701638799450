import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
// Components
import CustomCheckbox from "view/components/CheckBox";
import { CustomDropdown } from "view/components/CustomDropdown";
// Assets
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
// Utils
import splitString from "utils/splitString";
// Types
import { T_RasterLayersData } from "../LayersListing";
import { useSelector } from "react-redux";
import { RootState } from "store";
import usePermissions from "hooks/usePermissions";
import { useParams } from "react-router-dom";
import InfiniteScroll from "Components/Geography/InfiniteScroll";
import { TailSpin } from "react-loader-spinner";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { updateSelectedLayersItems } from "store/geography";
import { useDispatch } from "react-redux";
import useCustomBranding from "hooks/useCustomBranding";
import useRoles from "hooks/roles";

// types
type T_Action = { label: string };
type T_DataList = T_RasterLayersData[] | undefined;
type T_ActionList = {
  label: string;
}[];

const LAYER_DEFAULT_COLOR = "red";

interface I_LayerBox {
  handleClickOnMenuAction: (action: T_Action, dataItem: any) => void;
  heading: string;
  dataList: T_DataList | null;
  // secondDataList?: any[] | null;
  actionList: T_ActionList;
  handleClickOnAddButton: () => void;
  handleClickOnCheckBox?: any;
  hideItemThumb?: boolean;
  hideZoomFeature?: boolean;
  blockMarginB?: boolean;
  hideAddBtn?: boolean;
  layerType?: string;
  handleOnScrollEnd?: () => void;
  loading: boolean;
  handleClickOnTogglerBtn?: () => void;
  doShowDataList?: boolean;
  isGlobal?: boolean;
  updateDataList?: (list: any) => void;
  // firstDataListHeading?: string;
  // secondDataListHeading?: string;
}

const LayerBox = ({
  handleClickOnMenuAction,
  heading,
  dataList,
  actionList,
  handleClickOnAddButton,
  handleClickOnCheckBox,
  hideItemThumb,
  hideZoomFeature,
  blockMarginB,
  hideAddBtn,
  layerType,
  handleOnScrollEnd,
  loading,
  handleClickOnTogglerBtn,
  doShowDataList,
  isGlobal,
  updateDataList
  // secondDataList,
  // firstDataListHeading,
  // secondDataListHeading,
}: I_LayerBox) => {
  const { layersDataType } = useSelector((state: RootState) => state.geography);
  const { communities } = usePermissions();

  return (
    <div className="mb-1">
      <div className="flex items-center justify-between w-full py-1">
        <div className="flex items-center basis-1/2 gap-x-4">
          <h3 className="text-[17px] dark:text-textMain">{heading}</h3>
        </div>
        {
          // layersDataType !== "archive" &&
          communities.canCreateGeographyCommunities &&
          (heading.toLowerCase().includes("regions") &&
            !communities.canCreateRegionsCommunities ? (
            <></>
          ) : (
            <div className="flex items-center justify-end basis-1/2 gap-x-6">
              {!hideAddBtn && (
                <button
                  className="text-sm font-semibold text-primary w-11"
                  onClick={handleClickOnAddButton}
                >
                  + Add
                </button>
              )}
              {dataList && dataList?.length > 0 && (
                <button onClick={handleClickOnTogglerBtn}>
                  {doShowDataList ? (
                    <CheveronUpIcon className="w-[16px] fill-secondaryMid mb-1" />
                  ) : (
                    <CheveronDownIcon className="w-[16px] fill-secondaryMid mb-1" />
                  )}
                </button>
              )}
            </div>
          ))
        }
      </div>
      <div className="mr-1">
        {/* {firstDataListHeading && communityId && (
          <h5 className="my-3">{firstDataListHeading}</h5>
        )} */}
        <InfiniteScroll handleOnScrollEnd={handleOnScrollEnd} loading={loading}>
          <div className="px-3">
            {doShowDataList && dataList && dataList?.length > 0 && (
              // <ul className={!blockMarginB ? "mb-8" : ""}>
              <ul>
                {dataList?.map((item: any, index: number) => {
                  return (
                    <ListItem
                      item={item}
                      key={index.toString()}
                      text={item.name}
                      thumb={item.legendFilePath}
                      id={item.id}
                      actionsList={actionList}
                      handleClickOnMenuAction={(action) =>
                        !loading && handleClickOnMenuAction(action, item)
                      }
                      handleClickOnCheckBox={(e: any) => {
                        handleClickOnCheckBox(e, item);
                      }}
                      hideItemThumb={hideItemThumb}
                      hideZoomFeature={hideZoomFeature}
                      color={
                        item.color ??
                        (layerType &&
                          ["vector", "region", "shape"].includes(layerType)
                          ? LAYER_DEFAULT_COLOR
                          : null)
                      }
                      loading={item.loading}
                      layerType={layerType}
                      isGlobal={isGlobal}
                      updateDataList={updateDataList}
                    />
                  );
                })}
              </ul>
            )}
            {loading && <p className="text-center">Loading...</p>}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export { LayerBox };

interface I_ListItem {
  text: string;
  thumb?: string;
  actionsList: T_ActionList;
  handleClickOnMenuAction: (action: T_Action, dataItem: any) => void;
  handleClickOnCheckBox?: any;
  hideItemThumb?: boolean;
  hideZoomFeature?: boolean;
  id?: any;
  isGlobal?: boolean;
  color?: string;
  loading?: boolean;
  item?: any;
  layerType?: string;
  updateDataList?: (list: any) => void;
}

const ListItem = ({
  text,
  thumb,
  actionsList,
  handleClickOnMenuAction,
  handleClickOnCheckBox,
  hideItemThumb,
  hideZoomFeature,
  id,
  isGlobal,
  color,
  item,
  layerType,
  updateDataList
  // loading,
}: I_ListItem) => {
  const [doShowMenu, setDoShowMenu] = useState(false);
  const [isItemExist, setIsItemExist] = useState(false);
  const { selectedLayersItems, layersDataType } = useSelector(
    (state: RootState) => state.geography
  );
  const {isSuperAdmin} = useRoles();
  const [updatedActionList, setUpdatedActionList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [newLayer, setNewLayer] = useState<any>(null)

  const dispatch = useDispatch();
  const { communityId } = useParams();
  const { getBrandPlaceholder } = useCustomBranding();

  const updateActionsVisibility = useCallback(() => {
    return actionsList.map((action: any) => {
      if (action.key === "archive" || action.key === "restore") {
        // Determine visibility based on layersDataType
        const isVisible =
          action.key === "archive"
            ? layersDataType !== "archive"
            : layersDataType === "archive";
        return { ...action, isVisible: isVisible && !isGlobal };
      }
      else if(action.key === "edit") {
        return {...action, isVisible: isGlobal && isSuperAdmin() || !isGlobal}
      }
      return action;
    });
  }, [actionsList, layersDataType, isGlobal]);

  const updateZoomAction = useCallback(
    (updatedActions: any) => {
      const item = selectedLayersItems?.find((itm: any) => itm.item.id === id);
      if (item && item.status && !hideZoomFeature) {
        const hasZoomAction = updatedActions.some(
          (action: any) => action.label === "Zoom to selection"
        );
        if (!hasZoomAction) {
          return [
            ...updatedActions,
            { label: "Zoom to selection", isVisible: true },
          ];
        }
      } else {
        return updatedActions.filter(
          (action: any) => action.label !== "Zoom to selection"
        );
      }
      return updatedActions;
    },
    [selectedLayersItems, id, hideZoomFeature]
  );

  useEffect(() => {
    let updatedActions = updateActionsVisibility();
    updatedActions = updateZoomAction(updatedActions);
    setUpdatedActionList(updatedActions);
  }, [
    actionsList,
    layersDataType,
    isGlobal,
    selectedLayersItems,
    updateActionsVisibility,
    updateZoomAction,
  ]);

  const checkItemExistence = useCallback(() => {
    const itemExists = selectedLayersItems?.some(
      (itm: any) => itm.item.id === id
    );
    setIsItemExist(itemExists);
  }, [selectedLayersItems, id]);

  useEffect(() => {
    checkItemExistence();
  }, [selectedLayersItems, checkItemExistence]);

  useEffect(() => {
    if (newLayer) {
      const updatedSelectedLayersItems = [...(selectedLayersItems ?? []), newLayer];

      dispatch(updateSelectedLayersItems(updatedSelectedLayersItems));

      if (updateDataList) updateDataList(newLayer);

      setNewLayer(null);
    };
  }, [newLayer])

  // handlers
  const handleClickOnCheckBoxLocally = useCallback(async (e: any) => {
    const isChecked = e.target.checked;
    const layersItems = selectedLayersItems ?? [];
    const itemIndex = layersItems.findIndex(
      (itm: any) => itm.item.id === item.id
    );

    const alreadyExistedItem = layersItems[itemIndex];
    let upadatedItems;

    if (isChecked) {
      if (alreadyExistedItem) {
        // update item status to true
        alreadyExistedItem.status = true;

        layersItems[itemIndex] = alreadyExistedItem;
        upadatedItems = layersItems;
        dispatch(updateSelectedLayersItems([...(upadatedItems ?? [])]));

      } else {
        setLoading(true);
        if (communityId && !isGlobal) {
          await apiLibrary.geography
            .getSingleLayer(communityId, item.id)
            .then((data) => {
              let itemType = layerType;

              if (layerType === "vector") {
                itemType = item.type === "point" ? item.type : "vector";
              }

              const newItem = {
                item: data?.data,
                itemType: itemType,
                status: true,
              };
              // upadatedItems = [...layersItems, newItem];
              setNewLayer(newItem)
            })
            .catch((error) => {
              Toasts.error(error.message);
            })
            .finally(() => {
              setLoading(false);
            });
        }
        else {
          await apiLibrary.geography
            .getSingleGlobalLayer(item.id)
            .then((data) => {
              let itemType = layerType;

              if (layerType === "vector") {
                itemType = item.type === "point" ? item.type : "vector";
              }

              const newItem = {
                item: data?.data,
                itemType: itemType,
                status: true,
              };
              // upadatedItems = [...layersItems, newItem];
              setNewLayer(newItem)
            })
            .catch((error) => {
              Toasts.error(error.message);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    } else {
      if (itemIndex === -1) return;
      // update item status to false on unchecking the checkbox
      alreadyExistedItem.status = false;

      layersItems[itemIndex] = alreadyExistedItem;
      upadatedItems = layersItems;
      dispatch(updateSelectedLayersItems([...(upadatedItems ?? [])]));

    }

  }, [selectedLayersItems])
  return (
    <li
      className={`flex items-center py-[6px] ${loading ? "opacity-60 select-none cursor-progress" : ""} `}
    >
      <CustomCheckbox
        onChange={(e) => {
          handleClickOnCheckBoxLocally(e)
          // handleClickOnCheckBox && handleClickOnCheckBox(e);
        }}
        padding="0"
        style={{ marginRight: "12px" }}
        // checked={selectedLayersItems?.some(
        //   (itm: any) => itm?.item?.id === id && itm.status === true
        // )}
        disabled={loading}
      />
      {
        (item.type === "point" || item.shapeType === "point") ? (
          <img
            src={item?.markerStyle?.styleImage ?? getBrandPlaceholder("listingProfile")}
            className="h-[18px] w-[18px] mr-4 rounded-full"
          />
        ) : (
          color && (
            <span
              className="h-[18px] w-[18px] rounded-full inline-block mr-[14px]"
              style={{ background: color }}
            />
          )
        )
      }

      {!hideItemThumb && (
        <img
          src={item?.legendFilePath ?? getBrandPlaceholder("listingProfile")}
          className="w-6 h-6 mr-4 rounded-full"
        />
      )}
      <p className="text-[15px] flex-1 dark:text-textMain leading-normal ">
        {splitString(text, 25)}
      </p>
      {loading && (
        <TailSpin
          height="18"
          width="18"
          color="#005C89"
          ariaLabel="tail-spin-loading"
          radius="2"
          wrapperStyle={{}}
          wrapperClass="tailspin-loader"
          visible={true}
        />
      )}
      {!loading && <CustomDropdown
        isOpenDropdown={doShowMenu}
        setIsOpenDropdown={setDoShowMenu}
        menuList={updatedActionList}
        handleClickOnMenuItem={handleClickOnMenuAction}
      />}
    </li>
  );
};
