import React, { useCallback, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { Toasts } from "view/components/Toasts";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { closeAddOrEditObjectModal } from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { objectSchema } from "utils/validationSchemas";
import ObjectModalForm from "./objectModalForm";
import * as Yup from "yup";
import { fetchObjectsAction } from "store/objects";
import { fetchObjectProfileAction } from "store/objectProfile/reducer.actions";
import usePermissions from "hooks/usePermissions";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffff",
  borderRadius: "10px",
};
interface FormValues {
  name: string;
  description: string;
  images: number[];
  isStar?: number;
  typeId: number;
}

interface FormItem {
  value: string;
  label: string;
}

interface FormItem {
  value: string;
  label: string;
}

/**
 * Component for adding, editing, or using a form as a template.
 * @returns {JSX.Element} The component's JSX representation
 */

export const AddOrEditObjectModal = () => {
  // State variables

  const { addOrEditObjectModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { communityId } = useParams();

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [typesList, setTypesList] = useState([]);
  const { types } = usePermissions();

  const fetchAllTypes = useCallback(
    async (searchQuery?: string) => {
      if (types.canViewDropdown) {
        try {
          const res =
            await apiLibrary.SpeciesAndObjects.getTypesDropdown(searchQuery);
          setTypesList(
            res.data.map((type: any) => ({
              label: type?.typeName,
              value: type?.id,
            }))
          );
        } catch (error: any) {
          // Handle API errors
          console.error("Error fetching categories:", error);
        }
      }
    },
    [typesList]
  );

  const handleTypeSearch = (query: string) => {
    fetchAllTypes(query);
  };

  // Fetch Protocol Profile and Programs on component mount
  useEffect(() => {
    if (addOrEditObjectModal.isOpen) {
      fetchAllTypes();
    }
  }, [addOrEditObjectModal.isOpen]);

  /**
   * Handle form submission.
   * @param {object} values - Form values
   * @param {Function} setSubmitting - Function to set submitting state
   * @example
   * handleSubmit({ name: "Form Name" }, { setSubmitting: (bool) => {} });
   */

  const ACCEPTED_FILES_TYPES = {
    "image/jpeg": [".jpg", ".jpeg", ".png"],
  };
  const uploadFile = async (image: any) => {
    if (!image.id) {
      const res = await apiLibrary.file.fileUpload(image.file);
      return { id: res.data.id, isStar: image.isStar };
    }
    return { id: image.id, isStar: image.isStar };
  };
  // SUBMIT HANDLER
  const handleSubmit = async (
    values: any,
    {
      setSubmitting,
      setFieldError,
    }: { setSubmitting: (isSubmitting: boolean) => void; setFieldError: any }
  ) => {
    setIsLoading(true);

    try {
      const formValues: FormValues = {
        name: values.name,
        description: values.description,
        images: [],
        typeId: values.typeId.value,
      };

      if (values?.images?.length > 0) {
        const uploadFilePromises = values.images.map((image: any) =>
          uploadFile(image)
        );

        const uploadResults = await Promise.all(uploadFilePromises);

        formValues.images = uploadResults.map((result) => result.id);
        const starredImage = uploadResults.find((result) => result.isStar);
        if (starredImage) {
          formValues.isStar = starredImage.id;
        }
      }

      if (addOrEditObjectModal.editMode && addOrEditObjectModal.data.id) {
        const { data, message } = await apiLibrary.SpeciesAndObjects.editObject(
          addOrEditObjectModal.data.id,
          formValues
        );
        if (addOrEditObjectModal.data.path === "directory") {
          dispatch(fetchObjectsAction(communityId));
        } else if (addOrEditObjectModal.data.path === "profilePage") {
          dispatch(fetchObjectProfileAction(addOrEditObjectModal.data.id));
        }
        Toasts.success(message);
        handleClose();
      } else {
        const { data, message } =
          await apiLibrary.SpeciesAndObjects.addObject(formValues);
        let objectId = data.objectId;
        // if (addOrEditObjectModal.data.id) {
        //   addOrEditObjectModal?.data?.connectedEntitiesList?.push({
        //     id: objectId,
        //   });
        //   const { message } =
        //     await apiLibrary.Communities.addExistingObjectsToCommunity(
        //       addOrEditObjectModal.data.id,
        //       {
        //         objectIds:
        //           addOrEditObjectModal?.data?.connectedEntitiesList?.map(
        //             (entity: any) => {
        //               return entity.id;
        //             }
        //           ),
        //       }
        //     );
        //   Toasts.success(message);
        // }

        navigate(`/object/${data.objectId}/profile`);

        Toasts.success(message);
        handleClose();
      }
    } catch (error: any) {
      // Handle API errors
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  // HANDLE MODAL CLOSE
  const handleClose = () => {
    if (!isLoading) {
      dispatch(closeAddOrEditObjectModal());
    }
  };

  return (
    <Modal
      open={addOrEditObjectModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
      sx={{
        ".MuiBox-root": {
          border: "none",
          outline: "none",
        },
      }}
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={addOrEditObjectModal.data}
          validationSchema={objectSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formikProps) => (
            <Form>
              <ObjectModalForm
                {...formikProps}
                handleClose={handleClose}
                acceptedFileTypes={ACCEPTED_FILES_TYPES}
                editMode={addOrEditObjectModal.editMode}
                typesList={typesList}
                handleTypeSearch={handleTypeSearch}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
