import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store";
import {
  openContactInfoModalAction,
  openCreateOrEditDataAccessPolicyModalAction,
} from "store/modals/reducer.actions";
import { IProgram } from "store/programProfile/initialState";

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { updateColumnsVisibilityOfObservationsByStatus } from "store/directoriesOrders/reducer.actions";
import usePermissions from "hooks/usePermissions";
import { Toasts } from "view/components/Toasts";

const generateDynamicProgramLinks = (programProfile: any) => {
  const { programs, file, observations, protocols } = usePermissions();

  return [
    {
      title: "Observations",
      link: `/programs/${programProfile?.id}/observations`,
      isVisible: observations.canViewObservations,
    },
    {
      title: "Members",
      link: `/programs/${programProfile?.id}/users`,
      isVisible: true,
      // isVisible: programs.canViewUserMembers,
    },
    {
      title: "Join Requests",
      link: `/programs/${programProfile?.id}/members-request`,
      isVisible:
        programProfile.appName === "skipper_science" &&
        !programProfile.isGlobalForSkipper,
    },
    {
      title: "Organizations",
      link: `/programs/${programProfile?.id}/organizations`,
      isVisible: true,
    },
    {
      title: "Communities",
      link: `/programs/${programProfile?.id}/communities`,
      isVisible: programs.canViewCommunities,
    },
    {
      title: "Forms",
      link: `/programs/${programProfile?.id}/forms`,
      isVisible: programs.canViewForms,
    },

    {
      title: "Protocols",
      link: `/programs/${programProfile?.id}/protocols`,
      isVisible: protocols.canViewProtocol,
    },
    {
      title: "Files",
      link: `/programs/${programProfile?.id}/files`,
      isVisible: file.canViewFile,
    },
    {
      title: "Data Access Policy",
      link: `/programs/${programProfile?.id}/dataAccessPolicy/${programProfile?.dapId}`,
      isVisible: programs.canViewDataAccessPolicy,
    },
    {
      title: "Management",
      link: `/programs/${programProfile?.id}/management`,
      isVisible: programs.canViewManagers,
    },
    {
      title: "Polls & Surveys",
      link: `/programs/${programProfile?.id}/polls-and-surveys`,
      isVisible: true,
      // isVisible: programs.canViewManagers,
    },
    {
      title: "Contact Info",
      link: ``,
      isVisible: programs.canViewContactInfo,
    },
  ];
};

export const ProfileNavigationLinks = () => {
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );
  const { programs } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const navigationLinks = generateDynamicProgramLinks(programProfile);

  const handleDataAccessPolicyClick = (event: any, link: any) => {
    const data = {
      lastUpdated: "",
      policyContent: "",
    };

    if (link.title === "Data Access Policy" && programProfile?.dapId === null) {
      event.preventDefault(); // Prevent navigation
      if (programs.canCreateDataAccessPolicy) {
        dispatch(openCreateOrEditDataAccessPolicyModalAction(data, false));
      } else {
        Toasts.error("No Data Acess Policy Found");
      }
    } else if (link.title === "Observations") {
      dispatch(updateColumnsVisibilityOfObservationsByStatus("program"));
    } else if (link.title === "Contact Info") {
      dispatch(
        openContactInfoModalAction(
          programProfile?.contactInfo,
          programProfile.status
        )
      );
    }
  };

  return (
    <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-8">
      <div className="flex flex-wrap items-center justify-start gap-2 ml-20">
        {navigationLinks?.map(
          (link: any, index: number) =>
            link.isVisible && (
              <Link
                key={index}
                to={link.link}
                onClick={(event) => handleDataAccessPolicyClick(event, link)}
                className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 p-1 rounded-lg bg-primaryExtraLight h-[32px] mr-1 hover:bg-primary_20 dark:bg-primary_40"
              >
                <div className="h-[32px] flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2 rounded">
                  <button className="flex-grow-0 flex-shrink-0 p-1 text-sm text-center text-secondaryMid dark:text-textMain ">
                    {link.title}
                  </button>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
};
