import React, { useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import { styled } from "@mui/system";
import useCustomBranding from "hooks/useCustomBranding";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomIcon = (props: any) => {
  const { open, onClick } = props;

  return (
    <CheveronDownIcon
      height={16}
      width={16}
      onClick={onClick}
      className={`-0 -0 w-6 h-6 relative ${open ? "transform rotate-180" : ""}`}
      preserveAspectRatio="xMidYMid meet"
      style={{ marginRight: "12px", cursor: "pointer" }}
    />
  );
};

const CustomOutlinedInput = styled(OutlinedInput)`
  // &:hover:not($disabled):not($focused):not($error) $notchedOutline {
  //   border-color: transparent;
  // }

  // &.Mui-focused $notchedOutline {
  //   border-color: transparent;
  // }
`;

interface SelectInputProps {
  data?: any[];
  value?: any;
  label?: string; // Add label prop to customize the label text
  placeholder?: string; // Add placeholder prop to customize the placeholder text
  updateUserProfileSettings?: any;
  name?: any;
  helperText?: string;
  className?: any;
  disabled?: any;
}

const SelectInput: React.FC<SelectInputProps> = ({
  data,
  value,
  label = "Select",
  placeholder = "",
  updateUserProfileSettings,
  name,
  className,
  disabled,
  helperText = null,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [open, setOpen] = useState(false);
  const { primaryColor } = useCustomBranding();

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <style>
        {`
          .Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: ${primaryColor} !important; 
          }
        
        `}
      </style>
      <FormControl className="w-full">
        <Select
          className="w-full h-10 selectremove_active dark:bg-secondaryLight dark:text-textMain dark:border-lineLight"
          displayEmpty
          value={value}
          disabled={disabled}
          name={name}
          onChange={updateUserProfileSettings}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          renderValue={(selected) => {
            if (selected === "") {
              return (
                <p>{placeholder || (data && data.length > 0 ? data[0] : "")}</p>
              );
            }
            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={CustomIcon}
          open={open}
          onClick={handleToggle}
          input={<CustomOutlinedInput />}
        >
          {data?.map((items) => (
            <MenuItem
              key={items}
              value={items}
              className="menu_items dark:bg-secondaryLight dark:text-textMain"
            >
              {items}
            </MenuItem>
          ))}
        </Select>
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
          {helperText !== null && (
            <p
              className={`flex-grow w-full text-xs text-left ${className ? className : "capitalize"} text-textMidLight dark:text-textMain`}
            >
              {helperText}
            </p>
          )}
        </div>
      </FormControl>
    </>
  );
};

export default SelectInput;
