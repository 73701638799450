import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";
import useDateFormatter from "hooks/formatDateWithPattern";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  openArchiveOrPublishOrganizationModalAction,
  openCreateOrganizationModalAction,
  openRemoveFromProgramAction,
} from "store/modals/reducer.actions";
import PopOverMenu from "view/components/PopOverMenu";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import useCustomBranding from "hooks/useCustomBranding";

interface HeaderProps {
  OrganizationData: any;
}
const Header: React.FC<HeaderProps> = ({ OrganizationData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { communityId, programId } = useParams();
  const [showFullText, setShowFullText] = useState(false);
  const {getBrandPlaceholder} = useCustomBranding();

  const maxChars = 600;
  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const { formatDate, timeFromNowInTimeZone } = useDateFormatter();

  const editOrganization = () => {
    const {
      address,
      missionStatement,
      name,
      organizationImage,
      organizationAdmins,
      organizationCommunities,
      organizationTags,
      id,
    } = OrganizationData;


    // Create the payload
    const payload = {
      communityIds: organizationCommunities,
      missionStatement: missionStatement,
      name: name,
      organizationImageId: organizationImage.id,
      tagIds:
        organizationTags?.length > 0
          ? organizationTags?.map((item: any) => {
            return { value: item.id, label: item.name };
          })
          : [],
      adminIds: organizationAdmins?.map((item: any) => {
        return { id: item.id, name: item.name, profileImage: item?.profileImage };
      }),
      profileImage: organizationImage.file.original,
      id,
      openAtStep: 0,
    };
    dispatch(openCreateOrganizationModalAction(payload, true));
  };
  const communityActions = (data: any) => {
    const {
      address,
      missionStatement,
      name,
      organizationImage,
      organizationAdmins,
      organizationCommunities,
      organizationTags,
      id,
    } = data;

    // Create the payload
    const payload = {
      communityIds: organizationCommunities,
      missionStatement: missionStatement,
      name: name,
      organizationImageId: organizationImage.id,
      tagIds:
        organizationTags?.length > 0
          ? organizationTags?.map((item: any) => {
            return { value: item.id, label: item.name };
          })
          : [],
      adminIds: organizationAdmins?.map((item: any) => {
        return { id: item.id, name: item.name, profileImage: item.profileImage };
      }),
      profileImage: organizationImage.file.original,
      id,
      openAtStep: 2,
    };
    return [
      {
        label: "Archive",
        onClick: () =>
          dispatch(
            openArchiveOrPublishOrganizationModalAction({
              id: data?.id, // Fixed the object structure here
              status: data?.status, // Fixed the object structure here
            })
          ),
        divider: false,
        isVisible: data?.status === "active" && !programId,
      },

      {
        label: "Assign an Admin",
        onClick: () =>
          dispatch(openCreateOrganizationModalAction(payload, true)),
        divider: false,
        isVisible: data?.status === "active" && !programId,
      },

      {
        label: "Restore Organization",
        onClick: () =>
          dispatch(
            openArchiveOrPublishOrganizationModalAction({
              id: data?.id, // Fixed the object structure here
              status: data?.status, // Fixed the object structure here
            })
          ),
        divider: false,
        isVisible: data?.status === "archived" && !programId,
      },

      {
        label: "Remove from program",
        onClick: () => {
          const dataa = {
            id: data.id,
            name: data.name,
            programId,
            entity: "organization",
          };
          dispatch(openRemoveFromProgramAction(dataa));
        },
        divider: false,
        isVisible: programId ? true : false,
      },
    ];
  };
  return (
    <>
      <div className="relative flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-6">
        <div className="relative flex-grow-0 flex-shrink-0 w-28 h-28 ">
          <div className="absolute top-0 left-0 overflow-hidden rounded-full w-28 h-28">
            <img
              className="object-cover w-full h-full"
              src={
                OrganizationData?.organizationImage?.file?.original
                  ? OrganizationData?.organizationImage?.file.original
                  : getBrandPlaceholder("organizationProfile")
              }
              alt={OrganizationData?.name}
            />
          </div>
        </div>

        <div className="flex flex-col justify-start items-start flex-grow gap-1.5 pt-1.5">
          <div className="flex flex-col items-start self-stretch justify-center flex-grow-0 flex-shrink-0">
            <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <div className="relative flex flex-col items-start justify-center flex-grow py-1">
                <p className="text-lg font-semibold text-left break-all cursor-default dark:text-textMain  text-secondaryMid">
                  {OrganizationData?.name}
                </p>
              </div>
              <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-2">
                <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                  <button
                    onClick={editOrganization}
                    className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-2"
                  >
                    <div className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                      <PencilAltIcon fill="#2C3236" />
                    </div>
                  </button>
                </div>
                <PopOverMenu icon={<DotsVerticalIcon />}>
                  <ActionDropDownMenu
                    actions={communityActions(OrganizationData)}
                  />
                </PopOverMenu>
              </div>
            </div>
          </div>
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[3px]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-left cursor-default text-secondaryMid dark:text-textMain  font-medium">
                {OrganizationData?.noOfPrograms}
              </p>
              <p className="flex-grow-0 flex-shrink-0 text-sm text-left cursor-default text-secondaryMid dark:text-textMain   font-medium">
                Programs
              </p>
            </div>
            <svg
              width={8}
              height={8}
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative flex-grow-0 flex-shrink-0 w-2 h-2"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.33398 3.99992C2.33398 3.07944 3.08018 2.33325 4.00065 2.33325C4.92113 2.33325 5.66732 3.07944 5.66732 3.99992C5.66732 4.92039 4.92113 5.66659 4.00065 5.66659C3.08018 5.66659 2.33398 4.92039 2.33398 3.99992Z"
                fill="#2C3236"
              />
            </svg>
            <div className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-[3px]">
              <p className="flex-grow-0 flex-shrink-0 text-sm text-left cursor-default text-secondaryMid dark:text-textMain  font-medium">
                {OrganizationData?.noOfCommunities}
              </p>
              <p className="flex-grow-0 flex-shrink-0 text-sm text-left cursor-default text-secondaryMid dark:text-textMain  font-medium">
                Communities
              </p>
            </div>
          </div>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 py-[3px]">
            <p className="flex-grow-0 flex-shrink-0 text-sm text-left cursor-default text-secondaryMid dark:text-textMain   font-medium">
              Date Created:
            </p>
            <p className="flex-grow w-[368px] text-sm text-left text-secondaryMid dark:text-textMain  cursor-default font-medium">
              {formatDate(OrganizationData?.dateAdded)}
            </p>
          </div>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-[3px]">
            <p className="flex-grow w-[460px] text-sm text-left text-secondaryMid dark:text-textMain  cursor-default">
              <p className="text-sm font-normal text-secondaryMid dark:text-textMain">
                {showFullText
                  ? OrganizationData?.missionStatement
                  : OrganizationData?.missionStatement?.slice(0, maxChars)}
              </p>

              {OrganizationData?.missionStatement?.length > maxChars && (
                <button onClick={toggleText} className="flex items-center mt-2">
                  <h5 className="pr-1 text-sm font-semibold text-secondaryMid dark:text-textMain">
                    {showFullText ? "See less" : "See more"}
                  </h5>
                  {showFullText ? (
                    <CheveronUpIcon height={16} />
                  ) : (
                    <CheveronDownIcon height={16} />
                  )}
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
