import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface IProps {
  setFieldValue: (field: string, value: any) => void;
  values: any;
  errors: any;
  editMode?: boolean;
}

// Themes color code and name
const themes = [
  {
    primaryColor: "#005C89",
    secondaryColor: "#EBF2F6",
    name: "blue",
  },
  {
    primaryColor: "#9E3425",
    secondaryColor: "#F4E5E2",
    name: "red",
  },
  {
    primaryColor: "#CE9349",
    secondaryColor: "#F6ECE0",
    name: "orange"
  },
  {
    primaryColor: "#488248",
    secondaryColor: "#DFF5DF",
    name: "green"
  },
  {
    primaryColor: "#318C8C",
    secondaryColor: "#DDF3F3",
    name: "sea green"
  },
  {
    primaryColor: "#7A337A",
    secondaryColor: "#F4E3F4",
    name: "purple"
  },
  {
    primaryColor: "#904773",
    secondaryColor: "#F1E4EC",
    name: "magenta"
  },
];

export const SystemTheme: React.FC<IProps> = ({
  setFieldValue,
  values,
  errors,
  editMode
}) => {
  // State to track the active theme

  const { brandId } = useParams();
  const [activeTheme, setActiveTheme] = useState<any>(null);

  useEffect(() => {
    if (brandId && values.theme) {
      setActiveTheme(values.theme)
    }
  }, [brandId, values])

  // if brand Id exist but edit mode is false
  const viewMode = !!brandId && !editMode;

  // Show only selected theme in view mode
  const updatedThemes = viewMode ? themes?.filter((theme) => theme.name === activeTheme?.name) : themes;

  // Function to handle theme selection
  const handleSelectTheme = (theme: any) => {
    setActiveTheme(theme); // Update active theme
    setFieldValue("theme", theme); // Update form field value
  };

  return (
    <>
      <p className="text-secondaryMid font-Overpass text-xl font-bold py-2">Styling</p>
      <p className="text-lg font-semibold font-Overpass py-4 text-secondaryMid">
        System Theme
      </p>
      {!viewMode && <p className="text-sm font-medium font-Overpass text-secondaryMid py-2">Select Theme<span className="text-accent_1Dark ml-1">*</span></p>}
      <div className="flex gap-6 py-2">
        {/* Render themes */}
        {updatedThemes.map((item, index) => {
          const isActive = activeTheme?.name === item.name;

          return (
            <div
              key={index}
              className={`min-w-fit flex rounded-lg p-3 gap-4 bg-primaryExtraLight items-center cursor-pointer ${isActive && !viewMode ? "border border-primary" : ""
                }`}
              onClick={() => handleSelectTheme(item)}
            >
              <div
                className={`h-8 w-8 rounded-full`}
                style={{
                  background: `linear-gradient(90deg, ${item.primaryColor} 50%, ${item.secondaryColor} 50%)`,
                }}
              ></div>
              <div className="text-textMidLight capitalize font-Overpass">{item.name}</div>
            </div>
          );
        })}
      </div>
      <div className="w-full border-b border-lineMid mt-6"></div>
    </>
  );
};
