import React from "react";
import NotFound from "view/components/NotFound";
import BackgroundImage from "assets/images/404.png";
import Head from "view/components/Head";
import { getAppCode } from "hooks/useCustomBranding/constants";
import NoBrandFound from "view/components/NoBrandFound";

const PageNotFound = () => {
  
  return getAppCode() === "isn" ? (
    <div
      className="flex justify-between relative  w-full h-screen "
      style={{
        backgroundImage: `url('${BackgroundImage}')`,
        backgroundSize: "cover",
        backgroundPositionX: "center",
      }}
    >
      <Head title="404 NOT FOUND" />
      <div className="absolute left-1/2 transform -translate-x-1/2">
        <NotFound
          title="Opps! Page not found!"
          link="/"
          btnText="Back to home page"
        />
      </div>
    </div>
  ) : (
    <NoBrandFound showBtn={true} />
  );
};

export default PageNotFound;
