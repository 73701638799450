export function identifyFileType(extension: string, filePath: any = null) {
  // Allowed extensions array (with mixed case variants)
  const allowedExtensions = [
    // Image extensions
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "tiff",
    "JPG",
    "PNG",
    "JPEG",
    "GIF",
    "BMP",
    // Video extensions
    "mp4",
    "avi",
    "mkv",
    "wmv",
    "flv",
    "mov",
    // Audio extension
    "mp3",
    // Document extensions
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    // Text file extensions
    "txt",
    "csv",
    // Archive file extensions
    "zip",
    "rar",
    "7z",
  ];

  // Normalize the provided extension (remove a leading dot if present and convert to lowercase)
  if (extension) {
    extension = extension.replace(/^\./, "").toLowerCase();
  }

  // Prepare a lowercase version of allowed extensions for comparison
  const allowedLower = allowedExtensions.map((e) => e.toLowerCase());

  // If an extension is provided but not in the allowed list (and a filePath is given),
  // extract the extension from the filePath.
  if (extension && !allowedLower.includes(extension) && filePath) {
    extension = getFileExtension(filePath).replace(/^\./, "").toLowerCase();
  }
  const images = ["jpg", "jpeg", "png", "webp"];
  const videos = ["mp4", "mov", "avi", "wmv"];
  const audios = ["mp3", "wav", "webm"];

  if (extension) {
    switch (true) {
      case images.includes(extension):
        return "image";

      case videos.includes(extension):
        return "video";

      case audios.includes(extension):
        return "audio";

      case extension === ".pdf":
        return "pdf";

      case extension === "vnd.ms-excel":
        return "xls";

      case extension ===
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlsx";

      default:
        return extension;
    }
  }

  return "unknown";
}

export function getFileExtension(fileName: string) {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}
