import React from "react";
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import Status from "view/components/Statuses";
import useCustomBranding from "hooks/useCustomBranding";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const {getBrandPlaceholder} = useCustomBranding();

  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "updatedByAdmin"
  ) {
    return (
      <div className={`flex justify-start w-[250px] items-center`}>
        <img
          src={
            cellValue?.updatedBy?.image?.thumb
              ? cellValue?.updatedBy?.image?.thumb
              : getBrandPlaceholder("userProfile")
          }
          alt=""
          height="80"
          width="80"
          className="object-cover mx-2 Img_user_Data"
          style={{ flexShrink: 0 }}
        />
        <div
          style={{ flexWrap: "wrap" }}
          className=" flex flex-col w-full justify-start items-start relative gap-0.5"
        >
          <p className="text-sm w-[200px] text-left break-all text-textMid hover:text-primaryDark dark:text-textMain dark:hover:text-primary ">
            {cellValue?.updatedBy?.name}
          </p>
        </div>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "seasonStatus") {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <Status status={cellValue} />
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${
          columnDef.id === "address" ? "justify-start" : "justify-start"
        }`}
      >
        <p className={`text-sm text-left text-textMid whitespace-nowrap`}>
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
