import { AxiosRequestConfig } from 'axios';  // Import Axios types
import { apiClient } from "../config";

// Make sure `abortV` is typed as AbortSignal
async function getProcessedReportVersion(reportId: any, abortV?: AbortSignal) {
  const config: AxiosRequestConfig = {
    signal: abortV, // Pass AbortSignal here
  };

  try {
    const res = await apiClient.get(`/admin/reports/${reportId}/processed-version`, config);
    return res.data;
  } catch (error: any) {
    if (error.name === 'AbortError') {
      console.log('Request aborted');
    } else {
      console.error("Error fetching report version:", error);
    }
    throw error;  // Rethrow error for handling in calling function
  }
}

export default getProcessedReportVersion;