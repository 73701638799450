import React, { useEffect, useRef, useState } from "react";
import ExifReader from "exifreader";
// SurveyJs Utilities
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import {
  ElementFactory,
  Serializer,
  SvgRegistry,
  ComponentCollection,
  surveyLocalization,
  IElement,
} from "survey-core";
import {
  localization,
  SurveyQuestionEditorDefinition,
} from "survey-creator-core";
import { ReactQuestionFactory, Survey } from "survey-react-ui";
import { settings } from "survey-core";

// Panels
import riverConditionPanel from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/riverCondition";
import gpsInfoPanel from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/gpsInfo";
import speciesDetailsPanel from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/speciesDetails";
import countDetailsPanel, {
  countDetailsIcon,
} from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/countDetails";
import sightingDetailsPanel from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/sightingDetails";
import weatherConditionsPanel from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/weathreConditions";
import generalInfoPanel from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/generalInfo";
// import geographicalInfo from "./panels/geographicalInfo";
// import harvest from "./panels/harvest";
import sample from "./panels/sample";

// Custom question components
import SurveyAudioQuestion, {
  audioProperties,
} from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/Audio";
import SurveyGeoCoderQuestion, {
  geoCoderProperties,
} from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/Geocoder";

// Questions Models
import QuestionAudioModel from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/Audio/model";
import QuestionGeoCoderModel from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/Geocoder/model";

// Icons
import audioIcon from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/Audio/icon";
import regionIcon from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/RegionPicker/icon";
import geoCoderIcon from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/Geocoder/icon";
import shapePickerIcon from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/ShapePicker/icon";
import pointPickerIcon from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/PointPicker/icon";
import drawPolygonIcon from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/DrawPolygon/icon";
import dropPinIcon from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/DropPin/icon";
import getGpsDataIcon from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/GetGpsData/icon";
import { generalIcon } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/generalInfo";
import { weatherIcon } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/weathreConditions";
import { sightingIcon } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/sightingDetails";
import { harvestIcon } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/harvest";
import { speciesDetailIcon } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/speciesDetails";
import { geographicalIcon } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/geographicalInfo";
import { gpsIcon } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/gpsInfo";
import { riverIcon } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/panels/riverCondition";
import { sampleIcon } from "./panels/sample";

import questionTypes from "./questionTypes";
import { panelsName } from "./panelsTitle";
import questionsPropertiesToBeRemove from "./questionsPropertiesToBeRemove";
import surveyJsPropertiesToKeep from "./surveyJsPropertiesToKeep";

import { Header } from "view/pages/Forms/EditFormDetails/Components/SurveyJs/components/Header";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFormDetailsAction } from "store/formDetails/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useSelector } from "react-redux";
import store, { RootState } from "store";
import QuestionShapePickerModel from "./components/ShapePicker/model";
import SurveyShapePickerQuestion, {
  shapePickerProperties,
} from "./components/ShapePicker";
import { createPortal } from "react-dom";
import { updateDeletedQuestionEle } from "store/surveyJS";
import {
  CustomPropertyGridSidebar,
  HandlerComponent,
} from "./helperComponents";
import togglePropertyGridSidebar from "./utils/togglePropertyGridSidebar";
import toggleCustomPropertyGridSidebar from "./utils/toggleCustomPropertyGridSidebar";
import structureFormJSON from "./utils/structureFormJSON";
import registerCustomPropertyGridSidebar from "./utils/registerCustomPropertyGridSidebar";
import QuestionPointPickerModel from "./components/PointPicker/model";
import SurveyPointPickerQuestion, {
  pointPickerProperties,
} from "./components/PointPicker";
import {
  updateAddedGeographicalPanelMapQuestions,
  updateAddedHarvestPanelMapQuestions,
  updateAddedQuestionState,
  updateAllCommunityLayers,
  updateSelectedQuestionEle,
  updateSurveyActiveTab,
} from "store/surveyJS/reducer.actions";
import QuestionDrawPolygonModel from "./components/DrawPolygon/model";
import SurveyDrawPolygonQuestion, {
  drawPolygonProperties,
} from "./components/DrawPolygon";

import "survey-core/survey.i18n.js";
import "survey-creator-core/survey-creator-core.i18n.js";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import "view/pages/Forms/EditFormDetails/Components/SurveyJs/theme.css";
import QuestionDropPinModel from "./components/DropPin/model";
import SurveyDropPinQuestion, { dropPinProperties } from "./components/DropPin";
import {
  DrawPolygonState,
  DropPinState,
  GetGpsDataState,
  PointPickerState,
  ShapePickerState,
} from "./stateConstructors";
import generateUniqueId from "./utils/generateUniqueId";
import apiLibrary from "services/api";
import { baseURL } from "config";
import axios from "axios";
import { camelCase, sentenceCase, snakeCase } from "change-case";
import { toggleSidebar } from "store/sideBar/reducer.actions";
import {
  fetchDataSources,
  getQuestionNamesFromAllPanels,
  handleOtherProperties,
  handleRequireAll,
  handleShowAll,
  initializePropertyVisibility,
  propertiesToBeHiddeFromPanels,
  registerPanels,
  updatePropertyVisibility,
} from "./utils/handlingOfDropDownsInHardCodeSections";
import { iconComponents } from "./iconNamesWithElement";
import {
  GENERAL_INFO_QUESTIONS_NAME,
  SAMPLE_QUESTIONS_NAME,
  SPECIES_DETAILS_QUESTIONS_NAME,
} from "./panelsQuestionsName";
import {
  IfetchSurveyJsFormDatails,
  IFormDetails,
} from "store/formDetailsSurveyJsFields/initialState";
import { TailSpin } from "react-loader-spinner";
import SurveyGetGpsDataQuestion, {
  getGpsDataProperties,
} from "./components/GetGpsData";
import QuestionGetGpsDataModel from "./components/GetGpsData/model";
import { editorLocalization } from "survey-creator-core";
import { processExifData } from "view/pages/Observations/ObservationProfile";
import QuestionRegionPickerModel from "./components/RegionPicker/model";
import SurveyRegionPickerQuestion, { getRegionPickerProperties } from "./components/RegionPicker";

// panels json for APIs
export let panelsJson: any = [];

export function updatePanelsJson(json: any) {
  return (panelsJson = json);
}

// Add or modify locales using dedicated API methods

// const hardCodePanels = registerPanels();
const propertiesToHide = propertiesToBeHiddeFromPanels();

export function registerCustomQuestionsAndPanels() {
  // Register Custom Questions Models
  ElementFactory.Instance.registerElement(questionTypes.audio, (name) => {
    return new QuestionAudioModel(name);
  });

  ElementFactory.Instance.registerElement(questionTypes.geocoder, (name) => {
    return new QuestionGeoCoderModel(name);
  });
  ElementFactory.Instance.registerElement(questionTypes.shapePicker, (name) => {
    return new QuestionShapePickerModel(name);
  });
  ElementFactory.Instance.registerElement(questionTypes.pointPicker, (name) => {
    return new QuestionPointPickerModel(name);
  });
  ElementFactory.Instance.registerElement(questionTypes.drawPolygon, (name) => {
    return new QuestionDrawPolygonModel(name);
  });
  ElementFactory.Instance.registerElement(questionTypes.dropPin, (name) => {
    return new QuestionDropPinModel(name);
  });
  ElementFactory.Instance.registerElement(questionTypes.getGpsData, (name) => {
    return new QuestionGetGpsDataModel(name);
  });

  // ✅ Register region picker question type correctly
  ElementFactory.Instance.registerElement(questionTypes.regionPicker, (name) => {
    return new QuestionRegionPickerModel(name);
  });

  // Register Custom Questions Components
  ReactQuestionFactory.Instance.registerQuestion(
    questionTypes.audio,
    (props) => {
      return React.createElement(SurveyAudioQuestion, props);
    }
  );
  ReactQuestionFactory.Instance.registerQuestion(
    questionTypes.geocoder,
    (props) => {
      return React.createElement(SurveyGeoCoderQuestion, props);
    }
  );
  ReactQuestionFactory.Instance.registerQuestion(
    questionTypes.shapePicker,
    (props) => {
      return React.createElement(SurveyShapePickerQuestion, props);
    }
  );
  ReactQuestionFactory.Instance.registerQuestion(
    questionTypes.pointPicker,
    (props) => {
      return React.createElement(SurveyPointPickerQuestion, props);
    }
  );
  ReactQuestionFactory.Instance.registerQuestion(
    questionTypes.drawPolygon,
    (props) => {
      return React.createElement(SurveyDrawPolygonQuestion, props);
    }
  );
  ReactQuestionFactory.Instance.registerQuestion(
    questionTypes.dropPin,
    (props) => {
      return React.createElement(SurveyDropPinQuestion, props);
    }
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionTypes.getGpsData,
    (props) => {
      return React.createElement(SurveyGetGpsDataQuestion, props);
    }
  )

  ReactQuestionFactory.Instance.registerQuestion(
    questionTypes.regionPicker,
    (props) => {
      return React.createElement(SurveyRegionPickerQuestion, props);
    }
  )

  // Specify display names for the question type and its properties
  const locale = localization.getLocale("");
  locale.qt[questionTypes.audio] = "Audio";
  locale.qt[questionTypes.geocoder] = "Geocoder";
  locale.qt[questionTypes.shapePicker] = "Shape Picker";
  locale.qt[questionTypes.pointPicker] = "Point Picker";
  locale.qt[questionTypes.drawPolygon] = "Draw Polygon";
  locale.qt[questionTypes.dropPin] = "Drop A Pin";
  locale.qt[questionTypes.getGpsData] = "Get Gps Data";
  locale.qt[questionTypes.regionPicker] = "Region Picker";
  // Register Questions Panels
  // ComponentCollection.Instance.add(riverConditionPanel(panelsJson));
  // ComponentCollection.Instance.add(gpsInfoPanel);
  // ComponentCollection.Instance.add(speciesDetailsPanel);
  // ComponentCollection.Instance.add(countDetailsPanel);
  // ComponentCollection.Instance.add(sightingDetailsPanel);
  // ComponentCollection.Instance.add(weatherConditionsPanel);
  // ComponentCollection.Instance.add(generalInfoPanel);
  // ComponentCollection.Instance.add(geographicalInfo);
  // ComponentCollection.Instance.add(harvest);
  // ComponentCollection.Instance.add(sample);
}

registerCustomQuestionsAndPanels();

export const panelNames = Object.values(panelsName);

export function unRegisterCustomQuestions() {
  ElementFactory.Instance.unregisterElement(questionTypes.geocoder);
  ElementFactory.Instance.unregisterElement(questionTypes.audio);
  ElementFactory.Instance.unregisterElement(questionTypes.shapePicker);
  ElementFactory.Instance.unregisterElement(questionTypes.pointPicker);
  ElementFactory.Instance.unregisterElement(questionTypes.drawPolygon);
  ElementFactory.Instance.unregisterElement(questionTypes.dropPin);
  ElementFactory.Instance.unregisterElement(questionTypes.getGpsData);
  ElementFactory.Instance.unregisterElement(questionTypes.regionPicker);
}

const panelQuestionNames = getQuestionNamesFromAllPanels();

export function applyingAndRemovingProperties() {
  // Register Properties of Custom Questions
  Serializer.addClass(
    questionTypes.audio,
    audioProperties,
    function () {
      return new QuestionAudioModel("");
    },
    "question"
  );
  Serializer.addClass(
    questionTypes.geocoder,
    geoCoderProperties,
    function () {
      return new QuestionGeoCoderModel("");
    },
    "question"
  );
  Serializer.addClass(
    questionTypes.shapePicker,
    shapePickerProperties,
    function () {
      return new QuestionShapePickerModel("");
    },
    "question"
  );
  Serializer.addClass(
    questionTypes.pointPicker,
    pointPickerProperties,
    function () {
      return new QuestionPointPickerModel("");
    },
    "question"
  );
  Serializer.addClass(
    questionTypes.drawPolygon,
    drawPolygonProperties,
    function () {
      return new QuestionDrawPolygonModel("");
    },
    "question"
  );
  Serializer.addClass(
    questionTypes.dropPin,
    dropPinProperties,
    function () {
      return new QuestionDropPinModel("");
    },
    "question"
  );
  Serializer.addClass(
    questionTypes.getGpsData,
    getGpsDataProperties,
    function () {
      return new QuestionGetGpsDataModel("");
    },
    "question"
  );

  Serializer.addClass(
    questionTypes.regionPicker,
    getRegionPickerProperties,
    function () {
      return new QuestionRegionPickerModel("");
    },
    "question"
  );

  Serializer.addProperty(questionTypes.dropdown, {
    name: "serviceUrls:dropdown",
    displayName: "Services URL",
    // default: updateChoicesByUrl()[0],
    choices: updateChoicesByUrl(),
    category: "choicesByUrl",
    onSetValue: async (sender: any, options: any) => {
      const newServiceUrls = options;
      const updatedChoices = await getDataFromSourceUrl(newServiceUrls);
      sender.choices = updatedChoices;
      sender.choicesByUrl.url = newServiceUrls;
      sender.choicesByUrl.titleName = "text";
      sender.choicesByUrl.valueName = "value";
      sender.setPropertyValue(`dataSourceDetached`, false);
      sender.setPropertyValue("serviceUrls", newServiceUrls);
      sender.setPropertyValue(
        `dataSourceType`,
        extractDataSourceType(newServiceUrls)
      );
    },
  });
  Serializer.addProperty(questionTypes.tagbox, {
    name: "serviceUrls:dropdown",
    displayName: "Services URL",
    // default: updateChoicesByUrl()[0],
    choices: updateChoicesByUrl(),
    category: "choicesByUrl",
    onSetValue: async (sender: any, options: any) => {
      const newServiceUrls = options;
      const updatedChoices = await getDataFromSourceUrl(newServiceUrls);
      sender.choices = updatedChoices;
      sender.choicesByUrl.url = newServiceUrls;
      sender.choicesByUrl.titleName = "text";
      sender.choicesByUrl.valueName = "value";
      sender.setPropertyValue(`dataSourceDetached`, false);
      sender.setPropertyValue("serviceUrls", newServiceUrls);
      sender.setPropertyValue(
        `dataSourceType`,
        extractDataSourceType(newServiceUrls)
      );
    },
  });
  Serializer.addProperty(questionTypes.radio, {
    name: "serviceUrls:dropdown",
    displayName: "Services URL",
    // default: updateChoicesByUrl()[0],
    choices: updateChoicesByUrl(),
    category: "choicesByUrl",
    onSetValue: async (sender: any, options: any) => {
      const newServiceUrls = options;
      const updatedChoices = await getDataFromSourceUrl(newServiceUrls);
      sender.choices = updatedChoices;
      sender.choicesByUrl.url = newServiceUrls;
      sender.choicesByUrl.titleName = "text";
      sender.choicesByUrl.valueName = "value";
      sender.setPropertyValue(`dataSourceDetached`, false);
      sender.setPropertyValue("serviceUrls", newServiceUrls);
      sender.setPropertyValue(
        `dataSourceType`,
        extractDataSourceType(newServiceUrls)
      );
    },
  });
  Serializer.addProperty(questionTypes.checkbox, {
    name: "serviceUrls:dropdown",
    displayName: "Services URL",
    // default: updateChoicesByUrl()[0],
    choices: updateChoicesByUrl(),
    category: "choicesByUrl",
    onSetValue: async (sender: any, options: any) => {
      const newServiceUrls = options;
      const updatedChoices = await getDataFromSourceUrl(newServiceUrls);
      sender.choices = updatedChoices;
      sender.choicesByUrl.url = newServiceUrls;
      sender.choicesByUrl.titleName = "text";
      sender.choicesByUrl.valueName = "value";
      sender.getPropertyValue("dataSourceDetached");
      sender.setPropertyValue(`dataSourceDetached`, false);
      sender.setPropertyValue("serviceUrls", newServiceUrls);
      sender.setPropertyValue(
        `dataSourceType`,
        extractDataSourceType(newServiceUrls)
      );
    },
  });
  Serializer.getProperty("survey", "widthMode").defaultValue = "responsive";
  Serializer.getProperty("survey", "widthMode").visible = false;
  // Hide properties from questions
  questionsPropertiesToBeRemove.forEach((question: any) => {
    const { name, properties } = question;
    properties.forEach((property: string) => {
      if (Serializer.getProperty(name, property)) {
        Serializer.getProperty(name, property).visible = false;
      }
    });
  });

  Serializer.addProperty("panel", {
    name: "showAll:boolean",
    default: true,
    displayName: "Show All",
    category: "general",
  });

  Serializer.addProperty("panel", {
    name: "_id",
    type: "text",
    category: "general",
    default: null,
    visible: false,
  });
  Serializer.addProperty("panel", {
    name: "panelType",
    type: "text",
    category: "general",
    default: null,
    visible: false,
  });

  panelQuestionNames.forEach((element: any) => {
    Serializer.addProperty("panel", {
      name: `show${element.name}:boolean`,
      default: true,
      visible: false,
      displayName: element.title,
      category: "general",
    });
  });

  Serializer.addProperty("panel", {
    name: "requireAll:boolean",
    default: false,
    displayName: "Require  All",
    category: "general",
  });

  panelQuestionNames.forEach((element: any) => {
    Serializer.addProperty("panel", {
      name: `require${element.name}:boolean`,
      default: false,
      visible: false,
      displayName: element.title,
      category: "general",
    });
  });
}

addPropertyForRadioCheckBoxesTagboxAndDropdown();

export function resetAppliedAndReomvedProperties() {
  Serializer.removeClass(questionTypes.audio);
  Serializer.removeClass(questionTypes.geocoder);
  Serializer.removeClass(questionTypes.shapePicker);
  Serializer.removeClass(questionTypes.pointPicker);
  Serializer.removeClass(questionTypes.drawPolygon);
  Serializer.removeClass(questionTypes.dropPin);
  Serializer.removeClass(questionTypes.getGpsData);

  Serializer.removeProperty(questionTypes.dropdown, "serviceUrls");
  Serializer.removeProperty(questionTypes.tagbox, "serviceUrls");
  Serializer.removeProperty(questionTypes.radio, "serviceUrls");
  Serializer.removeProperty(questionTypes.checkbox, "serviceUrls");
  Serializer.removeProperty(questionTypes.dropdown, "dataSourceDetached");
  Serializer.removeProperty(questionTypes.tagbox, "dataSourceDetached");
  Serializer.removeProperty(questionTypes.radio, "dataSourceDetached");
  Serializer.removeProperty(questionTypes.checkbox, "dataSourceDetached");

  Serializer.removeClass(questionTypes.regionPicker);

  Serializer.getProperty("survey", "widthMode").visible = true;
  // reset properties from questions
  questionsPropertiesToBeRemove.forEach((question: any) => {
    const { name, properties } = question;
    properties.forEach((property: string) => {
      if (Serializer.getProperty(name, property)) {
        Serializer.getProperty(name, property).visible = true;
      }
    });
  });
}
export function unRegisterCustomPanels() {
  ComponentCollection.Instance.clear();
}

// Register SVG icon for the question type
SvgRegistry.registerIconFromSvg(questionTypes.audio, audioIcon);
SvgRegistry.registerIconFromSvg(questionTypes.geocoder, geoCoderIcon);
SvgRegistry.registerIconFromSvg(questionTypes.shapePicker, shapePickerIcon);
SvgRegistry.registerIconFromSvg(questionTypes.pointPicker, pointPickerIcon);
SvgRegistry.registerIconFromSvg(questionTypes.drawPolygon, drawPolygonIcon);
SvgRegistry.registerIconFromSvg(questionTypes.dropPin, dropPinIcon);
SvgRegistry.registerIconFromSvg(questionTypes.getGpsData, getGpsDataIcon);

SvgRegistry.registerIconFromSvg(questionTypes.regionPicker, regionIcon);

SvgRegistry.registerIconFromSvg(panelsName.RIVER_CONDITION, riverIcon);
SvgRegistry.registerIconFromSvg(panelsName.GPS_INFORMATION, gpsIcon);
SvgRegistry.registerIconFromSvg(
  panelsName.GEOGRAPHICAL_INFORMATION,
  geographicalIcon
);
SvgRegistry.registerIconFromSvg(panelsName.SPECIES_DETAIL, speciesDetailIcon);
SvgRegistry.registerIconFromSvg("harvest", harvestIcon);

SvgRegistry.registerIconFromSvg(panelsName.COUNT_DETAILS, countDetailsIcon);
SvgRegistry.registerIconFromSvg(panelsName.SIGHTING_DETAIL, sightingIcon);
SvgRegistry.registerIconFromSvg(panelsName.WEATHER_CONDITION, weatherIcon);
SvgRegistry.registerIconFromSvg(panelsName.GENERAL_INFORMATION, generalIcon);
SvgRegistry.registerIconFromSvg(panelsName.SAMPLE, sampleIcon);
SvgRegistry.registerIconFromSvg(
  panelsName.ENVIRONMENTAL_CONDITIONS,
  sampleIcon
);

// Global Settings
function applyBackground(color: string) {
  setTimeout(() => {
    const surveyEl = document.getElementsByClassName(
      "sd-root-modern"
    )[0] as HTMLDivElement;
    if (surveyEl) {
      surveyEl.style.setProperty("--background", color);
    }
  }, 50);
}
function handleActiveTabChange(sender: any, options: any) {
  if (options.tabName === "test" || options.tabName === "designer") {
    applyBackground(sender.survey.backgroundColor);
  }
}

//A stop list of properties displayed in Layout,Validation and Data categories for different survey elements
const propertyStopList = [
  "state",
  "titleLocation",
  "descriptionLocation",
  "indent",
  "width",
  "minWidth",
  "maxWidth",
  "questionTitleLocation",
  "removePanelButtonLocation",
  "inputFieldSize",
  "rows",
  "autoGrow",
  "allowResize",
  "startWithNewLine",
  "hideNumber",
  //"colCount",
  "size",
  "questionsOrder",
  "innerIndent",
  "panelRemoveButtonLocation",
  "templateTitleLocation",
  "requiredErrorText",
  "validators",
  "maxLength",
  "keyName",
  "keyDuplicationError",
  "focusOnFirstError",
  "checkErrorsMode",
  "valuePropertyName",
];
const options = {
  showLogicTab: true,
  showJSONEditorTab: false,
  showSurveyTitle: false,
  allowModifyPages: false,
  showNavigationButtons: false,
  showNavigationTabs: false,
  showNavigationPanel: false,
  showNavigationPanelTitle: false,
  showNavigationPanelButtons: false,
  showToolbar: true,
  allowChangeThemeInPreview: false,
  // questionTypes: Object.values(questionTypes),
  questionTypes: Object.values(questionTypes),
  showTitlesInExpressions: true,
};

// change the text message for empty survey
surveyLocalization.locales[surveyLocalization.defaultLocale]["emptySurvey"] =
  "Click one of the elements in the design interface to see its setting options";

surveyLocalization.locales[surveyLocalization.defaultLocale]["name"] =
  "Click one of the elements in the design interface to see its setting options";
// Assert the type of locales to any to bypass TypeScript checks
const editorLocales = editorLocalization.locales as any;

// Now you can modify the locales without TypeScript errors
editorLocales["en"] = {
  ...editorLocales["en"],
  pe: {
    name: "Custom Identifier",
    title: "Custom Title",
    description: "Custom Description",
  },
};
export const SurveyComponent = ({
  dataSourcesData,
  formDetails,
  doHide,
}: {
  dataSourcesData: any;
  formDetails: IFormDetails;
  doHide?: boolean;
}) => {
  const [creator, setCreator] = useState<any>();
  // const [observers, setObservers] = useState<any[]>([]);
  // const [objects, setObjects] = useState<any[]>([]);
  // const [types, setTypes] = useState<any[]>([]);
  // const [categories, setCategories] = useState<any[]>([]);
  // const [species, setSpecies] = useState<any[]>([]);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [customSidebarContainer, setCustomSidebarContainer] = useState();
  const hardCodePanels = registerPanels(formDetails.programId);
  const translations = localization.getLocale("");
  translations.pehelp.question = {
    name: "The name you fill in here will be what gets displayed as column header in case you include this field in a report.",
  };

  if (SurveyQuestionEditorDefinition.definition["question"]?.properties) {
    const properties: any =
      SurveyQuestionEditorDefinition.definition["question"].properties;
    if (properties.name) {
      properties.name.title = "Custom Name";
    }
  }
  useEffect(() => {
    if (!creator) return;

    // Define categories (assuming this method is correct)
    creator.toolbox.defineCategories(
      [
        {
          category: "general",
          items: [panelsName.GEOGRAPHICAL_INFORMATION, panelsName.HARVEST],
        },
        {
          category: "complexElements",
          items: [
            questionTypes.shapePicker,
            questionTypes.pointPicker,
            questionTypes.drawPolygon,
            questionTypes.dropPin,
            questionTypes.getGpsData,
            questionTypes.geocoder,
            questionTypes.regionPicker
          ],
        },
        {
          category: "readOnlyElements",
          items: [
            "text",
            "comment",
            "radiogroup",
            "checkbox",
            "dropdown",
            "tagbox",
            "boolean",
            "file",
            "imagepicker",
            "audio",
          ],
        },
        {
          category: "panels",
          items: ["panel", "paneldynamic"],
        },
      ],
      true
    );
  }, [creator]);

  const mapQuestionCreators = [
    questionTypes.shapePicker,
    questionTypes.pointPicker,
    questionTypes.drawPolygon,
    questionTypes.dropPin,
    questionTypes.getGpsData,
  ];

  const setTheChoicesOfDropdowns = (
    fieldName: any,
    dataSourcesData: {
      observers: any[];
      types: any[];
      objects: any[];
      categories: any[];
      species: any[];
    }
  ) => {
    switch (fieldName) {
      case SPECIES_DETAILS_QUESTIONS_NAME.Category:
        return dataSourcesData.categories;
      case SPECIES_DETAILS_QUESTIONS_NAME.SpecieName:
        return dataSourcesData.species;
      case SPECIES_DETAILS_QUESTIONS_NAME.Type:
        return dataSourcesData.types;
      case SPECIES_DETAILS_QUESTIONS_NAME.ObjectName:
        return dataSourcesData.objects;
      case SAMPLE_QUESTIONS_NAME.Sampler:
      case GENERAL_INFO_QUESTIONS_NAME.Observers:
      case GENERAL_INFO_QUESTIONS_NAME.DataEntry:
      case GENERAL_INFO_QUESTIONS_NAME.Reported:
        return dataSourcesData.observers;
      default:
        return [];
    }
  };

  let selectedQuestion: any = null;

  useEffect(() => {
    // dispatch(toggleSidebar(false));
    if (creator) return;

    // Serializer.getProperty(
    //   panelsName.GEOGRAPHICAL_INFORMATION,
    //   "description"
    // ).displayName = "Panel Description";
    // Serializer.getProperty(panelsName.HARVEST, "description").displayName =
    //   "Panel Description";

    setCreator(new SurveyCreator(options));
  }, []);

  useEffect(() => {
    if (!creator) return;

    applyingAndRemovingProperties();
    hardCodePanels.forEach((panel: any, index: number) => {
      if (
        panel.name === panelsName.RIVER_CONDITION ||
        panel.name === panelsName.WEATHER_CONDITION
      ) {
        return;
      }
      creator.toolbox.addItem(
        {
          name: panel.name,
          isCopied: false,
          iconName: `icon-${panel.name}`,
          title: panel.title,
          json: panel,
          category: "general",
        },
        index
      );
    });

    Serializer.addProperty("panel", {
      name: "note:text", // Custom property to hold the note, non-editable
      isSerializable: false, // This ensures it doesn't affect the actual survey JSON
      readOnly: true,
      displayName:
        "Please utilize the checkboxes provided below to selectively display or conceal questions within this section. It is important to note that hidden questions will remain accessible within the designer interface of the form builder; therefore, we recommend utilizing the preview mode to confirm the accurate concealment of questions.",
      category: "general",
      visibleIndex: 4,
    });
    // const toolboxItems = creator.toolbox.items;
  }, [creator]);

  useEffect(() => {
    if (!creator) return;
    // registering the custom sidebar
    !customSidebarContainer &&
      registerCustomPropertyGridSidebar(setCustomSidebarContainer);

    creator.onSelectedElementChanged.add((sender: any, options: any) => {
      selectedQuestion = options.newSelectedElement;
      if (options.newSelectedElement === null) {
        dispatch(updateSelectedQuestionEle(null));
      } else {
        dispatch(updateSelectedQuestionEle(options.newSelectedElement));
      }
      if (options.newSelectedElement !== null) {
        const doDisplayDefaultOptions = [
          panelsName.HARVEST,
          panelsName.GEOGRAPHICAL_INFORMATION,
        ].includes(options.newSelectedElement.name);

        handleDefaultAndCustomPropertyGridSidebars(
          options.newSelectedElement,
          doDisplayDefaultOptions
        );
      }
    });

    let isDeletionConfirmed = false;
    creator.onElementDeleting.add((sender: any, options: any) => {
      // Exit if deletion has already been confirmed to prevent a loop
      if (isDeletionConfirmed) {
        isDeletionConfirmed = false; // Reset for next deletion
        return;
      }

      // Prevent immediate deletion
      options.allowing = false;

      // Asynchronously confirm the deletion
      settings.confirmActionAsync("Are you sure?", (confirmed) => {
        if (confirmed) {
          isDeletionConfirmed = true;
          sender.deleteElement(options.element);

          const deletedElement = options.element;
          const eleType = deletedElement?.getType();
          if (mapQuestionCreators.includes(eleType)) {
            // update deleted element for reseting states of questions
            dispatch(updateDeletedQuestionEle(deletedElement));
          } else if (eleType === panelsName.HARVEST) {
            dispatch(updateAddedHarvestPanelMapQuestions({}));
          } else if (eleType === panelsName.GEOGRAPHICAL_INFORMATION) {
            dispatch(updateAddedGeographicalPanelMapQuestions({}));
          } else if (eleType === panelsName.HARVEST) {
            dispatch(updateAddedHarvestPanelMapQuestions({}));
          } else if (eleType === panelsName.GEOGRAPHICAL_INFORMATION) {
            dispatch(updateAddedGeographicalPanelMapQuestions({}));
          }
        }
      });
    });

    function handleDefaultAndCustomPropertyGridSidebars(
      element: any,
      doDisplayDefaultOptions: boolean
    ) {
      const questionType = element?.isPanel
        ? element?.name
        : element?.getType();
      if (
        mapQuestionCreators.includes(questionType) ||
        questionType === panelsName.HARVEST ||
        questionType === panelsName.GEOGRAPHICAL_INFORMATION
      ) {
        // displaying custom sidebar
        toggleCustomPropertyGridSidebar(true);

        // hiding default sidebar
        togglePropertyGridSidebar(doDisplayDefaultOptions);
      } else {
        // hiding custom sidebar
        toggleCustomPropertyGridSidebar(false);
        // displaying default sidebar
        if (!doDisplayDefaultOptions) {
          togglePropertyGridSidebar(true);
        }
      }
    }
  }, [creator]);

  useEffect(() => {
    if (!creator) return;

    creator.onElementAllowOperations.add(function (_: any, options: any) {
      const questionType = options.obj?.getType();
      if (questionType === "panel") {
        options.allowChangeType = false;
        options.allowAddPanel = false;
        // options.allowCopy = false;
        options.allowChangeRequired = false;
      }

      if (mapQuestionCreators.includes(questionType)) {
        options.allowCopy = false;
        options.allowChangeRequired = false;
      }

      if (options.obj.isQuestion) {
        options.obj.logicTitle = options.obj.title;
      }

      if (
        options?.obj?.jsonObj?.type === panelsName.GEOGRAPHICAL_INFORMATION ||
        options?.obj?.jsonObj?.type === panelsName.HARVEST
      ) {
        options.allowEdit = false; // Disallow editing the panel itself
      }
    });
    // Prevending the duplication of panels and specific questions
    const panels = Object.values(panelsName);

    creator.onDesignerSurveyCreated.add(function (sender: any, options: any) {
      options.survey.showQuestionNumbers = "off";
    });
    creator.onShowingProperty.add(function (sender: any, options: any) {
      const questionName = options.obj?.jsonObj?.name;
      const propertyName = options.property?.name;

      setTimeout(() => {
        const notePropertyDomElement: HTMLElement | null =
          document.querySelector("div[data-name='note']");

        if (propertyName === "note" && notePropertyDomElement) {
          if (isTargetedQuestion(questionName)) {
            notePropertyDomElement.style.display = "none";
          } else {
            notePropertyDomElement.style.display = "block";

            const textField = notePropertyDomElement.querySelector("textarea");

            if (textField) {
              textField.style.display = "none";
            }
          }
        }
      }, 0);

      function isTargetedQuestion(name: string) {
        if (!Object.values(panelsName).includes(name)) {
          return true;
        }
        return false;
      }

      // if (!options.property.name && !options?.obj?.toJSON()?.name) return;
      // if (
      //   propertiesToHide.includes(options.property.name) &&
      //   panelNames.includes(options.obj.toJSON().name)
      // ) {
      //   options.showProperty = false;
      //   options.canShow = false;
      // }
      // if (options.obj.parent && options.obj.parent.getType() === "panel") {
      //   if (panelNames.includes(options.obj.parent.name)) {
      //     if (
      //       (options.obj.isQuestion && options.obj.getType() !== "dropdown") ||
      //       (options.obj.getType() === "dropdown" &&
      //         options.property.name !== "choices")
      //     ) {
      //       options.showProperty = false;
      //       options.canShow = false;
      //     }
      //   }
      // }
    });
    creator.onModified.add(async function (sender: any, options: any) {
      const questionsToModify = [
        questionTypes.checkbox,
        questionTypes.radio,
        questionTypes.dropdown,
        questionTypes.tagbox,
      ];
      if (options.type === "PROPERTY_CHANGED") {
        if (
          options.name === "choices" ||
          options.name === "text" ||
          options.name === "value"
        ) {
          // if (options.target.getType() === "itemvalue") {
          //   const item = options.target;
          //   item.value = camelCase(item.text);
          //   item.text = item.text;
          // }
          if (
            questionsToModify.includes(selectedQuestion.getType()) &&
            selectedQuestion.choicesByUrl &&
            selectedQuestion.choicesByUrl.url
          ) {
            selectedQuestion.choicesByUrl.url = "";
            selectedQuestion.setPropertyValue(`dataSourceDetached`, true);
            selectedQuestion.setPropertyValue(`serviceUrls`, "");
            selectedQuestion.setPropertyValue(`dataSourceType`, "");
          }
        }
      }
      // if (options.type === "PROPERTY_CHANGED" && options.name === "title") {
      //   const questionType = options.target.getType();
      //   if (!mapQuestionCreators.includes(questionType)) {
      //     const name = camelCase(options.newValue).replace(/_/g, "");

      //     options.target.name = name;
      //     // options.target.title = name;
      //   }
      // } else if (
      //   options.type === "PROPERTY_CHANGED" &&
      //   options.name === "name"
      // ) {
      //   const questionType = options.target.getType();
      //   if (
      //     !mapQuestionCreators.includes(questionType) &&
      //     options.newValue !== undefined
      //   ) {
      //     const name = camelCase(options.newValue).replace(/_/g, "");
      //     options.target.name = name;
      //     // options.target.title = sentenceCase(options.newValue ?? "");
      //   }
      // }
    });
    creator.onPropertyValidationCustomError.add((sender: any, options: any) => {
      if (options.propertyName === "name") {
        const element = options.obj;
        const elementType = element.getType();

        if (
          (elementType === "paneldynamic" || elementType === "panel") &&
          panels.includes(options.value)
        ) {
          options.error =
            "The name you entered is already in use for predefined panels. Please choose a different name.";
        }
      }

      // else if (options.propertyName === "title") {
      //   const element = options.obj;
      //   const elementType = element.getType();
      //   console.log(elementType);
      //   if (
      //     (elementType === "paneldynamic" || elementType === "panel") &&
      //     element.name === camelCase(options.value ?? "") &&
      //     panels.includes(camelCase(options.value ?? ""))
      //   ) {
      //     options.error =
      //       "The name you entered is already in use for predefined panels. Please choose a different name.";
      //   }
      // }
    });
    creator.onQuestionAdded.add((sender: any, options: any) => {
      const questionType = options.question.jsonObj?.type;

      if (panels.includes(questionType)) {
        // getting the counting of added panel in questions array
        const questionExistenceCountArr = JSON.stringify(creator.JSON).match(
          new RegExp(questionType, "g")
        ) as any[];

        const isPanelDuplicate = questionExistenceCountArr?.length > 1;

        if (isPanelDuplicate) {
          let updatedJSON = creator.JSON;

          // finding currently added panel index
          const addedPanelIndex = updatedJSON.pages[0]?.elements?.findLastIndex(
            (e: any) => e?.type === questionType
          );

          if (addedPanelIndex >= 0) {
            // removing the added panel because it is duplication
            const updatedQuestionElements =
              updatedJSON.pages[0]?.elements?.filter(
                (e: any, index: number) => index !== addedPanelIndex
              );

            updatedJSON.pages[0].elements = updatedQuestionElements;

            // replacing the main json with updated json
            creator.JSON = updatedJSON;
          }
        }
      }
      // handle map type components
      if (mapQuestionCreators.includes(questionType)) {
        // initializeQuestionState(options.question,dispatch)
        let questionInstance;
        switch (questionType) {
          case questionTypes.shapePicker:
            const shapePickerInstance = new ShapePickerState();
            questionInstance = shapePickerInstance;

            break;

          case questionTypes.pointPicker:
            const pointPickerInstance = new PointPickerState();
            questionInstance = pointPickerInstance;

            break;

          case questionTypes.drawPolygon:
            const drawPolygonInstance = new DrawPolygonState();
            questionInstance = drawPolygonInstance;

            break;

          case questionTypes.dropPin:
            const dropPinInstance = new DropPinState();
            questionInstance = dropPinInstance;

            break;

          case questionTypes.getGpsData:
            const getGpsDataInstance = new GetGpsDataState();
            questionInstance = getGpsDataInstance;

            break;
        }

        if (questionInstance) {
          dispatch(updateAddedQuestionState(questionInstance));
          options.question._id = questionInstance.id;
          options.question.rdx_id = questionInstance.id;
        }
      }

      // handle map type components in panels
      if (questionType === panelsName.HARVEST) {
        const retrievalShapePicker = new ShapePickerState({
          mapCenterPoint: {
            longitude: -145.6433003,
            latitude: 65.0710178,
          },
        });
        const struckShapePicker = new ShapePickerState({
          mapCenterPoint: {
            longitude: -145.6433003,
            latitude: 65.0710178,
          },
        });
        const retrievalPointPicker = new PointPickerState({
          mapCenterPoint: {
            longitude: -145.6433003,
            latitude: 65.0710178,
          },
        });
        const struckPointPicker = new PointPickerState({
          mapCenterPoint: {
            longitude: -145.6433003,
            latitude: 65.0710178,
          },
        });

        const harvest = {
          id: generateUniqueId("harvest#"),
          retrievalShapePicker,
          struckShapePicker,
          retrievalPointPicker,
          struckPointPicker,
        };

        options.question._id = harvest.id;
        dispatch(updateAddedHarvestPanelMapQuestions(harvest));
      } else if (questionType === panelsName.GEOGRAPHICAL_INFORMATION) {
        const regionTypeShapePicker = new ShapePickerState({
          mapCenterPoint: {
            longitude: -145.6433003,
            latitude: 65.0710178,
          },
        });
        const viewTypeShapePicker = new ShapePickerState({
          mapCenterPoint: {
            longitude: -145.6433003,
            latitude: 65.0710178,
          },
        });
        const pointPicker = new PointPickerState({
          mapCenterPoint: {
            longitude: -145.6433003,
            latitude: 65.0710178,
          },
        });

        const geographical = {
          id: generateUniqueId("geographical#"),
          regionTypeShapePicker,
          viewTypeShapePicker,
          pointPicker,
        };

        options.question._id = geographical.id;

        dispatch(updateAddedGeographicalPanelMapQuestions(geographical));
      }
    });
    creator.onModified.add((sender: any, options: any) => {
      if (options.type === "QUESTION_CONVERTED") {
        const questionType = options.newValue.getType();

        if (panels.includes(questionType)) {
          // getting the counting of added panel in questions array
          const questionExistenceCountArr = JSON.stringify(creator.JSON).match(
            new RegExp(questionType, "g")
          ) as any[];

          const isPanelDuplicate = questionExistenceCountArr?.length > 1;

          if (isPanelDuplicate) {
            let updatedJSON = creator.JSON;

            // finding currently added panel index
            const addedPanelIndex =
              updatedJSON.pages[0]?.elements?.findLastIndex(
                (e: any) => e?.type === questionType
              );

            if (addedPanelIndex >= 0) {
              // removing the added panel because it is duplication
              const updatedQuestionElements =
                updatedJSON.pages[0]?.elements?.filter(
                  (e: any, index: number) => index !== addedPanelIndex
                );

              updatedJSON.pages[0].elements = updatedQuestionElements;

              // replacing the main json with updated json
              creator.JSON = updatedJSON;
            }
          }
        }
        // handle map type components
        if (mapQuestionCreators.includes(questionType)) {
          // initializeQuestionState(options.question,dispatch)
          let questionInstance;
          switch (questionType) {
            case questionTypes.shapePicker:
              const shapePickerInstance = new ShapePickerState();
              questionInstance = shapePickerInstance;

              break;

            case questionTypes.pointPicker:
              const pointPickerInstance = new PointPickerState();
              questionInstance = pointPickerInstance;

              break;

            case questionTypes.drawPolygon:
              const drawPolygonInstance = new DrawPolygonState();
              questionInstance = drawPolygonInstance;

              break;

            case questionTypes.dropPin:
              const dropPinInstance = new DropPinState();
              questionInstance = dropPinInstance;

              break;

            case questionTypes.getGpsData:
              const getGpsDataInstance = new GetGpsDataState();
              questionInstance = getGpsDataInstance;

              break;
          }

          if (questionInstance) {
            dispatch(updateAddedQuestionState(questionInstance));
            options.newValue._id = questionInstance.id;
            options.newValue.rdx_id = questionInstance.id;
          }
        }
        // handle map type components in panels
        if (questionType === panelsName.HARVEST) {
          const retrievalShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const struckShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const retrievalPointPicker = new PointPickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const struckPointPicker = new PointPickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });

          const harvest = {
            id: generateUniqueId("harvest#"),
            retrievalShapePicker,
            struckShapePicker,
            retrievalPointPicker,
            struckPointPicker,
          };

          options.newValue._id = harvest.id;
          dispatch(updateAddedHarvestPanelMapQuestions(harvest));
        } else if (questionType === panelsName.GEOGRAPHICAL_INFORMATION) {
          const regionTypeShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const viewTypeShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const pointPicker = new PointPickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });

          const geographical = {
            id: generateUniqueId("geographical#"),
            regionTypeShapePicker,
            viewTypeShapePicker,
            pointPicker,
          };

          options.newValue._id = geographical.id;

          dispatch(updateAddedGeographicalPanelMapQuestions(geographical));
        }
      }
    });
    // Remove custom panels and questions from dynamic panel change type selector
    creator.onDefineElementMenuItems.add((_: any, options: any) => {
      const parentQuestion = options.obj.parent?.getType();

      if (
        parentQuestion === questionTypes.paneldynamic ||
        parentQuestion === questionTypes.panel
      ) {
        const converToAction: any = options.items.find(
          (item: any) => item.innerItem.id === "convertTo"
        );
        // edit questions list of element selecotrs
        try {
          if (converToAction) {
            const newActions =
              converToAction.innerItem.popupModel.propertyHash.contentComponentData.model.propertyHash.actions.filter(
                (action: any) => {
                  if (
                    action.id !== questionTypes.paneldynamic &&
                    !panels.includes(action.id) &&
                    action.id !== questionTypes.panel
                  ) {
                    return true;
                  }
                  return false;
                }
              );

            converToAction.innerItem.popupModel.propertyHash.contentComponentData.model.propertyHash.actions =
              newActions;
          }
        } catch { }
      }
    });
    // stop addition of some questions element into panels or dynamic panel through drag and drop system
    creator.onDragDropAllow.add((sender: any, options: any) => {
      const source = options.source?.getType();
      const target = options.target?.getType();
      const targetParent = options.target.parent?.getType();
      // stop addition of some questions element into panels or dynamic panel through drag and drop system

      let updatedJSON = creator.JSON;

      if (options.draggedElement.toolboxItemIconName === undefined) {
        return;
      }

      const panelName = options.draggedElement.toolboxItemIconName.replace(
        "icon-",
        ""
      );
      const panelInstances = updatedJSON?.pages?.flatMap((page: any) =>
        page.elements.filter(
          (e: any) => e.type === "panel" && e.name === panelName
        )
      );

      if (
        panelNames.includes(options.target?.parent?.name) ||
        panelInstances.length > 0
      ) {
        options.allow = false;
      }

      const questionsStopToAdded = [
        questionTypes.paneldynamic,
        questionTypes.panel,
        ...Object.values(panelsName),
      ];

      if (
        questionsStopToAdded.includes(source) &&
        ([questionTypes.paneldynamic, questionTypes.panel].includes(target) ||
          [questionTypes.paneldynamic, questionTypes.panel].includes(
            targetParent
          ))
      ) {
        options.allow = false;
      }
    });

    // handle upload file via survey js
    creator.onUploadFile.add(async (_: any, options: any) => {
      try {
        // Upload the file and get its data
        const { data: fileData } = await apiLibrary.file.fileUpload(
          options.files[0],
          false,
          "public"
        );

        // Load EXIF tags from the uploaded file
        const loadedTags = await ExifReader.load(options.files[0], {
          async: true,
        });

        const processedExifData = processExifData(loadedTags);

        // Post the processed EXIF data using the file's id
        await apiLibrary.Observations.postExifData(
          fileData.id,
          processedExifData
        );

        // Return success with the file URL and appended file id
        options.callback(
          "success",
          `${fileData.file.original}?fileId=${fileData.id}`
        );
      } catch (error: any) {
        options.callback("error");
      }
    });

    creator.onDragEnd.add((sender: any, options: any) => {
      if (options.fromElement?.getType() !== "page") {
        const questionType = options.draggedElement.jsonObj?.type;

        // if (panels.includes(questionType)) {
        //   // getting the counting of added panel in questions array
        //   const questionExistenceCountArr = JSON.stringify(creator.JSON).match(
        //     new RegExp(questionType, "g")
        //   ) as any[];

        //   const isPanelDuplicate = questionExistenceCountArr?.length > 1;

        //   if (isPanelDuplicate) {
        //     let updatedJSON = creator.JSON;

        //     // finding currently added panel index
        //     const addedPanelIndex =
        //       updatedJSON.pages[0]?.elements?.findLastIndex(
        //         (e: any) => e?.type === questionType
        //       );

        //     if (addedPanelIndex >= 0) {
        //       // removing the added panel because it is duplication
        //       const updatedQuestionElements =
        //         updatedJSON.pages[0]?.elements?.filter(
        //           (e: any, index: number) => index !== addedPanelIndex
        //         );

        //       updatedJSON.pages[0].elements = updatedQuestionElements;

        //       // replacing the main json with updated json
        //       creator.JSON = updatedJSON;
        //     }
        //   }
        // }
        // handle map type components
        if (mapQuestionCreators.includes(questionType)) {
          // initializeQuestionState(options.question,dispatch)
          let questionInstance;
          switch (questionType) {
            case questionTypes.shapePicker:
              const shapePickerInstance = new ShapePickerState();
              questionInstance = shapePickerInstance;

              break;

            case questionTypes.pointPicker:
              const pointPickerInstance = new PointPickerState();
              questionInstance = pointPickerInstance;

              break;

            case questionTypes.drawPolygon:
              const drawPolygonInstance = new DrawPolygonState();
              questionInstance = drawPolygonInstance;

              break;

            case questionTypes.dropPin:
              const dropPinInstance = new DropPinState();
              questionInstance = dropPinInstance;

              break;

            case questionTypes.getGpsData:
              const getGpsDataInstance = new GetGpsDataState();
              questionInstance = getGpsDataInstance;

              break;
          }

          if (questionInstance) {
            dispatch(updateAddedQuestionState(questionInstance));
            options.draggedElement._id = questionInstance.id;
            options.draggedElement.rdx_id = questionInstance.id;
          }
        }
        // handle map type components in panels
        if (questionType === panelsName.HARVEST) {
          const retrievalShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const struckShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const retrievalPointPicker = new PointPickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const struckPointPicker = new PointPickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const harvest = {
            id: generateUniqueId("harvest#"),
            retrievalShapePicker,
            struckShapePicker,
            retrievalPointPicker,
            struckPointPicker,
          };

          options.draggedElement._id = harvest.id;
          dispatch(updateAddedHarvestPanelMapQuestions(harvest));
        } else if (questionType === panelsName.GEOGRAPHICAL_INFORMATION) {
          const regionTypeShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const viewTypeShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const pointPicker = new PointPickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const geographical = {
            id: generateUniqueId("geographical#"),
            regionTypeShapePicker,
            viewTypeShapePicker,
            pointPicker,
          };

          options.draggedElement._id = geographical.id;

          dispatch(updateAddedGeographicalPanelMapQuestions(geographical));
        }
      }
    });
  }, [creator]);

  useEffect(() => {
    if (!creator) return;
    // if (formId) {
    //   dispatch(fetchFormDetailsAction({ id: formId }));
    // }

    creator.onActiveTabChanged.add((sender: any, option: any) => {
      const activeTabName = sender.activeTab;
      dispatch(updateSurveyActiveTab(activeTabName));
      handleActiveTabChange(sender, option);
    });

    creator.onShowingProperty.add(function (sender: any, options: any) {
      options.canShow = propertyStopList.indexOf(options.property.name) == -1;

      if (!options.property.name && !options?.obj?.toJSON()?.name) return;

      if (
        propertiesToHide.includes(options.property.name) &&
        panelNames.includes(options.obj.toJSON().name)
      ) {
        options.showProperty = false;
        options.canShow = false;
      }
      // if (options.obj.parent && options.obj.parent.getType() === "panel") {
      //   if (panelNames.includes(options.obj.parent.name)) {
      //     if (options.obj.isQuestion && options.property.name !== "choices") {
      //       options.showProperty = false;
      //       options.canShow = false;
      //     }
      //   }
      // }

      creator.onShowingProperty.add(function (sender: any, options: any) {
        if (!options.property.name && !options?.obj?.toJSON()?.name) return;

        if (
          propertiesToHide.includes(options.property.name) &&
          panelNames.includes(options.obj.toJSON().name)
        ) {
          options.showProperty = false;
          options.canShow = false;
        }
        if (options.obj.parent && options.obj.parent.getType() === "panel") {
          if (panelNames.includes(options.obj.parent.name)) {
            const isDataSourceAttached = options?.obj?.jsonObj.choicesByUrl
              ? true
              : false;
            const isQuestionTypeExist = [
              questionTypes.checkbox,
              questionTypes.dropdown,
              questionTypes.tagbox,
              questionTypes.radio,
              questionTypes.text,
              questionTypes.boolean,
            ].includes(options.obj.getType());

            const isPropertyExist = isDataSourceAttached
              ? ["defaultValue", "colCount"].includes(options.property.name)
              : ["choices", "defaultValue", "colCount"].includes(
                options.property.name
              );
            if (
              options.obj.isQuestion &&
              isQuestionTypeExist &&
              isPropertyExist
            ) {
              options.showProperty = true;
              options.canShow = true;
            } else {
              options.showProperty = false;
              options.canShow = false;
            }
          }
        }
      });
    });
  }, [creator]);

  useEffect(() => {
    if (!creator) return;

    creator.onPanelAdded.add((sender: any, options: any) => {
      let panel = options.panel;

      if (panel.jsonObj && panelNames.includes(panel.jsonObj.name)) {
        panel.name = panel.jsonObj.name;
        options.panel.setPropertyValue("panelType", "static");
        panel?.elements?.forEach((question: any) => {
          question.name = question.jsonObj.name;
          if (
            (question.getType() === questionTypes.tagbox ||
              question.getType() === questionTypes.dropdown) &&
            question.choicesByUrl &&
            question.choicesByUrl.url
          ) {
            question.choices = setTheChoicesOfDropdowns(
              question.jsonObj.name,
              dataSourcesData
            );
            question.jsonObj.choices = setTheChoicesOfDropdowns(
              question.jsonObj.name,
              dataSourcesData
            );
          }
        });

        if (panel.jsonObj.name === panelsName.HARVEST) {
          const retrievalShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const struckShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const retrievalPointPicker = new PointPickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const struckPointPicker = new PointPickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });

          const harvest = {
            id: generateUniqueId("harvest#"),
            retrievalShapePicker,
            struckShapePicker,
            retrievalPointPicker,
            struckPointPicker,
          };

          options.panel._id = harvest.id;
          options.panel.setPropertyValue("_id", harvest.id);
          dispatch(updateAddedHarvestPanelMapQuestions(harvest));
        } else if (panel.jsonObj.name === panelsName.GEOGRAPHICAL_INFORMATION) {
          const regionTypeShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const viewTypeShapePicker = new ShapePickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });
          const pointPicker = new PointPickerState({
            mapCenterPoint: {
              longitude: -145.6433003,
              latitude: 65.0710178,
            },
          });

          const geographical = {
            id: generateUniqueId("geographical#"),
            regionTypeShapePicker,
            viewTypeShapePicker,
            pointPicker,
          };

          options.panel._id = geographical.id;
          options.panel.setPropertyValue("_id", geographical.id);
          dispatch(updateAddedGeographicalPanelMapQuestions(geographical));
        }

        let updatedJSON = creator.JSON;

        const panelInstances = updatedJSON?.pages?.flatMap((page: any) =>
          page.elements.filter(
            (e: any) => e.type === "panel" && e.name === panel.name
          )
        );

        if (panelInstances?.length > 1) {
          const addedPanelIndex = updatedJSON.pages.findIndex((page: any) =>
            page.elements.some((e: any) => e.name === panel.name)
          );

          if (addedPanelIndex >= 0) {
            updatedJSON.pages[0].elements =
              updatedJSON.pages[0].elements.filter(
                (element: any, index: any, array: any[]) => {
                  // Check if the current element's name is unique in the array before this index
                  return (
                    array.findIndex((ele: any) => ele.name === element.name) ===
                    index
                  );
                }
              );

            creator.JSON = updatedJSON;
          }
        }
      }
    });

    creator.onShowingProperty.add(function (sender: any, options: any) {
      if (!options.property.name && !options?.obj?.toJSON()?.name) return;

      if (
        propertiesToHide.includes(options.property.name) &&
        panelNames.includes(options.obj.toJSON().name)
      ) {
        options.showProperty = false;
        options.canShow = false;
      }
      // if (options.obj.parent && options.obj.parent.getType() === "panel") {
      //   if (panelNames.includes(options.obj.parent.name)) {
      //     if (
      //       (options.obj.isQuestion && options.obj.getType() !== "dropdown") ||
      //       (options.obj.getType() === "dropdown" &&
      //         options.property.name !== "choices")
      //     ) {
      //       options.showProperty = false;
      //       options.canShow = false;
      //     }
      //   }
      // }
    });

    creator.onElementAllowOperations.add(function (sender: any, options: any) {
      if (panelNames.includes(options.obj.jsonObj?.name)) {
        options.allowChangeType = false;
        options.allowAddPanel = false;
        options.allowCopy = false;
        options.allowChangeRequired = false;
        options.allowChangeTitle = false;
        options.allowEdit = false;
        options.allowShowPanel = false;
        options.allowHidePanel = false;
        options.allowChangePageTitle = false;
        options.allowChangePageDescription = false;
        options.allowChangeDescription = false;
        options.allowDragging = false;
        options.allowChangeInputType = false;
        options.allowShowSettings = false;
        options.allowSelection = false;
      }

      if (
        options.obj.isQuestion &&
        panelNames.includes(options.obj.parent?.jsonObj?.name)
      ) {
        options.allowChangeType = false;
        options.allowAddPanel = false;
        options.allowCopy = false;
        options.allowChangeRequired = false;
        options.allowChangeTitle = false;
        options.allowDelete = false;
        options.allowEdit = false;
        // options.allowShowPanel = false;
        options.allowHidePanel = false;
        options.allowChangePageTitle = false;
        options.allowChangePageDescription = false;
        options.allowChangeDescription = false;
        options.allowDragging = false;
        options.allowChangeInputType = false;
        options.allowShowSettings = false;
        options.allowSelection = false;
      }
    });

    let lastSelectedDropdownWithinCustomPanel: any = null;

    creator.onSelectedElementChanging.add((sender: any, options: any) => {
      const newSelectedElement = options.newSelectedElement;
      const parent = newSelectedElement?.parent;
      const isPanel = parent?.isPanel;
      const parentNameIncluded = panelNames.includes(parent?.name);
      const questionType = newSelectedElement?.getType();
      const isQuestionTypeExist = [
        questionTypes.checkbox,
        questionTypes.dropdown,
        questionTypes.tagbox,
        questionTypes.radio,
      ].includes(questionType);

      if (
        [questionTypes.panel, questionTypes.paneldynamic].includes(questionType)
      ) {
        Serializer.findProperty(questionType, "name").displayName =
          "Panel Name";
      } else {
        Serializer.findProperty("question", "name").displayName = "Column Name";
      }

      const isMapQuestion = [
        questionTypes.shapePicker,
        questionTypes.pointPicker,
        questionTypes.drawPolygon,
        questionTypes.dropPin,
        questionTypes.getGpsData,
      ].includes(questionType);

      if (
        newSelectedElement &&
        isPanel &&
        parentNameIncluded &&
        isMapQuestion
      ) {
        options.newSelectedElement = parent;
      } else if (
        newSelectedElement &&
        isPanel &&
        parentNameIncluded &&
        !isQuestionTypeExist &&
        ![questionTypes.text, questionTypes.boolean].includes(questionType)
      ) {
        options.newSelectedElement = lastSelectedDropdownWithinCustomPanel;
      } else if (
        [questionTypes.text, questionTypes.boolean].includes(questionType)
      ) {
        lastSelectedDropdownWithinCustomPanel = null;
      }

      initializePropertyVisibility(
        newSelectedElement,
        newSelectedElement?.toJSON(),
        panelNames,
        Serializer
      );

      if (
        newSelectedElement !== null &&
        panelNames.includes(newSelectedElement.name) &&
        newSelectedElement.isPanel
      ) {
        const panelJson = hardCodePanels.find(
          (panel: any) => panel.name === newSelectedElement.name
        );

        const currentElementJson = newSelectedElement?.jsonObj;
        if (
          currentElementJson &&
          panelNames.includes(currentElementJson.name)
        ) {
          panelQuestionNames.forEach((panelElement: any) => {
            updatePropertyVisibility(
              panelElement,
              currentElementJson,
              Serializer
            );
          });
        }

        newSelectedElement.onPropertyChanged.add(
          (panelSender: any, panelOptions: any) => {
            const findPanelJson = hardCodePanels.find(
              (item) => item.name === panelSender.name
            );
            switch (panelOptions.name) {
              case "requireAll":
                handleRequireAll(
                  panelSender,
                  newSelectedElement,
                  currentElementJson,
                  creator
                );
                break;
              case "showAll":
                const showAll = newSelectedElement.getPropertyValue("showAll");
                handleShowAll(
                  creator,
                  newSelectedElement,
                  panelSender,
                  showAll,
                  findPanelJson,
                  Serializer
                );
                break;
              default:
                handleOtherProperties(
                  creator,
                  newSelectedElement,
                  panelSender,
                  findPanelJson,
                  panelOptions,
                  Serializer
                );
                break;
            }
          }
        );
      }

      if (
        newSelectedElement &&
        newSelectedElement.parent &&
        panelNames.includes(newSelectedElement.parent.name)
      ) {
        if (isMapQuestion) {
          lastSelectedDropdownWithinCustomPanel = parent;
        } else if (isQuestionTypeExist) {
          lastSelectedDropdownWithinCustomPanel = newSelectedElement;
        }
      }
    });

    creator.onDragEnd.add((sender: any, options: any) => {
      const draggedElement = options.draggedElement;
      if (!draggedElement || !options.draggedElement.isPanel) {
        options.allow = true;
        return;
      }

      if (options.draggedElement.toolboxItemIconName !== undefined) {
        return;
      }

      const panelName = options.draggedElement.toolboxItemIconName.replace(
        "icon-",
        ""
      );

      if (
        options.draggedElement &&
        panelName &&
        panelNames.includes(panelName) &&
        draggedElement.isPanel
      ) {
        let panel = draggedElement;
        panel.name = panelName;
        panel.jsonObj.name = panelName;
        const panelJson = hardCodePanels.find(
          (panel: any) => panel.name === panelName
        );
        panel.jsonObj = panelJson;
        // options.draggedElement.setPropertyValue("panelType", "static");

        // if (panel.jsonObj.name === panelsName.HARVEST) {
        //   const retrievalShapePicker = new ShapePickerState({
        //     mapCenterPoint: {
        //       longitude: -145.6433003,
        //       latitude: 65.0710178,
        //     },
        //   });
        //   const struckShapePicker = new ShapePickerState({
        //     mapCenterPoint: {
        //       longitude: -145.6433003,
        //       latitude: 65.0710178,
        //     },
        //   });
        //   const retrievalPointPicker = new PointPickerState({
        //     mapCenterPoint: {
        //       longitude: -145.6433003,
        //       latitude: 65.0710178,
        //     },
        //   });
        //   const struckPointPicker = new PointPickerState({
        //     mapCenterPoint: {
        //       longitude: -145.6433003,
        //       latitude: 65.0710178,
        //     },
        //   });

        //   const harvest = {
        //     id: generateUniqueId("harvest#"),
        //     retrievalShapePicker,
        //     struckShapePicker,
        //     retrievalPointPicker,
        //     struckPointPicker,
        //   };

        //   options.draggedElement._id = harvest.id;
        //   options.draggedElement.setPropertyValue("_id", harvest.id);
        //   dispatch(updateAddedHarvestPanelMapQuestions(harvest));
        // }
        if (panelJson) {
          draggedElement.elements.forEach((element: any, index: number) => {
            const jsonElement = panelJson.elements[index];
            if (jsonElement) {
              element.name = jsonElement.name;

              if (
                (element.getType() === questionTypes.tagbox ||
                  element.getType() === questionTypes.dropdown) &&
                element.choicesByUrl &&
                element.choicesByUrl.url
              ) {
                element.choices = setTheChoicesOfDropdowns(
                  jsonElement.name,
                  dataSourcesData
                );
                element.jsonObj.choices = setTheChoicesOfDropdowns(
                  jsonElement.name,
                  dataSourcesData
                );
              }
            }
          });
        }
        // debugger;
        let updatedJSON = creator.JSON;

        const panelInstances = updatedJSON?.pages?.flatMap((page: any) =>
          page.elements.filter(
            (e: any) => e.type === "panel" && e.name === panel.name
          )
        );

        const isDuplicate = panelInstances.length > 1;

        if (isDuplicate) {
          options.allow = false;
        } else {
          options.allow = true;
        }
      } else {
        options.allow = true;
      }
    });

    creator.onDragStart.add((sender: any, options: any) => {
      const draggedElement = options.draggedElement;
      const target = options.target;

      if (!draggedElement || !options.draggedElement.isPanel) {
        options.allow = true;
        return;
      }

      if (options.draggedElement.toolboxItemIconName !== undefined) {
        return;
      }

      const panelName = options.draggedElement.toolboxItemIconName.replace(
        "icon-",
        ""
      );

      if (
        options.draggedElement &&
        panelName &&
        panelNames.includes(panelName)
      ) {
        let panel = draggedElement;
        panel.name = panelName;
        panel.jsonObj.name = panelName;
        const panelJson = hardCodePanels.find(
          (panel: any) => panel.name === panelName
        );
        panel.jsonObj = panelJson;

        // let updatedJSON = creator.JSON;

        // const panelInstances = updatedJSON.pages.flatMap((page: any) =>
        //   page.elements.filter(
        //     (e: any) => e.type === "panel" && e.name === panel.name
        //   )
        // );

        // const isDuplicate = panelInstances.length > 1;

        // if (isDuplicate) {
        //   const addedPanelIndex = updatedJSON.pages.findIndex(
        //     (page: any) =>
        //       page.elements.findIndex((e: any) => e.name === panel.name) !== -1
        //   );

        //   if (addedPanelIndex >= 0) {
        //     updatedJSON.pages[addedPanelIndex].elements = updatedJSON.pages[
        //       addedPanelIndex
        //     ].elements.filter(
        //       (e: any, index: any) =>
        //         !(e.name === panel.name && index === panelInstances.length - 1)
        //     );
        //     creator.JSON = updatedJSON;
        //   }
        // } else {
        //   options.allow = true;
        // }
        options.allow = true;
      } else {
        // Allow the drop if the above conditions are not met
        options.allow = true;
      }

      options.allow = true;
    });
  }, [creator, dataSourcesData]);

  useEffect(() => {
    return () => {
      dispatch(resetFormDetailsAction());
    };
  }, []);

  useEffect(() => {
    if (!creator) return;

    Serializer.addProperty("checkbox", {
      name: "checkboxLayoutText",
      type: "text",
      category: "layout",
      readOnly: true,
      visibleIndex: 2,
      displayName:
        "Please be aware that column counts larger than two can only be applied to the observer web view. For mobile devices, the maximum amount of columns that can be applied is two.",
      baseClassName: "checkboxLayoutText",
      visibleIf: function (obj) {
        return obj.getType() === "checkbox"; // Only visible for checkbox types
      },
    });
  }, [creator]);

  useEffect(() => {
    if (!creator) return;
    if (formDetails.formFields?.pages) {
      const newJson = structureFormJSON(
        formDetails?.formFields,
        panelsJson,
        [],
        "response",
        dataSourcesData
      );

      creator.JSON = newJson;
    }
  }, [formDetails, creator]);

  if (creator) {
    return (
      <>
        <div
          className="flex-col items-start justify-start w-full "
          style={{ display: doHide ? "none" : "flex" }}
        >
          <Header creator={creator} dataSourcesData={dataSourcesData} />
        </div>
        <div
          className=" flex-col justify-start items-start h-[85.3vh] w-[100%] dynamic-form-surveyjs"
          style={{ display: doHide ? "none" : "flex" }}
        >
          <SurveyCreatorComponent creator={creator} />
          {customSidebarContainer &&
            createPortal(<CustomPropertyGridSidebar />, customSidebarContainer)}
        </div>
        <HandlerComponent />
      </>
    );
  }
  return (
    <div className="loader min-h-[100vh] justify-center items-center flex w-full">
      <TailSpin
        height="50"
        width="50"
        color="#005C89"
        ariaLabel="tail-spin-loading"
        radius="2"
        wrapperStyle={{}}
        wrapperClass="tailspin-loader"
        visible={true}
      />
    </div>
  );
};
function checkElementIsPanel(type: string) {
  const panels = Object.values(panelsName);

  if (panels.includes(type) || questionTypes.paneldynamic === type) {
    return true;
  }

  return false;
}

async function getDataFromSourceUrl(url: string) {
  const { data } = await axios.get(url);

  return data;
}

function updateChoicesByUrl() {
  const formDetsils: IFormDetails =
    store?.getState()?.formSurveyJsDetails?.formDetails;

  const dataSources = [
    "Objects",
    "Species",
    "Categories",
    "Types",
    "Observers",
    "Behaviors",
    "Conditions",
    "stations",
  ];

  const dataSourcesUrls = dataSources.map((source: string) => {
    if (formDetsils.programId) {
      return {
        value: `${baseURL}/common/dropdown/${source.toLowerCase()}?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${formDetsils.programId}`,
        text: source,
      };
    }
    return {
      value: ``,
      text: source,
    };
  });
  return dataSourcesUrls;
}

function setChoicesByUrl(urlKey: string, programId: string | null) {
  return {
    url: `${baseURL}/common/dropdown/${urlKey}?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
    valueName: "value",
    titleName: "text",
  };
}

function addPropertyForRadioCheckBoxesTagboxAndDropdown() {
  const questions = [
    questionTypes.dropdown,
    questionTypes.tagbox,
    questionTypes.radio,
    questionTypes.checkbox,
  ];
  questions.forEach((question) => {
    Serializer.addProperty(question, {
      name: `dataSourceDetached:boolean`,
      default: false,
      visible: false,
      displayName: "dataSourceDetached",
      category: "general",
    });

    Serializer.addProperty(question, {
      name: `dataSourceType`,
      type: "text",
      default: "",
      visible: false,
      displayName: "dataSourceType",
      category: "general",
    });
  });
}

function extractDataSourceType(url: string) {
  try {
    // Create a URL object
    const parsedUrl = new URL(url);

    // Get the pathname from the URL
    const pathname = parsedUrl.pathname;

    // Split the pathname to isolate parts
    const segments = pathname.split("/");

    // The segment after 'dropdown/' is typically the third element after splitting by '/'
    const dataSourceType = segments[segments.indexOf("dropdown") + 1];

    return dataSourceType;
  } catch (error) {
    console.error("Invalid URL:", error);
    return "";
  }
}
