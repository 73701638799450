import Plus2Icon from "assets/icons/HeroIcons/Plus2Icon";
import useCustomBranding from "hooks/useCustomBranding";
import React from "react";

interface AddAnotherAddressButtonProps {
  onClick: () => void;
  title: string;
}

const AddAnotherAddressButton: React.FC<AddAnotherAddressButtonProps> = ({
  onClick,
  title,
}) => {
  const {primaryColor} = useCustomBranding();
  return (
    <button
      onClick={onClick}
      type="button"
      className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 rounded-3xl focus:outline-none"
    >
      <Plus2Icon width={24} height={24} fill={primaryColor} />
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
        <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
          {title}
        </p>
      </div>
    </button>
  );
};

export default AddAnotherAddressButton;
