import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import Roles from "hooks/roles";
import useCustomBranding from "hooks/useCustomBranding";
import usePermissions from "hooks/usePermissions";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { RootState } from "store";
import { updateAuthDataAction } from "store/auth/reducer.actions";
import { getUserProfileAction } from "store/userProfile";
import { UserProfileState } from "store/userProfile/initialState";
import { Toasts } from "view/components/Toasts";

interface DeleteAnAddressBtnProps {
  address: any;
  remove: any;
  cureentAddressIndex: number;
}

export const DeleteAnAddressBtn: React.FC<DeleteAnAddressBtnProps> = ({
  address,
  remove,
  cureentAddressIndex,
}) => {
  const dispatch = useDispatch();
  const { isMyProfile } = Roles();
  const {primaryColor} = useCustomBranding();

  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const { profile } = usePermissions();
  const isAddressExist = userProfile.addresses.find(
    (item) => item.id === address.id
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveAddress = async (cureentAddressIndex: number) => {
    setIsLoading(true);
    try {
      if (isAddressExist && profile.canEditDeleteAddressProfile) {
        const res = await apiLibrary.userProfile.deleteAddress([
          isAddressExist.id,
        ]);
        if (isMyProfile(res.data?.personId)) {
          dispatch(updateAuthDataAction(res.data));
        }
        dispatch(getUserProfileAction(res.data));
        // Display a success message using res
        Toasts.success(res.message);
      }
      remove(cureentAddressIndex);
    } catch (error: any) {
      // Handle the error if needed
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-start flex-grow-0 flex-shrink-0 gap-4">
      {profile.canEditDeleteAddressProfile && (
        <button
          type="button"
          disabled={address.isPrimary === true || isLoading}
          onClick={() => handleRemoveAddress(cureentAddressIndex)}
          className="flex justify-center items-center disabled:cursor-not-allowed flex-grow-0 flex-shrink-0 relative gap-1 rounded-3xl focus:outline-none"
          title="Delete"
          aria-label="Delete"
        >
          <TrashIcon width={24} height={24} fill={primaryColor} />
        </button>
      )}
    </div>
  );
};
