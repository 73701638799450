import React, { useState } from 'react';
/************ hooks ************/
import useDateFormatter from 'hooks/formatDateWithPattern';
/************ External Library ************/
import { FormikProps, FormikValues } from 'formik';
/************ Styles ************/
// import "./TextInputs.scss";
import ImagePicker from 'Components/ObservationProfile/ImagePicker';
import { snakeToCamel } from 'utils/caseConvertor';
import {
  Dropdown,
  MultiselectDropdown,
  TextField,
  RadioButtonGroup,
  Checkboxes,
  FileUpload,
} from '../Components';
import { conditionalityForObservations } from 'utils/conditionalityForObservations';
import {
  DrawPolygon,
  DropPin,
  PointPicker,
  ShapePicker,
  GetGpsData,
} from '../MapComponents';
import questionTypes from 'view/pages/Forms/EditFormDetails/Components/SurveyJs/questionTypes';
import GooglePlacesAutocomplete from '../Components/LocationInput';
import AudioRecorderComponent from '../Components/Audio';
import { evaluateCondition, parseCondition } from '../utilities/conditionality';

/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */
interface PanelComponentProps extends FormikProps<FormikValues> {
  panel: any; // replace 'any' with the appropriate type for the 'panel' prop'
  editMode: boolean;
  formikProps: any;
  error?: any;
  handleUpdateStatus: any;
}

const PanelComponent: React.FC<PanelComponentProps> = ({
  panel,
  editMode,
  formikProps,
  setFieldValue,
  handleBlur,
  touched,
  errors,
  error,
  values,
  handleUpdateStatus,
  ...restProps
}) => {
  const panelElements =
    panel.templateElements && panel.templateElements.length > 0
      ? panel.templateElements
      : panel.elements && panel.elements.length > 0
        ? panel.elements
        : [];

  const panelName = snakeToCamel(panel.name ?? '');

  const { formatDate } = useDateFormatter();

  // Image click handler
  // const handleImageClick = (name: string, value: string) => {
  //   if (editMode) {
  //     setFieldValue(name, value);
  //   }
  // };

  const onChange = (eleName: string, value: any, name: string) => {
    setFieldValue(eleName, value);
    handleUpdateStatus(name, panel.sectionId);
  };

  return (
    <div className='flex flex-col flex-grow w-full rounded-lg dark:bg-secondaryLight'>
      <div className='relative flex flex-col gap-4 py-2 mb-5'>
        {panelElements.length > 0 &&
          panelElements.map((element: any, index: number) => {
            const {
              name,
              title,
              fieldAs,
              visible = true,
              isRequired = false,
              rows,
              value,
              inputType,
              type,
              placeholder,
              disabled,
              max,
              labelTrue,
              labelFalse,
              choices,
              choicesByUrl,
              visibleIf,
              enableIf,
              setValueIf,
              setValueExpression,
              requiredIf,
              id,
              select_multiple,
              selected_layers,
              selected_shapes,
              answer,
              description,
              defaultValue,
              multiSelect,
              colCount,
              isUpdated,
              maskType,
              maskSettings,
            } = element;
            const eleName = [panelName, element.name].join('.');
            const error = formikProps.errors[panelName]?.[element.name];
            const touched = formikProps.touched[panelName]?.[name];

            if (visible === false || type === 'html' || defaultValue) {
              return;
            }

            if (visibleIf) {
              const result = parseCondition(element);

              if (result) {
                // Evaluate the condition
                const isConditionMet = evaluateCondition(formikProps?.values, result);

                if (!isConditionMet) {
                  return;
                }
              }
            }

            switch (type) {
              case 'dropdown':
              case questionTypes.regionPicker:
                return (
                  <div key={index}>
                    <Dropdown
                      key={index}
                      label={title || name}
                      name={name}
                      choicesByUrl={choicesByUrl}
                      choices={choices}
                      value={formikProps.values[panelName]?.[name]}
                      handleChange={(item: any) =>
                        onChange(eleName, item, name)
                      }
                      handleBlur={() =>
                        formikProps.setFieldTouched(eleName, true, true)
                      }
                      editMode={editMode}
                      placeholder={placeholder || 'Select'}
                      required={isRequired}
                      error={error}
                      touched={touched}
                      visibleIf={visibleIf}
                      answers={formikProps.values[panelName]}
                      enableIf={enableIf}
                      setValueIf={setValueIf}
                      setValueExpression={setValueExpression}
                      requiredIf={requiredIf}
                      panel={panel}
                      element={element}
                    />
                  </div>
                );

              case 'tagbox':
                return (
                  <div key={index}>
                    <MultiselectDropdown
                      name={name}
                      data={choices}
                      label={title || name}
                      choicesByUrl={choicesByUrl}
                      placeholder={placeholder || `Select ${title ?? ''}`}
                      editMode={editMode}
                      value={formikProps.values[panelName]?.[name]}
                      handleChange={(selectedOptions: any) => {
                        const selectedValues = selectedOptions.map(
                          (option: any) => option
                        );
                        onChange(eleName, selectedValues, name);
                      }}
                      handleBlur={() =>
                        formikProps.setFieldTouched(eleName, true, true)
                      }
                      required={isRequired}
                      error={error}
                      touched={touched}
                      visibleIf={visibleIf}
                      answers={formikProps.values[panelName]}
                      enableIf={enableIf}
                      setValueIf={setValueIf}
                      setValueExpression={setValueExpression}
                      requiredIf={requiredIf}
                      panel={panel}
                      element={element}
                    />
                  </div>
                );

              case 'text':
                return (
                  <TextField
                    key={index}
                    label={title || name}
                    type={inputType || type}
                    name={name}
                    value={formikProps.values[panelName]?.[name]}
                    handleChange={(value: any) =>
                      onChange(eleName, value, name)
                    }
                    handleBlur={() =>
                      formikProps.setFieldTouched(eleName, true, true)
                    }
                    fieldAs={fieldAs}
                    rows={rows}
                    editMode={editMode}
                    placeholder={value}
                    required={isRequired}
                    error={error}
                    touched={touched}
                    visibleIf={visibleIf}
                    answers={formikProps.values[panelName]}
                    enableIf={
                      enableIf !== null
                        ? conditionalityForObservations(
                          enableIf,
                          formikProps.values[panelName],
                          'enableIf'
                        )
                        : false
                    }
                    setValueIf={setValueIf}
                    setValueExpression={setValueExpression}
                    requiredIf={requiredIf}
                    isUpdated={isUpdated}
                    maskType={maskType}
                    maskSettings={maskSettings}
                    panel={panel}
                    element={element}
                  />
                );

              case 'radiogroup':
                return (
                  <div key={index}>
                    <RadioButtonGroup
                      key={index}
                      label={title || name}
                      name={eleName}
                      editMode={editMode}
                      value={formikProps.values[panelName]?.[name]}
                      handleChange={(item: any) => {
                        onChange(eleName, item, name);
                      }}
                      required={isRequired}
                      data={choices}
                      error={error}
                      touched={touched}
                      choicesByUrl={choicesByUrl}
                      visibleIf={visibleIf}
                      answers={formikProps.values[panelName]}
                      enableIf={enableIf}
                      setValueIf={setValueIf}
                      setValueExpression={setValueExpression}
                      requiredIf={requiredIf}
                      columns={colCount}
                      panel={panel}
                      element={element}
                    />
                  </div>
                );

              case 'boolean':
                return (
                  <div key={index}>
                    <RadioButtonGroup
                      key={index}
                      label={title || name}
                      name={eleName}
                      editMode={editMode}
                      value={formikProps.values[panelName]?.[name]}
                      required={isRequired}
                      handleChange={(item: any) =>
                        onChange(eleName, item, name)
                      }
                      data={[labelTrue, labelFalse]}
                      error={error}
                      touched={touched}
                      visibleIf={visibleIf}
                      answers={formikProps.values[panelName]}
                      enableIf={enableIf}
                      setValueIf={setValueIf}
                      setValueExpression={setValueExpression}
                      requiredIf={requiredIf}
                      panel={panel}
                      element={element}
                    />
                  </div>
                );

              case 'checkbox':
                return (
                  <div key={index}>
                    <Checkboxes
                      key={index}
                      data={choices}
                      choicesByUrl={choicesByUrl}
                      handleChange={(item: any) => {
                        onChange(eleName, item, name);
                      }}
                      value={formikProps.values[panelName]?.[name]}
                      name={eleName}
                      label={title || name}
                      required={isRequired}
                      editMode={editMode}
                      error={error}
                      touched={touched}
                      visibleIf={visibleIf}
                      enableIf={enableIf}
                      answers={formikProps.values[panelName]}
                      setValueIf={setValueIf}
                      setValueExpression={setValueExpression}
                      requiredIf={requiredIf}
                      columns={colCount}
                      panel={panel}
                      element={element}
                    />
                  </div>
                );

              case 'comment':
                return (
                  <div className='col-span-12'>
                    <TextField
                      key={index}
                      label={title || name}
                      type='text'
                      name={name}
                      value={formikProps.values[panelName]?.[name]}
                      handleChange={(value: any) =>
                        onChange(eleName, value, name)
                      }
                      handleBlur={() =>
                        formikProps.setFieldTouched(eleName, true, true)
                      }
                      fieldAs='textarea'
                      rows={rows}
                      editMode={editMode}
                      placeholder={placeholder || 'Type'}
                      required={isRequired}
                      error={error}
                      touched={touched}
                      visibleIf={visibleIf}
                      answers={formikProps.values[panelName]}
                      enableIf={enableIf}
                      setValueIf={setValueIf}
                      setValueExpression={setValueExpression}
                      requiredIf={requiredIf}
                      panel={panel}
                      element={element}
                    />
                  </div>
                );

              case 'geocoder':
                return (
                  <div className='col-span-12'>
                    <GooglePlacesAutocomplete
                      label={title || name}
                      error={errors?.address}
                      editMode={editMode}
                      onBlur={handleBlur}
                      placeholder='Geocode'
                      handleAddressSelect={(address: any) => {
                        onChange(eleName, address, name);
                      }}
                      AddressValue={formikProps.values[panelName]?.[name]}
                      handleLatitudeAndLongitude={(
                        longitude: any,
                        latitude: any
                      ) => {
                        // setFieldValue("location.lat", latitude);
                        // setFieldValue("location.long", longitude);
                      }}
                      required={isRequired}
                      panel={panel}
                      element={element}
                    />
                  </div>
                );

              case 'audio':
                return (
                  <div className='col-span-12'>
                    <AudioRecorderComponent
                      deleteAudio={() => {
                        onChange(eleName, { url: '', transcription: '' }, name);
                      }}
                      setAudio={(audioSrc: any) => {
                        onChange(eleName, audioSrc, name);
                      }}
                      editMode={editMode}
                      label={title || name}
                      required={isRequired}
                      error={error}
                      panel={panel}
                      element={element}
                      placeholder={placeholder}
                      key={index}
                      name={eleName}
                      value={formikProps.values[panelName]?.[name]}
                      formikProps={formikProps}
                      touched={touched}
                    />
                  </div>
                );

              case 'imagepicker':
                return (
                  <ImagePicker
                    choices={choices}
                    value={formikProps.values[panelName]?.[name]}
                    label={title || name}
                    name={eleName}
                    editMode={editMode}
                    handleClick={(link) => {
                      if (editMode) {
                        onChange(eleName, link, name);
                      }
                    }}
                    required={isRequired}
                    error={error}
                    touched={touched}
                    visibleIf={visibleIf}
                    answers={formikProps.values[panelName]}
                    enableIf={enableIf}
                    setValueIf={setValueIf}
                    setValueExpression={setValueExpression}
                    requiredIf={requiredIf}
                    multiSelect={multiSelect}
                    panel={panel}
                    element={element}
                  />
                );
              case 'file':
                return (
                  <FileUpload
                    choices={choices}
                    files={formikProps.values[panelName]?.[name]}
                    choicesByUrl={choicesByUrl}
                    name={eleName}
                    editMode={editMode}
                    handleUploadFile={(file: File[]) =>
                      onChange(eleName, file, name)
                    }
                    required={isRequired}
                    error={error}
                    touched={touched}
                    visibleIf={visibleIf}
                    enableIf={enableIf}
                    setValueIf={setValueIf}
                    setValueExpression={setValueExpression}
                    requiredIf={requiredIf}
                    acceptedTypes={null}
                    label={title || name}
                    panel={panel}
                    element={element}
                  />
                );

              case questionTypes.shapePicker:
                return (
                  <ShapePicker
                    editMode={editMode}
                    eleName={eleName}
                    answer={formikProps.values[panelName]?.[name]}
                    element={element}
                    setFieldValue={(name: string, value: any) =>
                      onChange(eleName, value, name)
                    }
                    label={title || name}
                    required={isRequired}
                    error={error}
                    touched={touched}
                  />
                );
              case questionTypes.pointPicker:
                return (
                  <PointPicker
                    editMode={editMode}
                    eleName={eleName}
                    answer={formikProps.values[panelName]?.[name]}
                    element={element}
                    setFieldValue={(name: string, value: any) =>
                      onChange(eleName, value, name)
                    }
                    label={title || name}
                    required={isRequired}
                    error={error}
                    touched={touched}
                  />
                );

              case questionTypes.drawPolygon:
                return (
                  <DrawPolygon
                    editMode={editMode}
                    eleName={eleName}
                    answer={formikProps.values[panelName]?.[name]}
                    element={element}
                    setFieldValue={(name: string, value: any) =>
                      onChange(eleName, value, name)
                    }
                    label={title || name}
                    required={isRequired}
                    error={error}
                    touched={touched}
                  />
                );

              case questionTypes.dropPin:
                return (
                  <DropPin
                    editMode={editMode}
                    eleName={eleName}
                    answer={formikProps.values[panelName]?.[name]}
                    element={element}
                    setFieldValue={(name: string, value: any) =>
                      onChange(eleName, value, name)
                    }
                    label={title || name}
                    required={isRequired}
                    error={error}
                    touched={touched}
                  />
                );
              case questionTypes.getGpsData:
                return (
                  <GetGpsData
                    formikProps={formikProps}
                    editMode={editMode}
                    eleName={eleName}
                    answer={formikProps.values[panelName]?.[name]}
                    element={element}
                    setFieldValue={(eleName: string, value: any, name: any) =>
                      onChange(eleName, value, name)
                    }
                    label={title || name}
                    required={isRequired}
                    error={error}
                    touched={touched}
                  />
                );
              default:
                return (
                  <div className='relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0'>
                    <p className='w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-left font-normal text-textAnswer dark:text-textMain '>
                      {type === 'date'
                        ? formatDate(value === undefined ? ' ' : value)
                        : value}
                    </p>
                  </div>
                );
            }
          })}
      </div>
    </div>
  );
};

export default PanelComponent;
