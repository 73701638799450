import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import useCustomBranding from "hooks/useCustomBranding";

interface CustomizedSwitchProps extends SwitchProps {
  label?: string;
  labelClassName?: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: any;
  labelPlacement?: "bottom" | "top" | "end" | "start" | undefined;
  primaryColor?: string; // Added primaryColor here
}

const IOSSwitch = styled(
  ({
    label,
    checked,
    onChange,
    labelPlacement = "start",
    primaryColor, // Use primaryColor here
    ...props
  }: CustomizedSwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      checked={checked}
      onChange={onChange}
      {...props}
    />
  )
)(({ theme, primaryColor }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? primaryColor : primaryColor,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const CustomizedSwitches: React.FC<CustomizedSwitchProps> = (props) => {
  const {
    label,
    checked,
    onChange,
    sx,
    labelClassName = "",
    labelPlacement = "start",
    ...switchProps
  } = props;
  
  const { primaryColor } = useCustomBranding();

  return (
    <FormGroup>
      <FormControlLabel
        labelPlacement={labelPlacement}
        control={
          <IOSSwitch
            labelPlacement={labelPlacement}
            sx={{ m: 1 }}
            checked={checked}
            onChange={onChange}
            primaryColor={primaryColor} // Pass primaryColor here
            {...switchProps}
          />
        }
        label={
          <div className="flex items-center justify-start flex-grow py-2 pl-2">
            <div className="flex justify-start items-center flex-grow relative py-0.5">
              <p className={`${labelClassName} text-[15px]`}>{label}</p>
            </div>
          </div>
        }
        sx={sx}
      />
    </FormGroup>
  );
};
