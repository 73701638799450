import { Formik } from "formik";
// Components
import Button from "view/components/Button";
import { Toasts } from "view/components/Toasts";
import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiLibrary from "services/api";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { EntityType } from "Components/Geography/SideBar/hooks/useFetchGlobalAndCommunityVectorLayers";
import ReferenceVectorLayersList from "../../ReferenceVectorLayersList";
import usePermissions from "hooks/usePermissions";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import structureFormJSON from "../../../../SurveyJs/utils/structureFormJSON";
import { dynamicFormsStatusAction } from "store/filters";
import { useFetchGlobalAndCommunityVectorLayers } from "../../../hooks/useFetchGlobalAndCommunityVectorLayers";
import questionTypes from "../../../../SurveyJs/questionTypes";
import { getSelectedShapesAndDefaultLayers } from "..";
import _ from "lodash";

const initialValues = {
  vectorLayers: [],
  mapData: null,
};

interface SelectFormInterface {
  height: string;
  dataSourcesData: any;
}

export const SelectForm = ({
  height,
  dataSourcesData,
}: SelectFormInterface) => {
  const [page, setPage] = useState({
    vector: 1,
    publicVector: 1,
  });
  const [entityType, setEntityType] = useState<EntityType>(null);
  const [currentOpenToggle, setCurrentOpenToggle] = useState<
    "vector" | "publicVector" | null
  >(null);

  const surveyState = useSelector((state: RootState) => state.surveyJS);
  const { formDetails, status } = useSelector(
    (state: RootState) => state.formSurveyJsDetails
  );

  const {
    communityLayersLoading,
    communityVectorLayers,
    globalLayersLoading,
    globalVectorLayers,
  } = useFetchGlobalAndCommunityVectorLayers({
    vectorLayerType: "polygon",
    entityType,
    currentOpenToggle,
    setCurrentOpenToggle,
    page,
  });

  const { geography, formJson } = useSelector<
    RootState,
    I_InitialStateOfSurveyJS
  >((state) => state.surveyJS);

  const { choosedLayers } = geography;

  const { communities, dynamicForms } = usePermissions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // handlers
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    if (!dynamicForms.canEditDynamicFormFields) {
      return;
    }

    if (choosedLayers.length === 0 && !surveyState.formJson.disabledOffline) {
      setFieldError("mapData", "Select a shape from map!");
      return;
    }

    try {
      const {
        shapePickers,
        pointPickers,
        drawPolygons,
        dropPins,
        getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      } = surveyState;

      const { json, panelJson, status, disabledOffline } = formJson;

      const dataStoredInGlobalState = [
        ...shapePickers,
        ...pointPickers,
        ...dropPins,
        ...drawPolygons,
        ...getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      ];

      let choosedVectorLayers: any = [];

      // form_area.features.forEach((f) => {
      //   const vectorLayerId = values.vectorLayers.find((id: any) =>
      //     f.id.includes(id)
      //   );

      //   if (vectorLayerId && !choosedVectorLayers.includes(vectorLayerId)) {
      //     choosedVectorLayers.push(vectorLayerId);
      //   }
      // });

      const { updatedFormArea, layerIds } =
        extractUniqueNumericLayerIds(choosedLayers);

      const form_area = {
        type: "FeatureCollection",
        features: updatedFormArea,
      };
      let payload;
      if (form_area.features.length === 0) {
        payload = {
          form_fields: structureFormJSON(
            json,
            panelJson,
            dataStoredInGlobalState,
            "request",
            dataSourcesData
          ),
          offline_disabled: disabledOffline,
          status,
        };
      } else {
        payload = {
          form_fields: structureFormJSON(
            json,
            panelJson,
            dataStoredInGlobalState,
            "request",
            dataSourcesData
          ),
          form_area: form_area,
          form_area_vector_layer_ids: layerIds,
          form_area_mode: "select",
          offline_disabled: disabledOffline,
          status,
        };
      }

      // const {selectedDefaultLayers,selectedShapes} = getSelectedShapesAndDefaultLayers(payload.form_fields);

      // payload.selected_layers_ids = selectedDefaultLayers;
      // payload.selected_options = selectedShapes;

      const res = await apiLibrary.Forms.updateFormFields(
        formDetails.id,
        payload
      );

      Toasts.success(res.message);
      dispatch(dynamicFormsStatusAction(status ?? ""));
      navigate("/forms/list");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };
  const handleSelectVectorLayer = (
    e: any,
    item: any,
    values: any,
    setFieldValue: any
  ) => {
    const layerId = parseInt(e.target.value);

    const isLayerIdExist = (values.vectorLayers as number[]).includes(layerId);

    setFieldValue(
      "vectorLayers",
      isLayerIdExist
        ? values.vectorLayers.filter((layer: any) => layer !== layerId)
        : [...values.vectorLayers, layerId]
    );
  };
  const handleScrollEnd = (type: EntityType) => {
    const totalPages =
      type === "vector"
        ? communityVectorLayers.totalPages
        : globalVectorLayers.totalPages;
    if (page && type && totalPages >= page[type] + 1) {
      setPage((prev: any) => ({
        ...prev,
        [type]: prev[type] + 1,
      }));

      if (entityType !== type) {
        setEntityType(type);
      }
    }
  };
  const handleClickOnTogglerBtn = (type: EntityType) => {
    if (currentOpenToggle !== type) setCurrentOpenToggle(type);
  };
  const handleSubmitFormWithoutFormArea = async (
    setSubmitting: Dispatch<SetStateAction<boolean>> | any
  ) => {
    if (!dynamicForms.canEditDynamicFormFields) {
      return;
    }
    setSubmitting(true);
    try {
      const {
        shapePickers,
        pointPickers,
        drawPolygons,
        dropPins,
        getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      } = surveyState;

      const { json, panelJson, status, disabledOffline } = formJson;

      const dataStoredInGlobalState = [
        ...shapePickers,
        ...pointPickers,
        ...dropPins,
        ...drawPolygons,
        ...getGpsDatas,
        harvestPanelMapQuestions,
        geographicalPanelMapQuestions,
      ];

      const payload: any = {
        form_fields: structureFormJSON(
          json,
          panelJson,
          dataStoredInGlobalState,
          "request",
          dataSourcesData
        ),
        offline_disabled: disabledOffline,
        status,
      };

      // const {selectedDefaultLayers,selectedShapes} = getSelectedShapesAndDefaultLayers(payload.form_fields);

      // payload.selected_layers_ids = selectedDefaultLayers;
      // payload.selected_options = selectedShapes;

      const res = await apiLibrary.Forms.updateFormFields(
        formDetails.id,
        payload
      );

      Toasts.success(res.message);
      dispatch(dynamicFormsStatusAction(status ?? ""));
      navigate("/forms/list");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
      {({
        values,
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <p className="text-[15px] text-textMidLight italic mb-6 dark:text-textMain ">
              Turn on the vector layers from which you want to select the
              regions in which the form should be available for offline use to
              observers. Click on a polygon to highlight it as one of the areas
              in which the form should be available when observers use the app
              offline.
            </p>
            <div className="flex flex-col justify-start overflow-y-auto overflow-x-hidden md:max-h-[28vh] xl:max-h-[45vh] 2xl:max-h-[52vh] ">
              {errors.vectorLayers && (
                <p className="flex-grow text-xs text-left capitalize text-accent_1Dark">
                  {errors.vectorLayers}
                </p>
              )}
              {errors.mapData && (
                <p className="flex-grow text-xs text-left capitalize text-accent_1Dark">
                  {errors.mapData}
                </p>
              )}
              {/* <div> */}
              <ReferenceVectorLayersList
                selectLayersHandler={(e: any, item: any) =>
                  handleSelectVectorLayer(e, item, values, setFieldValue)
                }
                layerIds={values.vectorLayers}
                layers={globalVectorLayers.vector}
                heading={"Select from Global layers"}
                message="Create vector layers first from the Geography"
                loading={globalLayersLoading}
                type="publicVector"
                newItemType="region"
                layerRenderingMode="paint"
                handleScrollEnd={handleScrollEnd}
                doShowDataList={true}
                handleClickOnTogglerBtn={handleClickOnTogglerBtn}
                height={"auto"}
              />
              <ReferenceVectorLayersList
                selectLayersHandler={(e: any, item: any) =>
                  handleSelectVectorLayer(e, item, values, setFieldValue)
                }
                layerIds={values.vectorLayers}
                layers={communityVectorLayers.vector}
                heading={"Select from Community layers"}
                message="Create vector layers first from the Listing section"
                loading={communityLayersLoading}
                type="vector"
                newItemType="region"
                layerRenderingMode="paint"
                handleScrollEnd={handleScrollEnd}
                doShowDataList={true}
                height={"auto"}
                handleClickOnTogglerBtn={handleClickOnTogglerBtn}
              />
            </div>

            <div className="flex justify-between pt-2 gap-2 pb-2 absolute  bottom-[-15px] w-full p-4 bg-bgWhite">
              <Button
                disabled={
                  isSubmitting ||
                  formJson?.status === "published" ||
                  !dynamicForms.canEditDynamicFormFields
                }
                type="button"
                text="Skip"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className={`px-5 py-2 w-[48.5%] ${surveyState.formJson?.disabledOffline && "z-50"} `}
                width="[48.t%]"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={() => handleSubmitFormWithoutFormArea(setSubmitting)}
              />
              <Button
                type="submit"
                text="Save"
                disabled={
                  isSubmitting || !dynamicForms.canEditDynamicFormFields
                }
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className={`px-5 py-2 w-[48.5%] ${surveyState.formJson?.disabledOffline && "z-50"} `}
                width="[48.5%]"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
              />
            </div>
            {/* </div> */}
          </form>
        );
      }}
    </Formik>
  );
};

function extractUniqueNumericLayerIds(features: any[]) {
  const layerIds = _.map(features, (feature) => {
    const match = feature.layer?.oldLayerId?.includes("region#")
      ? feature.layer.oldLayerId.match(/\d+/g)
      : feature.layer.id.match(/\d+/g);
    return match ? Number(match[0]) : null;
  });
  const updatedFormArea = features.map((feature: any) => {
    const updatedItem = { ...feature };
    feature.layer.id = feature.layer?.oldLayerId?.includes("region#")
      ? feature.layer.oldLayerId
      : feature.layer.id;

    return feature;
  });

  return { updatedFormArea, layerIds: _.uniq(layerIds).filter(Boolean) };
}
