import React, { useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FormikProps, FormikValues } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { DarkMode } from "store/darkMode/initialState";
import "./custom.css";

interface TextEditorProps extends FormikProps<FormikValues> {
  errors: any;
}

export const TextEditor: React.FC<TextEditorProps> = React.memo(
  ({ values, setFieldValue, errors, touched }) => {
    const quillRef = useRef<any>(null);
    const { isDarkMode } = useSelector<RootState, DarkMode>(
      (state) => state?.darkMode
    );

    useEffect(() => {
      // Apply dark mode styling if needed
      if (quillRef.current) {
        const editorElement = quillRef.current?.getEditor().container as any;
        if (isDarkMode) {
          editorElement.classList.add('ql-dark-theme');
        } else {
          editorElement.classList.remove('ql-dark-theme');
        }
      }
    }, [isDarkMode]);

    const handleEditorChange = (content: any) => {
      setFieldValue("policyContent", content);
    };

    // Define the formats we want to support
    const formats = [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image', 'color', 'background',
      'align', 'script', 'code-block'
    ];

    // Define the toolbar options
    const modules = {
      toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        ['link'],
      ],
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true
      },
    };

    // Custom CSS to handle dark mode
    const editorStyle = {
      height: '300px',
      marginBottom: '20px',
    };

    return (
      <>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={values?.policyContent || ''}
          onChange={handleEditorChange}
          formats={formats}
          modules={modules}
          style={editorStyle}
          placeholder="Add your description...."
        />

        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
          {touched.policyContent && errors.policyContent && (
            <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark mb-2">
              {errors.policyContent}
            </p>
          )}
        </div>
      </>
    );
  }
);