import React from "react";
import customBrand404 from "assets/images/custom-brand-404.png";
import { useNavigate } from "react-router-dom";

const NoBrandFound = ({ showBtn }: { showBtn: boolean }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <img src={customBrand404} alt="custom-brand-404" />
      <p className="w-[125px] h-[38px] bg-[#e2e2e2] rounded-md font-bold text-center border border-[#b3b3b3] mt-5 mb-0 leading-[33px]">
        404 error
      </p>
      <h2 className="text-[#2C3236] text-3xl pt-8 font-bold">
        We’ve Lost this page
      </h2>
      <p className="text-[#2C3236] pt-2">
        Sorry, the page you’re looking for doesn’t exist or may have been moved.
      </p>
      {showBtn && (
        <button
          type="button"
          onClick={() => navigate("/")}
          className="px-5 pb-[3px] mt-8 bg-[#6F747B] outline-[#6F747B] text-[#ffffff] w-full max-w-[350px] h-[50px] border-0 rounded-full font-semibold"
        >
          Go back to the homepage
        </button>
      )}
    </div>
  );
};

export default NoBrandFound;
