import { apiClient } from "../config";
import Cookies from "universal-cookie";

async function logout(token: any) {
  const res = await apiClient.get(
    "/admin/logout", {
      headers: {
        refresh_token: token,
      },
    }
  );
  return res.data;
}

export default logout;
