import React, { useEffect } from "react";
import { PageHeadingProps } from "../../types";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { RootState } from "store";
import { IProgram } from "store/programProfile/initialState";
import { fetchProgramProfileAction } from "store/programProfile/reducer.actions";
import Head from "view/components/Head";
import useCustomBranding from "hooks/useCustomBranding";

const PageHeading = ({ programId, name }: PageHeadingProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );
  const {getBrandPlaceholder} = useCustomBranding();

  useEffect(() => {
    dispatch(fetchProgramProfileAction(programId));
  }, []);

  return (
    <div className="flex flex-col justify-start items-start flex-grow py-0.5">
      <Head title={programId !== "" && name ? `Program ${name}: Data Access Policy` : "Data Access Policy"} />
      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="rounded-full w-8 h-8 mr-3">
          <img
            src={programProfile?.profileImage || getBrandPlaceholder("profile")}
            alt=" "
            className="w-8 h-8 rounded-full"
          />
        </div>

        <p className="break-all w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
          {programId !== "" && name ? (
            <>{name}: Data Access Policy</>
          ) : (
            "Data Access Policy"
          )}
        </p>
      </div>
    </div>
  );
};

export { PageHeading };
