import React, { useState, useRef, useEffect } from "react";
import { BellIcon, MoonIcon } from "assets/icons/HeroIcons";
import { SideBarRoutes, SideBarRoutesForEndDataViewer } from "./SideBarMenu";
import { RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import Plus2Icon from "assets/icons/HeroIcons/Plus2Icon";
import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import LogoutIcon from "assets/icons/HeroIcons/LogoutIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import QuestionMarkCircleIcon from "assets/icons/HeroIcons/QuestionMarkCircleIcon";
import SidebarDropdown from "./SidebarDropdown";
import {
  openNotificationsCenterModalAction,
  openSignOutModal,
} from "store/modals/reducer.actions";
import useRoles from "hooks/roles";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { toggleDarkMode } from "store/darkMode/reducer.actions";
import { DarkMode } from "store/darkMode/initialState";
import { CustomizedSwitches } from "../CustomizedSwitches";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { SideNav } from "store/sideBar/initialState";
import { toggleSidebar } from "store/sideBar/reducer.actions";
import GlobalSearch from "./components/Search";
import { IfetchNotifications } from "store/notificationCenter/initialState";
import { fetchNotificationCenterAction } from "store/notificationCenter";
import usePermissions from "hooks/usePermissions";
import PrivacyPolicyIcon from "assets/icons/CustomIcons/PrivacyPolicyIcon";
import SIGNLogo from "assets/logo/SIGNLogo";
import ISNSidebarLogo from "assets/logo/ISNSidebarLogo";
import useCustomBranding from "hooks/useCustomBranding";
interface IProps {
  handleToggleButton?: () => void;
}

const SideBar: React.FC<IProps> = ({ handleToggleButton }) => {
  const auth = useSelector((state: RootState) => state.auth);
  const { isExternalReviewer, isExternalDataEndReviewer } = useRoles();
  const [hideButton, setHideButton] = useState(false);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const routes = SideBarRoutes();
  const { notifications, users } = usePermissions();
  const routesForEndDataViewers = SideBarRoutesForEndDataViewer();
  const { data } = useSelector<RootState, IfetchNotifications>(
    (state) => state.notificationCenter
  );
  // DISPATCH HOOK
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );
  const { isCollapsed } = useSelector<RootState, SideNav>(
    (state) => state?.sideBar
  );
  const { theme, DefaultLogo, getBrandPlaceholder, isThemeLoaded } =
    useCustomBranding();
  const location = useLocation();

  // Add logic to determine active state based on the location.pathname
  const isActive = (routeLink: string) => {
    return location.pathname?.split("/")[1] === routeLink?.split("/")[1];
  };

  const handleHideButton = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHideButton(true);
  };
  const handleOutsideClick = (event: MouseEvent) => {
    setHideButton(false);
  };

  useEffect(() => {
    const eventListener = (event: Event) =>
      handleOutsideClick(event as MouseEvent);
    document.addEventListener("click", eventListener);

    return () => {
      document.removeEventListener("click", eventListener);
    };
  }, []);
  useEffect(() => {
    if (auth.accessToken && notifications.canViewNotifications) {
      dispatch(fetchNotificationCenterAction());
    }
  }, [data.unreadCount]);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar(isCollapsed ? false : true));
  };
  const renderSubMenu = (subMenu: any, parentIndex: number) => {
    return (
      <SubMenu
        key={parentIndex}
        label={subMenu.text}
        icon={subMenu.icon}
        rootStyles={{
          [".ps-submenu-content"]: {
            backgroundColor: `${isDarkMode && "#21262D"}`,
          },
          [".css-honxw6"]: {
            width: "9px",
            height: "9px",
          },
          [".css-jn69v9"]: {
            width: "9px",
            height: "9px",
          },
        }}
        className=" dark:text-inputText dark:hover:text-textLink"
      >
        {subMenu.children.map((child: any, childIndex: number) => {
          if (child.hasChildren) {
            return renderSubMenu(child, childIndex);
          }
          return (
            <MenuItem
              key={childIndex}
              component={child.link ? <Link to={child.link} /> : undefined}
              icon={child.icon}
              className={`${child.isActive &&
                "bg-primaryExtraLight   text-textLink dark:text-textLink font-semibold "
              }  hover:bg-primaryExtraLight hover:text-textLink  dark:text-inputText dark:hover:text-textLink `}
            >
              {child.text}
            </MenuItem>
          );
        })}
      </SubMenu>
    );
  };

  const renderMenuItem = (route: any, index: number) => {
    if (route.hasChildren) {
      return renderSubMenu(route, index);
    } else {
      return (
        <MenuItem
          key={index}
          component={route.link ? <Link to={route.link} /> : undefined}
          icon={route.icon}
          className={`${route.isActive &&
            "bg-primaryExtraLight text-textLink dark:text-textLink font-semibold fill-primary"
          } hover:bg-primaryExtraLight hover:text-textLink  dark:text-inputText dark:hover:text-textLink `}
        >
          {route.text}
        </MenuItem>
      );
    }
  };

  return (
    <>
      {isExternalDataEndReviewer() ? (
        <Sidebar
          collapsed={isCollapsed}
          backgroundColor={`${isDarkMode && "#21262D"}`}
          width="235px"
        >
          <Menu closeOnClick={true}>
            <div
              className={`w-full mt-2 ${isCollapsed ? "flex-col" : "flex justify-between"
              }`}
            >
              <div
                className={`${isCollapsed ? "flex-col" : " flex justify-between "}`}
              >
                <MenuItem
                  onClick={handleToggleSidebar}
                  icon={
                    <Bars3Icon
                      width={28}
                      height={28}
                      className="dark:stroke-caption"
                    />
                  }
                />
              </div>
            </div>

            <MenuItem
              icon={
                <img
                  className="object-cover w-full h-full rounded-full"
                  src={
                    auth.person?.personImage !== null
                      ? auth.person?.personImage?.file?.original
                      : getBrandPlaceholder("userProfile")
                  }
                  alt="User Profile"
                />
              }
              className={`${isActive(`/profile/${auth.person.personId}/person`)
                  ? "bg-primaryExtraLight text-textLink dark:text-textLink font-semibold"
                  : ""
              } hover:bg-primaryExtraLight hover:text-textLink dark:text-inputText dark:hover:text-textLink`}
            >
              {auth.person?.firstName} {auth.person?.lastName}
            </MenuItem>

            <>{hideButton && <SidebarDropdown className="" />}</>

            {routesForEndDataViewers.map((route, index) =>
              renderMenuItem(route, index)
            )}
            {/* Other menu items can be added here */}
            <MenuItem
              active={true}
              onClick={() => {
                dispatch(openSignOutModal());
              }}
              icon={<LogoutIcon fill="#2C3236" width={24} height={24} />}
              className=" hover:bg-primaryExtraLight hover:text-textLink dark:text-inputText dark:hover:text-textLink"
            >
              Sign Out
            </MenuItem>
            {!isCollapsed && (
              <MenuItem
                icon={<MoonIcon fill="#2C3236" />}
                suffix={
                  <CustomizedSwitches
                    label=""
                    checked={isDarkMode}
                    onChange={() => dispatch(toggleDarkMode())}
                    sx={{
                      "&.MuiFormControlLabel-root": {
                        marginRight: 0,
                      },
                      "& .MuiSwitch-root": {
                        margin: 0,
                      },
                      "& .MuiTypography-root": {
                        display: "none",
                      },
                    }}
                  />
                }
                className=" hover:bg-primaryExtraLight hover:text-textLink dark:text-inputText dark:hover:text-textLink"
              >
                Dark Theme
              </MenuItem>
            )}
          </Menu>
        </Sidebar>
      ) : (
        <Sidebar
          collapsed={isCollapsed}
          backgroundColor={`${isDarkMode && "#21262D"}`}
          width="235px"
        >
          <Menu closeOnClick={true}>
            <div
              className={`w-full mt-2 ${isCollapsed ? "flex-col" : "flex justify-between"
              }`}
            >
              <div
                className={`${isCollapsed ? "flex-col" : " flex justify-between "}`}
              >
                <MenuItem
                  onClick={handleToggleSidebar}
                  icon={
                    <Bars3Icon
                      width={28}
                      height={28}
                      className="dark:stroke-caption"
                    />
                  }
                />
                {!isExternalReviewer() && (
                  <MenuItem
                    component={<Link to="/" />}
                    icon={
                      theme?.logo && isThemeLoaded ? (
                        <img
                          src={theme?.logo?.file?.thumb}
                          alt={`${theme?.brandName} Logo`}
                          className="w-[30px] h-[30px] object-contain"
                        />
                      ) : (
                        isThemeLoaded && <DefaultLogo width={30} height={30} />
                      )
                    }
                  />
                )}
              </div>
              <div className="relative">
                {!isExternalReviewer() &&
                  notifications.canViewNotifications && (
                    <>
                      <div className="">
                        <MenuItem
                          onClick={() =>
                            dispatch(openNotificationsCenterModalAction())
                          }
                          icon={
                            <BellIcon
                              width={27}
                              height={27}
                              fill="#2C3236"
                              className=""
                            />
                          }
                        />
                        {data.unreadCount > 0 && (
                          <div className="absolute w-2 h-2 rounded-full bg-primaryMidLight top-3 left-9"></div>
                        )}
                      </div>
                    </>
                  )}
              </div>
            </div>

            {!isExternalReviewer() &&
              (users.canViewUsersProfile ? (
                <MenuItem
                  component={
                    <Link to={`/profile/${auth.person.personId}/person`} />
                  }
                  icon={
                    <img
                      className="object-cover w-full h-full rounded-full"
                      src={
                        auth.person?.personImage !== null
                          ? auth.person?.personImage?.file?.original
                          : getBrandPlaceholder("userProfile")
                      }
                      alt="User Profile"
                    />
                  }
                  className={`${isActive(`/profile/${auth.person.personId}/person`)
                      ? "bg-primaryExtraLight text-textLink dark:text-textLink font-semibold"
                      : ""
                  } hover:bg-primaryExtraLight hover:text-textLink dark:text-inputText dark:hover:text-textLink`}
                >
                  {auth.person?.firstName} {auth.person?.lastName}
                </MenuItem>
              ) : (
                <MenuItem
                  icon={
                    <img
                      className="object-cover w-full h-full rounded-full"
                      src={
                        auth.person?.personImage !== null
                          ? auth.person?.personImage?.file?.original
                          : getBrandPlaceholder("userProfile")
                      }
                      alt="User Profile"
                    />
                  }
                  className={`${isActive(`/profile/${auth.person.personId}/person`)
                      ? "bg-primaryExtraLight text-textLink dark:text-textLink font-semibold"
                      : ""
                  } hover:bg-primaryExtraLight hover:text-textLink dark:text-inputText dark:hover:text-textLink`}
                >
                  {auth.person?.firstName} {auth.person?.lastName}
                </MenuItem>
              ))}

            {!isExternalReviewer() && !isCollapsed && (
              <div className="w-full px-3">
                <GlobalSearch />
              </div>
            )}

            {!isExternalReviewer() && !isCollapsed && (
              <>
                {!hideButton ? (
                  <div
                    onClick={handleHideButton}
                    ref={inputRef}
                    className="px-3 my-2"
                  >
                    <MenuItem
                      icon={<Plus2Icon width={24} height={24} fill="white" />}
                      className=" text-textWhite  dark:text-inputText text-[15px] font-semibold rounded-full bg-primary hover:bg-primaryMid "
                      suffix={
                        <CheveronRightIcon
                          width={24}
                          height={24}
                          fill="white"
                        />
                      }
                      rootStyles={{
                        [".ps-menu-button"]: {
                          height: "42px !important",
                        },
                        [".ps-menu-button:hover"]: {
                          backgroundColor: "unset !important",
                        },
                      }}
                    >
                      Create New
                    </MenuItem>
                  </div>
                ) : (
                  <SidebarDropdown className="" />
                )}
              </>
            )}

            {routes.map((route, index: number) => renderMenuItem(route, index))}

            <MenuItem
              icon={<PrivacyPolicyIcon fill="#2C3236" width={24} height={24} />}
              onClick={() =>
                window.open(
                  "https://www.sentinelsnetwork.com/privacy-policy",
                  "_blank"
                )
              }
              className={`${isActive("/terms-and-conditions")
                  ? "bg-primaryExtraLight text-textLink dark:text-textLink font-semibold"
                  : ""
              } hover:bg-primaryExtraLight hover:text-textLink dark:text-inputText dark:hover:text-textLink`}
              title="Terms and Conditions"
            >
              Terms and Conditions
            </MenuItem>

            <MenuItem
              icon={
                <QuestionMarkCircleIcon fill="#2C3236" width={24} height={24} />
              }
              onClick={() =>
                window.open(isExternalReviewer() ? "https://confluence.web.app/i/lyzM/ER/" : "https://adminhelpsupport.wikipage.io/", "_blank")
              }
              className={`${isActive("/help-and-support")
                  ? "bg-primaryExtraLight text-textLink dark:text-textLink font-semibold"
                  : ""
              } hover:bg-primaryExtraLight hover:text-textLink dark:text-inputText dark:hover:text-textLink`}
            >
              Help and Support
            </MenuItem>
            <MenuItem
              active={true}
              onClick={() => {
                dispatch(openSignOutModal());
              }}
              icon={<LogoutIcon fill="#2C3236" width={24} height={24} />}
              className=" hover:bg-primaryExtraLight hover:text-textLink dark:text-inputText dark:hover:text-textLink"
            >
              Sign Out
            </MenuItem>
            {!isCollapsed && (
              <MenuItem
                icon={<MoonIcon fill="#2C3236" />}
                suffix={
                  <CustomizedSwitches
                    label=""
                    checked={isDarkMode}
                    onChange={() => dispatch(toggleDarkMode())}
                    sx={{
                      "&.MuiFormControlLabel-root": {
                        marginRight: 0,
                      },
                      "& .MuiSwitch-root": {
                        margin: 0,
                      },
                      "& .MuiTypography-root": {
                        display: "none",
                      },
                    }}
                  />
                }
                className=" hover:bg-primaryExtraLight hover:text-textLink dark:text-inputText dark:hover:text-textLink"
              >
                Dark Theme
              </MenuItem>
            )}
          </Menu>
        </Sidebar>
      )}
    </>
  );
};

export default SideBar;
