import { Header } from "../Header";
import { Form, Formik } from "formik";
import Roles from "hooks/roles";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { RootState } from "store";
import { getUserProfileAction } from "store/userProfile";
import { UserProfileState } from "store/userProfile/initialState";
import { Toasts } from "view/components/Toasts";
import * as Yup from "yup";
import { ChangeContactPreferences } from "../ChangeContactPreferences";
import { ContactPreference } from "../ContactPreference";
import { LinkedSocialAccounts } from "../LinkedSocialAccounts";
import { AccountDeletionRequest } from "../AccountDeletionRequest";
import { CreateAnAccountBtn } from "../CreateAnAccountBtn";
import { FormFields } from "../FormFields";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import { InviteToISN } from "../InviteToISN";
import usePermissions from "hooks/usePermissions";
import { UsedApps } from "../UsedApps";
import { getBrandName, getCurrentBranding } from "utils/customBranding";
import apiClient from "services/apiClient";

interface IProps {
  personId?: any;
  editMode?: boolean;
  setEditMode: any;
  AccountCreated: any;
}
export const NewAccountSetup: React.FC<IProps> = ({
  personId,
  editMode = false,
  setEditMode,
  AccountCreated,
}) => {
  const [isDivActive, setIsDivActive] = useState(false);
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  const { profile, users } = usePermissions();
  const dispatch = useDispatch();

  /**
   * Handles the process of changing the primary email or phone number.
   * @param {InitialValues} values - set the values according to the user's given data
   */

  const settingInitialValues = (userProfile: any) => {
    return {
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      email: userProfile.secondaryEmail.find((email: any) => email.isPrimary)
        ?.email,
      phoneNumber:
        userProfile.secondaryPhoneNumber.find(
          (phoneNumber: any) => phoneNumber.isPrimary
        )?.phoneNumber || "",
      contactPreference: userProfile.contactPreference
        ? userProfile.contactPreference
        : "",
    };
  };

  const initialValues = settingInitialValues(userProfile);

  const userProfileSchema = Yup.object().shape({
    contactPreference: Yup.string().optional(),
    firstName: Yup.string()
      .required("First name is required")
      .max(256, "First name must be at most 256 characters"),
    lastName: Yup.string()
      .required("Last name is required")
      .max(256, "Last name must be at most 256 characters"),
  });

  /**
   * Handles form submission to submit the person data.
   *
   * @param {FormValues} values - The form values containing user input.
   * @param {Object} options - Options object containing setSubmitting function.
   * @param {Function} options.setSubmitting - A function to set the submitting state.
   */

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    if (!users.canEditPersonProfile && editMode) {
      return;
    }
    dispatch(setLoadingProgress(getRandomProgress()));

    try {
      const formValues = {
        ...values,
      };
      const { data, message } = await apiLibrary.userProfile.updateUserProfile(
        // Call an API to update the userProfile
        userProfile.personId,
        formValues
      );
      Toasts.success(message);
      dispatch(getUserProfileAction(data));
      setEditMode(false);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message;
      errorMsg && Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
      dispatch(setLoadingProgress(100));
    }
  };

  /**
   * Handler to open the edit mode
   */
  const editAccountHandler = () => {
    setEditMode(!editMode);
    setIsDivActive(!isDivActive);
  };

  return (
    <div className="w-full border-t-0 border-b-0 border-l border-r-0 dark:bg-secondaryLight dark:border-lineLight border-lineMid">
      <div className="flex flex-col items-start self-stretch justify-start flex-grow w-full pb-8 overflow-hidden">
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full overflow-hidden">
          <Formik
            initialValues={initialValues}
            validationSchema={userProfileSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              resetForm,
              isSubmitting,
            }) => (
              <Form className="w-full">
                <Header
                  AccountCreated={AccountCreated}
                  title="Account Info"
                  editMode={editMode}
                  handleEdit={editAccountHandler}
                  resetForm={resetForm}
                  isSubmitting={isSubmitting}
                />
                {AccountCreated ? (
                  <div className="w-full ">
                    <FormFields
                      disabled={isSubmitting}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      editMode={editMode}
                      userProfile={userProfile}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                    />

                    {editMode ? (
                      <RenderEditModeContent
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    ) : (
                      <RenderNonEditModeContent />
                    )}
                  </div>
                ) : (
                  <CreateAnAccountBtn
                    helperText={"An account is not created yet. "}
                  />
                )}
                <UsedApps />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

interface RenderEditModeContentProps {
  values: {
    contactPreference: string; // Adjust the type if needed
  };
  setFieldValue: (field: string, value: any) => void; // Adjust the type if needed
}
const RenderEditModeContent: React.FC<RenderEditModeContentProps> = ({
  values,
  setFieldValue,
}) => {
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-3 px-8 border-t-0 border-b border-l-0 border-r-0 xl:px-12 border-lineMid">
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pr-3 rounded-lg">
          <div className="flex items-center justify-start flex-grow">
            <div className="relative flex items-center justify-start flex-grow">
              <p className="flex-grow text-base font-semibold text-left text-secondaryMid dark:text-textMain">
                Contact Preferencess
              </p>
            </div>
          </div>
        </div>
        <ChangeContactPreferences
          setFieldValue={setFieldValue}
          values={values}
        />
      </div>
    </div>
  );
};

const RenderNonEditModeContent = () => {
  const {
    isMyProfile,
    isSuperAdmin,
    isExternalDataEndReviewer,
    isExternalReviewer,
  } = Roles();

  const { personId } = useParams();
  const { deleteAccountRequest } = usePermissions();
  const [allApps, setAllApps] = useState<any>();
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  const getBrandsData = async (searchString: string = "") => {
    try {
      const resultData = getBrandName("brands", searchString);
      const { data } = await apiClient.get(resultData.url);

      if (data?.data?.brands) {
        const { brands } = data.data; // Destructure brands from the data
        const usedAppIds =
          userProfile?.usedApps?.map((item: any) => item.id) || []; // Create an array of used app IDs

        const appList = brands
          .map((item: any) => ({
            value: item.appId,
            label: item.name,
          }))
          .filter(
            (app: any) =>
              !usedAppIds.includes(app.value) && app.value !== "skipper_science"
          ); // Check using includes (O(n) for each filter item)
        const allApp = [...appList, { value: "isn", label: "ISN" }];
        setAllApps(allApp);
      }
    } catch (error) {
      console.error("Error fetching brand data:", error); // Log any errors for easier debugging
    }
  };
  // Filter apps where app.id includes "_admin"
  const usedApps: String[] = userProfile.usedApps.map((app: any) =>
    app.id.replace("_admin", "")
  );
  const uniqueApps = [...new Set(usedApps)];

  const hasAllIds = allApps?.every((app: any) => uniqueApps.includes(app));
  return (
    <>
      <ContactPreference />
      {isMyProfile(personId) && (
        <>
          <LinkedSocialAccounts />
          {deleteAccountRequest.canDeleteAccountRequest && (
            <AccountDeletionRequest />
          )}
        </>
      )}

      {!hasAllIds &&
        isSuperAdmin() &&
        !isMyProfile(personId) &&
        !isExternalDataEndReviewer() &&
        !isExternalReviewer() && <InviteToISN />}
    </>
  );
};
